import CTPLWizard from "../../organisms/WizardCTPL";
import CTPLCarDetails from "./car-details";
import CTPLOwnerDetails from "./owner-details";
import CTPLFormSummary from "./summary";
import { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import Benefits from "../Motor-Benefits/Benefits";
import { motorAPI, basePath, motorCtplAPI } from "../../../api/fetch-api";
import { Cache } from "aws-amplify";
import { Loading } from "../../atoms/loading";
import Modal from "../../molecules/Modal";

function CTPLFormPage(props) {
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubPage, setActiveSubPage] = useState(1);
  const [activeMain, setActiveMain] = useState(1);
  const [isLoading, setIsLoading] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerSubTitle, setSubHeaderTitle] = useState("");
  const [buttonTitle, setButtonTitle] = useState("Next");

  const [plateNumberState, setPlateNumberState] = useState([]);
  const [carDetailsState, setCarDetailsState] = useState([]);
  const [quoteState, setQuoteState] = useState({});
  const [carDisplay, setCarDisplay] = useState("");
  const [otherDetailsState, setOtherDetailsState] = useState([]);
  const [clientDetailsState, setClientDetailsState] = useState([]);
  const [clientTypeState, setClientTypeState] = useState(1);
  const [editContactDetailsFlag, setEditContactDetailsFlag] = useState(false)
  const [accountId, setAccountId] = useState("")
  const [ctplMotorResponse, setCtplMotorResponse] = useState([])
  const [productState, setProductState] = useState("");
  //Main USEEFFECT
   useEffect(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      let customerId = params.get("customerId");
      setAccountId(customerId);

      setCarDetailsState({
        year: "",
        make: "",
        model: "",
        modelid: "",
        engineType: null,
        fmv: null,
        bodyType: null,
        seats: null,
        color: null,
        carDisplay: null,
        startDate: "",
        endDate: "",
        insuranceAccountId: accountId
      });

      setClientDetailsState({
        address1: null,
        address2: null,
        addressType: null,
        bday: null,
        branchCode: "SI", // Set to standard "FO" value
        clientType:"I",
        contactNumber: null,
        contactType: "M",
        email: null,
        firstName: null,
        fullName: null,
        gender: null,
        idNo: null,
        idType: null,
        lastName: null,
        middleName: null,
        nationality: null,
        postalCode: "",
        province: "",
      });

      
      let cardetails = Cache.getItem("carDetailsState")
      let clientdetails = Cache.getItem("clientDetailsState")
      if(cardetails !== null && clientdetails !== null){
        if((cardetails.plateNumber !== undefined) && 
         (clientdetails.fullName !== null)
        ){
          setCarDetailsState(Cache.getItem("carDetailsState"))
          setClientDetailsState(Cache.getItem("clientDetailsState"))
          setActiveStep(2);
        }
      }
      
  }, []);

  const SectionState = () => {
    if (activeStep === 0) {
      props.sendGTM("/ctpl/form?form=CTPLMotorDetails","MotorDetails","CTPL","CTPL_GInsure1");
      setHeaderTitle("Motor Details");
      setButtonTitle("Next");
      return <CTPLCarDetails saveData={FormLogic} carDetails={carDetailsState} accountId={accountId}  productState={saveProduct} product={productState}/>;
    } else if (activeStep === 1) {
      props.sendGTM("/ctpl/form?form=CTPLOwnerDetails","OwnerDetails","CTPL","CTPL_GInsure2");
      setHeaderTitle("Owner Details");
      setButtonTitle("Next");
      return (
        <CTPLOwnerDetails
          saveData={FormLogic}
          carDetails={carDetailsState}
          clientDetailsState={clientDetailsState}
          clientTypeState={clientTypeState}
          setClientType={setClientType}
          editContactDetailsFlag={editContactDetailsFlag}
          accountId={accountId}
        />
      );
    } else if (activeStep === 2) {
      props.sendGTM("/ctpl/form?form=CTPLSummaryDetails","SummaryDetails","CTPL","CTPL_GInsure3");
      setHeaderTitle("Summary");
      setButtonTitle("Continue");
      return (
        <CTPLFormSummary
          saveData={FormLogic}
          backtoStart={BacktoStart}
          plateNumber={plateNumberState}
          carDetails={carDetailsState}
          carDisplay={carDisplay}
          clientDetail={clientDetailsState}
          otherDetails={otherDetailsState}
          editCarDetails={editCarDetails}
          editOwnerDetails={editOwnerDetails}
          editContactDetails={editContactDetails}
          
          
        />
      );
    } else {
      return <></>;
    }
  };

  const SubmitForm = () => {
    //API
    document.getElementById("btnSubmit").click();
  };

  //date formatter
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const saveProduct = (data) => {
    setProductState(data);
  }
  const FormLogic = (data,procedure) => {
    if (activeStep === 0) {
      
      nextStep();
      setCarDetailsState(data)
    } else if (activeStep === 1) {
      setClientDetailsState(data);
      nextStep();
      if(typeof procedure==="undefined"){
        //
      }
    }else if (activeStep === 2) {
      if(data.isError === false){
        nextStep();
      }
      
      
    }else if(activeStep === 3) {
      //
    }else if (activeStep === 4){
      nextStep();
    }
  };

  const setClientType = (data)=>{
    setClientTypeState(data);
  }

  const SetSubPage = (page) => {
    setActiveSubPage(1);
    setActiveMain(0);
  };
  const SubSectionState = () => {
    if (activeSubPage === 1) {
      setHeaderTitle("GCash x Standard Insurance");
      setSubHeaderTitle("Benefits");
      return <Benefits />;
    }
  };
  const backtoMain = () => {
    setActiveMain(1);
  };

  const nextStep = () => {
    let flag = true;
    if (activeStep === 2) {
      flag = false;
    }
    Cache.setItem("carDetailsState", carDetailsState);
    Cache.setItem("quoteState", quoteState);
    Cache.setItem("carDisplay", carDisplay);
    Cache.setItem("clientDetailsState", clientDetailsState);
    Cache.setItem("product", productState)
    if (flag) {
      setActiveStep(activeStep + 1);
      setIsLoading(1);
      setTimeout(() => {
        if (activeStep !== 4) {
          setIsLoading(0);
        }
      }, 1000);
    } else {
      setIsLoading(1);
      setActiveStep(3)
      setTimeout(() => {
        props.sendGTM("/ctpl/review","ReviewDetails","CTPL","CTPL_GInsure4");
        history.push("/ctpl/review");
      }, 1000);
    }
  };
  const backStep = () => {
    let flag = true;

    if (flag) {
      if (activeStep === 0) {
        history.goBack();
      }
      setActiveStep(activeStep - 1);
      setIsLoading(1);
      setTimeout(() => {
        setIsLoading(0);
      }, 1000);
    }
  };

  const editCarDetails = () => {
    setActiveStep(0);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };

  const editOwnerDetails = () => {
    setActiveStep(1);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };

  const editContactDetails = () => {
    setActiveStep(1);
    setEditContactDetailsFlag(true)
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const BacktoStart = () => {
    setActiveStep(0);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const [open, setOpen] = useState(false);
  return (
    <div>
      {/* Main Pages */}
      {activeMain === 1 && (
        <>
          <Header headerTitle={headerTitle} saveData={backStep} />

          <div className={props.main === "main" ? "main p-5" : "p-5"}>
            <img
              src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
              width="170"
              height="50"
              alt="logo"
            />
          </div>
          <main className={props.main === "main" ? "main p-5" : "p-5"}>
            {activeStep != 3 && (
              <CTPLWizard activeStep={activeStep} />
            )}
            {(!isLoading) && <SectionState />}
            {!isLoading || <Loading />}
          </main>
          <Modal open={open} setOpen={setOpen} title="">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 icon-center text-red-700"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="text-center my-5">
            Your vehicle is already insured with Standard Insurance.
          </p>
        </Modal>
          <Footer
            buttonType="button"
            saveData={SubmitForm}
            isLoading={isLoading}
            buttonTitle={buttonTitle}
          />
        </>
      )}
      {/* Sub Pages */}
      {activeMain !== 1 && (
        <>
          <div>
            <Header
              headerTitle={props.headerTitle}
              subHeader={headerSubTitle}
              subPage={"1"}
              saveData={backtoMain}
            />
            <div
              className="absolute w-full"
              // style={{
              //   backgroundImage: `url("/images/bg.png")`,
              //   backgroundRepeat: "no-repeat",
              //   width: "250px",
              // }}
            >
              <img alt="motorcar" src={"/images/bg.png"} className="image" />
            </div>
            <div className="p-5 pt-12">
              <img
                src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
                width="170"
                height="50"
                alt="logo"
              />
            </div>
            <main className="p-5">
              <div className="">
                <SubSectionState />
              </div>
            </main>
          </div>
        </>
      )}
    </div>
  );
}
export default CTPLFormPage;
