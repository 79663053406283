import { useEffect } from "react";

export default function ExclusionList() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <>
      <div className="text-left">
        <div className="mb-5">
          <h3 className="text-lg font-bold leading-tight">Exclusions</h3>
        </div>

        <div>
          <ol className="list-decimal text-xs text-gray-500 px-3">
            <li className="pb-3">
              Loss or damage in respect of any claim or series of claims arising
              out of one event, the first amount of each and every loss for each
              and every vehicle insured by this Policy, such amount being equal
              to the deductible.
            </li>
            <li className="pb-3">
              Damage to tires, unless the Schedule Vehicle is damaged at the
              same time;
            </li>
            <li className="pb-3">
              Any malicious damage caused by the Insured, any member of his
              family or by a person in the Insured’s service;
            </li>
            <li className="pb-3">
              Loss of, or damage, to accessories or spare parts by burglary,
              housebreaking, or theft, unless the schedule vehicle is stolen at
              the same time.
            </li>
            <li className="pb-3">
              Any Accident, or liability caused, or incurred
              <ol className="list-disc pl-5">
                <li>Outside the Republic of the Philippines.</li>
                <li>
                  Whilst any MOTOR VEHICLE in respect of which indemnity is
                  provided by the Policy is:
                  <ol className="list-decimal pl-5">
                    <li>
                      being used otherwise than in accordance with the
                      limitations as to use;
                    </li>
                    <li>
                      being driven by any person other than an Authorized
                      Driver.
                    </li>
                    <li>on board a sea vessel on inter-island transit;</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="pb-3">
              Any liability which attached by virtue of an agreement but to
              which would not have attached in the absence of such agreement,
              except liability arising out of an on spot agreement or amicable
              settlement of minor accident to avoid impairing the flow or
              traffic.
            </li>
            <li className="pb-3">
              Except in respect of claims arising under Sections I (Liability to
              the Public) and II (No Fault Indemnity) of this policy, any
              accident, loss, damage or liability directly or indirectly,
              proximately or remotely occasioned by, contributed to by or
              traceable to, or arising out of, or in connection with invasion,
              the act of foreign enemies, hostilities or warlike operations
              (whether war be declared or not), mutiny, rebellion, insurrection,
              military or usurped power, or by any direct or indirect
              consequences of any of the said and was in no way connected with
              or occasioned by, or contributed to, any of the said occurrences,
              or any consequences thereof, and in default of such proof, the
              Company shall not liable to make any payment in respect of such a
              claim.
            </li>
            <li className="pb-3">
              Any sum which the Insured would have been entitled to recover from
              any part but for an agreement between the Insured and such party.
            </li>
            <li className="pb-3">
              Bodily injury and/or death to pay person in the employ of the
              Insured arising out of and in the course of such employment, of
              bodily injury and/or death to any member of the Insured’s
              household who is riding in the Schedule Vehicle.
            </li>
          </ol>
        </div>
        {/* <Accordion questionsAnswers={questionsAnswers}/> */}
      </div>
    </>
  );
}
