import {Link} from "react-router-dom";
;
function Button(props) {
    if(!props.isDisabled)
    return (
    <Link  to={(props.link!==undefined)? props.link:"#"}
    >
        <button type="button" className="bg-blue-600 text-white py-3 w-10/12 rounded-full manage-policy-font">
            {props.buttonTitle!==undefined && (props.buttonTitle)}
            {(props.buttonTitle===undefined && props.buttonType===undefined) && ("NEXT")}
            {(props.buttonType!==undefined && props.buttonTitle===undefined) && ("NEXT")}
            {props.children}
        </button>
    </Link>)
    if(props.isDisabled)
    return (
        <button type="button" className="bg-blue-600 text-white py-3 w-10/12 rounded-full opacity-50 manage-policy-font" disabled>
        {props.buttonTitle!==undefined && (props.buttonTitle)}
        {(props.buttonTitle===undefined && props.buttonType===undefined) && ("NEXT")}
        {(props.buttonType!==undefined && props.buttonTitle===undefined) && ("NEXT")}
        {props.children}
    </button>)
}

export default Button;