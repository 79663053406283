import { useState, useEffect, useRef } from "react";
import WizardCTPL from "../../organisms/WizardCTPL";
import Header from "../../organisms/Header";
import Modal from "../../molecules/Modal";
import { Cache } from "aws-amplify";
import { Loading } from "../../atoms/loading";
import { cityPostalAPI, validationAPI } from "../../../api/fetch-api";
import CarDetails from "../Motor-Insurance/Car-Details";
import { formatContact, validateEmail } from "../../utils/helper";

function CTPLOwnerDetails(props) {
  // const clientType = props.clientTypeState;

  const [loading, setLoading] = useState(true);
  const ownerDetails = props.clientDetailsState;
  const clientType = ownerDetails.clientType;
  const [ownerDetailState, setownerDetailState] = useState(ownerDetails);
  const [cityProvince, setCityProvince] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [loadingCityProvince, setloadingCityProvince] = useState(true);
  const [loadingPostalCode, setLoadingPostalCode] = useState(true);

  const [address1, setAddress1] = useState(ownerDetails.address1);
  const [address2, setAddress2] = useState(ownerDetails.address1);
  const [addressType, setAddressType] = useState(ownerDetails.addressType);
  const [bday, setBday] = useState(ownerDetails.bday);
  const [branchCode, setBranchCode] = useState(ownerDetails.branchCode);
  const [clientTypes, setClientTypes] = useState(clientType);
  const [contactNumber, setContactNumber] = useState(
    ownerDetails.contactNumber
  );
  const [contactType, setContactType] = useState(ownerDetails.contactType);
  const [email, setEmail] = useState(ownerDetails.email);
  const [firstName, setFirstName] = useState(ownerDetails.firstName);
  const [fullName, setFullName] = useState(ownerDetails.fullName);
  const [gender, setGender] = useState(ownerDetails.gender);
  const [idNo, setIdNo] = useState(ownerDetails.idNo);
  const [idType, setIdType] = useState(ownerDetails.idType);
  const [middleName, setMiddleName] = useState(ownerDetails.middleName);
  const [lastName, setLastName] = useState(ownerDetails.lastName);
  const [nationality, setNationality] = useState(ownerDetails.nationality);
  //const [postalCode, setpostalCode] = useState(clientType.postalCode);
  const [province, setProvince] = useState(ownerDetails.province);
  const [ginsureAccountId, setGinsureAccountId] = useState(
    ownerDetails.insuranceAccountId
  );

  const [returnSubmitValue, setReturnSubmitValue] = useState(false);

  const goToContactDetails = useRef(null);

  const addressTypes = [
    { code: "H", name: "Home" },
    { code: "O", name: "Office" },
    // { code: "B", name: "Business" },
  ];

  const idTypes = [
    { code: "AC", name: "ACR Identity Card (I-Card)" },
    { code: "SS", name: "Social Security System ID / UMID" },
    { code: "TI", name: "TIN ID" },
    { code: "CI", name: "Consular ID" },
    { code: "DL", name: "DRIVER'S LICENSE" },
    { code: "DV", name: "Digitized Voter's ID" },
    { code: "GS", name: "Government Service Insurance System (GSIS)" },
    {
      code: "OS",
      name: "Office of Senior Citizen's Affairs (OSCA) / Senior Citizen's Card",
    },
    { code: "OF", name: "OFW ID" },
    { code: "OW", name: "Overseas Worker's Welfare Administration (OWWA)" },
    { code: "PS", name: "Passport" },
    { code: "PC", name: "Philhealth Card" },
    { code: "PO", name: "Philippine Overseas Employment Association (POEA)" },
    { code: "PI", name: "Postal ID" },
    { code: "PR", name: "Professional Regulation (PRC) ID" },
    { code: "SI", name: "Student's ID" },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 400);

    // console.log(Cache.getItem("carDetailsState"))
    // console.log("ginsureAccountId", props.accountId, ownerDetails.clientType, clientType === 1, clientType)
    setownerDetailState({
      ...ownerDetailState,
      ginsureAccountId: props.accountId,
      clientType: clientType,
    });
    // if(ownerDetails.clientType !== null) {
    //   SetClientType(ownerDetails.clientType)
    // }
  }, []);

  const SaveForm = async (e) => {
    // console.log("firstName", ownerDetailState.firstName)
    // setFullName(ownerDetailState.firstName+" "+ownerDetailState.middleName+". "+ownerDetailState.lastName);
    // setownerDetailState({
    //   ...ownerDetailState,
    //   fullName: fullName
    // });
    e.preventDefault();
    const ownerDetailsData = {
      address1: address1,
      address2: address2,
      addressType: addressType,
      bday: bday,
      branchCode: "FO", // Set to standard "FO" value
      clientType: clientTypes,
      contactNumber: contactNumber,
      contactType: contactType,
      email: email,
      firstName: firstName,
      fullName: fullName,
      gender: gender,
      idNo: idNo,
      idType: idType,
      lastName: lastName,
      middleName: middleName,
      nationality: nationality,
      postalCode: postalCode,
      province: province,
    };
    if (clientTypes == "I") {
      ownerDetailState.fullName =
        ownerDetailState.firstName +
        " " +
        ownerDetailState.middleName +
        ". " +
        ownerDetailState.lastName;
    }

    //props.saveData(ownerDetailState);

    const forceEmailhtml = e.target.querySelector('input[name="email"]');
    const forceMobilehtml = e.target.querySelector(
      'input[name="contactNumber"]'
    );

    await validateEmailViaAPI(forceEmailhtml, true);
    await validateMobileViaAPI(forceMobilehtml, true);

    const getCTPLReturnValue = Cache.getItem("ctplReturnSubmit");
    const getCTPLReturnValueMobile = Cache.getItem("ctplReturnSubmitMobile");

    var getReturnSubmitAgent = Cache.getItem("ctplReturnSubmitAgent");
    var getReturnSubmitAgentMobile = Cache.getItem(
      "ctplReturnSubmitAgentMobile"
    );

    if (!getCTPLReturnValue && !getCTPLReturnValueMobile) {
      props.saveData(ownerDetailState);
    } else if (!getReturnSubmitAgent && !getReturnSubmitAgentMobile) {
      alert(
        "Email Address and Contact Number match with our agent records.Please provide a new Email Address and Contact Number."
      );
      return false;
    }
    //validateEmailAPI(email);
  };

  const SetClientType = (data) => {
    //props.setClientType(data);
    setClientTypes(data);
    setTimeout(() => {
      setLoading(true);
    }, 200);
    setTimeout(() => {
      setLoading(false);
    }, 400);
  };
  useEffect(() => {
    setownerDetailState({
      ...ownerDetailState,
      clientType: clientTypes,
    });
    // console.log("update ownerdetailstate",ownerDetailState )
  }, [clientTypes]);
  const handleBlur = (e) => {
    if (e.target.name === "middleName") {
      if (e.target.value === "") {
        document.getElementsByName("middleName")[0].value = ".";
      } else if (!/[a-zA-Z]{1,}/i.test(e.target.value)) {
        document.getElementsByName("middleName")[0].value = ".";
      }
    }
  };
  const handleChange = (e) => {
    let fullname_ = "";
    if (clientTypes == "I") {
      if (e.target.name === "firstName") {
        document.getElementsByName("firstName")[0].value =
          e.target.value.match(/([a-zA-Z_ ]{1,})/i)[0];
        setFirstName(e.target.value);
      }
      if (e.target.name === "middleName") {
        // let input = e.
        //console.log("regex", e.target.value.match(/([a-zA-Z]{1,})/i)[0])
        //console.log("regex", /([a-zA-Z]{1,2})/i.test(e.target.value))
        if (/[a-zA-Z]{1,}/i.test(e.target.value)) {
          // console.log("regex", e.target.value.match(/([a-zA-Z]{1,})/i)[0])
          document.getElementsByName("middleName")[0].value =
            e.target.value.match(/([a-zA-Z_ ]{1,})/i)[0];
          setMiddleName(e.target.value.match(/([a-zA-Z]{1,})/i)[0]);
        } else {
          setMiddleName(".");
        }
      }
      if (e.target.name === "lastName") {
        document.getElementsByName("lastName")[0].value =
          e.target.value.match(/([a-zA-Z_ ]{1,})/i)[0];
        setLastName(e.target.value);
      }
      fullname_ = firstName + middleName + " " + lastName;
      setFullName(fullname_);
    } else if (e.target.name === "fullName") {
      // console.log("FULLNAME ONCHANGE")
      fullname_ = e.target.value;
      setFullName(fullname_);
    }
    // console.log(fullname_)
    setownerDetailState({
      ...ownerDetailState,
      [e.target.name]: e.target.value,
      clientType: clientTypes,
      ginsureAccountId: props.accountId,
      //fullName: fullName
    });

    let objName = e.target.name;
    if (objName === "province") {
      getPostalCode(e.target.value);
    }
  };

  const getPostalCode = (data) => {
    cityPostalAPI
      .getPostal(data)
      .then((data) => {
        // console.log("city",data)
        setLoadingPostalCode(false);

        const postalCodeList = data;
        const postalCodes = [
          ...new Set(
            postalCodeList
              .map((m) => m)
              .sort((a, b) => a.name.localeCompare(b.name))
          ),
        ];
        // console.log("postalcodes", postalCodes);
        setPostalCode(postalCodes);

        if (
          ownerDetails.postalCode !== null ||
          ownerDetails.postalCode !== undefined
        ) {
          document.getElementsByName("postalCode")[0].value =
            ownerDetails.postalCode;
        }
      })
      .catch((err) => console.log("FMV Error: ", err));
  };
  useEffect(() => {
    cityPostalAPI
      .getCity()
      .then((data) => {
        // console.log("city",data)
        setloadingCityProvince(false);
        setCityProvince(data);

        if (
          ownerDetails.province !== null ||
          ownerDetails.province !== undefined ||
          ownerDetails.province !== ""
        ) {
          document.getElementsByName("province")[0].value =
            ownerDetails.province;
          if (
            ownerDetails.postalCode !== null ||
            ownerDetails.postalCode !== undefined
          ) {
            if (
              typeof ownerDetails.province !== "undefined" &&
              ownerDetails.province
            ) {
              getPostalCode(ownerDetails.province);
            }

            document.getElementsByName("postalCode")[0].value =
              ownerDetails.postalCode;
          }
        }
      })
      .catch((err) => console.log("FMV Error: ", err));
  }, []);

  useEffect(() => {
    // // console.log(ownerDetailState);
  }, [ownerDetailState]);

  const scrolltoContactDetails = () =>
    goToContactDetails.current.scrollIntoView();

  useEffect(() => {
    if (props.editContactDetailsFlag == true) {
      scrolltoContactDetails();
    }
  });

  useState(() => {});

  //FUNCTIONS
  const validateEmailViaAPI = async (event, isForm) => {
    let emailInputs = isForm ? event : event.target;
    let emailValue = isForm ? event.value : emailInputs.value;

    Cache.setItem("ctplReturnSubmit", null);
    Cache.setItem("ctplReturnSubmitAgent", null);

    await validationAPI.getEmailValidation(emailValue).then((data) => {
      if (data.statusCode == "ISAGENT") {
        emailInputs.setCustomValidity(
          "Email Address matches with our agent records. Please provide a new email address."
        ); // Set the custom error message
        emailInputs.reportValidity();

        Cache.setItem("ctplReturnSubmit", true);
        Cache.setItem("ctplReturnSubmitAgent", true);
        return;

        return;
      } else if (
        data.statusCode == "UNDELIVERABLE" ||
        data.statusCode == "INVALID"
      ) {
        emailInputs.setCustomValidity(
          "Invalid email address format. Please provide a valid email address."
        ); // Set the custom error message
        emailInputs.reportValidity();
        Cache.setItem("ctplReturnSubmit", true);
        Cache.setItem("ctplReturnSubmitAgent", false);

        return;
      }

      emailInputs.setCustomValidity("");
      Cache.setItem("ctplReturnSubmit", false);
      Cache.setItem("ctplReturnSubmitAgent", false);
    });
  };

  const validateMobileViaAPI = async (event, isForm) => {
    let mobileInputs = isForm ? event : event.target;
    let mobileValue = isForm ? event.value : mobileInputs.value;

    Cache.setItem("ctplReturnSubmitMobile", null);
    Cache.setItem("ctplReturnSubmitAgentMobile", null);

    await validationAPI.getMobileValidation(mobileValue).then((data) => {
      if (data.statusCode == "ISAGENT") {
        mobileInputs.setCustomValidity(
          "Contact Number matches with our agent records. Please provide a new Mobile Number."
        ); // Set the custom error message
        mobileInputs.reportValidity();

        Cache.setItem("ctplReturnSubmitMobile", true);
        Cache.setItem("ctplReturnSubmitAgentMobile", true);

        return;
      } else if (data.statusCode == "INVALID") {
        mobileInputs.setCustomValidity(data.message); // Set the custom error message
        mobileInputs.reportValidity();

        Cache.setItem("ctplReturnSubmitMobile", true);
        Cache.setItem("ctplReturnSubmitAgentMobile", false);
        return;
      } else if (data.statusCode == "NONZERO") {
        mobileInputs.setCustomValidity(data.message); // Set the custom error message
        mobileInputs.reportValidity();

        Cache.setItem("ctplReturnSubmitMobile", true);
        Cache.setItem("ctplReturnSubmitAgentMobile", false);
        return;
      }

      mobileInputs.setCustomValidity("");
      Cache.setItem("ctplReturnSubmitMobile", false);
      Cache.setItem("ctplReturnSubmitAgentMobile", false);
      return;
    });
  };

  return (
    <>
      <main>
        <form onSubmit={SaveForm}>
          <div className="text-left mb-32">
            <div className="mb-5">
              <h3 className="text-lg font-bold">Please fill in your details</h3>
              <p>Is vehicle registered under Individual or Company?</p>
            </div>

            {!loading && (
              <div className="grid grid-cols-2 gap-4 my-5">
                <div
                  className={
                    clientTypes === "I"
                      ? `bg-blue-600 text-white px-5 py-3 rounded-lg text-center`
                      : `bg-white text-gray-500 px-5 py-3 rounded-lg text-center border border-blue-600 font-bold`
                  }
                >
                  <button
                    type="button"
                    onClick={() => {
                      SetClientType("I");
                    }}
                  >
                    <span className="font-bold">Individual</span>
                  </button>
                </div>
                <div
                  className={
                    clientTypes === "C"
                      ? `bg-blue-600 text-white px-5 py-3 rounded-lg text-center`
                      : `bg-white text-gray-500 px-5 py-3 rounded-lg text-center border border-blue-600 font-bold`
                  }
                >
                  <button
                    type="button"
                    onClick={() => {
                      SetClientType("C");
                    }}
                  >
                    <span className="font-bold">Company</span>
                  </button>
                </div>
              </div>
            )}

            <h3 className="mb-5 font-bold text-base">Owner Details</h3>
            {!loading && clientTypes === "I" && (
              <>
                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    First name
                  </label>
                  <div className="block relative w-full">
                    <input
                      type="text"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      name="firstName"
                      defaultValue={ownerDetails.firstName}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      maxLength="35"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Middle name
                  </label>
                  <div className="block relative w-full">
                    <input
                      type="text"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      name="middleName"
                      defaultValue={ownerDetails.middleName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter your middle name"
                      maxLength="35"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Last name
                  </label>
                  <div className="block relative w-full">
                    <input
                      type="text"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      name="lastName"
                      defaultValue={ownerDetails.lastName}
                      onChange={handleChange}
                      placeholder="Enter your last name"
                      maxLength="35"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Birthday
                  </label>
                  <div className="block relative w-full">
                    <input
                      type="date"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      name="bday"
                      onChange={handleChange}
                      placeholder="MM/DD/YYYY"
                      defaultValue={ownerDetails.bday}
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Nationality
                  </label>
                  <div className="block relative w-full">
                    <input
                      type="text"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      name="nationality"
                      defaultValue={ownerDetails.nationality}
                      onChange={handleChange}
                      placeholder="Enter your nationality"
                      maxLength="25"
                      required
                      //value={"FILIPINO"}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Gender:
                  </label>
                  <div className="block relative w-full">
                    <select
                      name="gender"
                      defaultValue={ownerDetails.gender}
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      required
                      onChange={handleChange}
                    >
                      <option value="">Select gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!loading && clientTypes === "C" && (
              <>
                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Company Name
                  </label>
                  <div className="block relative w-full">
                    <input
                      type="text"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      name="fullName"
                      defaultValue={ownerDetails.fullName}
                      onChange={handleChange}
                      placeholder="e.g. ABC Company Inc"
                      maxLength="35"
                      required
                    />
                  </div>
                </div>
              </>
            )}
            {loading && <Loading />}
            <h3
              className="mt-10 mb-5 text-base font-bold"
              ref={goToContactDetails}
            >
              Contact Details
            </h3>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Address Type
              </label>
              <div className="block relative w-full">
                <select
                  onChange={handleChange}
                  name="addressType"
                  defaultValue={ownerDetails.addressType}
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                >
                  <option value="">Select address type</option>
                  {addressTypes.map((page) => (
                    <option value={page.code} key={page.code}>
                      {page.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Address 1:
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="address1"
                  defaultValue={ownerDetails.address1}
                  onChange={handleChange}
                  placeholder="Enter your Address 1"
                  maxLength="100"
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Address 2:
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="address2"
                  defaultValue={ownerDetails.address2}
                  onChange={handleChange}
                  placeholder="Enter your Address 2"
                  maxLength="100"
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Province/City:
              </label>
              <div className="block relative w-full">
                {loadingCityProvince || (
                  <select
                    onChange={handleChange}
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    name="province"
                    defaultValue={ownerDetails.province}
                    required
                  >
                    <option value="">Select Province/City</option>
                    {cityProvince.map((page, index) => (
                      <option value={page.code} key={index}>
                        {page.name}
                      </option>
                    ))}
                  </select>
                )}
                {loadingCityProvince && (
                  <select
                    onChange={handleChange}
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    name="province"
                    defaultValue={ownerDetails.province}
                    required
                  >
                    <option value="">No Data</option>
                  </select>
                )}

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Postal Code:
              </label>
              <div className="block relative w-full">
                {loadingPostalCode || (
                  <select
                    onChange={handleChange}
                    name="postalCode"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    defaultValue={ownerDetails.postalCode}
                    required
                  >
                    <option value="">Select Postal Code</option>
                    {postalCode
                      .map((page, index) => (
                        <option value={page.code} key={index}>
                          {page.name}
                        </option>
                      ))
                      .sort()}
                  </select>
                )}
                {loadingPostalCode && (
                  <select
                    onChange={handleChange}
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    name="postalCode"
                    defaultValue={ownerDetails.postalCode}
                    required
                  >
                    <option value="">No Data</option>
                  </select>
                )}

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Contact Type:
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="contactType"
                  defaultValue={ownerDetails.contactType === "M" && "Mobile"}
                  onChange={handleChange}
                  placeholder="Mobile"
                  disabled
                  required
                />
                {/* <select
                  onChange={handleChange}
                  name="contactType"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  defaultValue={ownerDetails.contactType}
                  required
                >
                  <option value="">Select Contact Type</option>
                  {addressTypes.map((page) => (
                    <option value={page.code} key={page.code}>
                      {page.name}
                    </option>
                  ))}
                </select> */}
                {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div> */}
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Contact Number
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="contactNumber"
                  defaultValue={ownerDetails.contactNumber}
                  onChange={handleChange}
                  placeholder="Enter your contact number"
                  maxLength="11"
                  minLength="11"
                  onBlur={(e) => {
                    //formatContact(e, false);
                    validateMobileViaAPI(e, false);
                  }}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                ID Type:
              </label>
              <div className="block relative w-full">
                <select
                  onChange={handleChange}
                  name="idType"
                  defaultValue={ownerDetails.idType}
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                >
                  <option value="">Select ID Type</option>
                  {idTypes.map((page) => (
                    <option value={page.code} key={page.code}>
                      {page.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                ID Number
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  required
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="idNo"
                  defaultValue={ownerDetails.idNo}
                  onChange={handleChange}
                  placeholder="Enter your ID number"
                  maxLength="15"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Email Address
              </label>
              <div className="block relative w-full">
                <input
                  type="email"
                  required
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="email"
                  defaultValue={ownerDetails.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  onBlur={(e) => {
                    validateEmail(e, false);
                    validateEmailViaAPI(e, false);
                  }}
                  maxLength="60"
                />
              </div>
            </div>
          </div>
          <button type="submit" style={{ display: "none" }} id="btnSubmit">
            submit
          </button>
        </form>
      </main>
    </>
  );
}
export default CTPLOwnerDetails;
