import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Cache } from "aws-amplify";

import {
  travelAPI,
  travelLocalAPI,
  travelSaverAPI,
} from "../../../api/fetch-api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";
import styles from "../../../App.css"
import Header from "../../../components/organisms/Header";
import TravelBenefits from "../../../components/organisms/Travel-Insurance/Benefits";
import WizardCTPL from "../../../components/organisms/WizardCTPL";

const TravelDetails = (props) => {
  let history = useHistory();

  const today = dayjs();
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(null);
  const [selectedArrivalDate, setSelectedArrivalDate] = useState(props.travelDetailsState.selectedArrivalDate);

  const dateStyle = {
    border: "none !important",

    width: "100% !important",
    // Add more styles as needed
  };

  const [packageVersion, setPackageVersion] = useState(
    props.travelDetailsState.packageVersion
  );
  const [countries, setCountries] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [ageBracket, setAgeBracket] = useState(
    props.travelDetailsState.ageBracket
  ); //default []
  const [packageList, setPackageList] = useState(
    props.travelDetailsState.packageList || []
  );
  const [benefits, setBenefits] = useState([]);
  const [purpose, setPurpose] = useState(props.travelDetailsState.purposes);
  const [coverType, setCoverType] = useState(""); // individual / group / family

  const [destCount, setDestCount] = useState(
    props.travelDetailsState.destCount
  );
  const [selectedDest, setSelectedDest] = useState([]);

  const [addedDest, setAddedDest] = useState([]);

  const [numDays, setNumDays] = useState(props.travelDetailsState.days);
  const [numPax, setNumPax] = useState(props.travelDetailsState.numPax); //no of allowed pax, default 1
  const [totalPax, setTotalPax] = useState(props.travelDetailsState.totalPax); //total of pax added

  const [values, onChange] = useState(new Date());

  const [ageCount, setAgeCount] = useState(0);

  const [groupAge, setGroupAge] = useState({});

  //form field states
  const [days, setDays] = useState(0);
  const [numberOfDays,setNumberOfDays]= useState(0) ;

  const [hazardous, setHazardous] = useState(
    props.travelDetailsState.hazardous
  );
  const [sports, setSports] = useState(props.travelDetailsState.sports);
  const [destinations, setDestinations] = useState(
    props.travelDetailsState.destinations
  );
  const [departure, setDeparture] = useState(
    props.travelDetailsState.departure
  );
  const [arrival, setArrival] = useState(props.travelDetailsState.arrival);
  const [groupCount, setGroupCount] = useState(
    props.travelDetailsState.groupCount
  );
  const [groupCount2, setGroupCount2] = useState(
    props.travelDetailsState.groupCount2
  );
  const [groupCountArr, setGroupCountArr] = useState(
    Cache.getItem("groupCountArr")
  );
  const [zone, setZone] = useState(props.travelDetailsState.zone);
  const [packageType, setPackageType] = useState("");
  const [premium, setPremium] = useState(props.travelDetailsState.premium);

  const [arrivalMin, setArrivalMin] = useState(
    props.travelDetailsState.departure
  );
  const [arrivalMax, setArrivalMax] = useState(
    props.travelDetailsState.arrivalMax
  );

  const [groupAgeMin, setGroupAgeMin] = useState(
    props.travelDetailsState.groupAgeMin
  );
  const [groupAgeMax, setGroupAgeMax] = useState(
    props.travelDetailsState.groupAgeMax
  );

  const [packageDesc, setPackageDesc] = useState(
    props.travelDetailsState.packageDesc
  );

  const [localAgeBracket, setLocalAgeBracket] = useState();
  const [disableAdd, setDisableAdd] = useState(false);

  //how are you traveling
  const [disabledGroupType, setDisabledGroupType] = useState(
    props.travelDetailsState.disabledGroupType
  );
  const [checkedInd, setCheckedInd] = useState(
    props.travelDetailsState.checkedInd
  );
  const [checkedGroup, setCheckedGroup] = useState(
    props.travelDetailsState.checkedGroup
  );
  const [checkedForSomeone, setCheckedForSomeone] = useState(
    props.travelDetailsState.checkedForSomeone
  );
  const [groupType, setGroupType] = useState(
    props.travelDetailsState.groupType
  );
  const [howAreYou, setHowAreYou] = useState(
    props.travelDetailsState.howAreYou
  );

  // setAgeBracket(props.travelDetailsState.ageBracket)
  //     setTotalPax(props.travelDetailsState.totalPax);
  //     setDeparture(props.travelDetailsState.departure);
  //     setArrival(props.travelDetailsState.arrival);
  //     setNumPax(props.travelDetailsState.numPax);

  //Asean
  const purposes = [
    { value: "LE", text: "Leisure" },
    { value: "VA", text: "Vacation" },
    { value: "HO", text: "Holiday" },
    { value: "SC", text: "School / Field Trip" },
    { value: "BU", text: "Business" },
    { value: "CO", text: "Company Event / Outing" },
    {
      value: "TR",
      text: "Training / Seminar / Conference",
    },
    { value: "ST", text: "Study" },
  ];

  //Schengen
  const [schengenValidation, setSchengenValidation] = useState(false);
  const schengenCountries = [
    {
      name: "Austria",
    },
    {
      name: "Belgium",
    },
    {
      name: "Czech Republic",
    },
    {
      name: "Denmark",
    },
    {
      name: "Estonia",
    },
    {
      name: "Finland",
    },
    {
      name: "France",
    },
    {
      name: "Germany",
    },
    {
      name: "Greece",
    },
    {
      name: "Hungary",
    },
    {
      name: "Iceland",
    },
    {
      name: "Italy",
    },
    {
      name: "Latvia",
    },
    {
      name: "Liechtenstein",
    },
    {
      name: "Lithuania",
    },
    {
      name: "Luxembourg",
    },
    {
      name: "Malta",
    },
    {
      name: "Netherlands",
    },
    {
      name: "Norway",
    },
    {
      name: "Poland",
    },
    {
      name: "Portugal",
    },
    {
      name: "Slovakia (slovak Republic)",
    },
    {
      name: "Slovenia",
    },
    {
      name: "Spain",
    },
    {
      name: "Sweden",
    },
    {
      name: "Switzerland",
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  // const cover = urlParams.get("type");
  const cover =
    window.location.href.indexOf("international") > -1
      ? "international"
      : "asean";

  //**USE EFFECTS
  useEffect(() => {
    setCoverType(cover);
    setPackageType(props.travelDetailsState.groupType);

    //minDateToday(); //minimum date today
    window.scrollTo(0, 0);
    // if(typeof premium!="undefined" && parseInt(premium)!=0)
    //   props.setIsDisabled(false);
    // else
    //   props.setIsDisabled(true);
  }, []);
  useEffect(() => {}, [destCount]);
  //travel api
  useEffect(() => {
    if (cover === "international") {
      travelAPI
        .getCountries()
        .then((data) => {
          setCountries(data);
        })
        .catch((err) => console.log("Countries: ", err));
    } else {
      travelSaverAPI
        .getTravelSaver({
          Label: "ORI",
        })
        .then((data) => {
          setOrigin(data);
        })
        .catch((err) => console.log("Origin Countries: ", err));

      // travelLocalAPI.
      // getOriginList()
      // .then((data) => {
      //   setOrigin(data)
      // })
      // .catch((err) => console.log("Countries: ", err));

      travelSaverAPI
        .getTravelSaver({
          Label: "DES",
        })
        .then((data) => {
          setCountries(data);
        })
        .catch((err) => console.log("Destination Countries: ", err));

      // travelLocalAPI
      // .getLocalDestinations()
      // .then((data) => {
      //   setCountries(data)
      // })
      // .catch((err) => console.log("Countries: ", err));
    }
  }, []);

  useEffect(() => {
    //handle travel api - load only on first load - if has data already, wil not be called
    //if has data and travelAPI called, it will override data saved for age bracket.
    if (cover === "international") {
      if (
        ageBracket !== undefined &&
        Object.keys(props.travelDetailsState.ageBracket).length === 0
      ) {
        //to validate if 1st load
        travelAPI
          .getAgeTravelVersion()
          .then((data) => {
            const ages = data.ageBracket.split(",");
            const version = data.packageVersion;

            const ageBrackets = ages.map(function (ageBracket, index) {
              var ageBracketFormatted = {
                floor: parseInt(ageBracket.substring(0, 2)),
                ceiling: parseInt(ageBracket.substring(2, 4)),
                count: parseInt(ageBracket.substring(4, 8)),
                ageCount: 0,
                paxLimit: false,
                rawAgeBracket: ageBracket,
              };

              return ageBracketFormatted;
            });
            setAgeBracket(ageBrackets);
            setPackageVersion(version);
          })
          .catch((err) => console.log("Travel Version: ", err));
      }
    } else {
      if (
        ageBracket !== undefined &&
        Object.keys(props.travelDetailsState.ageBracket).length === 0
      ) {
        travelAPI
          .getAgeTravelVersion()
          .then((data) => {
            const ages = data.ageBracket.split(",");
            const version = data.packageVersion;

            const localAge = [
              {
                floor: 0,
                ceiling: 65,
                count: 0,
                ageCount: 0,
                paxLimit: false,
                rawAgeBracket: "00650000",
                ageBracket1: 0,
                ageBracket2: 0,
              },
              {
                floor: 66,
                ceiling: 75,
                count: 0,
                ageCount: 0,
                paxLimit: false,
                rawAgeBracket: "66750000",
                ageBracket1: 0,
                ageBracket2: 0,
              },
            ];

            setAgeBracket(localAge);
            setPackageVersion(version);
          })
          .catch((err) => console.log("Travel Version: ", err));
      }
    }
  }, []);

  //Sample For Package List
  useEffect(() => {}, [selectedDest]);

  useEffect(() => {
    if (ageBracket !== undefined) {
      const bracket = ageBracket.map(function (x, index) {
        return { index };
      });
      setGroupAge(bracket);
    }
  }, [ageBracket]);

  useEffect(() => {
    //fill destinations if has cached data
    if (
      destinations !== undefined ||
      (destinations !== null && props.travelDetailsState.destinations !== null)
    ) {
      let el = document.getElementsByName("destinations");
      if (
        props.travelDetailsState.destinations !== undefined &&
        props.travelDetailsState.destinations !== null
      ) {
        for (let x = 0; x < el.length; x++) {
          el[x].value = props.travelDetailsState.destinations[x]?.value;
        }
        //delete duplicates on destination dropdown
        let dest = document.getElementsByName("destinations");
        setTimeout(() => {
          for (let i = 0; i < dest.length; i++) {
            for (let x = 0; x < dest.length; x++) {
              if (
                x != i &&
                dest[i].options[dest[x].selectedIndex] !== undefined
              ) {
                dest[i].options[dest[x].selectedIndex].style.display = "none";
              }
            }
          }
        }, 10);
        //end duplicates
      } //if
    }
  }, [countries]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      //handle groupCount - join totalpax to groupCount
      let test = String(groupCount);
      let test2 = String(groupCount2);
      const tester = test.split("");
      // const tester2 = test2.split("")

      let arr = [];

      let add = parseInt(groupCount) + parseInt(totalPax);
      // let add2 = parseInt(groupCount2)+parseInt(totalPax)
      // if(groupType === "individual") {
      if (false) {
        if (parseInt(tester[6]) == 0 && parseInt(tester[7]) == 0) {
          if (parseInt(tester[2]) === 5) add = "00" + add;
          setGroupCount(add);
          //// handlePremium();
        } else {
          // setGroupCount(add)
        }
      } else {
        for (let i = 0; i < ageBracket.length; i++) {
          // if(ageBracket[i].ageCount !== 0 && totalPax > 1) {
          if (ageBracket[i].ageCount !== 0 && totalPax > 0) {
            let test2 = ageBracket[i].rawAgeBracket;
            let tester2 = test2.split("");
            let add2 =
              parseInt(ageBracket[i].rawAgeBracket) +
              parseInt(ageBracket[i].ageCount);
            if (parseInt(tester2[6]) == 0 && parseInt(tester2[7]) == 0) {
              if (parseInt(tester2[2]) === 5) add2 = "00" + add2;
              arr.push(add2);
              setGroupCountArr(arr.join(","));
              Cache.setItem("groupCountArr", arr.join(","));
              //// handlePremium();
            } else {
              // setGroupCount(add)
            }
          }
        }
      }
    }

    //call premium handling
    handlePremium();
  }, [
    groupCount,
    ageBracket,
    days,
    zone,
    hazardous,
    sports,
    howAreYou,
    groupType,
    numberOfDays
  ]);

  // useEffect(()=>{
  //   let el = document.getElementsByName("package");

  //   if(totalPax > 0) {
  //     for(let x=0; x<el.length; x++) {
  //       // el[x].setAttribute("required", true)
  //     }
  //   }
  // },[totalPax])
  //Functions
  const SaveForm = (e) => {
    e.preventDefault();
    Cache.setItem("fromCoverPage", false);
    if (numberOfDays === 0) {
      alert("Please ensure that your departure date is earlier than your return date.");
      e.preventDefault();
      return false;
    }
    if (totalPax === 0) {
      alert("Please fill up how many are traveling");
      e.preventDefault();
      return false;
    }
    if (groupType === "group" && totalPax <= 1) {
      alert(
        "For Package type Group, total travelers should be more than 1 person."
      );
      return false;
    } else if (groupType === "individual" && totalPax > 1) {
      alert("Maximum of 1 Traveler  is allowed for Individual package.");
      return false;
    }

    if (groupType === "group" && totalPax > 10) {
      alert("Maximum of 10 Travelers are allowed for Group package.");
      return false;
    }

    if (totalPax > 0 && packageDesc === "") {
      alert("Please select a Package.");
      return false;
    }

    if (departure === undefined || departure===null || departure==="") {
      alert("Date of Departure is required.");
      return false;    
    }
    if (arrival === undefined || arrival===null || arrival==="") {
      alert("Date of arrival is required.");
      return false;    
    }
    // set properly min-max for individual
    // if age count reduces from group to individual
    let ageMin = groupAgeMin;
    let ageMax = groupAgeMax;
    if (packageType === "individual") {
      let ageBrackets = ageBracket.find((range) => range.ageCount !== 0);

      if (ageBrackets) {
        const { floor, ceiling } = ageBrackets;
        ageMin = floor;
        ageMax = ceiling;
      }
    }

    const traveDetailsData = {
      covid: true,
      days: numberOfDays,
      hazardous: hazardous,
      sports: sports,
      destinations: destinations,
      departure: departure,
      arrival: arrival,
      // groupCount: (groupType === "individual")?groupCount:Cache.getItem("groupCountArr"),
      groupCount:
        groupType === "individual"
          ? Cache.getItem("groupCountArr")
          : Cache.getItem("groupCountArr"),
      zone: zone,
      premium: premium,
      ageBracket: ageBracket,
      totalPax: totalPax,
      numPax: numPax,
      destCount:
        coverType === "international"
          ? document.getElementsByName("destinations").length - 1
          : document.getElementsByName("destinations").length - 2,
      packageList: packageList,
      packageVersion: packageVersion,
      arrivalMin: arrivalMin,
      arrivalMax: arrivalMax,
      groupAge: groupAge,
      groupAgeMin: ageMin,
      groupAgeMax: ageMax,
      packageDesc: packageDesc,
      packageType: packageType,
      coverType: coverType,
      purposes: purpose,
      ageBracket1: ageBracket[0]?.ageBracket1,
      ageBracket2: ageBracket[1]?.ageBracket1,
      origin: destinations[0].value,
      checkedInd: checkedInd,
      checkedGroup: checkedGroup,
      checkedForSomeone: checkedForSomeone,
      groupType: groupType,
      disabledGroupType: disabledGroupType,
      howAreYou: howAreYou,
      selectedArrivalDate:selectedArrivalDate
    };
    props.saveData(traveDetailsData);
  };

  const handleChange = (e) => {
    let evt = e.target;
    if (evt.name === "destinations") {
      let el = document.getElementsByName("destinations");
      let arr = [];
      handleSelectedDest(e);
      // if(schengenCount>0){
      //   setSchengenValidation(true);
      // }
      // else{
      //   setSchengenValidation(false);
      // }
      setSchengenValidation(false);
      for (let x = 0; x < el.length; x++) {
        arr.push({
          code: el[x].options[el[x].selectedIndex].getAttribute("code"),
          value: el[x].value,
        });
        handleSchengen(el[x].value);
      }
      setDestinations(arr);
    } else if (evt.name === "departure") {
      handleDepart(e);

      setDeparture(evt.value);
      setDays(numDays);
    } else if (evt.name === "arrival") {
      handleArrival(e);

      setArrival(evt.value);
      setDays(numDays);
      if (evt.checkValidity() === true) {
      }
    } else if (evt.name === "package") {
      setZone(evt.id);
      setPackageDesc(evt.getAttribute("desc"));
    } else if (evt.name === "hazard") {
      setHazardous(evt.checked);
    } else if (evt.name === "contest") {
      setSports(evt.checked);
    } else if (evt.id === "origin") {
      let el = document.getElementsByClassName("destinations");
      // el[1].options[el.selectedIndex].style.display = "none";
    } else if (evt.name === "purpose") {
      setPurpose(evt.value);
    } else if (evt.name === "howareyou") {
      let radiolabel = document.querySelector("[value='" + evt.value + "']");
      radiolabel = radiolabel.nextSibling.textContent;

      setHowAreYou(radiolabel);
      if (evt.value === "forsomeone") {
        setDisabledGroupType(false);
        setCheckedForSomeone(true);
        setCheckedInd(false);
        setCheckedGroup(false);
        document.getElementById("groupType").value = "individual";
        setGroupType("individual");
      } else {
        setCheckedForSomeone(false);

        if (evt.value === "individual") {
          document.getElementById("groupType").value = evt.value;

          setGroupType(evt.value);
          setPackageType(evt.value);
          setCheckedInd(true);
          setCheckedGroup(false);
          setDisabledGroupType(true);
        } else {
          document.getElementById("groupType").value = evt.value;
          setGroupType(evt.value);
          setPackageType(evt.value);
          setCheckedGroup(true);
          setCheckedInd(false);
          setDisabledGroupType(true);
        }
      }
    } else if (evt.name === "groupType") {
      setGroupType(evt.value);
    }

    // handlePremium();
  };

  const handleSchengen = (country) => {
    //let country = dest[dlength].options[dest[dlength-1]?.selectedIndex].text;

    let countries = country.split(" ");

    for (let i = 0; i < countries.length; i++) {
      if (countries[i][0] != undefined)
        countries[i] =
          countries[i][0].toUpperCase() + countries[i].substr(1).toLowerCase();
    }

    country = countries.join(" ");

    let obj = schengenCountries.find((o) => o.name == country);
    console.log("Desination schengen selected: ", country);
    console.log("Desination schengen: ", obj);
    if (obj !== undefined) {
      console.log("Desination schengen: ", obj.name);
      setSchengenValidation(true);
    }
  };

  const handleAgeGroupValidity = () => {
    let validateAgeGroup = false;
    let index = 0;

    let el = document.querySelectorAll("input");
    for (let x = 0; x < el.length; x++) {
      if (el[x].name === "arrival") {
        index = x;
        if (el[x + 1].checkValidity === false) {
          validateAgeGroup = true;
        }
        if (validateAgeGroup === true && x > index) {
          el.required = false;
        }
      }
    }
  };

  //currency formatting
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const handlePremium = () => {
    // PREMIUM API computation
    //travel/premium?covid=true&days=20&groupCount=00590001&hazardous=false&packageType=I&sports=false&zone=WA22
    let differenceInTime = new Date(arrival).getTime() - new Date(departure).getTime();
    let differenceInDays = (differenceInTime / (1000 * 3600 * 24))+1;
    differenceInDays = (differenceInDays>0)?parseInt(differenceInDays):0;
    if(new Date(arrival).getTime() < new Date(departure).getTime()){
      differenceInDays= 0;
    }
    //differenceInDays+=1;
    if (cover === "international") {
      let params = {
        covid: true,
        days: differenceInDays,
        // groupCount: (groupType === "individual")?groupCount:Cache.getItem("groupCountArr"),
        groupCount:
          groupType === "individual"
            ? Cache.getItem("groupCountArr")
            : Cache.getItem("groupCountArr"),
        hazardous: hazardous,
        sports: sports,
        packageType: groupType === "individual" ? "I" : "G",
        zone: String(zone),
      };
      //handle first params to prevent error onload api
      if (
        params.days !== undefined &&
        params.groupCount !== undefined &&
        params.sports !== undefined &&
        params.zone !== undefined &&
        zone !== null &&
        ((groupType === "individual" && totalPax === 1) ||
          (groupType === "group" && totalPax > 1))
      ) {
        travelAPI
          .getTravelPremium(params)
          .then((data) => {
            setPremium(data);
            // if(typeof data!="undefined" && parseInt(data)!=0)
            //   props.setIsDisabled(false);
            // else
            //   props.setIsDisabled(true);
          })
          .catch((err) => console.log("premium: ", err));
      } else {
        setPremium(0.0);
      }
    } else {
      // const {ageBracket1} = ageBracket
      if (ageBracket !== undefined) {
        let params = {
          days: differenceInDays,
          packageType: groupType === "individual" ? "I" : "G",
          zone: String(zone),
          ageBracket1: ageBracket[0]?.ageBracket1,
          ageBracket2: ageBracket[1]?.ageBracket1,
        };

        if (
          params.days !== undefined &&
          params.zone !== undefined &&
          zone !== null
        ) {
          travelLocalAPI
            .getLocalTravelPremium(params)
            .then((data) => {
              setPremium(data);
              // if(typeof data!="undefined" && parseInt(data)!=0)
              //   props.setIsDisabled(false);
              // else
              //   props.setIsDisabled(true);
            })
            .catch((err) => console.log("premium: ", err));
        }
      }
    }
  };

  //set arrval, departure minimum date to date today
  // const minDateToday = () => {
  //   // let newDate = new Date().toISOString().split("T")[0];
  //   // document.getElementsByName("departure")[0].setAttribute("min", newDate);
  //   if(props.travelDetailsState.arrival !== undefined && props.travelDetailsState.arrival.length === 0) {
  //     document.getElementsByName("arrival")[0].setAttribute("min", newDate);
  //   }

  // }

  const handlePackageType = () => {
    let packType = "";
    let countries = [];
    let elem = document.getElementsByName("destinations");
    cover === "international" ? (packType = "I") : (packType = "A");
    if (document.getElementsByName("destinations")[0].value !== "") {
      // let selected =
      for (let x = 0; x < elem.length; x++) {
        countries.push(elem[x].value);
      }
    }
    let join = countries.join("*");
    if (cover === "international") {
      travelAPI
        .getPackages(join, packType, packageVersion)
        .then((data) => {
          setPackageList(data);
        })
        .catch((err) => console.log("Countries: ", err));
    } else {
      travelLocalAPI
        .getLocalPackageList()
        .then((data) => {
          setPackageList(data);
        })
        .catch((err) => console.log("Countries: ", err));
    }
  };
  const handleNote = () => {
    const origin = (destinations ?? "")[0] ? destinations[0].value : "";
    const traveDetailsData = {
      covid: true,
      days: numberOfDays,
      hazardous: hazardous,
      sports: sports,
      destinations: destinations,
      departure: departure,
      arrival: arrival,
      groupCount: groupCount,
      zone: zone,
      premium: premium,
      ageBracket: ageBracket,
      totalPax: totalPax,
      numPax: numPax,
      destCount:
        coverType === "international"
          ? document.getElementsByName("destinations").length - 1
          : document.getElementsByName("destinations").length - 2,
      packageList: packageList,
      packageVersion: packageVersion,
      arrivalMin: arrivalMin,
      arrivalMax: arrivalMax,
      groupAgeMin: groupAgeMin,
      groupAgeMax: groupAgeMax,
      packageDesc: packageDesc,
      packageType: packageType,
      coverType: coverType,
      purposes: purpose,
      ageBracket1: ageBracket[0]?.ageBracket1,
      ageBracket2: ageBracket[1]?.ageBracket1,
      origin: origin,
      checkedInd: checkedInd,
      checkedGroup: checkedGroup,
      checkedForSomeone: checkedForSomeone,
      groupType: groupType,
      disabledGroupType: disabledGroupType,
      howAreYou: howAreYou,
    };
    let arr = [];
    if (props.assuredDetailsState.assured[0].fullName !== "") {
      let test = props.assuredDetailsState?.assured;

      for (let i = 0; i < test?.length; i++) {
        arr.push({
          fullName: test[i].fullName,
          bday: test[i].bday,
          passport: test[i].passport,
          email: test[i].email,
          contactNo: test[i].contactNo,
          age: test[i].age,
          primary: test[i].primary,
          beneficiary: test[i].beneficiary,
          maritalStatus: test[i].maritalStatus,
          gender: test[i].gender,
          paxOne: test[i].paxOne,
          paxTwo: test[i].paxTwo,
          paxThree: test[i].paxThree,
        });
      }
    }
    let assured = {
      assured: arr,
    };
    props.saveTravelDetails(traveDetailsData, assured);

    history.push("/covid");
    Cache.setItem("benefitFlag", true);
  };

  const handleBenefits = (e, zone) => {
    e.preventDefault();
    
    travelAPI
      .getBenefits(zone)
      .then((data) => {
        //setBenefits(data);
        //Cache.setItem("benefits", benefits);

        const traveDetailsData = {
          covid: true,
          days: numberOfDays,
          hazardous: hazardous,
          sports: sports,
          destinations: destinations,
          departure: departure,
          arrival: arrival,
          groupCount: groupCount,
          zone: zone,
          premium: premium,
          ageBracket: ageBracket,
          totalPax: totalPax,
          numPax: numPax,
          destCount:
            coverType === "international"
              ? document.getElementsByName("destinations").length - 1
              : document.getElementsByName("destinations").length - 2,
          packageList: packageList,
          packageVersion: packageVersion,
          arrivalMin: arrivalMin,
          arrivalMax: arrivalMax,
          groupAgeMin: groupAgeMin,
          groupAgeMax: groupAgeMax,
          packageDesc: packageDesc,
          packageType: packageType,
          coverType: coverType,
          purposes: purpose,
          ageBracket1: ageBracket[0]?.ageBracket1,
          ageBracket2: ageBracket[1]?.ageBracket1,
          origin: destinations[0].value,
          checkedInd: checkedInd,
          checkedGroup: checkedGroup,
          checkedForSomeone: checkedForSomeone,
          groupType: groupType,
          disabledGroupType: disabledGroupType,
          howAreYou: howAreYou,
        };

        props.saveTravelDetails(traveDetailsData);

        setTimeout(() => {
          history.push({
            pathname: `/travel/${coverType}/benefits`,
            state: { ...data, coverType: coverType },
            // test: "test"
          });
        }, 100);

        Cache.setItem("benefitFlag", true);
      })
      .catch((err) => console.log("Benefits: ", err));
  };
  const handleSelectedDest = (e) => {
    handleSelectOption(e);
    handlePackageType();
  };

  const handleSelectOption = (e) => {
    let arr = selectedDest;

    let target = e.target.value;
    arr.push(target);
    setSelectedDest(arr);

    let selectobject = document.getElementsByName("destinations");
    let prevIndex = "";
    for (var i = 0; i < selectobject.length; i++) {
      if (
        selectobject[i].getAttribute("prevdata") === "" ||
        selectobject[i].value === e.target.value
      ) {
        if (selectobject[i].getAttribute("prevdata") !== "")
          prevIndex = parseInt(
            selectobject[i].getAttribute("prevdata").split("-")[1]
          );
        if (selectobject[i].value !== "")
          selectobject[i].setAttribute(
            "prevdata",
            selectobject[i].value + "-" + e.target.selectedIndex + "-" + i
          );
      }
    }
    for (var i = 0; i < selectobject.length; i++) {
      if (
        selectobject[i].value !==
        selectobject[i].getAttribute("prevdata").split("-")[0]
      ) {
      } else {
        //show previously hidden selected option
        if (selectobject[i].getAttribute("prevdata") !== "") {
          if (
            prevIndex !== "" &&
            !isNaN(prevIndex) &&
            selectobject[i].options[prevIndex] !== undefined
          ) {
            selectobject[i].options[prevIndex].style.display = "block";
          }
        }
        //hide previous and next index of current element
        if (e.target.selectedIndex !== 0) {
          if (selectobject[i - 1] !== undefined) {
            // selectobject[i-1].options[e.target.selectedIndex].style.display = "none"; //original hiding
            if (
              selectobject[i].selectedIndex !== undefined &&
              selectobject[i].selectedIndex !== 0 &&
              selectobject[i - 1].options[selectobject[i].selectedIndex] !==
                undefined
            )
              selectobject[i - 1].options[
                selectobject[i].selectedIndex
              ].style.display = "none";
          }
          if (selectobject[i + 1] !== undefined) {
            if (
              selectobject[i].selectedIndex !== undefined &&
              selectobject[i].selectedIndex !== 0
            )
              selectobject[i + 1].options[
                selectobject[i].selectedIndex
              ].style.display = "none";
          }
        } //if
      } //else
    } //for
  }; //const

  const handleDeleteDest = (e) => {
    e.preventDefault();
    let btn = e.target.id;
    const arr = btn.split("-");
    const elem = document.getElementById("cselect" + arr[2]);
    let select = document.querySelector("#select" + arr[2] + " select");
    let getDestinations = document.getElementsByName("destinations");

    for (let i = 0; i < getDestinations.length; i++) {
      if (getDestinations[i].value !== select.value) {
        getDestinations[i].options[select.selectedIndex].style.display =
          "block";
      }
    }

    if (elem !== null) {
      elem.remove();
    }
    setSchengenValidation(false);
    for (let i = 0; i < getDestinations.length; i++) {
      console.log("Destination #" + (i + 1) + " ", getDestinations[i].value);
      handleSchengen(getDestinations[i].value);
    }
    // setDestCount(destCount => destCount - 1)
    //elem.remove();
  };
  const addDestination = (e) => {
    e.preventDefault();
    setDestCount((count) => count + 1);
    // let dest = document.getElementsByName("destinations")
    let dest = document.getElementsByClassName("sel-dest");
    let dlength = dest.length;

    setTimeout(() => {
      // hide option for the next index of destination
      for (let i = 0; i < dest.length - 1; i++) {
        if (
          dest[dlength - 1].selectedIndex !== 0 &&
          dest[dlength] !== undefined
        ) {
          dest[dlength].options[
            dest[dlength - 1]?.selectedIndex
          ].style.display = "none";
        }
      }
    }, 300);
    // hideOptionOnCreateDestination();
  };

  const hideOptionOnCreateDestination = () => {
    let dest = document.getElementsByName("destinations");
    let dlength = dest.length;

    for (let i = 0; i < dest.length - 1; i++) {
      if (
        dest[dlength - 1].selectedIndex !== 0 &&
        dest[dlength] !== undefined
      ) {
        dest[dlength].options[dest[dlength - 1]?.selectedIndex].style.display =
          "none";
      }
    }
  };

  //departure field event
  const handleDepart = (e) => {
    //clear arrival onchange departure
    if (document.getElementsByName("arrival")[0] !== "") {
      document.getElementsByName("arrival")[0].value = "";
    }

    //call number of days computation
    handleNumDays(e);

    let arrLimit = new Date(e.target.value);
    if (coverType === "international") {
      arrLimit = arrLimit.setDate(arrLimit.getDate() + 89);
    } else {
      arrLimit = arrLimit.setDate(arrLimit.getDate() + 365);
    }

    let newDate = new Date(arrLimit).toISOString().split("T")[0];
    let newDate2 = new Date(e.target.value).toISOString().split("T")[0];

    //set Arrival min and max
    document.getElementsByName("arrival")[0].setAttribute("max", newDate);
    document.getElementsByName("arrival")[0].setAttribute("min", newDate2);
    setArrivalMin(newDate2);
    setArrivalMax(newDate);
  };

  //departure field event
  const handleArrival = (e) => {
    handleNumDays(e);
  };

  //number of days handling
  const handleNumDays = (e) => {
    let dept = document.getElementsByName("departure")[0].value;
    let arr = document.getElementsByName("arrival")[0].value;
    if (dept !== "" && arr !== "") {
      dateDifference(dept, arr);
    } else {
      setNumDays(0);
      
    }
  };

  //number of days computation
  const dateDifference = (date_1, date_2) => {
    // let date2 = new Date(date_2)
    // date2.setDate(date2.getDate()-1)
    let difference = new Date(date_2).getTime() - new Date(date_1).getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays < 0) TotalDays = 0;
    setNumDays(TotalDays + 1);
    // return TotalDays;
  };

  const handleAgeCount = (e) => {
    e.preventDefault();
  };

  const addAgeCount = (e, index, gCount, min, max) => {
    e.preventDefault();

    if (numPax === 0) {
      let update = ageBracket.map((obj, i) => ({
        ...obj,
        paxLimit: true,
      }));
      // setAgeBracket(update)
    } else {
      let updateDisabled = ageBracket.map((obj, i) => ({
        ...obj,
        paxLimit: false,
      }));
      setAgeBracket(updateDisabled);

      let update = ageBracket.map((obj, i) =>
        index === i
          ? {
              ...obj,
              ageCount: obj.ageCount + 1,
              ageBracket1: obj.ageBracket1 + 1,
            }
          : obj
      );
      setNumPax((numPax) => numPax - 1);
      setTotalPax((totalPax) => totalPax + 1);
      if (groupType === "individual") {
        // setGroupCount(gCount);
        setGroupCount2(gCount);
      } else {
        setGroupCount2(gCount);
      }
      // setGroupCountArr(arrGroup)
      setAgeBracket(update);
      setGroupAgeMin(min);
      setGroupAgeMax(max);
    }
  };

  const minusAgeCount = (e, index, gCount, min, max) => {
    e.preventDefault();
    // set
    let arrGroup = [];

    if (ageBracket[index].ageCount === 0) {
      let updateIndex = ageBracket.map((obj, i) => ({
        ...obj,
        paxLimit: false,
      }));
      // setAgeBracket(updateIndex)
    } else {
      if (numPax >= 0) {
        let updatePax = ageBracket.map((obj, i) => ({
          ...obj,
          paxLimit: false,
        }));
        // setAgeBracket(updatePax)
      }

      let update = ageBracket.map((obj, i) =>
        index === i
          ? {
              ...obj,
              ageCount: obj.ageCount - 1,
              ageBracket1: obj.ageBracket1 - 1,
            }
          : obj
      );
      setNumPax((numPax) => numPax + 1);
      setTotalPax((totalPax) => totalPax - 1);

      if (totalPax == 0) {
        setGroupCount("");
      } else {
        if (groupType === "individual") {
          setGroupCount(gCount);
        } else {
          setGroupCount2(gCount);
        }
      }

      setAgeBracket(update);
      setGroupAgeMin(min);
      setGroupAgeMax(max);
    }
  };

  const handleDepartureDateChange = (date) => {
    setSelectedDepartureDate(date);
    setDeparture(date.format('YYYY-MM-DD'));
    if (date) {
      // Validate and set max date for arrival
      const maxDate = date.add(89, "day");
      if (selectedArrivalDate && selectedArrivalDate.isAfter(maxDate)) {
        setSelectedArrivalDate(maxDate);
      }
    }
  };

  const handleArrivalDateChange = (date) => {
    setSelectedArrivalDate(date);
    setArrival(date.format('YYYY-MM-DD'));
  if (date && date.isBefore(selectedDepartureDate)) {
      if (selectedDepartureDate && date.isBefore(selectedDepartureDate)) {
        setSelectedDepartureDate(date);
        setDeparture(date);
      }
    }
    // else{
    //   alert("not allowed");
    // }
  };

  // const handleDepartureDateChange = (date) => {
  //   setSelectedDepartureDate(date);

  //   if (date.isSame(today, 'day')) {
  //     setSelectedArrivalDate(today);
  //   }

  //   if (date.isAfter(selectedArrivalDate)) {
  //     setSelectedArrivalDate(date);
  //   }

  //   const maxDate = date.add(89, 'day');
  //   if (selectedArrivalDate.isAfter(maxDate)) {
  //     setSelectedArrivalDate(maxDate);
  //   }
  // };

  // const handleArrivalDateChange = (date) => {

  //   if (date.isBefore(selectedDepartureDate)) {
  //     setSelectedDepartureDate(date);
  //   }
  //   setSelectedArrivalDate(date);
  // };

  // Calculate the number of days between departure and arrival dates

  useEffect(()=>{
    try{
      console.log("selectedArrivalDate:",arrival);
      console.log("departure:",departure);

      let differenceInTime = new Date(arrival).getTime() - new Date(departure).getTime();
      let differenceInDays = (differenceInTime / (1000 * 3600 * 24))+1;
      if(departure!="" && arrival!="" && (differenceInDays>0))
        {
          differenceInDays = (differenceInDays>0)?parseInt(differenceInDays):0;
          setNumberOfDays(differenceInDays); 
        }
      else
        setNumberOfDays(0); 
      
    }
    catch(err){
      console.log(err);
    }
  },[departure,arrival]);
  return (
    <>
      <div>
        <form onSubmit={SaveForm}>
          <h3 className="text-lg font-bold title-font">
            Please provide your travel details.
          </h3>
          <div className="my-5">
            <p className="text-sm text-gray-500 font-bold">
              How are you traveling today?
            </p>
            <div className="mt-2">
              <input
                type="radio"
                className="mr-3"
                value="individual"
                name="howareyou"
                defaultChecked={checkedInd}
                onChange={handleChange}
                required
              />
              <label>I am traveling alone.</label>
            </div>
            <div>
              <input
                type="radio"
                className="mr-3"
                value="group"
                name="howareyou"
                defaultChecked={checkedGroup}
                onChange={handleChange}
                required
              />
              <label>I am traveling with a group.</label>
            </div>
            <div>
              {/* <input 
                type="checkbox" 
                className="mr-3" 
                name="contest"
                defaultChecked={props.travelDetailsState.sports}
                onChange={handleChange}
              /> */}
              <input
                type="radio"
                className="mr-3"
                value="forsomeone"
                name="howareyou"
                defaultChecked={checkedForSomeone}
                onChange={handleChange}
                required
              />
              <label>I am buying insurance for someone.</label>
            </div>
          </div>
          <div className="block relative w-full">
            <label className="inline-block text-gray-500 text-sm font-bold">
              Package Type
            </label>
            <select
              id="groupType"
              name="groupType"
              className="input-border-none focus:outline-none focus:shadow-outline sel-dest"
              onChange={handleChange}
              defaultValue={groupType}
              prevData=""
              required
              disabled={disabledGroupType}
            >
              {/* <option value=""> --- </option> */}
              <option code="I" value="individual">
                Individual
              </option>
              <option code="G" value="group">
                Group
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                hidden={disabledGroupType}
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div className="my-5">
            {/* Do not display if packageType == "I" */}
            {/* <div>
              <label className="inline-block text-gray-500 text-sm font-bold">
                Package Type
              </label>
              <div className="grid grid-cols-2 mt-2">
                <div>
                  <input type="radio" name="packagetype" className="mr-3" />
                  <label>Individual</label>
                </div>
                <div>
                  <input type="radio" name="packagetype" className="mr-3" />
                  <label>Group</label>
                </div>
              </div>
            </div> */}
            {/* Do not display*/}

            {coverType === "international" && (
              <div className="my-3">
                <span className="text-sm text-gray-500 font-bold">
                  Please declare if you are traveling for sports competitions.
                </span>
                <div className="mt-2">
                  <input
                    type="checkbox"
                    className="mr-3"
                    name="hazard"
                    defaultChecked={props.travelDetailsState.hazardous}
                    onChange={handleChange}
                  />
                  <label>Traveling with Hazardous Sports</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mr-3"
                    name="contest"
                    defaultChecked={props.travelDetailsState.sports}
                    onChange={handleChange}
                  />
                  <label>Traveling for Sports Competition or Tournament</label>
                </div>
                <p className="text-sm my-2 text-gray-500 font-bold">
                  Itinerary: Please specify the countries you will travel to and
                  the duration of your trip.
                </p>
              </div>
            )}

            {/* Should Display if Asean*/}
            {coverType === "asean" && (
              <p className="text-sm my-2 text-gray-500 font-bold">
                Itinerary: Please specify the cities you will travel to and the
                duration of your trip.
              </p>
            )}
            {/* Should Display if Asean*/}

            <div>
              {/* Should Display if Asean*/}
              {coverType === "asean" && (
                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Origin
                  </label>

                  <div className="block relative w-full">
                    <select
                      name="destinations"
                      id="origin"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      prevData=""
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select your origin</option>
                      {origin.map((page, index) => (
                        <option code={page.code} value={page.value} key={index}>
                          {page.name}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
              {/* Should Display if Asean*/}

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Destination/s
                </label>

                <div className="float-right">
                  <button onClick={addDestination}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-7 w-7 text-yellow-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <div className="block relative w-full">
                  <select
                    name="destinations"
                    className="input-border-none focus:outline-none focus:shadow-outline sel-dest"
                    onChange={handleChange}
                    defaultValue={destinations}
                    prevData=""
                    required
                  >
                    <option value="">Select Destinations</option>
                    {countries.map((page, index) => (
                      <option code={page.code} value={page.value} key={index}>
                        {page.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                {(() => {
                  let destArr = [];
                  // let i = (coverType==="international")?0:1
                  // let dCounts = (coverType==="international")?destCount:destCount-1
                  let dCounts = destCount;
                  for (let i = 0; i < dCounts; i++) {
                    destArr.push(
                      <div id={"cselect" + i}>
                        <div
                          id={"select" + i}
                          className="inline-block relative w-11/12 align-middle "
                        >
                          <select
                            name="destinations"
                            className="input-border-none focus:outline-none focus:shadow-outline sel-dest"
                            onChange={handleChange}
                            prevData=""
                            required
                          >
                            <option value="">Select Destinations</option>
                            {countries.map((page, index) => (
                              <option
                                code={page.code}
                                value={page.value}
                                key={index}
                              >
                                {page.name}
                              </option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>

                        <div className="inline-block align-middle">
                          <button
                            id={"btn-del-" + i}
                            onClick={(e, i) => {
                              e.preventDefault();
                              let index = destArr
                                .map(function (a) {
                                  console.log(a.props.id);
                                  return a.props.id;
                                })
                                .indexOf("cselect" + i);

                              handleDeleteDest(e);

                              destArr.splice(index, 1);
                            }}
                            className="mt-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-7 w-7 text-gray-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              style={{ pointerEvents: "none" }}
                              disabled
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                clipRule="evenodd"
                                disabled
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    );
                  }
                  return destArr;
                })()}

                {/* action button */}
              </div>

              {schengenValidation && (
                <>
                  <div className="mb-3">
                    <p
                      className={
                        "text-red-700 text-xs text-sm font-bold italic"
                      }
                    >
                      Some schengen countries requires an additional 15 days
                      travel insurance coverage beyond travel period. Kindly
                      check with the embassy to whom you are applying to avoid
                      delays with the visa application
                    </p>
                  </div>
                </>
              )}

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Date of Departure
                </label>
                {coverType === "international" && (
                  <p className="text-sm text-gray-500 mb-2">
                    (Departure date/time from the Usual Country of Residence, as
                    shown on your confirmed travel ticket)
                  </p>
                )}

                <div className="block relative w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={"customDatepickerBorder"}>
                      {/* <DatePicker
                        label="Select Date"
                        value={selectedDepartureDate}
                        onChange={handleDepartureDateChange}
                        minDate={today}
                        renderInput={(params) => (
                          <div style={{ position: "relative" }}>
                            <input {...params.inputProps} />
                            <CalendarTodayIcon
                              style={{
                                position: "absolute",
                                right: 10,
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        )}
                      /> */}
                      <DatePicker
                        // value={selectedDepartureDate}
                        defaultValue={dayjs(departure)}
                        inputFormat="YYYY-MM-DD"
                        onChange={handleDepartureDateChange}
                        minDate={today}
                        renderInput={(params) => (
                          <div style={{ position: "relative" }}>
                            <input {...params.inputProps} />
                          </div>
                        )}
                      />
                    </div>
                  </LocalizationProvider>
                  {/* <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="date"
                    name="departure"
                    defaultValue={props.travelDetailsState.departure}
                    onChange={handleChange}
                    required
                  /> */}
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Date of Return
                </label>
                {coverType === "international" && (
                  <p className="text-sm text-gray-500">
                    (Arrival date/time to the Usual Country of Residence, as
                    shown on your confirmed travel ticket)
                  </p>
                )}
                <div className="block relative w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={"customDatepickerBorder"}>
                      <DatePicker
                        //value={selectedArrivalDate}
                        minDate={today}
                        maxDate={
                          coverType === "international"
                            ? selectedDepartureDate &&
                              selectedDepartureDate.add(89, "day")
                            : undefined
                        }
                        //defaultValue={props.travelDetailsState.arrival}
                        defaultValue={dayjs(arrival)}
                        onChange={handleArrivalDateChange}
                        renderInput={(params) => (
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                left: "8px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }} 
                              
                            >
                              <CalendarTodayIcon />
                            </div>
                            <input
                              {...params.inputProps}
                              style={{ paddingLeft: "30px" }} 
                            />{" "}
                            {/* Adjust paddingLeft to accommodate icon */}
                          </div>
                        )}
                      />
                      {/* <DatePicker
                        label="Select Date"
                        value={selectedArrivalDate}
                        onChange={handleArrivalDateChange}
                        minDate={selectedDepartureDate}
                        maxDate={selectedDepartureDate.add(89, "day")} 
                        renderInput={(params) => (
                          <div style={{ position: "relative" }}>
                            <input {...params.inputProps} />
                            <CalendarTodayIcon
                              style={{
                                position: "absolute",
                                right: 10,
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        )}
                      /> */}
                    </div>
                  </LocalizationProvider>
                  {/* <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="date"
                    name="arrival"
                    defaultValue={props.travelDetailsState.arrival}
                    min={departure}
                    max={props.travelDetailsState.arrivalMax}
                    onChange={handleChange}
                    required
                  /> */}
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Number of Days:{" "}
                  <span className="text-gray-900 ml-2 font-bold">
                    {numberOfDays}
                  </span>
                </label>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  How many are traveling?
                </label>
                {coverType === "international" && (
                  <ul>
                    {ageBracket.map(function (age, index) {
                      return (
                        <li className="p-2 block" key={index}>
                          {age.floor} - {age.ceiling} yrs. old
                          <div className="float-right">
                            <button
                              className="mr-2 inline-block align-middle"
                              onClick={(e) =>
                                minusAgeCount(
                                  e,
                                  index,
                                  age.rawAgeBracket,
                                  age.floor,
                                  age.ceiling
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-gray-200"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <span className="inline-block align-middle">
                              {age.ageCount}
                            </span>
                            <button
                              className="ml-2 inline-block align-middle"
                              disabled={age.paxLimit}
                              onClick={(e) =>
                                addAgeCount(
                                  e,
                                  index,
                                  age.rawAgeBracket,
                                  age.floor,
                                  age.ceiling
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-yellow-500"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {/* Should Display if Asean && should display immediately afte the page load*/}
                {coverType === "asean" && (
                  <ul>
                    {ageBracket?.map(function (age, index) {
                      return (
                        <li className="p-2 block">
                          {age.floor} - {age.ceiling} yrs. old
                          <div className="float-right">
                            <button
                              className="mr-2 inline-block align-middle"
                              onClick={(e) =>
                                minusAgeCount(
                                  e,
                                  index,
                                  age.rawAgeBracket,
                                  age.floor,
                                  age.ceiling
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-gray-200"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <span className="inline-block align-middle">
                              {age.ageCount}
                            </span>
                            <button
                              className="ml-2 inline-block align-middle"
                              onClick={(e) =>
                                addAgeCount(
                                  e,
                                  index,
                                  age.rawAgeBracket,
                                  age.floor,
                                  age.ceiling
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 text-yellow-500"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {/* Should Display if Asean*/}
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Total Passenger/s:{" "}
                  <span className="text-gray-900 ml-2 font-bold">
                    {totalPax}
                    {/* passenger{totalPax > 1?"s":""} */}
                  </span>
                </label>
              </div>

              {/* Should Display if Asean*/}
              {coverType === "asean" && (
                <div className="mb-3">
                  <label className="inline-block text-gray-500 text-sm font-bold">
                    Purpose of Travel
                  </label>

                  <div className="inline-block relative w-full align-middle">
                    <select
                      name="purpose"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      onChange={handleChange}
                      defaultValue={props.travelDetailsState.purposes}
                      required
                    >
                      <option value="">Select Purpose of Travel</option>
                      {purposes.map((page, index) => (
                        <option code={page.text} value={page.value} key={index}>
                          {page.text}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
              {/* Should Display if Asean*/}

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Select Package
                </label>

                <div className="block relative w-full">
                  {/**Package will change depending on the state (international/asean) */}
                  <ul>
                    {packageList.map(function (list, index) {
                      return (
                        <li className="p-2 block" key={index}>
                          <input
                            type="radio"
                            className={`w-4 h-4 inline-block align-middle`}
                            id={list.code}
                            name="package"
                            onChange={handleChange}
                            checked={zone === list.code}
                            required={false}
                            desc={list.description}
                            // onInvalid={e => onInvalid(e)}
                          />{" "}
                          <span className="ml-1 inline-block align-middle">
                            {list.description}
                          </span>
                          <button
                            className="float-right"
                            onClick={(event) =>
                              handleBenefits(event, list.code)
                            }
                          >
                            <span className="text-yellow-500 text-sm font-bold text-center">
                              Benefits
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {/**Display if params sustained with correct data, total amount will change depending on user input */}
              <div className="mt-10 mb-10">
                {/* <label className="inline-block text-gray-500 text-sm font-bold">
                  Premium
                </label> */}

                <div
                  className="block relative w-full bg-blue-100 p-3 mt-2 rounded text-center"
                  style={{ display: "flex" }}
                >
                  <span style={{ flex: "1" }}>
                    <b>Premium</b> &nbsp;
                  </span>
                  <span className="text-lg text-blue-600" style={{ flex: "1" }}>
                    PHP {formatter.format(premium).replace("₱", "")}
                  </span>
                </div>
              </div>
              {coverType === "international" && (
                <div className="mt-10 mb-10">
                  <h3 className="text-lg font-bold title-font">REMINDERS:</h3>
                  <ul style={{ listStyleType: "disc" }}>
                    <li className="ml-6">
                      The package is intended for persons traveling on vacation,
                      study or seminars/conferences abroad.
                    </li>
                    <li className="ml-6 mt-1">
                      This package{" "}
                      <strong>
                        <u>EXCLUDES</u>
                      </strong>{" "}
                      coverage for the following:
                      <ul
                        className="ml-6 mt-1"
                        style={{ listStyleType: "circle" }}
                      >
                        <li>
                          Skilled persons/skilled laborers when traveling for
                          the purpose of employment or in the course of
                          employment
                        </li>
                        <li>
                          High-Risk occupations such as healthcare workers,
                          nursing professions
                        </li>
                        <li>One-way trips</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
              {/**Display if params sustained with the correct data, total amount will change depending on users input */}
              {coverType === "international" && (
                <p className="text-sm text-gray-500 mt-10 mb-20">
                  <strong>Covid-19 Coverage</strong> is included as part of your
                  coverage. To learn more tap{" "}
                  <a className="text-blue-500 font-bold" onClick={handleNote}>
                    here.
                  </a>
                </p>
              )}
              {coverType === "asean" && <p className="mt-10 mb-20"></p>}
            </div>
          </div>
          <button type="submit" style={{ display: "none" }} id="btnSubmit">
            submit
          </button>
        </form>
      </div>
    </>
  );
};

export default TravelDetails;
