import { useEffect } from "react";

export default function Benefits() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <>
      <div className="text-left">
        <div className="mb-5">
          <h3 className="text-lg font-bold leading-tight">Extension of Covers</h3>
        </div>
        <div>
          <ul className="text-xs text-gray-500 px-3">
            <li className="pb-3">
              A. FREE Auto Personal Accident (Up to maximum legal seating
              capacity)
              <span>Note: For Motorcycle, cover is for driver only.</span>
              <ul className="list-disc pl-8 py-3">
                <li>Accidental Death & Disablement<br/>-Php 100,000 / seat</li>
                <li>Accidental Medical Reimbursement<br/>-Php 20,000 / seat</li>
                <li>Surgical Benefit<br/>-Php 10,000 / seat</li>
                <li>Burial Benefit<br/>-Php 10,000</li>
              </ul>
              <span className=" block font-bold">This includes:</span>
              <ul className="list-disc pl-8 py-3">
                <li>Third party passengers, and </li>
                <li>
                  {" "}
                  Accident sustained by the assignee and declared assignee's
                  spouse and children while:
                  <ul>
                    <li>
                      Riding, alightning any road vehicle other than the insured
                      vehicle.
                    </li>
                    <li>
                      {" "}
                      As a passenger or driver, and any railway trains as
                      passenger.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="pb-3">
              B. Loss of Use
              <p className="py-3">
                {" "}
                Subject to proof of Loss of Use and presentation of
                evidence/proofs, provided that liability has been determine
                first, subject to SICI own evaluation.
              </p>
              <p className="pb-3">
                Php 300.00 per day per unit, maximum of Php 1,500.00 and in
                aggregate of 5 days, subject to submission of original Official
                Receipts. In lieu of ORs, settlement to be based on the
                reasonable amount of transportation fare consumed by the insured
                by submitting a Summary of Expenses.
              </p>
              <p className="pt-3">Annual aggregate limit of Php 1,500.00 Allowance starts:</p>
              <ul className="list-disc pl-8 py-3">
                <li>3 days from the commencement of repairs per occurrence.</li>
                <li>
                  On the date the company has declared the Schedule Vehicle a
                  constructive loss due to accident and will cease upon release
                  of settlement check or limit of Php 5,000.00 whichever comes
                  first.
                </li>{" "}
                <li>
                  In case of theft/carnap and catastrophic claim, Loss of Use
                  will not respond.
                </li>
              </ul>
            </li>

            <li className="pb-3">
              C. Standard Insurance Technical and Training Center
              <p className="py-3">
                {" "}
                With the use of our technologically advanced repair and
                restoration facility located in Naic, Cavite, assured can avail
                the following benefits:
              </p>
              <ul className="list-disc pl-8 py-3">
                <li>
                  {" "}
                  Free pickup and delivery with our towing service provider and
                  our very own tow trucks and boom truck (within Metro Manila
                  and nearby provinces)
                </li>
                <li>
                  With TTC's utilization of multi-sourced parts, deductibles are
                  waived on all valid claims.
                </li>
                <li>
                  TTC may provide a loaner car while the insured unit is being
                  repaired with us.
                </li>
              </ul>
            </li>

            <li className="pb-3">
              D. Auto Road Assistance Program
              <p className="py-3">
                Free 24 hours emergency roadside assistance (For Private Cars
                including SUVs/AUVs up to fifteen (15) years of age and
                Motorcycle not less than 400cc).
              </p>
              <span className="font-bold pb-3">Other Benefits/Conditions:</span>
              <p className="pt-3">
                All accessories installed addition to the standard built-in
                accessories (non- standard) are subject to declaration and
                additional premium.
              </p>
              <ul className="list-disc pl-8 py-3">
                <li className="pb-3">
                  <span className="font-bold block pb-1">Automatic Extension of Period of Insurance</span>
                  <p>
                    {" "}
                    30 days subject to additional pro-rata premium based on
                    expiring terms and conditions.
                  </p>
                </li>

                <li className="pb-3">
                  <span className="font-bold block pb-1">Good Driver Bonus</span>
                  <p>
                    5% of the premium shall be refunded and deducted from the
                    renewal policy with zero claim in the previous year.
                  </p>
                </li>

                <li className="pb-3">
                  <span className="font-bold block pb-1">Repair Shop Clause</span>
                  <p>
                    If the age of the insured vehicle at the time of loss is:
                  </p>
                  <span>Below twelve (12) years old - Casa</span>
                  <span>
                    Above twelve (12) years old - Non- Dealer/Non-Case
                    accredited repair shop
                  </span>
                </li>

                <li className="pb-3">
                  <span className="font-bold block pb-1">Importation Clause</span>
                  <p>
                    Warranted that in the event of loss to the whole vehicle or
                    losses necessitating importation of parts not locally
                    manufactured, the Company's liability shall be limited to
                    the actual cost of the vehicle or the imported parts
                    concerned less cost of taxes, freight and other
                    miscellaneous charges relative thereto.
                  </p>
                </li>
                <li className="pb-3">
                  <span className="font-bold block pb-1"> Personal Accident</span>
                  <ul>
                    <li>
                      Covers the Assured against Accidental Death with limit up
                      to Php 100,000.00
                    </li>
                    <li>
                      Scope is anywhere in the world, 24/7 excluding whilst on
                      board the insured vehicle.
                    </li>
                  </ul>
                </li>

               

                <li className="pb-3">
                  <span className="font-bold block pb-1">Immediate Repair</span>
                  <ul>
                    <li>
                      of Windshield, Windshield Molding, Sunroofs, Back Glass,
                      Window Glasses, Side View Mirror (subject to reimbursement
                      of actual charges; not subject to depreciation).
                    </li>
                    <li>
                      Insured may also choose to have repairs done in TTC.
                    </li>
                  </ul>
                </li>

                <li className="pb-3">
                  <span className="font-bold block pb-1"> Airbag Clause</span>
                  <ul>
                    <li>If applicable</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
