import React, { useState } from "react";

const FaqProduct = () => {
  return (
    <>
      <div className="w-full">
        <input type="checkbox" name="panel" id="panel1" className="hidden" />
        <label
          htmlFor="panel1"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What is Standard Insurance Car Protect?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel1">
            <p className="text-sm">
              Car Protect is a comprehensive motorcar insurance that protects
              your vehicle from losses arising from accidents, theft and Acts of
              Nature (optional). Car Protect was designed exclusively for
              four-wheeled private use vehicles including sedans, SUVs, AUVs,
              vans, station wagons and pickups.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What are the benefits of this product?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              The Standard Insurance Car Protect responds in cases where:
            </p>
            <ul className="text-sm mt-3">
              <li>• the assured’s vehicle is damaged, </li>
              <li>• the assured or its passengers are injured, </li>
              <li>
                • the assured’s vehicle caused damage to property of other
                people (i.e., another car or a fence),{" "}
              </li>
              <li>
                • the assured’s vehicle injured other people (i.e. a pedestrian)
              </li>
            </ul>

            <p className="text-sm mt-5">
              A. Compulsory Third Party Liability (CTPL){" "}
            </p>
            <span className="text-xs mt-1 block">
              Note: Bought separately from Comprehensive Insurance.
            </span>
            <p className="text-sm mt-3">
              Covers Bodily injury and/or death caused to a Third Party
              (person/s) by the Assured; required by law upon vehicle
              registration. Standard Insurance will settle a valid claim of up
              to PhP100,000, subject to the policy limits. This is purchased
              separately.
            </p>

            <p className="text-sm mt-5">B. Comprehensive Insurance </p>
            <ul className="text-sm mt-3">
              <li>
                • Own Damage and Theft (OD/Th)
                <span className="ml-3 my-2 text-xs block">
                  Covers accidental collision, overturning, falling, fire and
                  malicious acts of a Third Party on the Assured’s vehicle;
                  Standard Insurance will settle a valid claim of up to the Sum
                  Insured.
                </span>
              </li>
              <li>
                • Voluntary Third Party Bodily Injury (VTPBI)
                <span className="ml-3 my-2 text-xs block">
                  Responds in excess of the Assured’s CTPL insurance in respect
                  of bodily injury and/or death caused to a Third Party
                  (person/s) by the Assured. Standard Insurance will settle a
                  valid claim of up to the sum insured, over and above the
                  PhP100,000 covered by the Assured’s CTPL insurance.{" "}
                </span>
              </li>
              <li>
                • Voluntary Third Party Property Damage (VTPPD)
                <span className="ml-3 my-2 text-xs block">
                  Covers damage to property owned by one or more third parties,
                  arising from an accident caused by the Assured’s vehicle.
                  Standard Insurance will settle a valid claim of up the sum
                  insured.
                </span>
              </li>
              <li>
                • Personal Accident (PA) Rider (Auto PA)
                <span className="ml-3 my-2 text-xs block">
                  Covers Medical treatment, dismemberment, permanent disability
                  and/or death arising from bodily injuries caused by an
                  external accident while the Assured or an authorized driver or
                  any of the vehicle’s passengers are riding in, boarding or
                  alighting from the insured vehicle. Standard Insurance will
                  settle a valid claim of up to PhP100,000 per person, up to its
                  maximum legal seating capacity.{" "}
                </span>
              </li>

              <li>
                • Acts of Nature (AON)
                <span className="ml-3 my-2 text-xs block">
                  Covers direct loss of, or damage to, the insured vehicle, its
                  accessories and spare parts caused by an earthquake, typhoon,
                  flood, volcanic eruption, lightning, fire and/or other
                  convulsions of nature; Standard Insurance will settle a valid
                  claim of up to the Sum Insured.{" "}
                </span>
              </li>

              <li>
                • Roadside Assistance
                <span className="ml-3 my-2 text-xs block">
                  Provides free towing service, battery boosting, locksmith,
                  personal assistance up to the limit specified.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-3" className="hidden" />
        <label
          htmlFor="panel-3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How can I avail this product?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              To avail of the Standard Insurance Car Protect:
            </p>
            <ul className="text-sm mt-3">
              <li>
                <strong>STEP 1. </strong>Login to your GCash app, then click the
                GInsure icon
              </li>
              <li>
                <strong>STEP 2. </strong>Choose any Standard Insurance product
                for “Car”
              </li>
              <li>
                <strong>STEP 3. </strong>Provide the necessary customer and car
                details{" "}
              </li>
              <li>
                <strong>STEP 4. </strong>Review your policy details and confirm
              </li>
              <li>
                <strong>STEP 5. </strong> Click “Confirm and Pay”
              </li>
              <li>
                <strong>STEP 6. </strong> Expect a copy of your insurance policy
                and official receipt via email.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-4" className="hidden" />
        <label
          htmlFor="panel-4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What type of vehicles can be insured?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <ul className="text-sm mt-3">
              <li>
                • Private Cars such as Sedan, AUV, MPV, Wagon, Van, Pick-up
              </li>
              <li>• Age up to 15 years old</li>
              <li>• Usage must be Private (not as taxi or Grab)</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-5" className="hidden" />
        <label
          htmlFor="panel-5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          Am I eligible to avail this product?
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              You can avail this product if you are a FULLY VERIFIED GCASH user:
            </p>
            <ul className="text-sm mt-3">
              <li>• who owns a car that is insurable</li>
              <li>
                • who would like to purchase an insurance policy for somebody
                else
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-6" className="hidden" />
        <label
          htmlFor="panel-6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I buy a policy for someone else?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <p className="text-sm">
              Yes, you can buy a policy on behalf of someone else or if you are
              not the owner of the vehicle. You will be asked to indicate the
              name of the registered owner and confirm that the vehicle to be
              insured is for private use and not more than 15 years old.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-7" className="hidden" />
        <label
          htmlFor="panel-7"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I pay for my insurance?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel7">
            <p className="text-sm">
              Amount for annual premium payment will be automatically deducted
              from your GCash wallet upon purchase. Please make sure you have
              enough balance in your GCash wallet to make a successful
              transaction.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-8" className="hidden" />
        <label
          htmlFor="panel-8"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            When will the policy be issued?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel8">
            <p className="text-sm">
              We will issue an e-Policy after you have completed the application
              process and payment. It will be sent to your registered email
              address within an hour from the time of your purchase. If you did
              not receive it, please check your SPAM Folder or contact us at the
              following:
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-9" className="hidden" />
        <label
          htmlFor="panel-9"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I avail of emergency assistance or roadside assistance?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel9">
            <p className="text-sm">
              Please call our 24/7 hotline number at (632) 8845-1111 for details
              of the roadside assistance program, or please go to{" "}
              <a
                href="https://bit.ly/EmergencyTowing"
                target="_blank"
                className="text-yellow-500"
              >
                {" "}
                https://bit.ly/EmergencyTowing
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-10" className="hidden" />
        <label
          htmlFor="panel-10"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Will my bank be automatically provided with a copy of my insurance
            policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel10">
            <p className="text-sm">
              No. If your vehicle is mortgaged, you are required to submit a
              copy of your insurance policy to the bank. If you would like us to
              do this for you, please indicate in the application process the
              following:
            </p>
            <p className="mt-3 text-sm">Address</p>
            <p className="text-sm">Contact Person</p>
            <p className="text-sm">
              Contact Number
            </p>
            <p className="text-sm">
              Email Address
            </p>

            <p className="mt-3 text-sm">
              To reconfirm the delivery, you may call us at:
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-11" className="hidden" />
        <label
          htmlFor="panel-11"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I insure my vehicle even if ownership has not yet been
            transferred to my name?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel11">
            <p className="text-sm">
              Yes, you can still purchase an insurance policy provided that a
              notarized Deed of Sale is submitted to us.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-12" className="hidden" />
        <label
          htmlFor="panel-12"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Are the accessories installed in my vehicle automatically covered in
            my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel12">
            <p className="text-sm mt-3">
              Standard built-in accessories are automatically covered in the
              policy. These are the accessories already included in the vehicle
              when it was newly released from the dealer and becomes part of the
              sum insured or the total value.
            </p>

            <p className="text-sm mt-3">
              On the other hand, non-standard accessories are NOT automatically
              covered in your policy. These are the items which you bought
              separately and installed in your vehicle. Such items added or
              installed in the vehicle must be immediately declared to us so
              they can be covered in the event of an accident or theft.
              Additional premium shall be charged for the inclusion of
              non-standard accessories.
            </p>

            <p className="mt-3 text-sm">
              To declare non-standard accessories, please contact us at the following:{" "}
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-13" className="hidden" />
        <label
          htmlFor="panel-13"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How many insurance policies can I avail?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel13">
            <p className="text-sm">
              You can avail as many car insurance policies as you can depending
              on the number of cars that you own.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-14" className="hidden" />
        <label
          htmlFor="panel-14"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Who can I contact if I want the original copy of my policy to be
            delivered to me?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel14">
            <p className="mt-3 text-sm">
              If you prefer a hard copy of the policy, you may contact us at the following: 
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqProduct;
