import ContactUs from "../components/organisms/Contact-Us";
import Header from "../components/organisms/Header";

const ContactUsPage = (props) => {
  return (
    <div>
      <Header
        headerTitle={props.headerTitle}
        subHeader={props.subheaderTitle}
      />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image"/>
      </div>
      <div className="p-5 pt-12">
        <img
          src={"/images/sici-logo.png"}
          width="170"
          height="50"
          alt="logo"
        />
      </div>
      <main className="p-5">
        <div className="">
          <ContactUs />
        </div>
      </main>
    </div>
  );
};
export default ContactUsPage;
