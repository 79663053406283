import { useState, useEffect } from "react";
import Covered from "./Motor-Covered/Covered";
import { TabButtons } from "../molecules/TabButtons";
import Modal from "../molecules/Modal";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";
import {Link} from "react-router-dom";

const covered = [
  {
    id: "1",
    title: "Own Damage",
    description:
      "Damages caused by accidental crashing, overturning, falling, fire, and harmful acts by others.",
    imageUrl: "/images/car.png",
  },
  {
    id: "2",
    title: "Theft",
    description:
      "Loss of your whole insured vehicle or parts of your vehicle due to thieves or carnappers.",
    imageUrl: "/images/verified.png",
  },
  {
    id: "3",
    title: "Acts of Nature (AON)",
    description:
      "Coverage against the forces of nature. Protects your car against damages directly caused by typhoon, flood, hurricane, volcanic eruption, earthquake or other convulsions of nature.",
    imageUrl: "/images/storm.png",
  },
  {
    id: "4",
    title: "Excess Bodily Injury and Property Damage",
    description:
      "Voluntary coverage against damages caused by your vehicle to others (that are beyond the Compulsory Third Party Liability)",
    imageUrl: "/images/bi-pd.png",
  },
  {
    id: "5",
    title: "Personal Accident Rider",
    description:
      "Voluntary coverage for the insured vehicle’s passengers. This financially protects them against injuries or death due to car accident.",
    imageUrl: "/images/pa-rider.png",
  },
];

const notCovered = [
  {
    id: "1",
    title: "Your car was rented out to others.",
    imageUrl: "/images/car-key.png",
  },
  {
    id: "2",
    title: "You consumed alcohol before or while driving.",
    imageUrl: "/images/no-alcohol.png",
  },
  {
    id: "3",
    title:
      "Your car is damaged due to normal wear and tear from weather and regular use.",
    imageUrl: "/images/motor.png",
  },
  {
    id: "4",
    title: "Your car was damaged due to animal bite/s.",
    imageUrl: "/images/wire.png",
  },
  {
    id: "5",
    title: "Your car was damaged due to acts of terrorism.",
    imageUrl: "/images/explosion.png",
  },
];

const MotorCoverSection = ({ ...props }) => {
  Cache.setItem("plateNumberState", null);
  Cache.setItem("carDetailsState", null);
  Cache.setItem("quoteState", null);
  Cache.setItem("carDisplay", null);
  Cache.setItem("otherDetailsState", null);
  let history = useHistory();
  const [coveredState, setCoveredState] = useState(0); // 0 - question , 1 - user
  const [tabSectionState, setTabSectionState] = useState([]);
  useEffect(() => {
    Cache.setItem("flag", 0);
  }, []);

  const coveredSubTitle =
    "The Standard Motorcar Insurance will protect you and your vehicle from the following:";
  const notCoveredSubTitle =
    "Your vehicle insurance will not cover you in the following cases:";

  useEffect(() => {
    let tabList = [];

    tabList = [
      {
        title: "Covered",
        keyId: 0,
        status: "active",
      },
      {
        title: "Not Covered",
        keyId: 1,
        status: "inactive",
      },
    ];

    setTabSectionState(tabList);
  }, []);

  const changeActiveTab = (e) => {
    let activeButton = e.target.value;
    let newList = [];
    tabSectionState.forEach((element) => {
      const tab = { ...element };
      tab.status =
        parseInt(element.keyId) == parseInt(activeButton)
          ? "active"
          : "inactive";
      newList.push(tab);
    });
    setTabSectionState(newList);
  };

  useEffect(() => {
    tabSectionState.forEach((element) => {
      if (element.status === "active") setCoveredState(element.keyId);
    });
  }, [tabSectionState]);

  const SectionState = () => {
    if (coveredState === 0) {
      return (
        <Covered
          covered={covered}
          subTitle={coveredSubTitle}
          cover="inclusions"
          title="covered"
          product="motor"
        />
      );
    } else {
      return (
        <Covered
          covered={notCovered}
          subTitle={notCoveredSubTitle}
          cover="exclusions"
          title="notCovered"
          product="motor"
        />
      );
    }
  };

  const handleConfirmation = (event) => {
    const checked = event.target.checked;

    if (checked === true) {
      //setOpen(true);
      props.enableButton();
    } else {
      props.disableButton();
    }
  };
  const [isLoading, setIsLoading] = useState(0);
  const handleDataConfirmation = (event) => {
    const checked = event.target.checked;
    setIsLoading(1);
    if (checked === true) {
      Cache.setItem("flag", 1);
    } else {
      Cache.setItem("flag", 0);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const DisableButton = (event) => {
    Cache.setItem("flag", 0);
  };
  const gotoPlateValidation = (event) => {
    history.push("/motor-insurance/form");
  };

  const clickHandle=()=>{
    document.getElementById("handleConfirm").click();
  }

  return (
    <>
      <div className="mb-8">
        <h3>
          Stay safe and worry-free while driving! Get full insurance for your
          vehicle today.
        </h3>
      </div>
      <TabButtons ListData={tabSectionState} onClick={changeActiveTab} />
      <SectionState />

      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg mt-5">
        <p className="text-sm">This only issues quotations for private cars.</p>
        <div className="flex mt-5">
          <div className="pr-3">
            <input
              type="checkbox"
              className="w-4 h-4"
              onChange={handleConfirmation}
              id={"handleConfirm"}
            />
          </div>
          <div>
            <p className="text-sm" onClick={clickHandle}>
              I confirm that the vehicle I’m insuring is NOT used to carry
              fare-paying passengers.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white p-5 mt-5 more-questions">
        <div className="relative flex">
          <div className="flex-1 flex">
            <div className="pl-3 pr-3">
              <div className="w-full">
                <img
                  src={"/images/help.png"}
                  alt="help"
                  className="covered-image"
                />
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
              </div>
            </div>
            <div className="w-full">
              <h4 className="font-black text-left text-sm title-font">
                Have More Questions
              </h4>
              <p className="text-left text-xs text-gray-400">
                We're here to help
              </p>

              <div className="mt-8 mb-3 text-left">
                <ul>
                  <Link
                    to={"/faq"}
                    className="border-b border-gray-300 pb-2 block"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Frequently Asked Questions
                    </span>
                    <div className="absolute footer-position">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                  <Link
                    to={"/contact"}
                    className="border-b border-gray-300 pb-2 block mt-2"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Contact Us
                    </span>
                    <div className="absolute footer-position-contact">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={props.open}
        setOpen={props.setOpen}
        title="Data Privacy"
        saveData={gotoPlateValidation}
        isDisabled={Cache.getItem("flag")}
        disableButton={DisableButton}
        isLoading={isLoading}
      >
        <form>
          <div className="">
            <div className="flex my-1">
              <div className="pr-3">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  onChange={handleDataConfirmation}
                />
              </div>
              <div>
                <p className="text-xs">
                  In compliance with the Data Privacy Act (DPA) of 2012, and its
                  Implementing Rules and Regulations (IRR) effective September
                  9, 2016, I allow Standard Insurance to collect, store and
                  process my information to provide me certain services declared
                  in relation to the insurance policy/ies I am purchasing.
                </p>

                <p className="text-xs mt-3">
                  For more information on how Standard Insurance protects its
                  data, you may visit our Privacy Policy at{" "}
                  <a className={"text-blue-500"} href="https://standard-insurance.com/privacy-policy.html" target="_blank">
                  https://standard-insurance.com/privacy-policy.html
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default MotorCoverSection;
