import { useState, useEffect } from "react";
import Accordion from "../../molecules/Accordion";
import { Link } from "react-router-dom";

export default function PetFaqList() {
  const AccordionTitles = [
    "How does Standard Insurance Pet Insurance work?",
    "Are treatments only available with Accredited Vets?",
    "What breeds are eligible for Standard Insurance Pet Health Insurance?",
    "What is the age limit/s of the dog or cat at the time of purchase of insurance?",
    "What are the requirements to apply for a Standard Insurance Pet Health?",
    "What does Standard Insurance Pet Health NOT cover?",
    "What is a Pre-existing Condition?",
    "Is there a Waiting Period?",
    "How to make a claim?",
  ];
  const [isLoading, setIsLoading] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {}, [isLoading]);

  const AccordionContents = (index) => {
    if (index === 0) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <ul className="list-disc ml-3">
              <li>
                Pet insurance covers up to 100% of eligible vet bills when your
                dog or cat gets sick or is injured up to the maximum benefit
                limit of P50,000.
              </li>
              <li>
                There is No Deductible, which means you do not need to pay
                additional fees when you make a claim!
              </li>
              <li>
                There is No per condition limit which means you can maximize the
                benefit limit of PHP 50,000 for the treatment of single or
                multiple conditions.
              </li>
              <li>Benefit will be paid in reimbursement basis.</li>
              <li>
                This means that you must pay first the Vet Expenses and we will
                process reimbursement of your eligible Vet expenses.
              </li>
            </ul>
          </div>
        </>
      );
    } else if (index === 1) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>
              You may choose to get your pet treated by non-accredited Vets
              anywhere in the Philippines so long that they are licensed and
              registered.
            </p>
          </div>
        </>
      );
    } else if (index === 2) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>All Dog and Cat Breeds.</p>
          </div>
        </>
      );
    } else if (index === 3) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>The earliest age of enrollment is eight (8) weeks old</p>
            <p className="mt-2 text-sm">
              The maximum age of entry is 9 years old for cover that includes
              Illness.
            </p>
            <p className="mt-2">Age Bracket Definition</p>
            <ul className="list-disc ml-3">
              <li>Puppy/Kitten : 8 weeks to 11 months</li>
              <li>Puppy/Kitten : 8 weeks to 11 months</li>
              <li>Senior : 7 years old to 9 years old</li>
            </ul>
          </div>
        </>
      );
    } else if (index === 4) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>
              Application is easy! Simply complete our application form and we
              will process your application. We will only request for your pet’s
              complete veterinary history when you make your first claim.
            </p>
            <p className="mt-5">
              Should you wish to apply, kindly fill out our online application
              form using this link:{" "}
              <a href="https://bit.ly/sipethealthform" target="_blank">
                https://bit.ly/sipethealthform
              </a>
            </p>
            <p className="mt-5">
              Or you may contact our customer service hotline at (02) 8 845 1111
              and we will be happy to assist you with your application over the
              phone.
            </p>
          </div>
        </>
      );
    } else if (index === 5) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>
              The following conditions are some of the exclusions in the
              coverage:
            </p>
            <ul className="list-disc ml-5 mt-2">
              <li>Pre-existing Conditions</li>
              <li>Behavioral problems</li>
              <li>
                Elective treatments, procedures, food and diet, grooming and
                pregnancy are some examples of what Pet Insurance does not
                cover.
              </li>
              <li>
                For dogs: infectious canine hepatitis (canine adenovirus),
                parvovirus, canine distemper, parainfluenza, canine influenza
                and all forms of kennel cough;
              </li>
              <li>
                For cats: panleukopenia, chlamydia, leukemia (FeLV), Feline
                Immuno Deficiency Virus (FIV) and Feline Infectious Peritonitis
                (FIP), viral rhinotracheitis, calicivirus, herpes virus and all
                forms of cat flu;
              </li>
              <li>
                Conditions caused by ecto parasites, worms, ticks and fleas
              </li>
              <li>Preventative Care and Treatments</li>
              <li>Dental Conditions</li>
            </ul>
          </div>
        </>
      );
    } else if (index === 6) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>A pre-existing condition is:</p>
            <p className="mt-2">
              A condition that existed or occurred prior to the commencement
              date of your first policy period or within any applicable waiting
              period.
            </p>
            <ul className="list-disc ml-5 my-2">
              <li>
                A condition that you or your vet were aware of, or a reasonable
                person in your circumstances would have been aware of.
              </li>
              <li>
                This is irrespective of whether the underlying or causative
                condition was diagnosed at the time.
              </li>
            </ul>
          </div>
        </>
      );
    } else if (index === 7) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>
              Yes, there is a Waiting Period. Waiting Period means a period
              starting from the Commencement Date of the first Policy Period
              during which a Condition that occurs or shows symptoms or signs
              will be excluded from Cover unless otherwise stated on your Policy
              Schedule.
            </p>
            <p>
              A condition that existed or occurred prior to the commencement
              date of your first policy period or within any applicable waiting
              period.
            </p>

            <p className="mt-2">The Waiting Period for:</p>
            <ul className="list-disc ml-5 my-3">
              <li>
                Conditions covered under Illness Cover and, if applicable,
                Paralysis Tick Benefit, are subject to 30 days; and
              </li>
              <li>
                Cruciate ligament Conditions and any Conditions arising
                therefrom irrespective of cause or origin, are subject to 6
                months, unless:
                <p>
                  we have received a completed and signed 'Cruciate Ligament
                  Exam Form' from your Vet within fourteen (14) days of the
                  cruciate examination date certifying that your Pet has been
                  examined, at your expense, on or after the policy Commencement
                  Date; and
                </p>
                <p>
                  you receive written notification from us confirming our
                  agreement to waive this Waiting Period following our
                  assessment of the information provided on the 'Cruciate
                  Ligament Exam Form'.
                </p>
              </li>
            </ul>
            <p className="text-sm">
              Any waiver is at our discretion, and we are not required to
              provide justification for declining a request under this
              provision.
            </p>
          </div>
        </>
      );
    } else if (index === 8) {
      return (
        <>
          <div className="my-5 mx-3 text-sm">
            <p>
              To make a claim you need to submit to us the following documents:
            </p>

            <ul className="list-disc ml-5 my-3">
              <li>1. Completed Claim Form signed by you and your Vet.</li>
              <li>
                2. Itemized invoice showing that all vet expenses incurred are
                paid in full.
              </li>
              <li>3. Consultation notes.</li>
              <li>
                4. Complete Veterinary history of your pet if you are making
                your claim for the first time
              </li>
            </ul>
            <p className="text-sm">
              We will process your claim in 3-10 business days provided that
              complete documentation is received.
            </p>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className="text-left">
        <Accordion
          AccordionTitles={AccordionTitles}
          AccordionLocation={"pet-faq"}
          AccordionContents={AccordionContents}
        />

        <p className="text-xs mt-5 mb-2">See the full coverage of Benefits <Link
                to={{
                  pathname: "/inclusions" ,
                  state: { lob: "pet" },
                }}
                className="text-yellow-400 font-bold"
              >here</Link></p>
      </div>
    </>
  );
}
