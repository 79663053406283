import React, { useState } from "react";

const FaqProductInternational = () => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What is Travel Protect International?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <p className="text-sm">
              Travel Protect International is a comprehensive and cashless
              travel insurance plan that protects a traveler from certain
              financial risks and losses that can occur while traveling the
              world, including COVID-19. These risks may include delayed trips,
              loss of luggage, trip cancellation, a medical emergency overseas,
              accidental death, and more.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What does “Cashless” benefit means?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              When you travel and require hospitalization due to an accident or
              sickness that is not pre-existing, Travel Protect International
              will directly pay the hospital so that you don&#39;t need to shell
              out cash to pay the bills. The cashless medical benefit covers
              medical bills, including surgery, professional fees, and
              pharmaceutical products prescribed by the attending physician. The
              hospitalization will be &quot;cashless,&quot; provided the total
              bill amount falls within your travel policy limit.
            </p>

            <p className="mt-3 text-sm">
              Other Cashless Benefits:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Emergency Medical Evacuation/Repatriation</li>
                <li>Repatriation of Mortal Remains</li>
                <li>Care of Minor Children</li>
                <li>Compassionate Visit</li>
                <li>Delivery of Medicine</li>
                <li>Loss of Travel Documents</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel3" className="hidden" />
        <label
          htmlFor="panel3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Who can avail of Travel Protect International?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              Travel Protect International is designed for individuals traveling
              on vacation, study, or seminars/conferences.
            </p>
            <p className="text-sm mt-3">
              Travelers who are between 0-59 years old, may avail of Travel
              Protect International with COVID-19 based on regular rate.
              Travelers who are between 60 to 75 years old may be covered
              subject to a premium surcharge.
            </p>
            <p className="text-sm mt-3">
              Travel Protect International excludes cover for skilled persons/
              skilled laborers when travelling for the purpose of employment or
              in the course of employment. Also, COVID-19 high risk occupations
              such as healthcare workers, nursing professions are excluded.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel4" className="hidden" />
        <label
          htmlFor="panel4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What are the benefits of Travel Protect International?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              Travel Protect International provides the following benefits
              during travel:
            </p>
            <p className="text-sm mt-3">
              <strong>Travel Inconvenience Benefit</strong>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Loss of Personal Money</li>
                <li>Car Rental Excess Protection</li>
                <li>
                  Trip Cancellation including claims due to/ arising from
                  COVID-19
                </li>
                <li>Delayed Departure (Outbound and Inbound)</li>
                <li>Trip Postponement</li>
                <li>Missed Connection (Outbound and Inbound)</li>
                <li>Diversion of Trip (outbound and inbound)</li>
                <li>
                  Inconvenience Cash Assistance for Delayed Departure, Missed
                  Connection and Diversion of Trip (Not Receipted)
                </li>
                <li>
                  Trip Termination including claims due to/ arising from
                  COVID-19
                </li>
                <li>Baggage Delay</li>
                <li>
                  Inconvenience Cash Assistance for Baggage Delay (Not
                  Receipted)
                </li>
                <li>Loss or Damage of Baggage</li>
                <li>Hijack</li>
              </ul>

              <p className="mt-3">
                <strong>Emergency Medical Assistance</strong>
              </p>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>
                  Medical Treatment including claims due to/ arising from
                  COVID-19
                </li>
                <li>Emergency Medical Evacuation/Repatriation</li>
                <li>Repatriation of Mortal Remains</li>
                <li>Care of Minor Child/ren</li>
                <li>Compassionate Visit</li>
                <li>Delivery of Medicine</li>
                <li>Hospital Income</li>
                <li>Emergency Dental Care</li>
              </ul>

              <p className="mt-3">
                <strong>Personal Accident Benefit</strong>
              </p>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Accidental Death</li>
                <li>Permanent Total Disablement</li>
                <li>Burial Assistance</li>
                <li>Personal Liability</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel5" className="hidden" />
        <label
          htmlFor="panel5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            How can I avail of Travel Protect International?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              To avail of Travel Protect International, simply do the following:
            </p>
            <ul className="text-sm mt-3">
              <li>
                <strong>STEP 1. </strong>Login to your GCash app, then click the
                GInsure icon
              </li>
              <li>
                <strong>STEP 2. </strong>Choose Standard Insurance product for
                "Travel"
              </li>
              <li>
                <strong>STEP 3. </strong>Provide the necessary customer and travel
                details
              </li>
              <li>
                <strong>STEP 4. </strong>Review your policy details and confirm
              </li>
              <li>
                <strong>STEP 5. </strong>Click "Confirm and Pay"
              </li>
              <li>
                <strong>STEP 6. </strong> Expect a copy of your insurance policy
                and official receipt via email.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel6" className="hidden" />
        <label
          htmlFor="panel6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International cover travels intended for
            employment?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <p className="text-sm">
              Travel Protect International is intended for persons traveling on
              vacation, study, or seminars/conferences. It excludes cover for
              skilled persons/ skilled laborers when traveling for employment or
              in the course of employment.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel7" className="hidden" />
        <label
          htmlFor="panel7"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            When does Travel Protect International coverage becomes effective
            and when does it end?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel7">
            <p className="text-sm">
              Coverage shall start: 
              <ul className="text-sm mt-3 ml-3 list-disc">
                <li>
                  When the Policyholder leaves his/her home in the Usual Country
                  of Residence to commence the travel, but not earlier than
                  twenty-four (24) hours before the start date shown on the
                  policy schedule as evidenced by the original departure time
                  shown on the travel ticket, It ends under all other sections:
                </li>
                <li>
                  No more than 24 hours from actual date of return as evidenced
                  by the original arrival time shown on the travel ticket; or
                </li>
                <li>
                  No later than end date of the policy as shown in the policy
                  schedule; or when the Policyholder is in his/her home address
                  in the Usual Country of Residence, whichever is earlier.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel8" className="hidden" />
        <label
          htmlFor="panel8"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I buy Travel Protect International when I am about to leave
            already or I’m at the airport already?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel8    ">
            <p className="text-sm">
              Yes, for as long as your trip has yet to start and there is no
              known loss.
            </p>
            <p className="text-sm mt-3">
              Note: Trip Cancellation benefit will respond if the travel
              insurance is purchased at least three (3) days prior to the
              scheduled trip.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel9" className="hidden" />
        <label
          htmlFor="panel9"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International include any coverage for medical
            expenses related to COVID-19?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel9">
            <p className="text-sm">
              Covid-19 related claims is covered under Travel Protect
              International. The Insured person is covered if diagnosed with
              COVID-19 during the insured trip or within fifteen (15) days prior
              to the scheduled trip departure date, provided travel insurance is
              purchase prior to such diagnosis.
            </p>
            <p className="text-sm mt-3">
              Trip Cancellation benefit will respond only if the travel
              insurance policy was purchased at least three (3) days before the
              scheduled trip.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel10" className="hidden" />
        <label
          htmlFor="panel10"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Up to how many days can I be covered by Travel Protect
            International?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel10">
            <p className="text-sm">
              Travel Protect International can cover trips outside the
              Philippines for a maximum of ninety (90) days.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel11" className="hidden" />
        <label
          htmlFor="panel11"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Is Travel Protect International accepted when getting Schengen visa?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel11">
            <p className="text-sm">
              Yes, Travel Protect International is accredited by all Schengen
              States embassies. The Protect 2.5M Plan meets the insurance
              requirements of Schengen embassies in the Philippines.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel12" className="hidden" />
        <label
          htmlFor="panel12"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I refund my insurance premium if my visa application had been
            denied?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel12">
            <p className="text-sm">
              Cancellation of the Travel Protect International cover shall only
              be allowed for Policyholders with denied visa applications upon
              presentation of a denial letter issued by the embassy on or before
              the commencement of the scheduled flight. Refund of premium shall
              be exclusive of applicable taxes and service fees.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel13" className="hidden" />
        <label
          htmlFor="panel13"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Will Travel Protect International cover trip cancellation claim due
            to travel suspensions declared by other countries?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel13">
            <p className="text-sm">
              The COVID-19 coverage applies only if you are diagnosed with
              COVID-19 fifteen (15) days before your scheduled departure. Trip
              cancellation solely due to a pandemic-related travel advisory
              issued by the government is not covered.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel14" className="hidden" />
        <label
          htmlFor="panel14"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International provide any Trip Curtailment
            Coverage?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel14">
            <p className="text-sm">
              <strong>Trip Cancellation</strong>
            </p>

            <p className="mt-3 text-sm">
              Trip cancellation means the abandonment of the scheduled trip by
              outright cancellation of reservation made by the Policyholder for
              the travel ticket and accommodations resulting in the refund of a
              portion of the payments made in advance.
            </p>
            <p className="mt-3 text-sm">
              The Company will reimburse up to the limits stated in the Schedule
              of Benefits the non-refundable portion of the travel ticket and
              accommodation expenses or flight rebooking fees (if any),
              including non-refundable cruise tickets if the trip has to be
              cancelled.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel15" className="hidden" />
        <label
          htmlFor="panel15"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What are the specific exclusion/s to Trip Cancellation?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel15">
            <p className="text-sm">
              The Company will not pay for any loss that is covered by any other
              existing insurance scheme, government program or which will be
              paid or refunded by a hotel, airline or travel agent in relation
              to the cancelled trip.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel16" className="hidden" />
        <label
          htmlFor="panel16"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Will Travel Protect International respond if my flight was delayed
            or diverted?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel16">
            <p className="text-sm">
              <strong>Delayed Departure (Outbound and Inbound)</strong>
            </p>
            <p className="mt-3 text-sm">
              When the departure of the booked outward or inbound international
              journeys (by aircraft, ship, cruise ship, coach or cross-channel
              train) contracted by the Policyholder for travelling is delayed by
              at least six (6) hours because of acts of nature as defined in the
              policy, a mechanical breakdown of the carrier or sunset
              limitation, the Company, shall reimburse any additional expenses
              incurred such as transportation, non-refundable cruise and hotel
              accommodation/bookings, prime necessity expenses (i.e., food,
              necessary clothing and toiletries or those items that are
              indispensable while the Policyholder is waiting to leave). Such
              payment will be made upon presentation of the corresponding and
              relevant original invoices and original receipts and up to the
              limit specified in the Policy Schedule.
            </p>
            <p className="mt-3 text-sm">
              {" "}
              This guarantee duly excludes any delay that is a direct
              consequence of a strike called by employees belonging to the
              airline company and/or the departure or arrival airports for the
              flight, or to service companies subcontracted by the same.
            </p>

            <p className="mt-3 text-sm">
              Also excluded from this guarantee are those delays that occur on
              charter or non-regular flights.
            </p>
            <p className="mt-3 text-sm">
              <strong>Flight Diversion</strong>
            </p>

            <p className="mt-3 text-sm">
              If the carrier contracted were diverted because of mechanical
              breakdown of the carrier, acts of nature as defined in the policy,
              intervention by the Authorities or hijackers, the Company will
              compensate the Policyholder up to a limit of the plan, subject to
              presentation of the relevant original copies of the invoices, to
              defray the prime necessity expenses (i.e., necessary clothing and
              toiletries or those that are indispensable while the Policyholder
              is waiting to leave).
            </p>

            <p className="mt-3 text-sm">
              The Insurer will also reimburse the Insured’s reasonable
              additional expenses (up to the limit of the plan) incurred for the
              use of alternative scheduled public transport services to enable
              the Policyholder to arrive at the planned destination on time in
              the event that the Policyholder’s scheduled flights are rerouted
              due to the above-named unforeseen circumstances outside the
              Insured’s control.
            </p>

            <p className="mt-3 text-sm">
              Flight diversion as a result of a strike called by employees of or
              services companies subcontracted by the Airline and/or of the
              flight departure, port of call or destination airports, is
              excluded from this guarantee.
            </p>

            <p className="mt-3 text-sm">
              The delay must be certified by the carrier company stating the
              reasons for the delay in the flight for either Delayed Departure,
              Missed Connection and Diversion of Trip.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel17" className="hidden" />
        <label
          htmlFor="panel17"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International plan covers luggage that are
            damaged by the airline?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel17">
            <p className="text-sm">
              <strong>A. Loss or Damage of Checked-In Baggage</strong>
              <p>
                The Company will pay for loss or damage to the Policyholder’s
                baggage during the period of insurance up to the limits stated
                in the Policy Schedule. The limit of the Company’s liability per
                item, pair, set that is inside the baggage shall be
              </p>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Php 5,000, for Peso plans respectively and</li>
                <li>
                  US$100 for the bag itself or the actual amount to replace the
                  damaged luggage
                </li>
              </ul>
            </p>
            <p className="mt-3 text-sm">
              But the total of all shall not exceed the maximum limits as stated
              in the Policy Schedule.
            </p>
            <p className="mt-3 text-sm">
              Benefits will not be provided for claims not declared to the
              carrier company as soon as the Policyholder knows the luggage is
              lost or damaged.
            </p>

            <p className="mt-3 text-sm">
              {" "}
              Without prejudice to the liability that might correspond to the
              carrier company for the luggage as a whole, the Company shall
              indemnify the Policyholder in the event of loss or destruction
              during transportation by the carrier company. The Company will
              compensate for lost checked-in baggage by an airline, only the
              portion that is not covered by the AIRLINE BAGGAGE LIMIT OF
              LIABILITY as set by the airline, up to the limit specified in the
              Policy Schedule, as a sum of both compensation payments, for the
              collection of baggage and possessions checked in by each
              Policyholder, in the event of loss, robbery or total or partial
              destruction during the carriage by air performed by the carrier
              Insurer, for the purpose of which the Policyholder shall furnish a
              list of the contents including the estimated price and date of
              purchase of each item, as well as the settlement of the
              compensation payment by the carrier. Compensation payment for
              loss, robbery or partial destruction will be calculated according
              to the procedures recommended by international carriage by air
              organizations.
            </p>

            <p className="mt-3 text-sm">
              The minimum period of time that must elapse for the baggage to be
              considered to have been lost once and for all will be that
              stipulated by the carrier Insurer, with a minimum of twenty-one
              (21) days.
            </p>

            <p className="mt-3 text-sm">
              Compensation payments received under this guarantee will be net of
              the compensation received from the carrier company for the loss or
              damage of that baggage.
            </p>

            <p className="mt-3 text-sm">
              The Policyholder must:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>
                  Lodge a formal complaint before the police at the place where
                  it occurred, duly listing the contents of the luggage and
                  their economic value. Obtain a certificate of the said
                  complaint;
                </li>
                <li>
                  Present a written complaint before the carrier company, within
                  the time limits established by each company. Keep a copy of
                  the same; and
                </li>
                <li>
                  Make a list of the contents of the luggage and their value.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel18" className="hidden" />
        <label
          htmlFor="panel18"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What is not covered for Loss of and/or Damage to Checked-in Baggage?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel18">
            <p className="mt-3 text-sm">
              The following are excluded:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Baggage not declared to the carrier company</li>
                <li>
                  Valuable articles, money, jewelry, debit/credit cards, and any
                  type of document
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel19" className="hidden" />
        <label
          htmlFor="panel19"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I get Travel Protect International as a group or with my family
            members?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel19">
            <p className="text-sm">
              Yes, we may issue for a group of 2 or more individuals traveling
              with the same itinerary/destination, same travel dates, and same
              conveyance.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel20" className="hidden" />
        <label
          htmlFor="panel20"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I get a Travel Protect International Policy for someone else?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel20">
          <p className="text-sm">
          Yes, you may avail of the travel insurance for your family, friends, relatives, and colleagues for as long as you have their consent and you know their personal information and travel details.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel21" className="hidden" />
        <label
          htmlFor="panel21"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International cover sickness that are
            pre-existing?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel21">
            <p className="text-sm">
              Pre-existing illness or injuries are EXCLUDED from this guarantee.
            </p>
            <p className="mt-3 text-sm">
              Pre-existing conditions are illnesses or injuries for which the
              person has been under the care of a physician and has received
              medical care or advice or a condition for which a reasonable
              person should have sought treatment.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel22" className="hidden" />
        <label
          htmlFor="panel22"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International cover if my flight was delayed due
            to technical and mechanical problems with the aircraft?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel22">
            <p className="text-sm">
              When the departure of the booked outward or inbound international
              journeys (by aircraft, ship, cruise ship, coach or cross-channel
              train) contracted by the Policyholder for travelling is delayed by
              at least six (6) hours because of acts of nature as defined in the
              policy, a mechanical breakdown of the carrier or sunset
              limitation, the Company, shall reimburse any additional expenses
              incurred such as transportation, non-refundable cruise and hotel
              accommodation/bookings, prime necessity expenses (i.e., food,
              necessary clothing and toiletries or those items that are
              indispensable while the Policyholder is waiting to leave). Such
              payment will be made upon presentation of the corresponding and
              relevant original invoices and original receipts and up to the
              limit specified in the Policy Schedule.
            </p>
            <p className="mt-3 text-sm">
              This guarantee duly excludes any delay that is a direct
              consequence of a strike called by employees belonging to the
              airline company and/or the departure or arrival airports for the
              flight, or to service companies subcontracted by the same.
            </p>
            <p className="mt-3 text-sm">
              Also excluded from this guarantee are those delays that occur on
              charter or non-regular flights.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel23" className="hidden" />
        <label
          htmlFor="panel23"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International cover if my flight was delayed due
            to air traffic or immigration concern?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel23">
            <p className="text-sm">
              Delays due to air traffic or immigration concern are NOT covered
              under Travel Inconvenience Benefit.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel24" className="hidden" />
        <label
          htmlFor="panel24"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Are foods purchased during the flight delay covered by Travel
            Protect International?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel24">
            <p className="text-sm">
              Yes, restaurant meals can be reimbursed for flight delays (for a
              minimum of 6 hours of delay). The Insured should keep the receipts
              and secure a Certification for the Flight Delay from the airline as
              part of the documents needed in filing a claim.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel25" className="hidden" />
        <label
          htmlFor="panel25"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect International cover hand-carry baggage which
            contains my passport ticket, and other travel documents? Will the
            expenses to replace my travel documents be covered?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel25">
            <p className="text-sm">
              <strong>
                Indemnity for loss of luggage or personal belongings not
                checked-in.
              </strong>
            </p>
            <p className="mt-3 text-sm">
              Up to the limit duly established by the Policy purchased by the
              Policyholder, the Travel Protect International shall indemnify for
              material losses sustained by the Policyholder’s luggage or
              personal belongings, when travelling outside the Usual Country of
              Residence, in cases of:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Theft;</li>
                <li>Robbery;</li>
                <li>
                  Burglary within hotel rooms, where these are duly locked.
                </li>
              </ul>
            </p>
            <p className="mt-3 text-sm">
              Valuable items shall solely be covered against total loss due to
              theft, robbery, burglary or when they are deposited in the safe of
              a hotel or the Policyholder are wearing/ carrying them. Coverage
              for this benefit shall be up to 100% of the limit as specified in
              the policy schedule.
            </p>

            <p className="mt-3 text-sm">
              Any luggage or personal belongings placed inside a locked vehicle
              shall be covered when they are inside the trunk and out of sight
              of others.
            </p>
            <p className="mt-3 text-sm">
              The Policyholder must lodge a formal complaint before the police
              at the place where it occurred, duly listing the objects and their
              economic value; having obtained a copy of said complaint, this
              must be sent to the Company.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel26" className="hidden" />
        <label
          htmlFor="panel26"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            I tested positive for COVID-19 just before my trip. Does Travel
            Protect International cover the Trip Cancellation claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel26">
            <p className="text-sm">
              Yes, as long as it’s within fifteen (15) days prior to the
              scheduled trip departure date, provided travel insurance is taken
              prior to such diagnosis.
            </p>
            <p className="mt-3 text-sm">
              Note: This benefit will respond if the travel insurance is taken
              at least three (3) days prior to the scheduled trip
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqProductInternational;
