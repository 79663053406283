import React, { useState } from "react";

const FaqClaim = () => {
  return (
    <>
      <div className="w-full">
        <input type="checkbox" name="panel" id="panel1" className="hidden" />
        <label
          htmlFor="panel1"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            When should I file my claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel1">
            <p className="text-sm">
              You can file a claim for loss or damage immediately after the
              occurrence or up to 60 days. For Acts of Nature claims, must file
              your claim within seven (7) days from the date of typhoon, flood,
              earthquake, flood or other convulsions of nature.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-2" className="hidden" />
        <label
          htmlFor="panel-2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I claim the benefits from my insurance?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              You may file a claim by going to the Standard Insurance Online
              Claims Notification{" "}
              <a
                href="https://bit.ly/OnlineClaimNotification"
                target="_blank"
                className="text-yellow-500"
              >
                https://bit.ly/OnlineClaimNotification
              </a>
              . Once you have completed the form and have attached the required
              documents, someone will email you of the next steps necessary to
              process your claim. Alternatively, you may also contact us at the
              following:
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-3" className="hidden" />
        <label
          htmlFor="panel-3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What documents do I need to submit in filing a claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">Basic Documents</p>
            <ul className="text-sm mt-3">
              <li>
                <strong>1. </strong>Certificate of Registration (CR) and
                official receipt (OR) or CR covering loss date
              </li>
              <li>
                <strong>2. </strong>Driver's license and office receipt
              </li>
              <li>
                <strong>3. </strong> Affidavit or police report (if with third
                party)
              </li>
              <li>
                <strong>4. </strong>Photos showing full body of the unit (with
                plate number/conductions sticker or VIN plate), damages on the
                unit and odometer reading.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-4" className="hidden" />
        <label
          htmlFor="panel-4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How long would it take before I receive my claim settlement?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              Letter of Authority Issued to CASA/Repair Shop or Cash Settlement.
            </p>
            <p className="mt-3 text-sm">
              Once you have filed a claim and have completed the required
              documents, it will be processed within 5 days for claims up to
              Php100,000.00 and 5-20 days for other cases depending on the
              extent of damage.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-5" className="hidden" />
        <label
          htmlFor="panel-5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I claim If my insured vehicle gets into an accident while being
            driven by another person?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              Yes, your policy may respond if the insured vehicle gets into an
              accident while being driven by another person with the following
              conditions:
            </p>
            <ul className="text-sm mt-3">
              <li>
                a. You have authorized/permitted him/her to operate or drive
                your insured vehicle.
              </li>
              <li>
                b. The authorized/permitted driver carries a drivers license in
                accordance with the licensing law.
              </li>
              <li>c. Subject to all other policy terms and conditions.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-6" className="hidden" />
        <label
          htmlFor="panel-6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Where can I bring my Insured vehicle for a repair?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <ul className="text-sm mt-3">
              <li>
                1. We have more than 500 accredited dealerships nationwide where
                you can have your Insured vehicle repaired upon approval of your
                claim.
              </li>
              <li>
                2. We also have our very own Technical and Training Center in
                Naic, Cavite highly capable of repairing your damaged insured
                vehicle. To learn more, please contact (632) 8845-1111.
              </li>
              <li>
                3. If you have a preferred shop that is not accredited with
                Standard Insurance and not part of our blacklist, we will try to
                accommodate.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-7" className="hidden" />
        <label
          htmlFor="panel-7"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What are the benefits of having my vehicle repaired at your
            Technical and Training Center?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel7">
            <p className="text-sm">
              If you prefer to have your insured and damaged vehicle repaired at
              our own shop, you will enjoy the following benefits subject to
              terms and conditions:
            </p>

            <ul className="text-sm mt-3">
              <li>• waived deductible</li>
              <li>• waived depreciation if multi sourced parts are used</li>
              <li>
                • free pick-up and delivery of your insured vehicle within Metro
                Manila and nearby provinces of Cavite
              </li>
              <li>
                • free loaner or service car while your vehicle is being
                repaired
              </li>
            </ul>

            <p className="text-sm mt-3">
              To learn more, please contact (632) 8845-1111.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-8" className="hidden" />
        <label
          htmlFor="panel-8"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I get my claim settlement via cash mode?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel8">
            <p className="text-sm">
              Yes. If you prefer to receive your settlement via cash, we can
              process this based on our evaluation and computation. Claims
              proceeds may be coursed through check or online transfer.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-9" className="hidden" />
        <label
          htmlFor="panel-9"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Will there be fees or participation every time I file a claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel9">
            <p className="text-sm">
              Yes. For each and every claim, you are required to pay deductible
              and/or depreciation as stated in your policy.
            </p>

            <p className="text-sm mt-3">
              However, if you send your vehicle for repair to our Technical and
              Training Center (TTC) in Naic, Cavite, we may waive your
              deductible and depreciation, subject to terms and conditions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqClaim;
