import { useEffect } from "react";
import { useState } from "react";
import TitleCards from "../../molecules/TitleCard";

export default function Policy(props) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const Dformat = (date1) => {
    var d = new Date(date1),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var m = strArray[d.getMonth()];
    return m + " " + day + ", " + year;
  };
  function getAge(birthDate) {
    birthDate = new Date(birthDate);
      let otherDate = new Date();

      var years = (otherDate.getFullYear() - birthDate.getFullYear());

      if (otherDate.getMonth() < birthDate.getMonth() || 
          otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
          years--;
      }

      return years;
  }
  
  const dateDifference = (date_1, date_2) =>{
    // let date2 = new Date(date_2)
    // date2.setDate(date2.getDate()-1)
    let difference = new Date(date_2).getTime() - new Date(date_1).getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    console.log(TotalDays)
    if(TotalDays < 0) TotalDays = 0;
    return (TotalDays+1); 
    // return TotalDays;
}


  const SectionContents = (props) =>{
    console.log("data",props.data);
    let Contents=null;
    if(props.productType=="SIPETI01"){
      if(props.content=="annual"){
        return (<>
         <div>
            <div className="mb-2">
              <label className="font-bold">Basic Premium</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">VAT</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">DST</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">LGT</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">Total Amount</label>
            </div>

          </div>

          <div>
            <div className="mb-2">
              <label className="text-right block">
                PHP{" "}
                {formatter
                  .format(props.data.detail.premium.basicPremium)
                  .replace("₱", "")}
              </label>
            </div>

            <div className="mb-2">
              <label className="text-right block">
                PHP{" "}
                {formatter.format(props.data.detail.premium.vat).replace("₱", "")}
              </label>
            </div>

            <div className="mb-2">
              <label className="text-right block">
                PHP{" "}
                {formatter.format(props.data.detail.premium.dst).replace("₱", "")}
              </label>
            </div>

            <div className="mb-2">
              <label className="text-right block">
                PHP{" "}
                {formatter.format(props.data.detail.premium.lgt).replace("₱", "")}
              </label>
            </div>
            

            <div className="mb-2">
              <label className="text-right block">
                PHP{" "}
                {formatter
                  .format(props.data.detail.premium.totalPremium)
                  .replace("₱", "")}
              </label>
            </div>

            
          </div>

        </>);
      }
      else if(props.content=="petDetails"){
        return (<>
        <div>
            <div className="mb-2">
              <label className="font-bold">Pet Name</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">Species</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">Type</label>
            </div>

            <div className="mb-2">
              <label className="font-bold">Breed</label>
            </div>

          </div>

          <div>
            <div className="mb-2">
              <label className="text-right block">
                {props.data.detail.info.nameOfPet}
              </label>
            </div>

            <div className="mb-2">
              <label className="text-right block">
              {props.data.detail.info.speciesDescription}
              </label>
            </div>

            <div className="mb-2">
              <label className="text-right block">
              {props.data.detail.info.speciesTypeDescription}
              </label>
            </div>

            <div className="mb-2">
              <label className="text-right block">
              {props.data.detail.info.breed}
              </label>
            </div>
            


            
          </div>
        </>);
      }
    }

    return Contents;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    //setPolicyDetail(props.detail)
  }, []);
  return (
    <>
    
      {props.detailLoading || (
        <>
          <div className="px-5 py-8 bg-white manage-policy-font">
            {(props.productType!="SIPETI01" && props.productType!="SITRVL02" && props.productType!="SITRVL01") && (
              <>
                <h3 className="font-bold text-lg">Insured</h3>
                <div className=" main p-5 rounded-lg mt-5">
                  <span className="font-bold">{props.detail.insured}</span>
                </div>
                <h3 className="font-bold text-lg">Premium</h3>
                <div className="grid grid-cols-2 main p-5 rounded-lg mt-5">
                  <div>
                    <div className="mb-2">
                      <label className="font-bold">Basic Premium</label>
                    </div>

                    <div className="mb-2">
                      <label className="font-bold">VAT</label>
                    </div>

                    <div className="mb-2">
                      <label className="font-bold">DST</label>
                    </div>

                    <div className="mb-2">
                      <label className="font-bold">LGT</label>
                    </div>
                    {typeof props.detail.authNumber !== "object" &&
                      props.detail.coverType === "07" && (
                        <div className="mb-2">
                          <label className="font-bold">
                            Authentication fee
                          </label>
                        </div>
                      )}

                    <div className="mb-2">
                      <label className="font-bold">Total Amount</label>
                    </div>

                    <div className="mb-2">
                      <label className="font-bold">Deductible</label>
                    </div>
                  </div>

                  <div>
                    <div className="mb-2">
                      <label className="text-right block">
                        PHP{" "}
                        {formatter
                          .format(props.detail.basicPremium)
                          .replace("₱", "")}
                      </label>
                    </div>

                    <div className="mb-2">
                      <label className="text-right block">
                        PHP{" "}
                        {formatter.format(props.detail.vat).replace("₱", "")}
                      </label>
                    </div>

                    <div className="mb-2">
                      <label className="text-right block">
                        PHP{" "}
                        {formatter.format(props.detail.dst).replace("₱", "")}
                      </label>
                    </div>

                    <div className="mb-2">
                      <label className="text-right block">
                        PHP{" "}
                        {formatter.format(props.detail.lgt).replace("₱", "")}
                      </label>
                    </div>
                    {typeof props.detail.authNumber !== "object" &&
                      props.detail.coverType === "07" && (
                        <div className="mb-2">
                          <label className="text-right block">
                            PHP {formatter.format(50.4).replace("₱", "")}
                          </label>
                        </div>
                      )}

                    <div className="mb-2">
                      <label className="text-right block">
                        PHP{" "}
                        {formatter
                          .format(props.detail.totalAmount)
                          .replace("₱", "")}
                      </label>
                    </div>

                    <div className="mb-2">
                      <label className="text-right block">
                        PHP{" "}
                        {formatter
                          .format(props.detail.deductible)
                          .replace("₱", "")}
                      </label>
                    </div>
                  </div>
                </div>

                <h3 className="font-bold text-lg mt-8">Insured</h3>
                <div className=" main p-5 rounded-lg mt-5">
                  <span className="font-bold">{props.detail.insured}</span>
                </div>

                <h3 className="font-bold text-lg mt-8">Car Details</h3>
                <div className="grid main p-5 rounded-lg mt-5">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Plate Number</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.plateNumber}</span>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Year</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.year}</span>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Make</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.make}</span>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Model</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.model}</span>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Color</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.color}</span>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">
                        Chassis Number
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.chassisNumber}</span>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">
                        Engine Number
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">{props.detail.engineNumber}</span>
                    </div>
                  </div>
                  {typeof props.detail.authNumber === "object" &&
                    props.detail.coverType !== "07" && (
                      <div className="flex mt-2">
                        <div className="flex-1 text-left">
                          <span className="text-right font-bold">
                            Mortgaged
                          </span>
                        </div>

                        <div className="flex-1 text-right">
                          <span className="">
                            {props.detail.isMortaged == "Y"
                              ? props.detail.mortgageeDesc
                              : "NO"}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}

            {/** Travel International and ASEAN */}
            {(props.productType=="SITRVL02" || props.productType=="SITRVL01") && (
              <>
                <h3 className="font-bold text-lg">Package</h3>
                <div className="grid main p-5 rounded-lg mt-5">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Package Plan</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">
                        {props.travelDetails.zoneDescription}
                      </span>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Travel Date</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">
                        {Dformat(props.travelDetails.departure)}
                      </span>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">No. of Days</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">
                        {dateDifference(props.travelDetails.departure , props.travelDetails.arrival)}
                      </span>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">
                        Travel Destination
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      {typeof props.travelDetails.destinations!=="undefined" && ( props.travelDetails.destinations.map(
                        (category, index) => {
                          return <div className="">
                           {
                            props.travelDetails.destinations[index].name
                           }
                              
                          </div>;
                        }
                      ))}
                      {typeof props.travelDetails.countries!=="undefined"  && props.travelDetails.countries.map((category, index) => {
                          return (<div className="">
                          {props.travelDetails.countries[index].name}
                        </div>);
                        }
                      )}
                    </div>
                  </div>
                </div>

                <h3 className="font-bold text-lg mt-8">Premium</h3>
                <div className="grid main p-5 rounded-lg mt-5">
                  <div className="flex mt-2">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">Premium</span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">
                        PHP {props.travelDetails.premium.total.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                <h3 className="font-bold text-lg mt-8">Person(s) Included</h3>
                <div className="grid main p-5 rounded-lg mt-5">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right font-bold">
                        Number of Person(s)
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="">
                        {props.travelDetails.assuredList.length}
                      </span>
                    </div>
                  </div>
                  {props.travelDetails.assuredList.map((category, index) => (
                    <>
                      <div className="flex mt-4">
                        <div className="flex-1 text-left">
                          <span className="text-right font-bold">Name</span>
                        </div>

                        <div className="flex-1 text-right">
                          <span className="">
                            {props.travelDetails.assuredList[index].name}
                          </span>
                        </div>
                      </div>

                      <div className="flex mt-2">
                        <div className="flex-1 text-left">
                          <span className="text-right font-bold">Age</span>
                        </div>

                        <div className="flex-1 text-right">
                          <span className="">
                            {getAge(props.travelDetails.assuredList[index].bday )}{" "}
                            yrs old
                          </span>
                        </div>
                      </div>

                      <div className="flex mt-2">
                        <div className="flex-1 text-left">
                          <span className="text-right font-bold">Primary</span>
                        </div>

                        <div className="flex-1 text-right">
                          <span className="">
                            {props.travelDetails.assuredList[index].primary ===
                              true && "Yes"}
                            {props.travelDetails.assuredList[index].primary !==
                              true && "No"}
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}

            {props.productType=="SIPETI01" && (
              <>
              <TitleCards Title={"Annual Premium"} Contents={<SectionContents content={"annual"} productType={props.productType} data={props} />} />  
              <div className="mt-5"></div> 
              <TitleCards Title={"Pet Info"} Contents={<SectionContents content={"petDetails"} productType={props.productType} data={props}/>} />  
              </>
            )}

          </div>
        </>
      )}
    </>
  );
}
