import { useState, useEffect } from "react";
import { contactusAPI } from "../../api/fetch-api";
import { Loading } from "../atoms/loading";

export default function ContactUs() {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sendContactUs = (event) => {
    event.preventDefault();

    const data = {
      contentParam: [
        { param: event.target.firstname.value }, // First Name
        { param: event.target.lastname.value }, // Last Name
        { param: event.target.email.value }, // Email
        { param: event.target.subject.value }, // Subject
        { param: event.target.message.value }, // Message
      ],

      recipient:
        "ginsure@standard-insurance.com", //inquiry@standard-insurance.com
      emailbcc:
        "", //teamonline@standard-insurance.com
      emailcc: "",
      status: "Contact-Us", // Fixed
      subject: "MyStandardInsuranceOnline - Contact Us",
      systemApps: "FO", // Fixed
    };

    console.log("pass:", data);

    setIsLoading(true);
    contactusAPI
      .postContactUs(data)
      .then((data) => {
        console.log("contact response", data);

        if (data.message !== "") {
          console.log("reset form");
          setMessage("Succesfully send!");

          setIsLoading(false);
          document.getElementById('contactMessage').scrollIntoView();
          setTimeout(() => {
            setMessage("");
            window.scrollTo(0, 0);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("There was an error!");
      });
  };

  return (
    <>
      <div>
        <div className="text-left">
          <div className="mb-5">
            <h3 className="text-lg font-bold leading-tight title-font">
              Give us few details of what you're looking for and we'll get back
              to you right away!
            </h3>
          </div>

          
          {isLoading && <Loading className="my-20"/>}
          {!isLoading && (
            <form onSubmit={sendContactUs}>
              <div className="mt-10">
                <div className="mb-3">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-base">
                    First Name
                  </label>
                  <div className="block relative w-full mt-1">
                    <input
                      className="input-form text-sm"
                      type="text"
                      name="firstname"
                      maxLength="30"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-base">
                    Last Name
                  </label>
                  <div className="block relative w-full mt-1">
                    <input
                      className="input-form text-sm"
                      type="text"
                      name="lastname"
                      maxLength="30"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-base">
                    Email
                  </label>
                  <div className="block relative w-full mt-1">
                    <input
                      className="input-form text-sm"
                      type="email"
                      name="email"
                      maxLength="30"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-base">
                    Subject
                  </label>
                  <div className="block relative w-full mt-1">
                    <input
                      className="input-form text-sm"
                      type="text"
                      name="subject"
                      maxLength="50"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-base">
                    Message
                  </label>
                  <div className="block relative w-full mt-1">
                    <textarea
                      className="input-form input-textarea text-sm"
                      type="text"
                      name="message"
                      maxLength="100"
                    />
                  </div>
                </div>
              </div>
              {message === "" || (
                <div className="mt-10">
                  <p className="text-center mt-10 bg-green-50 p-3 rounded-lg" id="contactMessage">
                    {message}
                  </p>
                </div>
              )}
              {message === "" && (
                <div className="mt-10">
                  <p></p>
                </div>
              )}
              <div className="my-10">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-3 rounded-full font-bold"
                >
                  SEND
                </button>
              </div>
            </form>
          )}

          <hr className="my-5" />
          <div>
            <span className="block mb-5 font-bold leading-tight">
              Altenatively, you can reach us via the following channels.
            </span>

            <div className="mb-5">
              <label className="font-bold">Email us</label>
              <span className="text-blue-600 font-semibold block">
                ginsure@standard-insurance.com
              </span>
            </div>

            <div className="mb-5">
              <label className="font-bold">Call us</label>
              <span className="text-blue-600 font-semibold block">
                (+632) 8845-1111
              </span>
            </div>

            <div className="mb-5">
              <label className="font-bold">Visit</label>
              <span className="text-blue-600 font-semibold block">
                www.standard-insurance.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
