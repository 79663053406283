import { useState, useEffect } from "react";
import Accordion from "../../molecules/Accordion";

export default function ExclusionListPet() {
  const AccordionTitles = ["Detailed Exclusions", "General Exclusions"];
  const [isLoading, setIsLoading] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {}, [isLoading]);

  const AccordionContents = (index) => {
    if (index === 0) {
      return (
        <>
          <div className="mt-5 mx-3">
            <p className="text-sm mb-5">
              We will not pay any Vet Expenses incurred by you because of your
              Pet suffering an Illness which is attributable to or resulting
              from:{" "}
            </p>

            <div className="mb-5 text-sm">
              <ul>
                <li className="mb-3">
                  1. A Pre-existing Condition, a Related Condition or a
                  Condition arising within the applicable Waiting Period.
                </li>
                <li className="mb-3">
                  2. an Illness caused by endoparasites (such as worms) or
                  ectoparasites (such as ticks and fleas, with the exception of
                  skin and ear mites) unless covered under Paralysis Tick
                  Benefit{" "}
                </li>
                <li className="mb-3">
                  3. Treatment of the following irrespective of whether your dog
                  or cat was vaccinated or not:
                  <ol className="ml-5">
                    <li className="mt-3 mb-3">
                      a. For dogs: infectious canine hepatitis (canine
                      adenovirus), parvovirus, canine distemper, parainfluenza,
                      canine influenza and all forms of kennel cough.
                    </li>
                    <li>
                      b. For cats: panleukopenia, chlamydia, leukemia (FeLV),
                      Feline Immuno Deficiency Virus (FIV) and Feline Infectious
                      Peritonitis (FIP), viral rhinotracheitis, calicivirus,
                      herpes virus and all forms of cat flu.
                    </li>
                  </ol>
                </li>
                <li className="mb-3">
                  4. Any declared pandemic disease that causes widespread
                  Illness affecting dogs or cats.
                </li>
                <li className="mb-3">
                  5. More than one (1) incident per Policy Period of swallowing
                  a foreign object that causes a blockage or obstruction
                  requiring surgical or endoscopic removal.
                </li>
                <li className="mb-3">
                  6. Any of the excluded matters listed under General Exclusions
                </li>
                <li className="mb-3">
                  7. Voluntary euthanasia (i.e. non-essential euthanasia);
                </li>
                <li className="mb-3">
                  8. Euthanasia attributable to or resulting from an event or
                  circumstances specified in “What we will not” under Accidental
                  Injury Cover or Illness Cover and those under General
                  Exclusions
                </li>
                <li className="mb-3">9. An autopsy.</li>
                <li className="mb-3">
                  10. Disposal, burial, or cremation of a deceased Pet (unless
                  cover for this expense is noted on your Policy Schedule.)
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="mt-5 mx-3">
            <div className="mb-5">
              <h3 className="text-lg font-bold leading-tight text-yellow-500">
                Exclusion
              </h3>
            </div>
            <p className="text-sm mb-5 font-bold">
              The intention of Pet insurance is to help cover unforeseen Vet
              Expenses associated with pet ownership.
            </p>

            <p className="text-sm mb-5 ">
              Unless stated in your Policy Schedule, Vet Expenses and/or costs
              related to the following are not covered:
            </p>

            <div className="mb-5 text-sm">
              <ul>
                <li className="mb-3">
                  1. Pre-existing Conditions stated under definitions and
                  'Pre-existing Condition review'
                </li>
                <li className="mb-3">
                  2. Dental Care - dental procedures; dental diseases,
                  gingivitis; Treatment of teeth fractures; teeth
                  cleaning/scaling; orthodontics; removal of deciduous or
                  fractured teeth or any oral disease.
                </li>
                <li className="mb-3">
                  3. Certain Treatments and Conditions
                  <ol className="ml-5">
                    <li className="mt-3 mb-3">
                      a. Regular, prescription or dietary pet food, vitamins,
                      nutraceuticals, or mineral supplements, whether
                      recommended by your Vet or not.
                    </li>
                    <li className="mt-3">
                      b. Preventative procedures and treatments (including, but
                      not limited to, vaccinations, microchipping, routine anal
                      gland expression, flea/ tick/worm control, or motion /
                      travel sickness medication).
                    </li>
                    <li className="mt-3">
                      c. Grooming and bathing of your Pet (including medicated
                      baths and skin lotions).
                    </li>
                    <li className="mt-3">
                      d. Accessories such as but not limited to - pill poppers,
                      cage hire, crates, bedding and collars.
                    </li>
                    <li className="mt-3">
                      e. Training, socialization, therapy and alternative
                      therapies (including, but not restricted to, consultations
                      and Treatments involving homeopathic remedies,
                      acupuncture, laser therapy, hydrotherapy, chiropractic
                      treatments and/or physiotherapy) whether recommended by
                      your Vet or not.
                    </li>
                    <li className="mt-3">
                      f. Treatment while your pet is used for commercial or
                      occupational purposes including but not limited to racing,
                      breeding, organized fighting, law enforcement, guarding or
                      pig hunting dogs. Guide dogs and assistance dogs are
                      exempt from this exclusion.
                    </li>
                    <li className="mt-3">
                      g. Treatment of or Conditions attributable to behavioral
                      problems regardless of the cause (including but not
                      limited to anxiety disorders, phobias or chemical
                      imbalance).
                    </li>
                    <li className="mt-3">
                      h. Cell-replacement therapies, including but not limited
                      to stem cell therapy. This exclusion does not include
                      blood transfusions, which are covered when medically
                      necessary.
                    </li>
                    <li className="mt-3">
                      i. A Condition where the diagnosis is inconclusive, but
                      where the Treatment protocol is consistent with a
                      Treatment protocol typically applied to an Accidental
                      Injury or Illness which is not covered (e.g. Treatment for
                      coughing where kennel cough is suspected but not diagnosed
                      or excluded as a diagnosis).
                    </li>
                    <li className="mt-3">
                      j. Treatment for Conditions excluded by the policy and/or
                      due to complications and/or adverse reactions arising from
                      any Policy Exclusion.
                    </li>
                    <li className="mt-3">
                      k. Medication not approved or listed by the PVMA
                      (Philippine Veterinary Medical Association) unless
                      specifically agreed to by us in writing.
                    </li>
                    <li className="mt-3">
                      l. The provision of medication(s) for your Pet that covers
                      a period of more than thirty (30) days beyond the policy
                      End Date.
                    </li>
                    <li className="mt-3">
                      m. Your decision to pursue a course of Treatment other
                      than that which was recommended to you by your Vet unless
                      specifically authorized by us prior to Treatment. For
                      example: ignoring a Vet’s recommendation to remove an eye,
                      which then results in extra costs associated with chronic
                      eye issues.
                    </li>
                    <li className="mt-3">
                      n. Breeding or obstetrics, or Treatment of Conditions
                      arising as a result of breeding or obstetrics.
                    </li>
                  </ol>
                </li>
                <li className="mb-3">
                  4. Certain Services & Procedures
                  <ol className="ml-5">
                    <li className="mt-3 mb-3">
                      a. Transport or boarding expenses other than the benefits
                      provided under Emergency Boarding, regardless of reason.
                    </li>
                    <li className="mt-3 mb-3">
                      b. Ambulance fees and non-essential hospitalization.
                    </li>

                    <li className="mt-3 mb-3">
                      c. House calls, phone consultations and out-of-hours
                      Treatment unless the Vet believes an emergency
                      consultation was necessary, in which case our liability is
                      limited to the amount that would have been payable had the
                      Treatment been provided at a Vet practice during normal
                      consultation hours.
                    </li>
                    <li className="mt-3 mb-3">
                      d. Genetic/chromosome testing including procedures to
                      determine the suitability or categorization of your Pet
                      for breeding or genealogical purposes.
                    </li>
                    <li className="mt-3 mb-3">
                      e. Organ transplant surgery, artificial limbs, external
                      fixtures (e.g. wheel chairs), prosthetics(such as total
                      hip replacements), pacemakers and any associated expenses
                      unless authorized and agreed to by us in writing prior to
                      any treatment.
                    </li>
                  </ol>
                </li>
                <li className="mb-3">
                  5. Elective Procedures and Treatments
                  <ol className="ml-5">
                    <li className="mt-3 mb-3">
                      a. Routine examinations; cosmetic procedures; experimental
                      Treatments or therapies;(undescended testicles); hip and
                      elbow scoring; Elective Treatments, including but not
                      limited to nail clipping, tail docking, debarking,
                      pre-anesthetic blood tests, declawing, cropping and nasal
                      fold, skin fold, stenotic nares and soft palate
                      resections; or chemical castration, superlorin implants or
                      other desexing procedures, unless required to treat pet’s
                      reproductive organs due to inflammation, infection or
                      cancer. Your Pet not being protected your pet not being
                      protected due to gross negligence by you or your failure
                      to take all reasonable precautions to protect your Pet
                      from situations that may result in injury or Illness (such
                      as ingestion of hazardous substances) or from aggravating
                      a treated Condition.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className="text-left">
        <div className="mb-5">
          <h3 className="text-lg font-bold leading-tight">Exclusion</h3>
        </div>

        <Accordion
          AccordionTitles={AccordionTitles}
          AccordionLocation={"pet"}
          AccordionContents={AccordionContents}
        />
      </div>
    </>
  );
}
