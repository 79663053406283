const WizardComponent = (props) => {
  let activeClass1 = (props.activeStep>=1) ? "bg-blue-600":"border-gray-100 bg-gray-100";
  let activeClass2 = (props.activeStep>=2)? "bg-blue-600":"border-gray-100 bg-gray-100";
  let activeClass3 = (props.activeStep>=3)? "bg-blue-600":"border-gray-100 bg-gray-100";
  let activeClass4 = (props.activeStep>=4)? "bg-blue-600":"border-gray-100 bg-gray-100";
  let barActiveClass2 = (props.activeStep>=2)? "bg-blue-100":"bg-gray-200";
  let barActiveClass3 = (props.activeStep>=3)? "bg-blue-100":"bg-gray-200";
  let barActiveClass4 = (props.activeStep>=4)? "bg-blue-100":"bg-gray-200";
  const labels = props.stepsLabel;
  const width = 12 / labels.length;
  console.log("labels",labels);
  return (
    <>
      <div className="w-full mb-10">
        <div className="flex justify-center">
        {labels.map((label,index)  => {
            let activeClass="border-gray-100 bg-gray-100";
            let barActiveClass="bg-gray-200";
            if(props.activeStep>index){
              activeClass = "bg-blue-600";
              barActiveClass="bg-blue-100";
            }
            return <div className={`w-1/`+width} key={label}>
            <div className="relative mb-2">
              <div className="absolute flex align-center items-center align-middle content-center wizard">
                {(index>=1)&&(
                                  <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                  <div
                                    className={`w-full ${barActiveClass} py-1 rounded`}
                                  ></div>
                                </div>
                )}
              </div>

              <div className={`w-5 h-5 mx-auto border-2 rounded-full text-lg text-white flex items-center ` +activeClass}></div>
            </div>

            <div className="text-xs text-center font-bold">{label}</div>
          </div>
          })}
        
          

          
        </div>
      </div>
    </>
  );
};

export default WizardComponent;
