import { useState, useEffect } from "react";
import Covered from "../molecules/Covered";
import CoveredAbout from "./Travel-Covered/CoveredAbout";
import { TabButtons } from "../molecules/TabButtons";
import Modal from "../molecules/Modal";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
const items1 = [
  "When you travel and require hospitalization due to an accident or sickness that is not pre-existing, Standard Insurance International travel will directly pay the hospital so that you don't need to shell out cash to pay the bills.",
  "The cashless medical benefit covers medical bills including surgery, professional fees, and pharmaceutical products prescribed by the attending physician.",
  "The hospitalization will be 'Cashless', provided the total bill amount falls within your travel policy limit.",
];
const items2 = [
  "Emergency Medical Evacuation/Repatriation",
  "Repatriation of Mortal Remains",
  "Care of Minor Children",
  "Compassionate Visit",
  "Delivery of Medicine",
  "Loss of Travel Documents",
];
const coveredInternationalAbout = [
  {
    id: "1",
    title: "Cashless Benefit",
    item: items1,
    imageUrl: "/images/travel/health-insurance.png",
  },
  {
    id: "2",
    title: "Other Cashless Benefits",
    item: items2,
    imageUrl: "/images/travel/health-care.png",
  },
  {
    id: "3",
    title:
      "For travel insurance claims, call the Standard Insurance Emergency Hotline at +632 8845-1111.",
    item: [],
    imageUrl: "/images/travel/stdins-logo.png",
  },
];
const coveredInternational = [
  {
    id: "1",
    title:
      "Emergency Medical treatment including claims due to/ arising from COVID-19 up to PHP 2.5M",
    imageUrl: "/images/travel/heart.png",
  },
  {
    id: "2",
    title: "Personal Accident benefit up to PHP 2M",
    imageUrl: "/images/travel/personal-accident.png",
  },
  {
    id: "3",
    title:
      "Emergency Travel Assistance such as Medical Evacuation/Repartriation",
    imageUrl: "/images/travel/emergency.png",
  },
  {
    id: "4",
    title:
      "Travel Inconvenience benefits such as delayed departure, baggage delay and loss or damage of baggage",
    imageUrl: "/images/travel/baggage.png",
  },
  {
    id: "5",
    title: "Trip Cancellation including claims due to/ arising from COVID-19",
    imageUrl: "/images/travel/airplane-mode.png",
  },
];

const notCoveredInternational = [
  {
    id: "1",
    title: "Pre existing and Congenital conditions",
    imageUrl: "/images/travel/medical-global.png",
  },
  {
    id: "2",
    title:
      "Trip cancellation or termination, if trip is cancelled or terminated because of disclination to travel, change of mind, or fear of travelling",
    imageUrl: "/images/travel/airplane-mode.png",
  },
  {
    id: "3",
    title: "Theft without police report",
    imageUrl: "/images/travel/police-badge.png",
  },
  {
    id: "4",
    title: "Airline operational delay",
    imageUrl: "/images/travel/airplane-schedule.png",
  },
  {
    id: "5",
    title: "Extra hazardous sports",
    imageUrl: "/images/travel/hazardous.png",
  },
];

//Asean
const coveredAseanAbout = [
  {
    id: "1",
    title:
      "Travel Protect Saver Plus is a reimbursement type of travel insurance that provides coverage within the Philippines.",
    imageUrl: "/images/travel/travel-insurance.png",
  },
  {
    id: "2",
    title:
      "It pays the Insured for all medical and surgical fees, hospital and emergency dental expenses incurred as a result of any accident, and covered illness occuring wholly during the Period of Insurance up to the plan benefit limit. It also includes coverage for trip cancellation, trip curtailment, and other travel inconveniences.",
    imageUrl: "/images/travel/medicine-briefcase.png",
  },
  {
    id: "3",
    title:
      "For travel insurance claims, call the Standard Insurance Emergency Hotline at +632 8845-1111.",
    imageUrl: "/images/travel/stdins-logo.png",
  },
];
const coveredAsean = [
  {
    id: "1",
    title:
      "Medical reimbursement due to covered conditions and accident-related claims",
    imageUrl: "/images/travel/medical.png",
  },
  {
    id: "2",
    title: "Emergency medical evacuation and repartriation",
    imageUrl: "/images/travel/emergency.png",
  },
  {
    id: "3",
    title: "Trip Cancellation and Termination up to PHP 30k",
    imageUrl: "/images/travel/airplane-mode.png",
  },
  {
    id: "4",
    title:
      "Hotel accomodation and food allowance in case of delayed flight due to adverse weather condition, technical problems or mechanical breakdown",
    imageUrl: "/images/travel/hotel.png",
  },
  {
    id: "5",
    title: "Loss of and/or Damage to Baggage",
    imageUrl: "/images/travel/lost-items.png",
  },
];

//Asean
const notCoveredAsean = [
  {
    id: "1",
    title: "Pre existing and Congenital conditions",
    imageUrl: "/images/travel/medical-global.png",
  },
  {
    id: "2",
    title: "Claims due to/arising from Covid-19",
    imageUrl: "/images/travel/receipts.png",
  },
  {
    id: "3",
    title:
      "Trip cancellation or termination, if trip is cancelled or terminated because of disclination to travel, change of mind or fear of travelling",
    imageUrl: "/images/travel/airplane-mode.png",
  },
  {
    id: "4",
    title: "Theft without police report",
    imageUrl: "/images/travel/police-badge.png",
  },
  {
    id: "5",
    title: "Airline operational delay",
    imageUrl: "/images/travel/airplane-schedule.png",
  },
];

const TravelCoverSection = ({ ...props }) => {
  let history = useHistory();
  const [display, setDisplay] = useState(false);
  const [coveredState, setCoveredState] = useState(0); // 0 - question , 1 - user
  const [tabSectionState, setTabSectionState] = useState([]);
  const [covered, setCovered] = useState([]);
  const [notCovered, setNotCovered] = useState([]);
  const [titleCover, setTitleCover] = useState("");
  const [coveredAbout, setCoveredAbout] = useState([]);

  useEffect(() => {
    Cache.setItem("flag", 0);
  }, []);

  useEffect(() => {
    console.log("props1", props.cover);
    setDisplay(false);
    if (props.cover === "international") {
      setCoveredAbout(coveredInternationalAbout);
      setCovered(coveredInternational);
      setNotCovered(notCoveredInternational);
      setTitleCover("Travel Protect International");
    } else {
      setCoveredAbout(coveredAseanAbout);
      setCovered(coveredAsean);
      setNotCovered(notCoveredAsean);
      setTitleCover("Travel Protect Saver Plus");
    }
  }, [props.cover]);

  const coveredSubTitle =
    "Your Travel Insurance will cover you in the following.";
  const notCoveredSubTitle =
    "Your Travel Insurance will not cover you in the following.";

  useEffect(() => {
    let tabList = [];

    tabList = [
      {
        title: "Covered",
        keyId: 0,
        status: "active",
      },
      {
        title: "Not Covered",
        keyId: 1,
        status: "inactive",
      },
    ];

    setTabSectionState(tabList);
  }, []);

  const changeActiveTab = (e) => {
    let activeButton = e.target.value;
    let newList = [];
    tabSectionState.forEach((element) => {
      const tab = { ...element };
      tab.status =
        parseInt(element.keyId) == parseInt(activeButton)
          ? "active"
          : "inactive";
      newList.push(tab);
    });
    setTabSectionState(newList);
  };

  useEffect(() => {
    tabSectionState.forEach((element) => {
      if (element.status === "active") setCoveredState(element.keyId);
    });
  }, [tabSectionState]);

  const SectionState = () => {
    if (coveredState === 0) {
      return (
        <>
          <CoveredAbout
            covered={coveredAbout}
            subTitle={coveredSubTitle}
            cover="inclusions"
            title="covered"
          />
          <Covered
            covered={covered}
            subTitle={coveredSubTitle}
            display={display}
            cover="inclusions"
            title="covered"
            product="travel"
          />
        </>
      );
    } else {
      return (
        <Covered
          covered={notCovered}
          subTitle={notCoveredSubTitle}
          display={display}
          cover="exclusions"
          title="notCovered"
          lob="travel"
          product="travel"
        />
      );
    }
  };

  const handleConfirmation = (event) => {
    const checked = event.target.checked;

    if (checked === true) {
      //setOpen(true);
      props.enableButton();
    } else {
      props.disableButton();
    }
  };
  const [isLoading, setIsLoading] = useState(0);
  const handleDataConfirmation = (event) => {
    const checked = event.target.checked;
    setIsLoading(1);
    if (checked === true) {
      Cache.setItem("flag", 1);
    } else {
      Cache.setItem("flag", 0);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const DisableButton = (event) => {
    Cache.setItem("flag", 0);
  };
  const gotoPlateValidation = (event) => {
    history.push("/motor-insurance/form");
  };

  const clickHandle = () => {
    document.getElementById("handleConfirm").click();
  };

  const gotoForms = () => {
    history.push(`/travel/${props.cover}/form`);
  };

  return (
    <>
      <div className="mb-8">
        <h3 className="font-bold text-lg">{titleCover}</h3>
      </div>

      <TabButtons ListData={tabSectionState} onClick={changeActiveTab} />
      <SectionState />

      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg mt-5">
        <p className="text-sm">
          In case of a claim, please prepare the following documents as SICI's
          reference:
        </p>
        <div className="mt-3 mb-1">
          <div className="pr-3">
            <ul className="text-sm">
              <li>1. Passport</li>
              <li>2. Airline Ticket</li>
              <li>3. Copy of OR for additional expenses such as food, clothing and toiletries.</li>
            </ul>
          </div>
          <div className="mt-3">
            <p className="text-sm">
              Please make sure you have enough GCash Balance to pay for the
              insurance premium.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white p-5 mt-5 more-questions">
        <div className="relative flex">
          <div className="flex-1 flex">
            <div className="pl-3 pr-3">
              <div className="w-full">
                <img
                  src={"/images/help.png"}
                  alt="help"
                  className="covered-image"
                />
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
              </div>
            </div>
            <div className="w-full">
              <h4 className="font-black text-left text-sm title-font">
                Have More Questions
              </h4>
              <p className="text-left text-xs text-gray-400">
                We're here to help
              </p>

              <div className="mt-8 mb-3 text-left">
                <ul>
                  <Link
                    to={`/faq/${props.cover}`}
                    className="border-b border-gray-300 pb-2 block"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Frequently Asked Questions
                    </span>
                    <div className="absolute footer-position">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                  <Link
                    to={"/contact"}
                    className="border-b border-gray-300 pb-2 block mt-2"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Contact Us
                    </span>
                    <div className="absolute footer-position-contact">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={props.open}
        setOpen={props.setOpen}
        title="Data Privacy"
        isDisabled={Cache.getItem("flag")}
        disableButton={DisableButton}
        isLoading={isLoading}
        saveData={gotoForms}
      >
        <form>
          <div className="">
            <div className="flex my-1">
              <div className="pr-3">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  onChange={handleDataConfirmation}
                />
              </div>
              <div>
                <p className="text-xs">
                  In compliance with the Data Privacy Act (DPA) of 2012, and its
                  Implementing Rules and Regulations (IRR) effective September
                  9, 2016, I allow Standard Insurance to collect, store and
                  process my information to provide me certain services declared
                  in relation to the insurance policy/ies I am purchasing.
                </p>

                <p className="text-xs mt-3">
                  For more information on how Standard Insurance protects its
                  data, you may visit our Privacy Policy at{" "}
                  <a
                    className={"text-blue-500"}
                    href="https://standard-insurance.com/privacy-policy.html"
                    target="_blank"
                  >
                    https://standard-insurance.com/privacy-policy.html
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default TravelCoverSection;
