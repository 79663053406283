export const TabButtons = ({ ListData, onClick, ...props }) => {
  const TabListSection = () => {
    return (
    
      <div className="pb-5 max-w-full">
        <div className="relative flex">
          {ListData.map((item, index) => (
            <div className="mr-5" key={index}>
              <button
                type="button"
                className={
                  `font-bold text-base tab-` + item.status
                }
                onClick={onClick}
                value={item.keyId}
              >
                {item.title}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return <TabListSection />;
};
