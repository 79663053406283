import PetCovered from "../../components/organisms/Pet-Insurance/covered";


const PetInsuranceCoveredPage = ({ ...props }) => {
  

  return (
    <PetCovered />
  );
};

export default PetInsuranceCoveredPage;
