import { Cache } from "aws-amplify";
import { useEffect, useState } from "react";

const Summary = (props) => {
  const plateNumber = props.plateNumber;
  const carDetails = props.carDetails;
  const carDisplay = props.carDisplay;
  const otherDetails = props.otherDetails;
  const clientInfo = JSON.parse(Cache.getItem("clientInfo"));
  const SaveForm = (e) => {
    e.preventDefault();
    props.saveData();
  };
  const [mname, setMname] = useState("");
  
  useEffect(() => {
    try {
      setMname(clientInfo.resultData.middleName.charAt(0));
      if (
        clientInfo.resultData.middleName.charAt(0) != "-" &&
        clientInfo.resultData.middleName.charAt(0) != ""
      ) {
        setMname(clientInfo.resultData.middleName.charAt(0) + ".");
      }
    } catch (err) {
      setMname("");
    }
  }, []);
  return (
    <form onSubmit={SaveForm}>
      <div>
        <div className="text-left mb-24">
          <div className="mb-5">
            <h3 className="text-lg font-bold title-font">
              Review your details one last time before we continue!
            </h3>
          </div>

          <div className="flex">
            <div className="flex-1 text-left">
              <span className="font-semibold title-font">Motorcar Details</span>
            </div>
            <div className="flex-1 text-right">
              <button type="button" onClick={props.backtoStart}>
              <span className="text-blue-500 font-bold text-xs">Edit</span>
              </button>
            </div>
          </div>

          <div className="mt-5">
            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Plate Number
                </label>
                <span className="text-lg font-semibold block">{plateNumber.plateNumber}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Year
                </label>
                <span className="text-lg font-semibold block">{carDetails.year}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Make
                </label>
                <span className="text-lg font-semibold block">{carDetails.make}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Model
                </label>
                <span className="text-lg font-semibold block">
                  {carDisplay}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Color
                </label>
                <span className="text-lg font-semibold block">{otherDetails.color}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Chassis Number
                </label>
                <span className="text-lg font-semibold block">
                {otherDetails.chassisNumber}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Engine Number
                </label>
                <span className="text-lg font-semibold block">
                {otherDetails.engineNumber}
                </span>
              </div>
            </div>

            {/* <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  MV File Number
                </label>
                <span className="text-lg font-semibold block">
                  ABC12345678912
                </span>
              </div>
            </div> */}

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Mortgaged
                </label>
                <span className="text-lg font-semibold block">{(otherDetails.isMortgage==="Y")?otherDetails.mortgageeName:"No"}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Owner
                </label>
                <span className="text-lg font-semibold block">
                {(otherDetails.isOwner==="Y")?clientInfo.resultData != null &&
                        clientInfo.resultData.firstName +
                          " " +
                          clientInfo.resultData.middleName +
                          " " +
                          clientInfo.resultData.lastName:otherDetails.ownerFName + " " + otherDetails.ownerMName + " " + otherDetails.ownerLName}
                </span>
              </div>
            </div>

            <div className="mt-16">
              <p className="block text-center text-xs text-gray-400">
                By clicking this button, I agree to share my personal
                information to Standard Insurance for the purpose of processing my
                application and managing my policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" style={{ display: "none" }} id="btnSubmit">
        submit
      </button>
    </form>
  );
};

export default Summary;
