import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/organisms/Header";
import InclusionList from "../components/organisms/Motor-Covered/Inclusion-List";
import InclusionListPet from "../components/organisms/Pet-Covered/Inclusion-List";

const InclusionPage = ({ ...props }) => {
  const location = useLocation();
  

 
  const InclusionDetails = () => {

    //console.log('pet',product)
    if (location.state.lob == "motor") {
      return <InclusionList />;
    } else if (location.state.lob == "pet") {
      return <InclusionListPet />;
    }
  };
  return (
    <div>
      <Header
        headerTitle={props.headerTitle}
        subHeader={props.subheaderTitle}
      />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className="p-5 pt-12">
        <img src={"/images/sici-logo.png"} width="170" height="50" alt="logo" />
      </div>
      <main className="p-5">
        <div className=""><InclusionDetails/></div>
      </main>
    </div>
  );
};
export default InclusionPage;
