import { Cache } from "aws-amplify";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import {
  authDetails,
  motorAPI,
  authAPI,
  basePath,
  travelLocalAPI,
  travelAPI,
  petAPI
} from "../../api/fetch-api";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import { Loading } from "../atoms/loading";

const PaymentSuccess = (props) => {
  const history = useHistory();
  const [policyData, setPolicyData] = useState({});
  const [policy, setPolicy] = useState("");
  const [amount, setAmount] = useState(0);
  const [gcashRefNo, setGcashRefNo] = useState("");
  const [email, setEmail] = useState("");
  const [loading, SetLoading] = useState(true);
  const [buttonTitle, setButtonTitle] = useState("VIEW POLICY");
  const [travelType, setTravelType] = useState("travelAsean");
  const [travel, setTravel] = useState(false);
  const [ctpl, setCTPL] = useState(false);
  const [productType, setProductType] = useState("");

  useEffect(() => {
    Cache.setItem("unid", null);
    const params = new URLSearchParams(window.location.search);
    const policyId = params.get("unid");

    const ctpl_payment = params.get("ctpl");
    if (ctpl_payment !== null) {
      setCTPL(true);
      setProductType("SIVEIN02");
    }

    const pet_payment = params.get("pet");
    if (pet_payment !== null) {
      setProductType("SIPETI01");
    }

    

    //TRAVEL params
    const travel_payment = params.get("travelAsean");
    const travel_payment_international = params.get("travelInternational");
    if (travel_payment !== null) {
      setTravel(true);
      setButtonTitle("VIEW TRAVEL CERTIFICATE");
      setProductType("SITRVL02");
    } else if (travel_payment_international !== null) {
      setTravelType("travelInternational");
      setTravel(true);
      setButtonTitle("VIEW TRAVEL CERTIFICATE");
      setProductType("SITRVL01");
    }else if(productType === "SIPETI01"){
      setTravel(false);
      setButtonTitle("VIEW TRAVEL CERTIFICATE");
      setProductType("SIPETI01");
      GetData(policyId);
    } else {
      GetData(policyId);
    }
  }, []);
  useEffect(() => {}, [ctpl]);
  useEffect(() => {
    
  }, [travel, travelType]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const policyId = params.get("unid");
    GetData(policyId);

  }, [productType]);

  const CurrencyFormat = (num) => {
    const decimalCount = (num) => {
      // Convert to String
      const numStr = String(num);
      // String Contains Decimal
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      // String Does Not Contain Decimal
      return 0;
    };

    let noDecimal = num;
    if (decimalCount(noDecimal) == 1 || decimalCount(noDecimal) == 0)
      noDecimal = noDecimal.toFixed(2); //if decimal place <=1 put toFixed

    let with2Decimals = noDecimal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    let num_parts = with2Decimals.toString().split(".");

    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let formatted = num_parts.join(".");

    return formatted;
  };

  const GetData = (policyId) => {
    var customerID = null;

    //Check Employee Information
    if (
      typeof Cache.getItem("customerId") !== "undefined" &&
      Cache.getItem("customerId") !== undefined &&
      Cache.getItem("customerId") !== null
    )
      customerID = Cache.getItem("customerId")
        .replaceAll("+", " ")
        .replaceAll(" ", "%2B");
    else {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      customerID = params.get("customerId");
      if (customerID === null) {
        history.push("/coming-soon");
      } else {
        customerID = customerID.replaceAll("+", " ").replaceAll(" ", "%2B");
      }
    }

    if (travel) {
      authAPI.getAuth(authDetails).then((data1) => {
        Cache.setItem("ginsure_token", data1.token);
        const param = new URLSearchParams(window.location.search);
        var params = {
          unid: policyId,
          email: atob(param.get("email")),
        };
        setEmail(atob(param.get("email")));
        if (travelType === "travelAsean") {
          travelLocalAPI
            .getLocalTravelPolicy(params)
            .then((data) => {
              console.log("data policy: ", data);
              if (
                typeof data.polno === "object" ||
                data.polno == ""
              ) {
                setTimeout(() => {
                  GetData(policyId);
                }, 1000);
              } else {
                setPolicy(data.branchCode+"-TVL-"+data.certificateNumber);
                setPolicyData(data);
                SetLoading(false);
                Cache.setItem("customerId", customerID);
                setAmount(data.premium.total);
                setGcashRefNo(data.bankReferenceNumber);
                setEmail(data.client.email);
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
            props.sendGTM("/payment-success/?product=TravelAsean","PaymentSuccessTravelAsean","TravelAsean","travela_GInsure5");
        } else {
          travelAPI
            .getTravelPolicy(params)
            .then((data) => {
              console.log("data policy: ", data);
              if (
                typeof data.polno === "object" ||
                data.polno == ""
              ) {
                setTimeout(() => {
                  GetData(policyId);
                }, 1000);
              } else {
                setPolicy(data.branchCode+"-TPP-"+data.certificateNumber);
                setPolicyData(data);
                SetLoading(false);
                Cache.setItem("customerId", customerID);
                setAmount(data.premium.total);
                setGcashRefNo(data.bankReferenceNumber);
                setEmail(data.client.email);
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
            props.sendGTM("/payment-success/?product=TravelInternational","PaymentSuccessTravelInternational","TravelInternational","traveli_GInsure5");
        }
      });
    } else if (!travel) {
      console.log('HEEEEREEE')
      if(productType=="SIPETI01"){
        try {
          authAPI.getAuth(authDetails).then((data1) => {
            console.log("data", data1);
            Cache.setItem("ginsure_token", data1.token);
            const param = new URLSearchParams(window.location.search);
            setEmail(atob(param.get("email")));
            petAPI
            .getPetQuotation(policyId,customerID)
            .then((data) =>{
                setPolicy(data.branchCode +"-"+ data.prodcode +"-"+ data.polno);
                setPolicyData(data);
                console.log("data policy: ", data);
                if (
                  typeof data.polno === "object" ||
                  data.polno == ""
                ){
                  setTimeout(() => {
                    GetData(policyId);
                  }, 1000);
                } else {
                  SetLoading(false);
                }
                Cache.setItem(
                  "customerId",
                  data.insuranceAccountId
                    .replaceAll("+", " ")
                    .replaceAll(" ", "%2B")
                );
                setAmount(data.premium.totalPremium);
                setGcashRefNo(data.bankReferenceNumber);
                setEmail(data.client.email);
              })
              .catch((error) => {
                console.log(error);
                console.log("There was an error!");
              });
          });
        } catch (err) {
          console.log("Error fetch details:", err);
        }
        props.sendGTM("/payment-success/?product=Pet","PaymentSuccessPet","Pet","pet_GInsure7");

      }
      else{
        try {
          authAPI.getAuth(authDetails).then((data1) => {
            console.log("data", data1);
            Cache.setItem("ginsure_token", data1.token);
            const param = new URLSearchParams(window.location.search);
            setEmail(atob(param.get("email")));
            motorAPI
              .getMotorPolicy(policyId, customerID, data1.token)
              .then((data) => {
                setPolicy(data.policyNumber);
                setPolicyData(data);
                console.log("data policy: ", data);
                if (data.policyNumber.indexOf("null") !== -1) {
                  setTimeout(() => {
                    GetData(policyId);
                  }, 1000);
                } else {
                  SetLoading(false);
                }
                Cache.setItem(
                  "customerId",
                  data.client.ginsureAccountId
                    .replaceAll("+", " ")
                    .replaceAll(" ", "%2B")
                );
                setAmount(data.premium.total);
                setGcashRefNo(data.bankReferenceNumber);
                setEmail(data.client.email);
              })
              .catch((error) => {
                console.log(error);
                console.log("There was an error!");
              });
          });
        } catch (err) {
          console.log("Error fetch details:", err);
        }
        if (ctpl === true) {
          props.sendGTM("/payment-success/?product=CTPL","PaymentSuccessCTPL","CTPL","CTPL_GInsure5");
        }
        else{
          props.sendGTM("/payment-success/?product=Motor","PaymentSuccessMotor","Motor","motor_GInsure5");
        }
      }
     
    }
  };

  const onPrint = () => {
    // split then get the unid part (first index)
    const params = new URLSearchParams(window.location.search);
    const unid = params.get("unid");
    var isCmp = false;
    var lob = "motor";
    let email = params.get("email");
    var manage_url =
      "/manage-policy/" +
      unid +
      "/" +
      email +
      "/" +
      productType +
      "?customerId=" +
      params.get("customerId");
    // if(productType==="travelAsean"){
    //   let email= params.get("email");
    //   manage_url = "/manage-policy/"+unid+"/"+email+"/?customerId="+params.get("customerId");
    // }

    authAPI.getAuth(authDetails).then((data1) => {
      //window.location.href = basePath.baseUrl + "/policy/" + unid;
      SetLoading(true);
      history.push(manage_url);
      //window.location.href = "http://docs.google.com/gview?embedded=true&url=https://gymp3t84o3.execute-api.ap-southeast-1.amazonaws.com/api1/policy/quotation/"+ unid;
      fetch(basePath.baseUrl + "/policy/" + unid, {
        //fetch("https://gymp3t84o3.execute-api.ap-southeast-1.amazonaws.com/api1/policy/quotation/"+ unid,{
        method: "GET",
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
          Authorization: data1.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => {
          //*********Download */
          // var link = document.createElement('a');
          // link.href = window.URL.createObjectURL(data);
          // link.download = "Policy Schedule.pdf";
          // link.click();

          //*********Display */
          var file = new Blob([data.data], { type: "application/pdf" });
          var fileUrl = URL.createObjectURL(data);
          //window.open(fileUrl, "Policy Schedule");
          //window.location.href = basePath.baseUrl + "/policy/" + unid;
        })
        .catch((error) => {
          console.log("There was an error printing: ", error);
        });
    });
  };

  const returnToMain = (e) => {
    e.preventDefault();
    //alert("");
  };

  return (
    <>
      <Header headerTitle={props.headerTitle} saveData={returnToMain} />
      {loading && <Loading />}
      {!loading && (
        <>
          <div
            className="absolute w-full"
            // style={{
            //   backgroundImage: `url("/images/bg.png")`,
            //   backgroundRepeat: "no-repeat",
            //   width: "250px",
            // }}
          >
            <img alt="standard" src={"/images/bg.png"} className="image" />
          </div>
          <div className={"p-5 pt-12"}>
            <img
              src="/images/sici-logo.png"
              width="170"
              height="50"
              alt="logo"
            />
          </div>
          <div className="mt-8 p-4">
            <div className="text-left mb-10">
              {/* <div>
            <img alt="payment-banner"/>
        </div> */}
              <div className="mb-5">
                {ctpl === true &&
                  typeof policyData.authNumber !== "object" &&
                  policyData.coverType === "07" && (
                    <h3 className="text-base font-bold leading-tight text-center text-green-700">
                      Thank you and Congratulations! You are now covered.
                    </h3>
                  )}
                {ctpl === true &&
                  typeof policyData.authNumber === "object" &&
                  policyData.coverType === "07" && (
                    <h3 className="text-base font-bold leading-tight text-center text-green-700">
                      Thank you!
                    </h3>
                  )}{" "}
                {/**FAILED */}
                {ctpl || (
                  <h3 className="text-base font-bold leading-tight text-center text-green-700 title-font">
                    Congratulations, you are now insured!
                  </h3>
                )}
              </div>

              <div>
                {(ctpl !== true ||
                  (typeof policyData.authNumber !== "object" &&
                    policyData.coverType === "07")) && (
                  <p className="text-xs mt-5 mb-10 font-bold text-center">
                    That’s one less thing to worry about! We sent you a welcome
                    email with your insurance details at{" "}
                    {/* <span className="font-bold">{email}</span>.If it’s not in */}
                    <b>{email}</b>.If it’s not in
                    your inbox, check your spam folder!
                  </p>
                )}

                {ctpl === true &&
                  (policyData.authNumber === null ||
                    policyData.authNumber === "") &&
                  policyData.coverType === "07" &&
                  policyData.coverType === "07" && (
                    <>
                      <p className="text-xs mt-5 mb-10 font-bold text-center">
                        However, your Certificate of Cover failed
                        authentication. Please wait for a call from our
                        representative.
                      </p>

                      <p className="text-xs mt-5 mb-10 font-bold text-center">
                        We sent you a welcome email with your insurance details
                        at
                        <span className="font-bold">{email}</span>. If it’s not
                        in your inbox, check your spam folder!
                      </p>
                    </>
                  )}

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Policy Number
                    </span>
                  </div>

                  <div className="flex-1 text-right">
                    <span className="text-sm">{policy}</span>
                  </div>
                </div>
                {ctpl && policyData.coverType === "07" && (
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        COC Number
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {policyData.cocNumber !== null &&
                          policyData.cocNumber !== "" &&
                          policyData.coverType === "07" &&
                          policyData.cocNumber}
                      </span>
                    </div>
                  </div>
                )}
                {ctpl &&
                  policyData.authNumber !== null &&
                  policyData.authNumber !== "" &&
                  policyData.coverType === "07" && (
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Authentication Number
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">
                          {policyData.authNumber !== null &&
                            policyData.authNumber !== "" &&
                            policyData.coverType === "07" &&
                            policyData.authNumber}
                        </span>
                      </div>
                    </div>
                  )}

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Amount Paid
                    </span>
                  </div>

                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      PHP {CurrencyFormat(amount)}
                    </span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      GCash Reference No
                    </span>
                  </div>

                  <div className="flex-1 text-right">
                    <span className="text-sm">{gcashRefNo}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed bottom-20 bg-white justify-center text-center w-full p-4 ">
            <div>
              <p className="block text-center text-xs text-gray-400">
                Should you need an assistance or if you have any inquiries,
                please email us at{" "}
                <span className="text-blue-600 font-bold">
                  insurance@gcash.com
                </span>
              </p>
            </div>
          </div>
        </>
      )}

      <Footer
        buttonType="button"
        saveData={onPrint}
        isLoading={loading}
        // isDisabled={isDisabled}
        buttonTitle={buttonTitle}
      />
    </>
  );
};

export default PaymentSuccess;
