import { Cache } from "aws-amplify";
export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
};

function get(url,params) {
  let requestOptions = {};
  if(params!==undefined && params!==null){
    requestOptions = {
      method: "GET",
      headers: { Authorization: params },
    };
  }
  else if(Cache.getItem("ginsure_token")!==null && Cache.getItem("ginsure_token")!==undefined){
    requestOptions = {
      method: "GET",
      headers: { Authorization: Cache.getItem("ginsure_token") },
    };
  }
  else{
    requestOptions = {
      method: "GET"
    };
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, key) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  };

  if(Cache.getItem("ginsure_token")!==null && Cache.getItem("ginsure_token")!==undefined){
    requestOptions.headers = { "Content-Type": "application/json",Authorization: Cache.getItem("ginsure_token") };
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body,key) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  if(Cache.getItem("ginsure_token")!==null && Cache.getItem("ginsure_token")!==undefined){
    requestOptions.headers = {"Content-Type": "application/json", Authorization: Cache.getItem("ginsure_token") };
  }
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {
  if (response.ok) {
    return response.json();
  }
  throw response.json();
}
