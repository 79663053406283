import { useState, useEffect } from "react";
import Header from "../../components/organisms/Header";

const TravelAssuredDetails = (props) => {
  return (
    <>
      <div>
        <Header headerTitle={props.headerTitle} />

        <div className={props.main === "main" ? "main p-5" : "p-5"}>
          <img
            src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
            width="170"
            height="50"
            alt="logo"
          />
        </div>
        <main className=" p-5">
          <p>Insert Wizard</p>

          <h3 className="text-lg font-bold title-font">
            Please fill in your details
          </h3>

          <h4 className="font-bold mt-8 mb-3">Assured 1</h4>

          <div>
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Full name
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="fullname"
                />
              </div>
            </div>

            {/**Birthday validation should correspond to ageBracket */}
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Birthday
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="date"
                  name="birthday"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Passport Number
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="passport"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Beneficiary
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="beneficiary"
                  value="COMPULSARY HEIRS"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Email Address
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="emailaddress"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Contact Number
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="contanctno"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default TravelAssuredDetails;
