import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ModalMessage({ open, setOpen, children, ...props }) {
  const cancelButtonRef = useRef(null);
  const closeModal = () => {
    setOpen(false);
    if (props.disableButton !== undefined) {
      props.disableButton();
    }
  };
  const closeDisabled = () => {
    setOpen(false);
    if (props.disableButton !== undefined) {
      props.disableButton();
    }
  };
  const [isLoading, setIsLoading] = useState(props.isLoading);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={closeDisabled}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-10/12">
              <div className="bg-white px-5 pb-5">
                <div className="">
                  <div className="mt-5 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base leading-6 font-bold text-gray-900 title-font"
                    >
                      {props.title}
                    </Dialog.Title>
                    <div className="mt-5">{children}</div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3">
                <div className="w-full">
                  {props.isDisabled !== undefined && props.isDisabled === 0 && (
                    <button
                      type="button"
                      className="bg-blue-600 text-white py-2 w-full rounded-full text-xs opacity-50"
                      disabled
                    >
                      {props.buttonTitle!==undefined? props.buttonTitle : "SUBMIT"}
                    </button>
                  )}
                  {props.saveData !== undefined &&
                    (props.isDisabled === undefined ||
                      props.isDisabled !== 0) && (
                      <button
                        type="button"
                        className="bg-blue-600 text-white py-2 w-full rounded-full text-xs"
                        onClick={props.saveData}
                      >
                        {props.buttonTitle!==undefined? props.buttonTitle : "SUBMIT"}
                      </button>
                    )}
                  {props.saveData === undefined &&
                    (props.isDisabled === undefined ||
                      props.isDisabled !== 0) && (
                      <button
                        type="button"
                        className="bg-blue-600 text-white py-2 w-full rounded-full text-xs"
                        onClick={closeModal}
                      >
                        {props.buttonTitle!==undefined? props.buttonTitle : "OK"}
                      </button>
                    )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
