/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  let history = useHistory();
  return (
    <Disclosure as="nav" className="sticky top-0 z-50 bg-blue-600">
      <>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-12">
            <div className="items-center sm:justify-start">
              {props.saveData === undefined && (
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="flex"
                >
                  <div className="items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className=" flex-1 block sm:ml-10 xs:ml-3 text-lg">
                    <div>
                      <span className="text-white text-base block ml-2 align-middle title-font">
                        {props.headerTitle !== undefined && props.headerTitle}
                        {props.headerTitle === undefined &&
                          "GCash X Standard Insurance"}
                      </span>
                    </div>
                  </div>
                </button>
              )}
              {props.saveData !== undefined && (
                <button type="button" onClick={props.saveData} className="flex">
                  <div className="items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className=" flex-1 block sm:ml-10 xs:ml-3 text-lg">
                    <div>
                      <span className="text-white text-base block ml-2 align-middle title-font">
                        {props.headerTitle !== undefined && props.headerTitle}
                        {props.headerTitle !== undefined ||
                          "GCash X Standard Insurance"}
                      </span>
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>

        {props.subHeader !== undefined && (
          <div className="">
            <div className="bg-white p-3 border-b-2 border-gray-200">
              <div className="block sm:ml-10 xs:ml-3 text-lg text-center relative">
                <div>
                  <span className="text-gray-700 font-bold">
                    {props.subHeader}
                  </span>
                  {props.subPage === undefined && (
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="absolute top-0 right-0"
                    >
                      <div className="items-right font-bold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </button>
                  )}
                  {props.subPage !== undefined && (
                    <button
                      type="button"
                      onClick={props.saveData}
                      className="absolute top-0 right-0"
                    >
                      <div className="items-right font-bold">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
           
          </div>
        )}
      </>
    </Disclosure>
  );
};
export default Header;
