import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Cache } from "aws-amplify";
import Header from "../../organisms/Header";
import Footer from "../../organisms/Footer";
import { basePath, motorCtplAPI, gcashAPI, authAPI, authDetails} from "../../../api/fetch-api";
import { Loading } from "../../atoms/loading";


import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/helper";

function CTPLFormReview(props, test) {
  let history = useHistory();

  const [ctplData, setCtplData] = useState({})
  const [premium, setPremium] = useState("")
  const [isDisabled, setIsDisabled] = useState(true);
  const [disabledBtnFlag, setDisabledBtnFlag] = useState(true);
  const [loading, setLoading] = useState(true)

  const [cbx1, setCbx1] = useState(false);
  const [cbx2, setCbx2] = useState(false);
  const [cbx3, setCbx3] = useState(false);
  const [cboxes, setCboxes] = useState([false,false,false,false])

  const [cardetailsCache, setCardetailsCache] = useState(Cache.getItem("carDetailsState"))
  const [clientdetailsCache, setClientdetailsCache] = useState(Cache.getItem("clientDetailsState"))
  const [unid, setUnid] = useState("")
  const [auth, setAuth] = useState("")
  const [premiumData, setPremiumData] = useState(Cache.getItem("premiumData"))
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  });
  const premiumPay = {
    ctplCoverage: (premiumData.ctplCoverage != null)?premiumData.ctplCoverage:0,
    basicPremium: (premiumData.basicPremium != null)?premiumData.basicPremium:0,
    evat: (premiumData.evat != null)?premiumData.evat:0,
    dst: (premiumData.dst != null)?premiumData.dst:0,
    lgt: (premiumData.lgt != null)?premiumData.lgt:0,
    vf: (premiumData.vf != null)?premiumData.vf:0,
    totalPremium: (premiumData.totalPremium != null)?premiumData.totalPremium:0,
  }

  useEffect(() => {
    cbxOnLoad();

    if(Cache.getItem("ctplMotorResponse") !== undefined || 
       Cache.getItem("ctplMotorResponse") !== null 
    ) {
      const prem_cache = Cache.getItem("ctplMotorResponse")
      setCtplData(prem_cache)
    }

    authAPI
      .getAuth(authDetails)
      .then((data) => { 
        setAuth(data) 
      })

    
  }, []);
  useEffect(() => {
  }, [ctplData]);
  const cbxOnLoad = () => {
  
    
    setTimeout(() => {
    let cbx = document.querySelectorAll("[name*='cbx']")
    if(isDisabled === true) {
      for(let x=0; x<cbx.length; x++) {
          cbx[x].checked = false
      }
      setLoading(false)
    }
    }, 800);
  }
  const handleCheckbox = (e) => {
    let objName = e.target.name
    let cbindex;

    if(objName === "cbx1" ) {
      cbindex = 0;
    }else if(objName === "cbx2") {
      cbindex = 1;
    }else if(objName === "cbx3") {
      cbindex = 2;
    }else if(objName === "cbx4") {
      cbindex = 3;
    }
    
    const checkboxes = cboxes.map(function (item, index)  {
        if(index === cbindex) {
          return !item
        }else {
          return item
        }

      }
    );

    setCboxes(checkboxes)
  }
  

  useEffect(() => {
    if(cboxes[0] === true && cboxes[1] === true && cboxes[2] === true && cboxes[3] === true) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [cboxes]);

  const SubmitForm = () => {
    setIsDisabled(true)
    const client = {
      address1: clientdetailsCache.address1,
      address2: clientdetailsCache.address2,
      addressType: clientdetailsCache.addressType,
      bday: clientdetailsCache.bday,
      branchCode: clientdetailsCache.branchCode,
      clientType: clientdetailsCache.clientType,
      contactNumber: formatNumber(clientdetailsCache.contactNumber),
      contactType: clientdetailsCache.contactType,
      email: clientdetailsCache.email,
      firstName: clientdetailsCache.firstName,
      fullName: clientdetailsCache.fullName,
      gender: clientdetailsCache.gender,
      idNo: clientdetailsCache.idNo,
      idType: clientdetailsCache.idType,
      lastName: clientdetailsCache.lastName,
      middleName: clientdetailsCache.middleName,
      nationality: clientdetailsCache.nationality,
      postalCode: clientdetailsCache.postalCode,
      province: clientdetailsCache.province,
      ginsureAccountId: clientdetailsCache.ginsureAccountId
    }

    const postData = {
      year: cardetailsCache.year,
      make: cardetailsCache.make,
      model: cardetailsCache.model,
      //modelid: cardetailsCache.modelid,
      engineType: cardetailsCache.engineType,
      fmv: cardetailsCache.fmv,
      bodyType: cardetailsCache.bodyType,
      seats: cardetailsCache.seats,
      carDisplay: cardetailsCache.carDisplay,
      color: cardetailsCache.color,
      chassisNumber: cardetailsCache.chassisNumber,
      engineNumber: cardetailsCache.engineNumber,
      plateNumber: cardetailsCache.plateNumber,
      mvFileNumber: cardetailsCache.mvFileNumber,
      mvType: cardetailsCache.mvType,
      startDate: cardetailsCache.startDate,
      endDate: cardetailsCache.endDate,
      regType: cardetailsCache.regType,
      mvPremtype: cardetailsCache.mvPremtype,
      insuranceAccountId: cardetailsCache.insuranceAccountId,
      client: client,
      merchantId: ""+ process.env.REACT_APP_CTPL_ID+""
    }

    if(cardetailsCache.mvPremtype === "1") {
      postData.modelid = cardetailsCache.modelid
    }
    
    if (
      Cache.getItem("unid") !== undefined &&
      Cache.getItem("unid") !== 0 &&
      Cache.getItem("unid") !== null
    ) {

      postData.unid =  Cache.getItem("unid");
      fetch(
        basePath.baseUrl + "/quotation/motor/ctpl/" + Cache.getItem("unid"),
      // fetch(
      //   "https://1cr450kc61.execute-api.ap-southeast-1.amazonaws.com/dev/quotation/motor/ctpl/"+Cache.getItem("unid") ,
        {
          method: "PUT",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            "Authorization" : auth.token
          },
        }
      ).then((response) => {
            let response_status = response.status;
            if (response_status == 200) {

              var myHeaders = new Headers();
              myHeaders.append("Authorization", auth.token);

              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
              };

              fetch(basePath.baseUrl + "/order/" + Cache.getItem("unid") , requestOptions)
                .then(response => response.text())
                .then((result) => {
                  setTimeout(() => {
                          window.location.href = JSON.parse(result).checkoutUrl;
                        }, 1000);
                })
                .catch((error) => {
                  console.log('error', error)
                  setIsDisabled(false)
                });
            }
          })
        .catch((error) => {
          console.log(error);
          console.log("There was an error!");
          setIsDisabled(false);
        });
    } else {
      motorCtplAPI.postMotor(postData)
      .then((d) => {
        Cache.setItem("unid", d.unid);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", auth.token);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };

        // fetch("https://1cr450kc61.execute-api.ap-southeast-1.amazonaws.com/dev/order/"+Cache.getItem("unid"), requestOptions)
        fetch(basePath.baseUrl+"/order/"+Cache.getItem("unid"), requestOptions)
        .then(response => response.text())
        .then((result) => {
            setTimeout(() => {
              window.location.href = JSON.parse(result).checkoutUrl;
            }, 1000);
        })
        .catch(error => console.log('error', error));
      })
      .catch((err) => console.log("postMotor Error: ", err));
    }
    
  }



  return (
    <div>
      <Header headerTitle={props.headerTitle} />

      <div className="absolute w-full">
        <img alt="review" src={"/images/review.png"} className="image" />
      </div>
      { !loading && (
        <main className=" p-5">
        <div className="mt-48">
          <div className="text-left mb-10">
            {/* <div>
              <img alt="payment-banner" />
            </div> */}
            <div className="mt-5">
              <span className="text-base font-bold">Coverage</span>
              <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Coverage
                    </span>
                  </div>

                  <div className="flex-1 text-right">
                    <span className="text-sm">Up to PHP {formatter.format(premiumPay.ctplCoverage).replace("₱", "")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <span className="text-base font-bold">Premium</span>
              <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Premium
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">{formatter.format(premiumPay.basicPremium).replace("₱", "")}</span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Premium Tax
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">{formatter.format(premiumPay.evat).replace("₱", "")}</span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Doc Stamps
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">{formatter.format(premiumPay.dst).replace("₱", "")}</span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Local Govt. Tax
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">{formatter.format(premiumPay.lgt).replace("₱", "")}</span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Verification Fee
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm font-bold">{formatter.format(premiumPay.vf).replace("₱", "")}</span>
                  </div>
                </div>
                <hr className="my-5" />

                <div className="flex mt-5">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Total Amount{" "}
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">{formatter.format(premiumPay.totalPremium).replace("₱", "")}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <span className="text-base font-bold">Insured</span>
              <div className="mt-2 bg-blue-50 p-3 rounded-lg">
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">Name</span>
                  </div>

                  <div className="flex-1 text-right">
                    <span className="text-sm font-bold">{clientdetailsCache.fullName}</span>
                  </div>
                </div>

                <div className="flex mt-2">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Vehicle Insured
                    </span>
                  </div>

                  <div className="flex-1 text-right">
                    <span className="text-sm font-bold">{cardetailsCache.model}</span>
                  </div>
                </div>
              </div>
            </div>

            <span className="text-base font-bold mt-10 block">
              Declarations
            </span>
            <div className="flex mt-5">
              <div className="px-5">
                <input type="checkbox" name="cbx1" onChange={handleCheckbox}/>
              </div>
              <div>
                <p className="text-sm">
                  I confirm that the insured vehicle with new plate number is duly
                  registered and updated with the LTO. My non-compliance will
                  result to void transactions.
                </p>
              </div>
            </div>
            <div className="flex mt-5">
              <div className="px-5">
                <input type="checkbox" name="cbx2" onChange={handleCheckbox}/>
              </div>
              <div>
                <p className="text-sm">
                  I confirm that the owner of the insured vehicle has given
                  his/her consent to proceed with the application and that all
                  information provided here is true and correct.
                </p>
              </div>
            </div>

            <div className="flex mt-5">
              <div className="px-5">
                <input type="checkbox" name="cbx3" onChange={handleCheckbox}/>
              </div>
              <div>
                <p className="text-sm">
                  I confirm that the insured vehicle is located in the Philippines.
                </p>
              </div>
            </div>

            <span className="text-base font-bold mt-10 block">
              Terms and Conditions
            </span>
            <div className="flex mt-5">
              <div className="px-5">
                <input type="checkbox" name="cbx4" onChange={handleCheckbox}/>
              </div>
              <div>
                <p className="text-sm">
                  I have read{" "}
                  <span className="text-blue-500 font-bold">
                    {" "}
                    terms and agreements{" "}
                  </span>{" "}
                  of Standard Insurance Co., Inc.
                </p>
              </div>
            </div>

            <div className="relative flex mt-16 mb-32">
              <div className="flex-1 flex">
                <div className="pl-5 pr-5">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h4 className="font-black text-left text-sm">Need help?</h4>
                  <p className="text-left text-xs text-gray-400">
                    Should you need any assistance or have any inquiries, please
                    contact{" "}
                    <span className="text-blue-600 font-bold">
                      ginsure@standard-insurance.com
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      )}
      {loading && <Loading />}
      <Footer
        buttonType="button"
        saveData={SubmitForm}
        // isLoading={isLoading}
        isDisabled={isDisabled}
        buttonTitle={"CONFIRM AND PAY"}
      />
    </div>
  );
}
export default CTPLFormReview;
