import { useState } from "react";

const MutatingDropdown = ({
  valueKey,
  labelKey,
  data,
  setSelection,
  setSelectionTo,
  maxSelections,
  minSelections,
  selectedList
}) => {
  /* ======================= USESTATE ========================== */
  const [localSelected, setLocalSelected] = useState(selectedList ?? [""]);
  /* ======================= INITIALIZE ========================== */
  var disableAdd = (localSelected.length != 0 && localSelected[localSelected.length - 1] == "")||localSelected.length == maxSelections;

  /* ======================= FUNCTIONS ========================== */

  const addLocalSelected = () => {
    if (localSelected.length < maxSelections) {
      setLocalSelected((prevSelected) => [...prevSelected, ""]); //for some reason making this completely empty will make all selected empty aswell onEvent
    }
  };
  const updateLocalSelected = (index, event) => {
    
    const value = event.target.value;

    setLocalSelected((prevSelected) => {
      const updatedSelected = prevSelected.map((item, i) => {
        return i === index ? value : item;
      });
      setSelection(updatedSelected,setSelectionTo);
      return updatedSelected;
    });
  };
  const removeLocalSelected = (index) => {
    if(localSelected.length>minSelections){
      const updatedSelected = localSelected.filter((_, i) => i !== index);
      setLocalSelected(updatedSelected);
      setSelection(updatedSelected);
    }
  };

  /* ======================= RENDER UI ========================== */

  return (
    <div className="block relative w-full">
      <div className="mb-3">
        {" "}
        <label className={"inline-block text-gray-500 text-sm font-bold"}>
          Please select pre-existing condition/s{" "}
          <span className="text-red-500">*</span>{" "}
        </label>
        <div class="float-right">
            <button
              type="button"
              className={`mr-2 inline-block align-middle ${
                disableAdd ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={disableAdd}
            onClick={addLocalSelected}
            >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-yellow-500" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
        
      </div>
      {localSelected.map((item, index) => {
        var disableSelect = localSelected.length - 1 !== index;
        var disableDelete = minSelections - 1 == index;
        return (
          <>
          <div key={index} className="flex">
            <select
              name="make"
              className="input-border-none focus:outline-none focus:shadow-outline"
              value={item}
              onChange={(event) => {
                updateLocalSelected(index, event);
              }}
              disabled={disableSelect}
              required
            >
              <option value="">Please select condition</option>
              {data.map((data, dataIndex) => {
                const filteredData = data[valueKey] == item ? " " : data[valueKey];
                return (
                  !localSelected.includes(filteredData) && (
                    <option key={dataIndex} value={data[valueKey]}>
                      {data[labelKey]}
                    </option>
                  )
                );
              })}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
              <button
              type="button"
              className={`mr-2 inline-block align-middle`}
              onClick={() => removeLocalSelected(index)}
              disabled={disableDelete}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                style={{pointerEvents: "none"}}
                disabled
                
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                  clipRule="evenodd"
                  disabled
                />
              </svg>
            </button>
           
          </div>
          </>
        );
      })}
    </div>
  );
};

export default MutatingDropdown;