import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CTPLPremium = ({ ...props }) => {
  return (
    <>
      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg">
        <div>
          <h2 className="text-gray-800 text-sm font-bold leading-snug">
            Your Compulsory Third Party Liability (CTPL) offers the following
            premiums.
          </h2>

          <h4 className="mt-5 text-center text-sm font-bold">Renewal (1-year coverage)</h4>
          <div className="relative border border-gray-200 mt-1 p-3 rounded">
           

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Private Cars</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">610.40</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Motorcycles</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">300.40</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Light Medium Trucks</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">660.40</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Heavy Trucks</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">1, 250.40</span>
              </div>
            </div>
          </div>

          <h4 className="mt-5 text-center text-sm font-bold">Brand New (3-year coverage)</h4>
          <div className="relative border border-gray-200 mt-1 p-3 rounded">
            
            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Private Cars</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">1, 660.40</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Motorcycles</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">770.40</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Light Medium Trucks</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">1.800.40</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Heavy Trucks</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">PHP</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">3, 490.40</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTPLPremium;
