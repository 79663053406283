import Wizard from "../../components/organisms/Wizard";

const StepWizard = () => {
  return (
    <>
      <Wizard />
    </>
  );
};

export default StepWizard;
