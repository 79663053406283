import { useEffect, useState } from "react";
import Accordion from "../../molecules/Accordion";
import LabelledContent from "../../molecules/LabelledContents";
import ProgressBar from "../ProgressBar";
import { formatDate } from "../../utils/helper";
import { Cache } from "aws-amplify";

const PetSummary = (props) => {
  const AccordionTitles = ["Pet Details", "Other Pet Details"];
  const [isLoading, setIsLoading] = useState(1);

  

  const [petDetails, setPetDetails] = useState(props.petDetailsState)
  const [otherDetails, setOtherDetails] = useState(props.otherDetailsState)
  const [clientDetails, setClientDetails] = useState(props.clientDetailsState)

  const Labels = [
    "Gender", 
    "Color", 
    "Desexed?", 
    "Microchipped?",
    (otherDetails.microchipped)?"Microchip Number":"", 
    (otherDetails.microchipped)?"Date of Insertion":"", 
    "PCCI Number",
    "Regular Veterinary Clinic",
    "How was your pet acquired?",
    "Date Acquired",
    "Is your pet regulary vaccinated?",
    "With pre-existing conditions?",
    "Pre-existing conditions"
  ];
  const Contents = [
    (petDetails.petGender === "F")?"Female":"Male", 
    otherDetails.petColor, 
    (otherDetails.desexed)?"Yes":"No",
    otherDetails.microchipped?"Yes":"No",
    otherDetails.microchipped?otherDetails.microchipNo:"",
    otherDetails.microchipped?formatDate(new Date(otherDetails.dateOfInsertion)):"",
    (otherDetails.pcciNo.length === 0)?"None":otherDetails.pcciNo,
    otherDetails.veterinaryClinic,
    otherDetails.petAcquiredDesc,
    formatDate(new Date(otherDetails.dateAcquired)),
    otherDetails.regularlyVaccinated?"Yes":"No",
    otherDetails.sufferedAccidental?"Yes":"No",
    (otherDetails.preConditions.length === 0)?"None":otherDetails.preConditions.join(", ")
  ];

  useEffect(() => {
    console.log("test", petDetails, otherDetails);
    setIsLoading(false);
  }, []);

  useEffect(() => {}, [isLoading]);

  const AccordionContents = (index) => {
    if (index === 0) {
      return (
        <>
          <div className="mt-5 mx-3">
          <div className="flex">
            <div className="flex-1 text-left">
              <span className="font-semibold title-font"></span>
            </div>
            <div className="flex-1 text-right">
              <button type="button" onClick={props.BacktoStart}>
              <span className="text-blue-500 font-bold text-xs">Edit</span>
              </button>
            </div>
          </div>
            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Pet's Name
                </label>
                <span className="text-lg font-semibold block">{petDetails.petName}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Species
                </label>
                <span className="text-lg font-semibold block">{petDetails.species === "C"?"Canine":"Feline"}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Breed
                </label>
                <span className="text-lg font-semibold block">{petDetails.breed}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Type
                </label>
                <span className="text-lg font-semibold block">{petDetails.type === "P"?"Pure Breed":"Cross Breed"}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Birthdate
                </label>
                <span className="text-lg font-semibold block">{formatDate(new Date(petDetails.bday))}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Age
                </label>
                <span className="text-lg font-semibold block">{petDetails.petAge}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Age Group
                </label>
                <span className="text-lg font-semibold block">
                  {
                    petDetails.petAgeGroup === "P"?"Young" :
                    petDetails.petAgeGroup === "A"?"Adult" : "Senior"
                  }
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Sire
                </label>
                <span className="text-lg font-semibold block">{petDetails.sire}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Dam
                </label>
                <span className="text-lg font-semibold block">{petDetails.dam}</span>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div class="mt-5 mx-3">
            <div className="flex">
            <div className="flex-1 text-left">
              <span className="font-semibold title-font"></span>
            </div>
            <div className="flex-1 text-right">
              <button type="button" onClick={gotoStep}>
              <span className="text-blue-500 font-bold text-xs">Edit</span>
              </button>
            </div>
          </div>
          <LabelledContent Labels={Labels} Contents={Contents} />
        </div>
      );
    }
  };

  const SaveForm = (e) => {
    e.preventDefault();
    props.saveData();
    Cache.setItem("coveredFlag",false);
  };
  const gotoStep= (step)=>{
    props.GotoStep(2);
  }
  return (
    <form onSubmit={SaveForm}>
      <ProgressBar />

      <div className="my-5">
        <div>
          <div className="text-left mb-24">
            <div className="mb-5">
              <h3 className="text-lg font-bold">
                Review your details one last time before we continue!
              </h3>
            </div>

            {!isLoading && (
              <Accordion
                AccordionTitles={AccordionTitles}
                AccordionLocation={"summary"}
                AccordionContents={AccordionContents}
              />
            )}
          </div>
        </div>
        <button type="submit" style={{ display: "none" }} id="btnSubmit">
          submit
        </button>
      </div>
    </form>
  );
};

export default PetSummary;
