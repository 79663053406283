import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Cache } from "aws-amplify";
import { petAPI, gcashAPI, basePath } from "../../../api/fetch-api";

import Header from "../../organisms/Header";
import Footer from "../../organisms/Footer";
import { formatNumber } from "../../utils/helper";
const PetReview = (props) => {
  let history = useHistory();
  const unid = Cache.getItem("unid");
  const form_data = Cache.getItem("pet_data");
  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  const token_id = Cache.getItem("ginsure_token");

  const [petDetails, setPetDetails] = useState(Cache.getItem("petDetailsState"))
  const [quotationDetails, setQuotationDetails] = useState(Cache.getItem("quotationState"))
  const [otherDetails, setOtherDetails] = useState(Cache.getItem("otherDetailsState"))
  const [clientDetails, setClientDetails] = useState(Cache.getItem("clientDetailsState"))

  //gcash api - get clientinfo
  const [clientInfo, setClientInfo] = useState([]);

  const EnableButton = () => {
    setIsDisabled(0);
  };
  const DisableButton = () => {
    setIsDisabled(1);
  };
  useEffect(() => {
    console.log(petDetails, "-", quotationDetails, otherDetails, clientDetails)
    console.log(Cache.getItem("quotationState"))
    setClientInfo(JSON.parse(Cache.getItem("clientInfo")))
  }, []);

  useEffect(() => {
    try {
      setIsLoading(0);
      EnableButton();
    } catch (err) {
    }
  }, []);

  const gotoTerms = () =>{
    setIsLoading(1);
    window.location.replace("https://docs.google.com/gview?embedded=true&url="+ process.env.REACT_APP_PET_TERMS+"");
  }
  
  const SubmitButton = (e) => {
    document.getElementById("btnSubmit").click();
  }
  const SubmitForm = (e) => {
    e.preventDefault();
    DisableButton();

    try{
      // let clientAdd = [
      //   {
      //     address1: clientInfo.resultData.address.street,
      //     address2: (clientInfo.resultData.address.town !==null && clientInfo.resultData.address.town !=="")? clientInfo.resultData.address.town : " ",
      //     bday:clientInfo.resultData.birthday,
      //     contactType:"H",
      //     contactNo:clientInfo.resultData.mobileNumber,
      //     email:clientInfo.resultData.emailAddress,
      //     gender:clientDetails.gender,
      //     hasPa:false,
      //     name:clientDetails.fullName,
      //     passport:"",
      //     postal:clientInfo.resultData.address.zipCode,
      //     primary:true,
      //     province: "Manila",
      //     // relation:"Next of Kin",
      //     seq:0,
      //     unid:0,
      //     maritalStatus: assuredDetailsState?.assured?.[0]?.maritalStatus,
      //   }
      // ]
      console.log("petdetails",petDetails, quotationDetails, otherDetails)
      
      let preCond = otherDetails.preConditions;
      let arrCond = []
      for(let i=0; i<preCond.length; i++) {
        arrCond.push(
          { "preExistCondition": preCond[i]}
        )
      }
      
      const data = {
        client: {
          address1: clientInfo.resultData.address.street,
          address2: (clientInfo.resultData.address.town !==null && clientInfo.resultData.address.town !=="")? clientInfo.resultData.address.town : " ",
          addressType: "H", //default
          bday: clientDetails.bday,
          branchCode: "SI", //default
          clientType: "I", //default
          contactNumber: formatNumber(clientDetails.contactNumber),
          contactType: "M", //default
          email: clientDetails.emailAddress,
          firstName: clientDetails.firstName,
          fullName: clientDetails.firstName+" "+clientDetails.middleName+" "+clientDetails.lastName,
          gender: clientDetails.gender,
          idNo: "",
          idType: "",
          lastName: clientDetails.lastName,
          middleName: clientDetails.middleName,
          nationality: clientInfo.resultData.nationality,
          postalCode: clientInfo.resultData.address.zipCode,
          province: "Manila",
        },
        declarations: arrCond,
        info: {
          accidentalInjury: (otherDetails.sufferedAccidental)?"Y":"N",
          acquisitionDate: otherDetails.dateAcquired,
          age: petDetails.petAge,
          ageGroup: petDetails.petAgeGroup,
          breed: petDetails.breed,
          color: otherDetails.petColor,
          dateInsertion: otherDetails.dateOfInsertion,
          dateOfBirth: petDetails.bday,
          desexed: (otherDetails.desexed)?"Y":"N",
          gender: petDetails.petGender,
          maternal: petDetails.dam,
          microchipNumber: otherDetails.microchipNo,
          microchipped: (otherDetails.microchipNo)?"Y":"N",
          nameOfPet: petDetails.petName,
          paternal: petDetails.sire,
          pcciNumber: otherDetails.pcciNo,
          petAcquired: otherDetails.petAcquired,
          regularlyVaccinated: (otherDetails.regularlyVaccinated)?"Y":"N",
          regularVetClinic: otherDetails.veterinaryClinic,
          species: petDetails.species,
          speciesType: petDetails.type,
        },
        premium: {
          basicPremium: parseFloat(quotationDetails.Value_RoundedBasicPremium),
          coverage1: quotationDetails.Value_CLL,
          coverage2: quotationDetails.Value_Coverage,
          coverage3: quotationDetails.Value_EB,
          lgt: parseFloat(quotationDetails.Value_RoundedLGT),
          vat: parseFloat(quotationDetails.Value_RoundedPremiumTax),
          dst: 0.00,
          totalPremium: parseFloat(quotationDetails.Value_PremiumAndTaxes),
        },
        prodcode: "PHI",
        merchantId: ""+ process.env.REACT_APP_PET_ID+"",
        insuranceAccountId:Cache.getItem("customerId")
      };

      console.log(data, )
      // return false;
  
      if (unid !== 0 && unid !== null && typeof unid !== "undefined") {
        //put data
        data.client.clientNo = form_data.client.clientNo;
        data.unid = form_data.unid;
        data.quotationNumber = form_data.quotationNumber;
        data.pymtRefNumber = form_data.pymtRefNumber;
  
        data.merchantId = "0005";
        fetch(basePath.baseUrl + "/quotation/pet/" + Cache.getItem("unid"), {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: token_id,
          },
        })
          .then((response) => {
            let response_status = response.status;
            if (response_status == 200) {
              gcashAPI.postOrder(Cache.getItem("unid")).then((data1) => {
                setIsLoading(1);
                setTimeout(() => {
                  window.location.href = data1.checkoutUrl;
                  console.log("Checkout URL:", data1.checkoutUrl);
                }, 1000);
              });
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
          });
      } else {
        //post data
        petAPI
          .postPetQuotation(data)
          .then((response) => {
            Cache.setItem("unid", response.unid);
            Cache.setItem("pet_data", response);
            gcashAPI.postOrder(Cache.getItem("unid")).then((data1) => {
              setIsLoading(1);
              setTimeout(() => {
                window.location.href = data1.checkoutUrl;
                console.log("Checkout URL:", data1.checkoutUrl);
              }, 1000);
            });
          })
          .catch((err) => console.log("Model Error: ", err));
      }
    } 
    catch(err){
      EnableButton();
    }
  };

  return (
    <>
      <div>
        <Header headerTitle={props.headerTitle} />

        <div
          className="absolute w-full"
          // style={{
          //   backgroundImage: `url("/images/bg.png")`,
          //   backgroundRepeat: "no-repeat",
          //   width: "250px",
          // }}
        >
          <img
            alt="review"
            src={"/images/pet/review-banner.png"}
            className="image"
          />
        </div>
        <main className=" p-5">
          <form onSubmit={SubmitForm}>
            <div className="mt-44">
              <div className="text-left mb-15">
                {/* <div>
                <img alt="payment-banner" />
              </div> */}
                <div className="mt-5">
                  <span className="text-base font-bold">Coverage</span>
                  <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Coverage
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">Up to PHP 50,000.00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <span className="text-base font-bold">Annual Premium</span>
                  <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Basi Premium
                        </span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">PHP</span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">{quotationDetails.Value_RoundedBasicPremium}</span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          VAT
                        </span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">PHP</span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">{quotationDetails.Value_RoundedPremiumTax}</span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          DST
                        </span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">PHP</span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">0.00</span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          LGT
                        </span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">PHP</span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">{quotationDetails.Value_RoundedLGT}</span>
                      </div>
                    </div>
                    <hr className="my-3" />
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Total Amount
                        </span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">PHP</span>
                      </div>
                      <div className="flex-1 text-right">
                        <span className="text-sm">{quotationDetails.Value_PremiumAndTaxes}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <span className="text-base font-bold">Pet Insured</span>
                  <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Name
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">{petDetails.petName}</span>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Species
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">
                          {(petDetails.species === "C")?"Canine":"Feline"}
                        </span>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Breed
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">{petDetails.breed}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <span className="text-base font-bold">Client Info</span>
                  <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Name
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">{clientDetails.firstName+" "+clientDetails.middleName+" "+clientDetails.lastName}</span>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Contact Number
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm"> {clientDetails.contactNumber}</span>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Email Address
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">{clientDetails.emailAddress}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mt-10" />

                <span className="text-base font-bold mt-10 block">
                  Terms and Conditions
                </span>
                <div className="flex mt-5">
                  <div className="px-5">
                    <input type="checkbox" name="terms" required />
                  </div>
                  <div>
                    {/* <p className="text-sm">
                    I have read{" "}
                    <span className="text-blue-500 font-bold">
                      {" "}
                      terms and agreements{" "}
                    </span>{" "}
                    of Standard Insurance Co., Inc.
                  </p> */}
                    <p className="text-sm">
                      I have read{" "}
                      <span className="text-blue-600 font-bold">
                        <a style={{ cursor: "pointer" }} onClick={gotoTerms}>
                          {" "}
                          terms and agreements
                        </a>{" "}
                      </span>
                      of Standard Insurance Co., Inc.
                    </p>
                  </div>
                </div>

                <div className="relative flex mt-16">
                  <div className="flex-1 flex">
                    <div className="pr-5">
                      <div className="h-10 w-10">
                        <img
                          src={"/images/help.png"}
                          alt="help"
                          className="covered-image"
                        />
                        {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg> */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" 
                        fill="currentColor" 
                        className="bi bi-question-circle h-10 w-10 text-blue-500" 
                        viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg> */}
                      </div>
                    </div>
                    <div className="mb-24">
                      <h4 className="font-black text-left text-sm">
                        Need help?
                      </h4>
                      <p className="text-left text-xs text-gray-400">
                        Should you need any assistance or have any inquiries,
                        please contact{" "}
                        <span className="text-blue-600 font-bold">
                          ginsure@standard-insurance.com
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" style={{ display: "none" }} id="btnSubmit">
                submit
            </button>
          </form>
        </main>
        {/* {loading && <Loading />} */}
        <Footer
          buttonType="button"
          buttonTitle={"CONTINUE"}
          saveData={SubmitButton}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
};

export default PetReview;
