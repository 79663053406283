import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Cache } from "aws-amplify";
import Header from "../../organisms/Header";
import WizardCTPL from "../../organisms/WizardCTPL";
import Modal from "../../molecules/Modal";
import { subtractYears } from "../../utils/helper";
import { inputDateFormat } from "../../utils/helper";

const PetOtherDetails = (props) => {
  
  const [petColor, setPetColor] = useState(props.otherDetailsState.petColor);
  const [desexed, setDesexed] = useState(props.otherDetailsState.desexed)
  const [condCount, setCondCount] = useState(props.otherDetailsState.condCount);
  
  const [microchipped, setMicrochipped] = useState(props.otherDetailsState.microchipped);
  const [dateOfInsertion, setDateOfInsertion] = useState(props.otherDetailsState.dateOfInsertion);
  const [microchipNo, setMicrochipNo] = useState(props.otherDetailsState.microchipNo);
  const [pcciNo, setPcciNo] = useState(props.otherDetailsState.pcciNo);
  const [veterinaryClinic, setVeterinaryClinic] = useState(props.otherDetailsState.veterinaryClinic);
  const [petAcquired, setPetAcqquired] = useState(props.otherDetailsState.petAcquired);
  const [petAcquiredDesc, setPetAcquiredDesc] = useState(props.otherDetailsState.petAcquiredDesc)
  const [dateAcquired, setDateAcquired] = useState(props.otherDetailsState.dateAcquired);
  const [regularyVaccinated, setRegularlyVaccinated] = useState(props.otherDetailsState.regularlyVaccinated);
  
  const [injuryIllness, setInjuryIllness] = useState(props.otherDetailsState.sufferedAccidental);
  const [injuryIllnessYes, setInjuryIllnessYes] = useState(props.otherDetailsState.injuryIllnessYes);
  const [injuryIllnessNo, setInjuryIllnessNo] = useState(props.otherDetailsState.injuryIllnessNo);


  const [desexedYes, setDesexedYes] = useState(props.otherDetailsState.desexedYes);
  const [desexedNo, setDesexedNo] = useState(props.otherDetailsState.desexedNo);
  const [microchippedYes, setMicrochippedYes] = useState(props.otherDetailsState.microchippedYes);
  const [microchippedNo, setMicrochippedNo] = useState(props.otherDetailsState.microchippedNo);
  const [regularlyVaccinatedYes, setRegularlyVaccinatedYes] = useState(props.otherDetailsState.regularlyVaccinatedYes);
  const [regularlyVaccinatedNo, setRegularlyVaccinatedNo] = useState(props.otherDetailsState.regularlyVaccinatedNo);

  const [preConditions, setPreConditions] = useState(props.otherDetailsState.preConditions);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [minDateOfInsertion, setMinDateOfInsertion] = useState("")
  const [minDateAcq, setMinDateAcq] = useState("")

  useEffect(() => {
    Cache.setItem("flag", 0);

    let minimYears = subtractYears(25, new Date()) //
    minimYears = inputDateFormat(minimYears) //
    setMinDateOfInsertion(minimYears)
    setMinDateAcq(minimYears)

  },[])

  let history = useHistory();

  const SaveForm = (e) => {
    e.preventDefault();
    setOpen(true);
    
    // console.log(otherDetailsData)
    // props.saveData(otherDetailsData);
    
  };

  //functions
  const addCondition = (e) => {
    e.preventDefault();
    setCondCount(count => count + 1)
    // let dest = document.getElementsByName("destinations")
    let dest = document.getElementsByClassName("sel-dest")
    let dlength = dest.length

    setTimeout(()=>{
      // hide option for the next index of destination
      for(let i=0; i < dest.length-1; i++){
        if(dest[dlength-1].selectedIndex !== 0 &&  dest[dlength] !== undefined) {
          dest[dlength].options[dest[dlength-1]?.selectedIndex].style.display = "none";  
        }
      }
    }, 300)
    // hideOptionOnCreateDestination();
  }

  const handleDeleteDest = (e) => {
    e.preventDefault();
    let btn = e.target.id;
    console.log(e.target)
    const arr = btn.split("-");
    const elem = document.getElementById("cond"+arr[2]);
    console.log("ELEM-",elem)
    
    
    if(elem !== null){
      elem.remove();
      // let counter = condCount - 1
      // setCondCount(condCount - 1)
      addItems()
    }

    // setDestCount(destCount => destCount - 1)
    //elem.remove();
    
  }

  //delete items in conditions
  const deleteItems = (counter) => {
    let conditions = document.querySelectorAll("[id*='conditions'] input")
      let arr = []
      for(let y=0; y<counter; y++) {
        arr.push(conditions[y].value)
      }
      setPreConditions(arr)

  }
  const addItems = () => {
    let conditions = document.querySelectorAll("[id*='conditions'] input")
      let arr = []
      for(let y=0; y<conditions.length; y++) {
        arr.push(conditions[y].value)
      }
      setPreConditions(arr)

  }
  const handleChange = (e) => {
    let evt = e.target;

    if(evt.name === "sufferedAccidental") {
      console.log(evt.value);
      if(evt.value === "yes") {
        setInjuryIllness(true);
        setInjuryIllnessYes(true);
        setInjuryIllnessNo(false);
      }else {
        setInjuryIllness(false);
        setInjuryIllnessNo(true);
        setInjuryIllnessYes(false);
        setCondCount(1)
        setPreConditions([])
      }
    } else if(evt.name === "microchipped") {
      if(evt.value === "yes") {
        setMicrochipped(true);
        setMicrochippedYes(true);
        setMicrochippedNo(false)
      }else {
        setMicrochipped(false);
        setMicrochippedNo(true)
        setMicrochippedYes(false);
        document.getElementsByName("dateOfInsertion")[0].value = ""
        document.getElementsByName("microchipNumber")[0].value = ""
      }
    } else if(evt.name === "color") {
      setPetColor(evt.value);
    } else if(evt.name === "desexed") {
        if(evt.value === "yes") {
          setDesexed(true)
          setDesexedYes(true);
          setDesexedNo(false);
        }else {
          setDesexed(false)
          setDesexedNo(true);
          setDesexedYes(false);
        }
    } else if(evt.name === "dateOfInsertion") {
      // e.target.setCustomValidity('');
      setDateOfInsertion(evt.value);
    } else if(evt.name === "microchipNumber") {
      setMicrochipNo(evt.value);
    } else if(evt.name === "pcciNumber") {
      setPcciNo(evt.value);
    } else if(evt.name === "veterinaryClinic") {
      setVeterinaryClinic(evt.value);
    } else if(evt.name === "petAcquired") {
      setPetAcqquired(evt.value);
      if(evt.value === "A"){
        setPetAcquiredDesc("Breeders")
      }else if(evt.value === "B"){
        setPetAcquiredDesc("Online Seller")
      }else if(evt.value === "C"){
        setPetAcquiredDesc("Adoption or Rescued")
      }else if(evt.value === "D"){
        setPetAcquiredDesc("A Person You Know")
      }else {
        setPetAcquiredDesc("Own Pet's at Home")
      }

      console.log(evt.value, e)
    } else if(evt.name === "dateAcquired") {
      // e.target.setCustomValidity('');
      setDateAcquired(evt.value);
    } else if(evt.name === "regularlyVaccinated") {
        if(evt.value === "yes") {
          setRegularlyVaccinated(true);
          setRegularlyVaccinatedYes(true);
          setRegularlyVaccinatedNo(false);
        } else {
          setRegularlyVaccinated(false);
          setRegularlyVaccinatedNo(true);
          setRegularlyVaccinatedYes(false);
        }
    } else if(evt.name === "preConditions") {
      addItems();
      // let conditions = document.querySelectorAll("[id*='conditions'] input")
      // let arr = []
      // for(let y=0; y<conditions.length; y++) {
      //   arr.push(conditions[y].value)
      // }
      // setPreConditions(arr)
    }

  }

  const gotoFormPage=()=>{
    const otherDetailsData = {
      petColor: petColor,
      desexed: desexed,
      microchipped: microchipped,
      dateOfInsertion: dateOfInsertion,
      microchipNo: microchipNo,
      pcciNo: pcciNo,
      veterinaryClinic: veterinaryClinic,
      petAcquired: petAcquired,
      dateAcquired: dateAcquired,
      regularlyVaccinated: regularyVaccinated,
      sufferedAccidental: injuryIllness,
      preConditions:preConditions,
      desexedYes: desexedYes,
      desexedNo: desexedNo,
      microchippedYes: microchippedYes,
      microchippedNo: microchippedNo,
      regularlyVaccinatedYes: regularlyVaccinatedYes,
      regularlyVaccinatedNo: regularlyVaccinatedNo,
      injuryIllnessYes: injuryIllnessYes,
      injuryIllnessNo: injuryIllnessNo,
      condCount: document.querySelectorAll("[id*='conditions'] input").length,
      petAcquiredDesc: petAcquiredDesc
    }
    props.saveData(otherDetailsData);
  }
  const handleDataConfirmation = (event) => {
    const checked = event.target.checked;
    setIsLoading(1);
    if (checked === true) {
      Cache.setItem("flag", 1);
    } else {
      Cache.setItem("flag", 0);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const DisableButton = (event) => {
    Cache.setItem("flag", 0);
  };
  return (
    <>
      <form onSubmit={SaveForm}>
        <main className="mb-24">
          <h3 className="text-lg font-bold title-font">
            A few more question about your pet...
          </h3>

          <div className="my-5">
            <div>
              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Color <span className="text-red-700">*</span>
                </label>

                <div className="block relative w-full">
                  <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="text"
                    name="color"
                    placeholder="Enter your pet's color"
                    defaultValue={petColor}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Desexed? <span className="text-red-700">*</span>
                </label>
                <div className="grid grid-cols-2 mt-2">
                  <div>
                    <input type="radio" 
                      name="desexed" 
                      className="mr-3"
                      value="yes" 
                      defaultChecked={desexedYes}
                      onChange={handleChange}
                      required
                    />
                    <label>Yes</label>
                  </div>
                  <div>
                    <input type="radio" 
                      name="desexed" 
                      className="mr-3" 
                      value="no"
                      defaultChecked={desexedNo}
                      onChange={handleChange}
                      required
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Mircrochipped? <span className="text-red-700">*</span>
                </label>
                <div className="grid grid-cols-2 mt-2">
                  <div>
                    <input type="radio" 
                      name="microchipped" 
                      value="yes" 
                      className="mr-3"
                      defaultChecked={microchippedYes}
                      onChange={handleChange}
                      required
                    />
                    <label>Yes</label>
                  </div>
                  <div>
                    <input type="radio" 
                      name="microchipped" 
                      value="no" 
                      className="mr-3"
                      defaultChecked={microchippedNo} 
                      onChange={handleChange}
                      required
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Date of Insertion
                </label>

                <div className="block relative w-full">
                  <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="date"
                    name="dateOfInsertion"
                    placeholder="MM-DD-YYYY"
                    defaultValue={dateOfInsertion}
                    onChange={handleChange}
                    max={new Date().toISOString().split("T")[0]}
                    min={minDateOfInsertion}
                    disabled={!microchipped}
                    // onInvalid={e => e.target.setCustomValidity('Invalid Date!')}
                    required={microchipped}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Microchip Number
                </label>

                <div className="block relative w-full">
                  <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="text"
                    name="microchipNumber"
                    placeholder="Enter Microchip Number"
                    defaultValue={microchipNo}
                    onChange={handleChange}
                    disabled={!microchipped}
                    required={microchipped}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  PCCI Number
                </label>

                <div className="block relative w-full">
                  <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="text"
                    name="pcciNumber"
                    placeholder="Enter your pet's PCCI Number"
                    defaultValue={pcciNo}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Regular Veterinary Clinic
                </label>

                <div className="block relative w-full">
                  <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="text"
                    name="veterinaryClinic"
                    placeholder="Enter your regular veterinary clinic"
                    defaultValue={veterinaryClinic}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  How was your pet acquired? <span className="text-red-700">*</span>
                </label>

                <div className="block relative w-full">
                  <select
                    name="petAcquired"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    defaultValue={petAcquired}
                    onChange={handleChange}
                    required
                  >
                    <option value="">How was your pet acquired?</option>
                    <option value="A" desc="Breeders">Breeders</option>
                    <option value="B">Online Seller</option>
                    <option value="C">Adoption or Rescued</option>
                    <option value="D">A Person You Know</option>
                    <option value="E">Own Pet's at Home</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Date Acquired <span className="text-red-700">*</span>
                </label>

                <div className="block relative w-full">
                  <input
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    type="date"
                    name="dateAcquired"
                    placeholder="MM-DD-YYYY"
                    defaultValue={dateAcquired}
                    max={new Date().toISOString().split("T")[0]}
                    min={minDateAcq}
                    onChange={handleChange}
                    // onInvalid={e => e.target.setCustomValidity('Invalid Date!')}
                    required
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Is your pet regularly vaccinated? <span className="text-red-700">*</span>
                </label>
                <div className="grid grid-cols-2 mt-2">
                  <div>
                    <input
                      type="radio"
                      name="regularlyVaccinated"
                      className="mr-3"
                      value="yes"
                      defaultChecked={regularlyVaccinatedYes}
                      onChange={handleChange}
                      required
                    />
                    <label>Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="regularlyVaccinated"
                      className="mr-3"
                      value="no"
                      defaultChecked={regularlyVaccinatedNo}
                      onChange={handleChange}
                      required
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Has your pet suffered from any Accidental Injury or Illness
                  that you are aware of prior to you application today?
                </label>
                <div className="grid grid-cols-2 mt-2">
                  <div>
                    <input
                      type="radio"
                      name="sufferedAccidental"
                      className="mr-3"
                      value="yes"
                      defaultChecked={injuryIllnessYes}
                      onChange={handleChange}
                      required
                    />
                    <label>Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="sufferedAccidental"
                      className="mr-3"
                      value="no"
                      defaultChecked={injuryIllnessNo}
                      onChange={handleChange}
                      required
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              {/*  */}
              { injuryIllness === true && (
              <div className="mb-3">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Please enter your pet's pre-existing conditions
                </label>

                {/* <div>
                  <div className="inline-block relative w-11/12 align-middle">
                    <input
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      type="text"
                      name="preConditions"
                      value="Ear Infections"
                    />
                  </div>
                  <div className="inline-block align-middle">
                    <button className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="h-7 w-7 text-gray-500"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div> */}
                <div id="conditions">
                  {/*  */}
                  {/* <div className="inline-block relative w-10/12 align-middle">
                    <input
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      type="text"
                      name="preConditions"
                      value="Diarrhea"
                    />
                  </div> */}
                  {/*  */}
                  {(() => {
                    let destArr = [];
                    let dCounts = condCount
                    for (let i=0; i<dCounts; i++){
                      destArr.push(
                        <div id={"cond"+i} className="inline-block relative w-11/12 align-middle">
                          <div className="inline-block relative w-11/12 align-middle">
                            <input
                              className="input-border-none focus:outline-none focus:shadow-outline"
                              type="text"
                              name="preConditions"
                              disabled={!injuryIllness}
                              defaultValue={preConditions[i]}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          { i !== 0 && (
                          <div className="inline-block align-middle">
                            <button id={"btn-del-"+i} className="mt-2" onClick={((e,i)=>{
                            e.preventDefault();
                            let index = destArr.map(function(a) {console.log(a.props.id); return a.props.id}).indexOf('cond'+i)
                            handleDeleteDest(e)
                            destArr.splice(index, 1)
                            
                          })}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-7 w-7 text-gray-500"
                                style={{pointerEvents: "none"}}
                                disabled
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          )}
                          
                        </div>
                      )
                    }
                    return destArr;
                    })()
                  }
                  {/*  */}
                  <div className="inline-block align-middle">
                    {/* <button className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="h-7 w-7 text-gray-500"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button> */}

                    <button className="mt-2" onClick={addCondition} disabled={!injuryIllness}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7 text-yellow-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              )}
              {/*  */}
            </div>
          </div>
        </main>
        <button type="submit" style={{ display: "none" }} id="btnSubmit">
          submit
        </button>
      </form>
      <Modal title="Disclaimer"
      open={open}
      setOpen={setOpen}
      buttonTitle="SUBMIT"
      saveData={gotoFormPage}
      isDisabled={Cache.getItem("flag")}
      disableButton={DisableButton}
      >
        <form>
          <div className="text-xs">
            <p className="mb-3">
              Your Pet Health Policy comes with a "Waiting Period for Illness
              and Cruciate Ligament Conditions."
            </p>

            <p>
              During this period, conditions related to illness and cruciate
              ligament will not be covered.
            </p>

            <ul className="my-5 list-disc ml-5">
              <li>Illness Cover 30-day Waiting Period</li>
              <li>Cruciate Ligament 6-month Waiting Period</li>
              <li>Accidents No Waiting Period</li>

              <p className="mt-2 font-bold">
                Accidents that happened before or at the time of application
                will not be covered.
              </p>
            </ul>

            <p className="mb-5">
              You may apply for the "Cruciate Ligament Waiver" subject to
              application that you will receive upon completion of enrollment.
            </p>

            <div className="flex my-1">
              <div className="pr-3">
                <input type="checkbox" className="w-4 h-4"  onChange={handleDataConfirmation}/>
              </div>
              <p>
                I agree that conditions showing clinical signs or symtoms before
                the policy's commencement date and within the applicable
                "Waiting Periods" are not covered by the Pet Health Policy
              </p>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PetOtherDetails;
