const ProgressBar = (props) => {
  const labels = props.stepsLabel;
  const width = labels  !== undefined ? labels.length : 0;
  let activetitle="";
  if (width == 0) return <></>;
  else
    return (
      <>
        <div className="w-full mb-3">
          <div className="">
            {props.stepsLabel.map((label, index) => {
              let classNames = `p-1
          text-xs
          font-medium
          leading-none
          text-center 
          text-blue-100
          bg-blue-100 
          rounded-full
          inline-block
          mr-2
          `+props.product+`-progressBarWidth`;

              if (props.activeStep >= index + 1 ) {
                classNames += " bg-blue-600";
                activetitle = label;
              }
              if (props.activeStep >= index + 1) {
                return <div className={classNames}></div>;
              } else {
                return <div className={classNames}></div>;
              }
            })}
          </div>

          {/* <div className="w-full">
            {props.stepsLabel.map((label, index) => {
              return (
                <div
                  className={`w-1/` + width + ` inline-block progressBarTitle`}
                >
                  {label}
                </div>
              );
            })}
          </div> */}
          <h3 className="text-xl font-bold mb-5 mt-3">{activetitle}</h3>
        </div>
      </>
    );
};

export default ProgressBar;
