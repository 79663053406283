import ContactUs from "../components/organisms/Contact-Us";
import Header from "../components/organisms/Header";
import { Disclosure } from "@headlessui/react";

const PageError = (props) => {
  const close = () => {
    console.log("close");
  };
  return (
    <div>
      <Header headerTitle={props.headerTitle} />

      <main>
        <div className="page-not-found">
          <h1 className="text-9xl text-gray-600">500</h1>
          <p className="text-xl font-bold text-center text-blue-600 mb-5 text-xs">
            Sorry, something went wrong. Please try again later or contact us
            at:
          </p>
          <p className="font-bold text-gray-600 text-xs"> 8am to 5pm: (917) 828-3984</p>
          <p className="font-bold text-gray-600 text-xs">
            5pm to 8am: (632) 88845-1111
          </p>
          <p className="font-bold text-gray-600 text-xs">
            Email Address: ginsure@standard-insurance.com
          </p>
        </div>
      </main>
    </div>
  );
};
export default PageError;
