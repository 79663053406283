import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import Modal from "../components/molecules/Modal";
import { Cache } from "aws-amplify";
import CTPLCoverSection from "../components/organisms/CTPLCoverSection";
import { gcashAPI, authAPI, authDetails } from "../api/fetch-api";
import IndexPage from "../components/organisms/Motor-Insurance";
import IndexPageTravel from "../pages/travel/index";
import TravelCoverSection from "../components/organisms/TravelCoverSection";

function InitialPage(props) {
  let history = useHistory();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  // const SubmitForm = () => {
  //   setOpen(true);
  // };

  const IdChecker = (id)=>{
    id = id.replaceAll(" ", "+");
    const whitelists = ["/mdIRBQt6g3IRRebxlkHqhrqG09gSyMAEjAtG7notTQ=","/mdIRBQt6g3IRRebxlkHqlmv8me/NGq7TUE3A/Asa+o=","/mdIRBQt6g3IRRebxlkHqnaoJ2KprtxgvkMgdXq0cvM=","/mdIRBQt6g3IRRebxlkHqqJwWEb1Nsb4Jp4+DpipNys=","/mdIRBQt6g3IRRebxlkHquF5Po7Q/RJh9i4K2XOEQgQ=", "/mdIRBQt6g3IRRebxlkHqnJqetJP1JNa13HYccE8rS0="];
    for (let index = 0; index < whitelists.length; index++) {
      const element = whitelists[index];
      if(id===element){
        return true;
      }
    }
    
    return false;
  };
  let coverType = "";
  (window.location.href.indexOf("international") > -1)?coverType="international":coverType="asean"
  useEffect(() => {
    setCover(coverType);
  }, [coverType]);

  const [cover, setCover] = useState("");
  const EnableButton = () => {
    setIsDisabled(0);
  };
  const DisableButton = () => {
    setIsDisabled(1);
  };

  const SubmitForm = () => {
      console.log('saa');
      Cache.setItem("fromCoverPage", true)
      setOpen(true);
  }

  useEffect(() => {
    //clear cache first
    Cache.clear();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let customerId = params.get("customerId");
    // console.log(customerId);
    if (customerId === null){
    // if (customerId === null || IdChecker(customerId)===false) {
      history.push("/coming-soon");
      //history.push("/ctpl/packages");
    } else {
      customerId = customerId.replaceAll(" ", "%2B");

      //
      authAPI
      .getAuth(authDetails)
      .then((data1) => {
        // console.log("auth api", data1)
        Cache.setItem("ginsure_token", data1.token);
        gcashAPI
            .getClient(customerId)
            .then((data) => {
              setIsLoading(0);
              // console.log("Customer information :", data);
              Cache.setItem("clientInfo", JSON.stringify(data));
              Cache.setItem("customerId", customerId);
              setIsLoading(false);
              if (
                data.resultInfo.resultCode === "SYSTEM_ERROR" ||
                data.resultInfo.resultCode === "FAILED"
              ) {
                history.push("/500");
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
      })

      //redirect to cover page
      // let coverType = "";
      // (window.location.href.indexOf("international") > -1)?coverType="international":coverType="asean"
      // history.push(`/travel/${coverType}/cover`)
    }
    Cache.setItem("customerId", customerId);
    Cache.setItem("cptl_year", null);
  }, []);

  const product = (val) => {
    console.log("product", val);

    if (val == "ctpl") {
      setOpen(true);
    }else{
      history.push("/motor-insurance/?customerId="+Cache.getItem("customerId"));
    }
  };
  
  return (
    // <div>
    //   <Header headerTitle={props.headerTitle} />
    //   <div
    //     className="absolute w-full"
    //     // style={{
    //     //   backgroundImage: `url("/images/bg.png")`,
    //     //   backgroundRepeat: "no-repeat",
    //     //   width: "250px",
    //     // }}
    //   >
    //     <img alt="motorcar" src={"/images/bg.png"} className="image" />
    //   </div>
    //   <div className={"main p-5 pt-12"}>
    //     <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
    //   </div>
    //   <main className={"main p-5"}>
    //   <div>
    //       <h3>Choose vehicle registration type</h3>
    //       <div className="bg-gray-100 rounded-lg text-center p-5 mt-5 shadow border border-gray-100"  onClick={() => ctpl("3")}>
    //         <h4 className="text-xl font-bold">Brand New</h4>
    //         <span className="text-sm">3 year coverage</span>
    //       </div>

    //       <div className="bg-gray-100 rounded-lg text-center p-5 mt-5 shadow"  onClick={() => ctpl("1")}>
    //         <h4 className="text-xl font-bold">Renewal</h4>
    //         <span className="text-sm">1 year coverage</span>
    //       </div>
    //     </div>
    //   </main>

     
    // </div>
    <div>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className={"main p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div>
      <main className={"main p-5"}>
        <div className="mb-16">
          {/* <IndexPageTravel
            // enableButton={EnableButton}
            // disableButton={DisableButton}
            open={open}
            setOpen={setOpen}
          /> */}
          {!isLoading && (
            <TravelCoverSection
              enableButton={EnableButton}
              disableButton={DisableButton}
              open={open}
              setOpen={setOpen}
              cover={cover}
            />
          )}
          
        </div>
      </main>
      <Footer
        isMain={"1"}
        buttonType="button"
        saveData={SubmitForm}
        buttonTitle={"Get Started"}
      />
      {/* <Footer
        isMain={"1"}
        buttonType="button"
        buttonTitle={"Get Started"}
        saveData={SubmitForm}
      /> */}
    </div>
  );
}
export default InitialPage;
