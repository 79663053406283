import React, { useState } from "react";

const FaqProductAsean = () => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What is Travel Protect Saver Plus?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <p className="text-sm">
            Travel Protect Saver Plus is a reimbursement type of travel insurance that provides coverage within the Philippines.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Who can avail of Travel Protect Saver Plus?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
            Travel Protect Saver Plus covers individuals who are between 1 to 65 years old traveling on vacation, study or seminars/conferences and trips within the Philippines. Individuals who are 66 to 75 years old may be covered subject to a premium surcharge.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel3" className="hidden" />
        <label
          htmlFor="panel3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What are the benefits of Travel Protect Saver Plus?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm mt-3">
              <strong>Travel Inconvenience Benefit</strong>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Trip Cancellation</li>
                <li>Trip Curtailment</li>
                <li>Delayed Departure</li>
                <li>Flight Diversion</li>
                <li>Baggage Delay</li>
                <li>Inconvenience Cash Assistance due to Baggage Delay</li>
                <li>Loss of Baggage / Damage to Luggage</li>
                <li>Loss of Travel Documents</li>
                <li>Hijack</li>
              </ul>

              <p className="mt-3">
                <strong>Emergency Medical Assistance</strong>
              </p>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Medical Reimbursement</li>
                <li>Daily Hospital Cash</li>
                <li>Emergency Medical Evacuation and Repatriation</li>
              </ul>

              <p className="mt-3">
                <strong>Personal Accident Benefit</strong>
              </p>
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Accidental Death and Disablement</li>
                <li>Unprovoked Murder and Assault</li>
                <li>Burial Assistance</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel4" className="hidden" />
        <label
          htmlFor="panel4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            How can I avail of Travel Protect Saver Plus Plan?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              To avail of Travel Protect Saver Plus, simply do the following:
            </p>
            <ul className="text-sm mt-3">
              <li>
                <strong>STEP 1. </strong>Login to your GCash app, then click the
                GInsure icon
              </li>
              <li>
                <strong>STEP 2. </strong>Choose Standard Insurance product for
                "Travel"
              </li>
              <li>
                <strong>STEP 3. </strong>Provide the necessary customer and
                travel details
              </li>
              <li>
                <strong>STEP 4. </strong>Review your policy details and confirm
              </li>
              <li>
                <strong>STEP 5. </strong>Click "Confirm and Pay"
              </li>
              <li>
                <strong>STEP 6. </strong> Expect a copy of your insurance policy
                and official receipt via email.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel5" className="hidden" />
        <label
          htmlFor="panel5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus cover travels intended for
            employment?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              Travel Protect Saver Plus is intended for persons traveling on
              vacation, study, or seminars/conferences. It excludes cover for
              skilled persons/ skilled laborers when traveling for employment or
              in the course of employment.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel6" className="hidden" />
        <label
          htmlFor="panel6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            When does Travel Protect Saver Plus coverage becomes effective and
            when does it end?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <p className="text-sm">
              Coverage shall commence 12 o’clock in the morning of the day of
              scheduled trip and shall end on whichever of the following occurs
              first:
              <ul className="text-sm mt-3 ml-3">
                <li>a. the expiry date as specified in the Policy;</li>
                <li>
                  b. for annual plans, the Insured’s return date to his/her
                  normal place of residence;
                </li>
                <li>
                  c. for one-way itineraries, upon Insured’s arrival at place of
                  destination.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel7" className="hidden" />
        <label
          htmlFor="panel7"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I buy Travel Protect Saver Plus when I am about to leave already
            or I’m at the airport already?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel7    ">
            <p className="text-sm">
              Yes, for as long as your trip has yet to start and there is no
              known loss.
            </p>
            <p className="text-sm mt-3">
              Note: Benefits for Trip Cancellation and Delayed Departure shall
              not apply for policies requested/issued less than forty-eight (48)
              hours from the scheduled trip.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel8" className="hidden" />
        <label
          htmlFor="panel8"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus  include any coverage for medical
            expenses related to COVID-19?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel8">
            <p className="text-sm">
              Covid-19 related claims is NOT included in this guarantee.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel9" className="hidden" />
        <label
          htmlFor="panel9"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus provide any Trip Curtailment
            Coverage?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel9">
            <p className="text-sm font-bold">A. Trip Cancellation</p>

            <p className="mt-3 text-sm">
              For trip cancellation due to covered reason specified in the
              policy, Travel Protect Saver Plus will pay the Insured up to the
              limit specified in the policy. The forfeited and non-refundable
              portion of all travel costs invoiced to and paid by the insured in
              advance including travel agent’s cancellation fee should the trip
              be cancelled within 30 days before the date of commencement of the
              trip due to:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>
                  Death, serious injury or serious illness of the Insured,
                  his/her spouse, children, parents, parents-in-law, sisters,
                  brothers or grandparents;
                </li>
                <li>
                  Occurrence or outbreak of strike, riot, civil commotion or
                  similar occurrence.
                </li>
              </ul>
            </p>
            <p className="mt-3 text-sm font-bold">B. Trip Curtailment</p>
            <p className="mt-3 text-sm">
              The Insurer will pay the Insured up to the limit specified in the
              policy the pro- rata amount, if the journey is cut short and the
              Insured has to return to the place of residence as a result of:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>
                  The insured having sustained serious injury or illness or
                  death;
                </li>
                <li>
                  Serious injury or serious illness if the Insured’s spouse,
                  children, parents, sisters, brothers, parents -in-law or
                  grandparents.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel10" className="hidden" />
        <label
          htmlFor="panel10"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What are the specific exclusion/s to Trip Cancellation?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel10">
            <p className="text-sm">
              Travel Protect Saver Plus will not pay for any loss recoverable
              from any other sources including government programs, insurance
              schemes, airlines, hotels and travel agencies.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel11" className="hidden" />
        <label
          htmlFor="panel11"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Will Travel Protect Saver Plus respond if my flight was delayed or
            diverted?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel11">
            <p className="text-sm font-bold">Delayed Departure</p>
            <p className="mt-3 text-sm">
              When the departure of the means of public transport contracted by
              the Insured is delayed for more than six (6) consecutive hours
              from the departure time due to events that are uncontrollable by
              the Insured, Travel Protect Saver Plus shall reimburse all valid
              and reasonably incurred expenses as a result of the delay such as:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Hotel accommodation (for a minimum of 12 hours delay)</li>
                <li>Restaurant meals (for a minimum of 6 hours of delay)</li>
              </ul>
            </p>
            <p className="mt-3 text-sm">
              Reimbursement shall be up to the maximum limit as specified in the
              policy subject to submission of Official Receipt/s.
            </p>
            <p className="mt-3 text-sm">
              Delays uncontrollable by the Insured include adverse weather
              conditions, technical problems, mechanical breakdown, and the
              like.
            </p>
            <p className="mt-3 text-sm">
              Public transport shall mean vehicles such as buses, trains,
              subways, and other forms of transportation that charge set fares,
              run on fixed routes, and are available to the public.
            </p>

            <p className="mt-3 text-sm font-bold">Flight Diversion</p>
            <p className="mt-3 text-sm">
              If the carrier contracted were diverted because of a technical
              failure, meteorological problems or Acts of Nature, the Insurer
              will compensate the Insured up to a limit of the plan, subject to
              presentation of the relevant original copies of the invoices, to
              defray the prime necessity expenses (i.e., necessary clothing and
              toiletries or those that are indispensable while the Policyholder
              is waiting to leave).
            </p>
            <p className="mt-3 text-sm">
              The Insurer will also reimburse the Insured’s reasonable
              additional expenses (up to the limit of the plan) incurred for the
              use of alternative scheduled public transport services to enable
              the Policyholder to arrive at the planned destination on time in
              the event that the Policyholder’s scheduled flights are rerouted
              due to the above-named unforeseen circumstances outside the
              Insured’s control.
            </p>

            <p className="mt-3 text-sm">
              Flight diversion as a result of a strike called by employees of or
              services companies subcontracted by the Airline and/or of the
              flight departure, port of call or destination airports, is
              excluded from this guarantee.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel12" className="hidden" />
        <label
          htmlFor="panel12"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus plan covers luggage that are damaged
            by the airline?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel12">
            <p className="text-sm font-bold">
              Loss of and/or Damage to Baggage
            </p>
            <p className="mt-3 text-sm">
              Travel Protect Saver Plus will reimburse the insured up to the
              limit stated in the policy against loss of or damage to
              accompanied personal luggage, clothing or personal effects of the
              Insured Person or for which the Insured Person is responsible,
              occurring during the Period of Insurance.
            </p>
            <p className="mt-3 text-sm">
              The Insurer will pay subject to the limits as specified subject to
              the following conditions:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>
                  No one article or pair/set or articles shall exceed Php 2,500
                  up to the limit of the policy schedule
                </li>
                <li>
                  In respect of articles in pair or set, the Insurer shall only
                  be liable for the value of that part which have been lost or
                  damaged.
                </li>
                <li>
                  The Insurer may make payment in cash or may reinstate or
                  repair the articles loss or damaged, subject to reasonable
                  allowance for depreciation.
                </li>
                <li>
                  The Insured must take reasonable steps to safeguard their
                  baggage from loss or damage.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel13" className="hidden" />
        <label
          htmlFor="panel13"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What is not covered for Loss of and/or Damage to Baggage?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel13">
            <ul className="mt-3 text-sm ml-3 list-disc">
              <li>
                Loss or damage to crockery, China, glass, antiques, artifacts,
                paintings, works of art, musical instruments, skis, contact or
                corneal lenses, manuscripts or documents of any kind and
                description, jewelries, medals, coins, securities, computers
                including software and accessories, business goods or samples,
                data recorded or tapes or discs
              </li>
              <li>
                Loss or damage to wear and tear, gradual deterioration, insect,
                vermin, inherent vice, mechanical or electrical breakdown or
                derangement, any process of cleaning restoration, or renovating.
              </li>
              <li>
                Loss or damage due to confiscation, nationalization, requisition
                or destruction by any government, public, municipal or local
                authority
              </li>
              <li>
                Loss to baggage sent in advance, mailed or shipped separately,
                left unattended in any public place or as a result of the
                Insured failing to take necessary precautions to safeguard his
                property.
              </li>
              <li>
                Loss of cash, currency notes, stamps, bonds, negotiable
                instruments, deeds, securities of any kind, cheques, postal or
                money orders and travel documents.
              </li>
            </ul>
            <p>
              In the event that the baggage is recovered, the Insured, if
              already paid, is under obligation to return the compensation
              received for the loss in accordance with the Policy.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel14" className="hidden" />
        <label
          htmlFor="panel14"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I get an Travel Protect Saver Plus as a group or with my family
            members?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel14">
            <p className="text-sm">
              Yes, we may issue for a group of 2 or more individuals traveling
              with the same itinerary/destination, same travel dates, and same
              conveyance.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel15" className="hidden" />
        <label
          htmlFor="panel15"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Can I get a Travel Protect Saver Plus plan Policy for someone else?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel15">
            <p className="text-sm">
              Yes, you may avail of the travel insurance for your family,
              friends, relatives, and colleagues for as long as you have their
              consent and you know their personal information and travel
              details.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel16" className="hidden" />
        <label
          htmlFor="panel16"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus cover sickness that are pre-existing
            ?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel16">
            <p className="text-sm">
              Pre-existing illness or injuries are EXCLUDED from this guarantee.
            </p>
            <p className="mt-3 text-sm">
              Pre-existing conditions are illnesses or injuries for which the
              person has been under the care of a physician and has received
              medical care or advice or a condition for which a reasonable
              person should have sought treatment.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel17" className="hidden" />
        <label
          htmlFor="panel17"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus cover if my flight was delayed due to
            technical and mechanical problems with the aircraft?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel17">
            <p className="text-sm">
              When the departure of the means of public transport contracted by
              the Insured is delayed for more than six (6) consecutive hours
              from the departure time due technical and mechanical problems with
              the aircraft, Travel Protect Saver Plus shall reimburse all valid
              and reasonably incurred expenses as a result of the delay such as:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>Hotel accommodation (for a minimum of 12 hours delay)</li>
                <li>Restaurant meals (for a minimum of 6 hours of delay)</li>
              </ul>
            </p>
            <p className="mt-3 text-sm">
              Reimbursement shall be up to the maximum limit as specified in the
              policy subject to submission of Official Receipt/s.
            </p>
            <p className="mt-3 text-sm">
              Public transport shall mean vehicles such as buses, trains,
              subways, and other forms of transportation that charge set fares,
              run on fixed routes, and are available to the public.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel18" className="hidden" />
        <label
          htmlFor="panel18"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus Plan Cover if my flight was delayed
            due to air traffic or immigration concern.
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel18">
            <p className="text-sm">
              Delays due to air traffic or immigration concern are NOT covered
              under Travel Inconvenience Benefit.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel19" className="hidden" />
        <label
          htmlFor="panel19"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Are foods purchased during the flight delay covered by Travel
            Protect Saver Plus?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel19">
            <p className="text-sm">
              Yes, restaurant meals can be reimbursed for flight delays (for a
              minimum of 6 hours of delay). The Insured should keep the receipts
              and secure a Flight Delay Irregularity report from the airline as
              part of the documents needed in filing a claim.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel20" className="hidden" />
        <label
          htmlFor="panel20"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Does Travel Protect Saver Plus cover hand-carry baggage which
            contains my passport ticket, and other travel documents? Will the
            expenses to replace my travel documents be covered?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel20">
            <p className="text-sm font-bold">
              A. Loss of and/or Damage to Baggage
            </p>

            <p className="mt-3 text-sm">
              The Insurer will reimburse the insured up to the limit stated in
              the policy against loss of or damage to accompanied personal
              luggage, clothing or personal effects of the Insured Person or for
              which the Insured Person is responsible, occurring during the
              Period of Insurance.
            </p>

            <p className="mt-3 text-sm">
              The Insurer will pay subject to the limits as specified subject to
              the following conditions:
              <ul className="mt-3 text-sm ml-3 list-disc">
                <li>
                  No one article or pair/set or articles shall secede Php 2,500
                  up to the limit of the policy schedule
                </li>
                <li>
                  In respect of articles in pair or set, the Insurer shall only
                  be liable for the value of that part which have been lost or
                  damaged.
                </li>
                <li>
                  The Insurer may make payment in cash or may reinstate or
                  repair the articles loss or damaged, subject to reasonable
                  allowance for depreciation.
                </li>
                <li>
                  The Insured must take reasonable steps to safeguard their
                  baggage from loss or damage.
                </li>
                <li>
                  Any losses arising from theft or robbery must be reported to
                  the police within 24 hours at the place of theft or robbery.
                </li>
              </ul>
            </p>
            <p className="mt-3 text-sm font-bold">
              B. Loss of Travel Documents
            </p>
            <p className="mt-3 text-sm">
              The Insurer will reimburse expenses, for the transportation and
              communication expenses necessary in getting a replacement of lost
              passport and travel tickets including the cost of such
              replacement, up to the maximum limit stated in the schedule.
            </p>
            <p className="mt-3 text-sm">
              Provided such loss must be reported to the Police within twenty
              four (24) hours of the discovery and must be due to robbery,
              burglary, theft or natural disasters during the travel period.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel21" className="hidden" />
        <label
          htmlFor="panel21"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            I tested positive for COVID-19 just before my trip. Does Travel
            Protect Saver Plus cover the Trip Cancellation claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel21">
            <p className="text-sm">
              Any claims related to Covid-19 is excluded in this guarantee.
              However, you may send a written request to cancel the policy and
              premium refund before the Inception date of the policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqProductAsean;
