import { useState, useEffect } from "react";
import Header from "../../../components/organisms/Header";
import { Cache } from "aws-amplify";
import { blacklistAPI, validationAPI } from "../../../api/fetch-api";
import { formatContact, formatNumber, validateEmail } from "../../utils/helper";
import MutatingDropdown from "../../../components/molecules/MutatingDropdown";
import { list } from "postcss";
import Modal from "../../molecules/Modal";

const TravelAssuredDetails = (props) => {
  //States
  const [bdayMin, setBdayMin] = useState();
  const [bdayMax, setBdayMax] = useState();

  const [fullName, setFullName] = useState(props.assuredDetailsState?.fullName);
  const [bday, setBday] = useState(props.assuredDetailsState?.bday);
  const [passport, setPassport] = useState(props.assuredDetailsState?.passport);
  const [beneficiary, setBeneficiary] = useState();
  const [email, setEmail] = useState(props.assuredDetailsState?.email);
  const [contactNo, setContactNo] = useState(
    props.assuredDetailsState?.contactNo
  );
  const [age, setAge] = useState(props.assuredDetailsState?.age);
  const [civilstatus, setCivilstatus] = useState(
    props.assuredDetailsState?.maritalStatus
  );
  const [gender, setGender] = useState(props.assuredDetailsState?.gender);
  const [preExField, setPreExField] = useState(props.assuredDetailsState.assured[0].preexFlag);
  const [preExFieldFlags, setPreExFieldFlags] = useState(props.preexDetailsStateFlags);
  const [preExFields, setPreExFields] = useState(props.preexDetailsState);

  const [assured, setAssured] = useState(props.assuredDetailsState);
  
  //gcash api - get clientinfo
  const [clientInfo, setClientInfo] = useState([]);

  const [assuredIsLoaded, setAssuredIsLoaded] = useState(false);
  //age group passengers
  const [paxOne, setPaxOne] = useState(assured.assured[0]?.paxOne);
  const [paxTwo, setPaxTwo] = useState(assured.assured[0]?.paxTwo);
  const [paxThree, setPaxThree] = useState(assured.assured[0]?.paxThree);

  const [passportIsValid, setPassportIsValid] = useState(false);
  const [fullnameIsValid, setFullnameIsValid] = useState(false);

  const [isBdayCompute, setIsBdayCompute] = useState(false);

  const cover =
    window.location.href.indexOf("international") > -1
      ? "international"
      : "asean";

  //preexisting
  const preExList = props.preExList;
  const [selectedList,setSelectedList] = useState(props.assuredDetailsState.assured[0].preexList);
  const updatedSelectedList=(list)=>{
    setSelectedList(list);
  };
  const [isLoadingFlag,setIsLoadingFlag] = useState(0);
  useEffect(()=>{
    if(isLoadingFlag===1){
      setTimeout(() => {
        setIsLoadingFlag(0);
      }, 1000);
    }
  },[isLoadingFlag]);
  const [selectedListArray,setSelectedListArray] = useState(props.preexDetailsState);
  useEffect(() => {
    let test = document.querySelectorAll(".assured");

    if (Array.isArray(selectedListArray)) {
      for (let i = 0; i < test.length; i++) {
        if (selectedListArray[i] && selectedListArray[i].length === 0) {
          const newArray = [...selectedListArray];
          newArray[i] = [" "];
          setSelectedListArray(newArray);
        }
      }
    }
  }, [preExFieldFlags, selectedListArray]);
  useEffect(()=>{
  },[selectedListArray]);
  const updatedSelectedArrayList=(list,id)=>{
      console.log("===========");
      console.log(list,id);
      id = (id??0);
      const newArray = [...selectedListArray];
      newArray[id] = list; 
      setSelectedListArray(newArray);
  };
  //useEffects
  useEffect(() => {
    if (props.travelDetailsState.groupType === "individual") {
      let newDate = new Date(),
        newDate2 = new Date();

      newDate.setYear(newDate.getFullYear() - (props.bdayMax + 1));
      newDate.setMonth(newDate.getMonth());
      newDate.setDate(newDate.getDate() + 1);
      newDate = newDate.toISOString().split("T")[0];
      newDate2.setYear(newDate2.getFullYear() - props.bdayMin);
      newDate2.setDate(newDate2.getDate() - 1);
      if (props.bdayMin > 0) {
        // newDate2.setMonth(11)
        // newDate2.setDate(31)
      }
      newDate2 = newDate2.toISOString().split("T")[0];
      document.getElementsByName("birthday")[0].setAttribute("min", newDate);
      document.getElementsByName("birthday")[0].setAttribute("max", newDate2);
    } else {
      let test = document.querySelectorAll(".assured");
      let arr = [];

      let newDate = new Date(),newDate2 = new Date();

      newDate.setYear(newDate.getFullYear() - 76);
      newDate.setMonth(0);
      newDate.setDate(1);
      newDate = newDate.toISOString().split("T")[0];
      newDate2.setDate(newDate2.getDate() - 1);
      newDate2 = newDate2.toISOString().split("T")[0];

      for (let i = 0; i < test.length; i++) {
        document.getElementsByName("birthday")[i].setAttribute("min", newDate);
        document.getElementsByName("birthday")[i].setAttribute("max", newDate2);
      }
    }
    setBeneficiary("COMPULSARY HEIRS");

    Cache.setItem("paxOne", assured.assured[0]?.paxOne);
    Cache.setItem("paxTwo", assured.assured[0]?.paxTwo);
    Cache.setItem(
      "paxThree",
      assured.assured[0]?.paxThree ? assured.assured[0]?.paxThree : ""
    );
  }, []);

  useEffect(() => {
    //clientfo from gcash api
    setClientInfo(JSON.parse(Cache.getItem("clientInfo")));
  }, []);

  useEffect(() => {
    if (
      (props.travelDetailsState.howAreYou === "I am traveling alone." &&
        props.travelDetailsState.groupType === "individual") ||
      (props.travelDetailsState.howAreYou === "I am traveling with a group." &&
        props.travelDetailsState.groupType === "group")
    ) {
      if (!assuredIsLoaded && clientInfo?.resultData !== undefined) {
        preLoadedData(true, false);
      }
    } else if (
      props.travelDetailsState.howAreYou ===
        "I am buying insurance for someone." &&
      props.travelDetailsState.groupType === "individual"
    ) {
      if (!assuredIsLoaded && clientInfo.resultData !== undefined) {
        preLoadedData(false, true);
      }
    }
  }, [clientInfo]);

  //Functions
  const preLoadedData = (isEdit, forSomeone) => {
    //if forsomeone = true, do not preload data
    if (!forSomeone) {
      setAssuredIsLoaded(true);
      setFullName(
        clientInfo.resultData?.firstName +
          " " +
          clientInfo.resultData?.middleName +
          " " +
          clientInfo.resultData?.lastName
      );
      setBday(clientInfo.resultData?.birthday);
      setEmail(clientInfo.resultData?.emailAddress);
      setContactNo(formatNumber(clientInfo.resultData?.mobileNumber));
      setAge(calculateAge(clientInfo.resultData?.birthday));
      let test = {
        assured: [
          {
            bdayMin: "",
            bdayMax: "",
            fullName:
              clientInfo.resultData?.firstName +
              " " +
              clientInfo.resultData?.middleName +
              " " +
              clientInfo.resultData?.lastName,
            bday: clientInfo.resultData?.birthday,
            passport: "",
            beneficiary: beneficiary,
            email: clientInfo.resultData?.emailAddress,
            contactNo: formatNumber(clientInfo.resultData?.mobileNumber),
            age: calculateAge(clientInfo.resultData?.birthday),
            primary: "Yes",
            isEditable: isEdit,
            maritalStatus: "",
            gender: "",
            preexFlag:"N"
          },
        ],
      };
      setAssured(test);
    }
  };

  const SaveForm = async (e) => {
    e.preventDefault();
    Cache.getItem("isSaved", true);
    ageBracketBday();
    //validateForm();

    const emailInputs = e.target.querySelector('input[name="emailaddress"]');
    const forceEmailhtml = e.target.querySelector('input[name="emailaddress"]');
    const forceMobilehtml = e.target.querySelector('input[name="contanctno"]');
    console.log("event-----", forceMobilehtml);

    validateEmail(emailInputs, true);

    await validateEmailViaAPI(forceEmailhtml, true);
    await validateMobileViaAPI(forceMobilehtml, true);

    const getTravelReturnValue = Cache.getItem("travelReturnSubmit");
    const getTravelReturnValueMobile = Cache.getItem(
      "travelReturnSubmitMobile"
    );

    var getReturnSubmitAgent = Cache.getItem("travelReturnSubmitAgent");
    var getReturnSubmitAgentMobile = Cache.getItem(
      "travelReturnSubmitAgentMobile"
    );

    if (!getTravelReturnValue && !getTravelReturnValueMobile) {
      validateForm();
    } else if (!getReturnSubmitAgent && !getReturnSubmitAgentMobile) {
      alert(
        "Email Address and Contact Number match with our agent records.Please provide a new Email Address and Contact Number."
      );
      return false;
    }
  };
  const [saveDataDetails,setSaveDataDetails]=useState(null);
  const validateForm = async () => {
    const assuredDetailsData = {
      fullName: fullName,
      bday: bday,
      passport: passport,
      beneficiary: beneficiary,
      email: email,
      contactNo: contactNo,
      age: age,
      primary: "Yes",
      maritalStatus: civilstatus,
      gender: gender,
      preexFlag:preExFieldFlags[0],
      preexList:selectedListArray[0]
    };
    // props.saveData(assuredDetailsData);
    let test = document.querySelectorAll(".assured");
    let arr = [];
    for (let i = 1; i <= test.length; i++) {
      arr.push({
        fullName: document.querySelectorAll("#assured" + i + " input")[0].value,
        bday: document.querySelectorAll("#assured" + i + " input")[1].value,
        passport:
          props.travelDetailsState.coverType === "international"
            ? document.querySelectorAll("#assured" + i + " input")[2].value
            : "",
        email:
          props.travelDetailsState.coverType === "international"
            ? document.querySelectorAll("#assured" + 1 + " input")[4].value
            : document.querySelectorAll("#assured" + 1 + " input")[3].value,
        contactNo:
          props.travelDetailsState.coverType === "international"
            ? document.querySelectorAll("#assured" + 1 + " input")[5].value
            : document.querySelectorAll("#assured" + 1 + " input")[4].value,
        age:
          props.travelDetailsState.coverType === "international"
            ? calculateAge(
                new Date(
                  document.querySelectorAll("#assured" + i + " input")[1].value
                )
              )
            : calculateAge(
                new Date(
                  document.querySelectorAll("#assured" + i + " input")[1].value
                )
              ),
        primary: i === 1 ? "Yes" : "No",
        beneficiary: beneficiary,
        maritalStatus: document.querySelectorAll("#assured" + 1 + " select")[0]
          .value,
        gender: document.querySelectorAll("#assured" + 1 + " select")[1].value,
        preexFlag:preExFieldFlags[i-1],
        preexList:selectedListArray[i-1],
        paxOne: paxOne,
        paxTwo: paxTwo,
        paxThree: paxThree,
      });
    }

    let defaultPaxOne = props.travelDetailsState.ageBracket[0]?.ageCount,
      defaultPaxTwo = props.travelDetailsState.ageBracket[1]?.ageCount,
      defaultPaxThree = props.travelDetailsState.ageBracket[2]?.ageCount;

    //***START - age-group to birthday validation
    if (props.travelDetailsState.groupType === "group") {
      //test validation - if age group REQUIRED and NOT FILLED
      //Cache.getItem("paxOne")
      if (defaultPaxOne !== 0 && defaultPaxOne === Cache.getItem("paxOne")) {
        props.travelDetailsState.coverType === "international"
          ? alert(checkAgeTravellers("0-59"))
          : alert(checkAgeTravellers("0-65"));
        return false;
      }
      if (defaultPaxTwo !== 0 && defaultPaxTwo === Cache.getItem("paxTwo")) {
        props.travelDetailsState.coverType === "international"
          ? alert(checkAgeTravellers("60-69"))
          : alert(checkAgeTravellers("66-75"));
        return false;
      }
      if (
        props.travelDetailsState.coverType === "international" &&
        defaultPaxThree !== 0 &&
        defaultPaxThree === Cache.getItem("paxThree")
      ) {
        alert(checkAgeTravellers("70-75"));
        return false;
      }

      if (Cache.getItem("outBracket")) {
        alert(
          "Please check the age of the traveler(s) we do not allow 76 years old and above."
        );
        return false;
      }

      //test validation - if count exceeded
      //
      if (defaultPaxOne === 0 && Cache.getItem("paxOne") <= -1) {
        props.travelDetailsState.coverType === "international"
          ? alert(countExceed("0-59"))
          : alert(countExceed("0-65"));
        return false;
      }
      if (defaultPaxOne !== 0 && Cache.getItem("paxOne") <= -1) {
        props.travelDetailsState.coverType === "international"
          ? alert(countExceedReq("0-59"))
          : alert(countExceedReq("0-65"));
        return false;
      }
      //
      if (defaultPaxTwo === 0 && Cache.getItem("paxTwo") <= -1) {
        props.travelDetailsState.coverType === "international"
          ? alert(countExceed("60-69"))
          : alert(countExceed("66-75"));
        return false;
      }
      if (defaultPaxTwo !== 0 && Cache.getItem("paxTwo") <= -1) {
        props.travelDetailsState.coverType === "international"
          ? alert(countExceedReq("60-69"))
          : alert(countExceedReq("66-75"));
        return false;
      }
      //
      if (
        props.travelDetailsState.coverType === "international" &&
        defaultPaxThree === 0 &&
        Cache.getItem("paxThree") <= -1
      ) {
        alert(countExceed("70-75"));
        return false;
      }
      if (
        props.travelDetailsState.coverType === "international" &&
        defaultPaxThree !== 0 &&
        Cache.getItem("paxThree") <= -1
      ) {
        alert(countExceedReq("70-75"));
        return false;
      }
      //
      //***END - age-group to birthday validation

      //return false //temporary: return false to avoid next step

      let bdayerr = [];
      let isBdayToday = false;
      for (let i = 1; i <= test.length; i++) {
        if (
          new Date(
            document.querySelectorAll("#assured" + i + " input")[1].value
          ) === new Date()
        ) {
          isBdayToday = true;
          bdayerr.push(
            "-" +
              document.querySelectorAll("#assured" + i + " input")[0].value +
              "\n"
          );
        }
      }
      bdayerr = bdayerr.join("");
      if (isBdayToday) {
        alert(bdayerr + "Birthdays should not be date today.");
        return false;
      }

      //fullname and pass duplicate validation
      const arrs = [];
      const arrs2 = [];
      let pport = document.getElementsByName("passport");
      let fulln = document.getElementsByName("fullname");

      const validpass = Array.from(pport).every((input) => {
        if (arrs.includes(input.value)) {
          return false;
        } else {
          arrs.push(input.value);
          return true;
        }
      });

      const validname = Array.from(fulln).every((input) => {
        if (arrs2.includes(input.value)) {
          return false;
        } else {
          arrs2.push(input.value);
          return true;
        }
      });
      if (!validpass) {
        alert(`Passort must be unique for each assured.`);
        return false;
      }
      if (!validname) {
        alert(`Fullname must be unique for each assured.`);
        return false;
      }
    } //if group END
    const new_array = {};
    new_array.assured = arr;
    setAssured(new_array);

    //Blacklisted checker
    let blacklisted = false;
    let blacklist = "Blacklisted Assured name(s):\n";
    for (let i = 1; i <= test.length; i++) {
      try {
        let b_fullname = document.querySelectorAll("#assured" + i + " input")[0]
          .value;
        let b_bday = document.querySelectorAll("#assured" + i + " input")[1]
          .value;
        const isBlacklisted = await BlackListed(b_fullname, b_bday).then(
          (result) => {
            if (result) {
              blacklist += b_fullname + "\n";
              blacklisted = true;
            }
          }
        );
      } catch (err) {
        console.log(err);
      }
    }
    
    //preexcondition
    var preexBool= false;
    for (let i = 0; i < test.length; i++) {
      if(preExFieldFlags[i]==="Y"){
        if(selectedListArray[i].length==0)
          preexBool=true;
        else {
            selectedListArray[i].forEach(element => {
              if(element==" " || element==""){
                preexBool=true;
              }
          });
        }
      }
    }

    if(preexBool){
      alert("Please make sure that all pre-existing conditions are correctly filled.");
    }
    else if (!blacklisted) {
      Cache.setItem("saveDataDetails",new_array);
      setOpenpreex(true);
      //props.saveData(new_array);
    } else {
      const blacklistMessage =
        "Our insurance records show that you have a pre-existing condition which we are currently cannot insure. We regret that we are unable to write a policy for you.";
      //alert(blacklist);
      alert(blacklistMessage);
    }
  };

  const BlackListed = async (name, bdate) => {
    try {
      var date = new Date(bdate);

      var day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
      var month = parseInt(date.getMonth()) + 1;
      month = month <= 9 ? "0" + month : month;
      var formatted_bdate = month + "-" + day + "-" + date.getFullYear();

      return await blacklistAPI
        .checkBlacklist(name, formatted_bdate)
        .then((data) => {
          console.log("blacklisted return : ", data);
          if (data.assuredName != null) return true;
          else return false;
        })
        .catch((err) => console.log("Countries: ", err));
    } catch (err) {
      return false;
    }
  };

  const chosenBracket = (bracket) => {
    return (
      "Please check, the age of the traveler(s) is not within the selected age bracket (" +
      bracket +
      ")."
    );
  };

  const countExceed = (bracket) => {
    // return "The age of the traveler(s) is not the selected age bracket ("+bracket+")";
    return "No declared traveler(s) for age bracket (" + bracket + ").";
  };

  const countExceedReq = (bracket) => {
    // return "The age of the traveler(s) has exceeded the selected age bracket ("+bracket+")";
    return (
      "The number of declared traveler(s) in this age bracket (" +
      bracket +
      ") has exceeded the limit."
    );
  };

  const checkAgeTravellers = (bracket) => {
    return (
      "Please check the age of the traveler(s) if within the selected age bracket (" +
      bracket +
      ")."
    );
  };
  const handleChange = (e) => {
    let el = e.target;

    if (el.name === "fullname") {
      let len = document.querySelectorAll(".assured");
      let duplicate = false;
      for (let i = 1; i <= len.length; i++) {
        if (
          document.querySelectorAll("#assured" + i + " input")[0]?.value &&
          parseInt(
            document
              .querySelectorAll("#assured" + i + " input")[0]
              ?.getAttribute("index")
          ) !== parseInt(el.attributes.index.value) &&
          document
            .querySelectorAll("#assured" + i + " input")[0]
            .value.toLowerCase() === el.value.toLowerCase()
        ) {
          duplicate = true;
        }
      }
      if (duplicate) {
        alert("Fullname must be unique for each assured.");
        // el.value = "";
        setFullnameIsValid(true);
      } else {
        setFullnameIsValid(false);
      }
      setFullName(el.value);
    } else if (el.name === "birthday") {
      e.target.setCustomValidity("");
      setBday(el.value);
      setAge(calculateAge(el.value));
      ageBracketBday(); //compute age count
    } else if (el.name === "passport") {
      let len = document.querySelectorAll(".assured");
      let duplicate = false;
      for (let i = 1; i <= len.length; i++) {
        if (
          document.querySelectorAll("#assured" + i + " input")[2]?.value &&
          parseInt(
            document
              .querySelectorAll("#assured" + i + " input")[2]
              ?.getAttribute("index")
          ) !== parseInt(el.attributes.index.value) &&
          document.querySelectorAll("#assured" + i + " input")[2].value ===
            el.value
        ) {
          duplicate = true;
        }
      }
      if (duplicate) {
        alert("Passport must be unique for each assured.");
        // el.value = "";
        setPassportIsValid(true);
      } else {
        setPassportIsValid(false);
      }
      setPassport(el.value);
    } else if (el.name === "beneficiary") {
      setBeneficiary("COMPULSARY HEIRS");
    } else if (el.name === "emailaddress") {
      setEmail(el.value);
    } else if (el.name === "contanctno") {
      setContactNo(el.value);
    } else if (el.name === "civilstatus") {
      setCivilstatus(el.value);
    } else if (el.name === "gender") {
      setGender(el.value);
    } else if (el.name === "preexFlag"){
      setPreExField(el.value);
      const newArray = preExFieldFlags;
      newArray[0] = el.value; 
      setPreExFieldFlags(newArray);
    }
  };

  const handlePreexFlags = (e,index) =>{
    const newArray = preExFieldFlags;
    newArray[index] = e.target.value; 
    setPreExFieldFlags(newArray);
    setTimeout(() => {
      setIsLoadingFlag(1);
    }, 500);
  };

  const calculateAge = (birthDate) => {
    var today = new Date();
    var birthDate = new Date(birthDate);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const ageBracketBday = () => {
    //count of age based from age bracket travellers' count
    let bday = document.getElementsByName("birthday");
    let test;
    let age = 0;
    let bracket1 = props.travelDetailsState.ageBracket[0]?.ageCount;
    let bracket2 = props.travelDetailsState.ageBracket[1]?.ageCount;
    let bracket3 = props.travelDetailsState.ageBracket[2]?.ageCount;
    let bracket4 = false;
    for (let i = 0; i <= bday.length; i++) {
      // document.getElementsByName("birthday")[i].setAttribute("age", calculateAge(el.value));
      if (bday[i]?.value) {
        test = bday[i].value;
        if (props.travelDetailsState.coverType === "international") {
          if (calculateAge(test) >= 0 && calculateAge(test) <= 59) {
            bracket1 = parseInt(bracket1) - 1;
          } else if (calculateAge(test) >= 60 && calculateAge(test) <= 69) {
            bracket2 = parseInt(bracket2) - 1;
          } else if (calculateAge(test) >= 70 && calculateAge(test) <= 75) {
            bracket3 = parseInt(bracket3) - 1;
          } else if (calculateAge(test) >= 76) {
            bracket4 = calculateAge(test);
          }
        } else {
          if (calculateAge(test) >= 0 && calculateAge(test) <= 65) {
            bracket1 = parseInt(bracket1) - 1;
          } else if (calculateAge(test) >= 66 && calculateAge(test) <= 75) {
            bracket2 = parseInt(bracket2) - 1;
          } else if (calculateAge(test) >= 76) {
            bracket4 = true;
          }
        }
      }
    }
    setPaxOne(bracket1);
    setPaxTwo(bracket2);
    setPaxThree(bracket3);
    Cache.setItem("paxOne", bracket1);
    Cache.setItem("paxTwo", bracket2);
    Cache.setItem("paxThree", bracket3 ? bracket3 : "");
    Cache.setItem("outBracket", bracket4);
  };

  //FUNCTIONS
  const validateEmailViaAPI = async (event, isForm) => {
    let emailInputs = isForm ? event : event.target;
    let emailValue = isForm ? event.value : emailInputs.value;

    Cache.setItem("travelReturnSubmit", null);
    Cache.setItem("travelReturnSubmitAgent", null);

    await validationAPI.getEmailValidation(emailValue).then((data) => {
      if (data.statusCode == "ISAGENT") {
        emailInputs.setCustomValidity(
          "Email Address matches with our agent records. Please provide a new email address."
        ); // Set the custom error message
        emailInputs.reportValidity();

        Cache.setItem("travelReturnSubmit", true);
        Cache.setItem("travelReturnSubmitAgent", true);
        Cache.setItem("travelOnBlur", true);
        return;
      } else if (
        data.statusCode == "UNDELIVERABLE" ||
        data.statusCode == "INVALID"
      ) {
        emailInputs.setCustomValidity(
          "Invalid email address format. Please provide a valid email address."
        ); // Set the custom error message
        emailInputs.reportValidity();
        Cache.setItem("travelReturnSubmit", true);
        Cache.setItem("travelReturnSubmitAgent", false);

        return;
      }

      emailInputs.setCustomValidity("");
      Cache.setItem("travelReturnSubmit", false);
      Cache.setItem("travelReturnSubmitAgent", false);
      return;
    });
  };

  const validateMobileViaAPI = async (event, isForm) => {
    let mobileInputs = isForm ? event : event.target;
    let mobileValue = isForm ? event.value : mobileInputs.value;

    Cache.setItem("travelReturnSubmitMobile", null);
    Cache.setItem("travelReturnSubmitAgentMobile", null);

    if (mobileValue !== "") {
      await validationAPI
        .getMobileValidation(mobileValue)
        .then((data) => {
          console.log("MOBILE-API", data);
          if (data.statusCode == "ISAGENT") {
            mobileInputs.setCustomValidity(
              "Contact Number matches with our agent records. Please provide a new Mobile Number."
            ); // Set the custom error message
            mobileInputs.reportValidity();

            Cache.setItem("travelReturnSubmitMobile", true);
            Cache.setItem("travelReturnSubmitAgentMobile", true);

            return;
          } else if (data.statusCode == "INVALID") {
            mobileInputs.setCustomValidity(data.message); // Set the custom error message
            mobileInputs.reportValidity();

            Cache.setItem("travelReturnSubmitMobile", true);
            Cache.setItem("travelReturnSubmitAgentMobile", false);

            return;
          } else if (data.statusCode == "NONZERO") {
            mobileInputs.setCustomValidity(data.message); // Set the custom error message
            mobileInputs.reportValidity();

            Cache.setItem("travelReturnSubmitMobile", true);
            Cache.setItem("travelReturnSubmitAgentMobile", false);
            return;
          }

          mobileInputs.setCustomValidity("");
          Cache.setItem("travelReturnSubmitMobile", false);
          Cache.setItem("travelReturnSubmitAgentMobile", false);
          return;
        })
        .catch((error) => {
          console.log(error);
          console.log("There was an error!");
        });
    }
  };

  useEffect(() => {
    Cache.setItem("flag", 0);
  }, []);
  const [isLoading, setIsLoading] = useState(0);
  const [openPreex, setOpenpreex] = useState(false);
  const handleDataConfirmation = (event) => {
    const checked = event.target.checked;
    setIsLoading(1);
    if (checked === true) {
      Cache.setItem("flag", 1);
    } else {
      Cache.setItem("flag", 0);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const DisableButton = (event) => {
    Cache.setItem("flag", 0);
  };

  return (
    <>
      <div>
        <form onSubmit={SaveForm}>
          <main className="mb-24">
            {/* <p>Insert Wizard</p> */}

            <h3 className="text-lg font-bold title-font">
              Please fill in your details
            </h3>

            {(() => {
              const rows = [];
              let cnt = 0;
              for (let i = 1; i <= props.travelDetailsState.totalPax; i++) {
                // for(let i=1; i<=1; i++){

                rows.push(
                  <div className="mb-5 assured" id={`assured` + i}>
                    {i == 1 ? (
                      <h4 className="font-bold mt-8 mb-3">Primary Traveler</h4>
                    ) : (
                      <h4 className="font-bold mt-8 mb-3">Traveler {i}</h4>
                    )}
                    <div className="mb-3">
                      <label className="inline-block text-gray-500 text-sm font-bold">
                        Full Name
                      </label>

                      <div className="block relative w-full">
                        <input
                          className="input-border-none focus:outline-none focus:shadow-outline"
                          type="text"
                          name="fullname"
                          onChange={handleChange}
                          defaultValue={
                            i === 1
                              ? assured.assured?.[0]?.fullName
                              : assured.assured?.[cnt]?.fullName
                          }
                          placeholder="Firstname, MI, Lastname"
                          disabled={
                            assured.assured?.[cnt]?.isEditable ? true : false
                          }
                          test={assured.assured?.[cnt]?.isEditable}
                          maxLength="60"
                          index={i}
                          required
                        />
                      </div>
                    </div>

                    {/**Birthday validation should correspond to ageBracket */}
                    <div className="mb-3">
                      <label className="inline-block text-gray-500 text-sm font-bold">
                        Birthday
                      </label>

                      <div className="block relative w-full">
                        <input
                          className="input-border-none focus:outline-none focus:shadow-outline"
                          type="date"
                          name="birthday"
                          age={0}
                          onChange={handleChange}
                          defaultValue={assured.assured?.[cnt]?.bday}
                          // disabled={(assured.assured[cnt]?.isEditable)?true:false}
                          style={{
                            pointerEvents: assured.assured?.[cnt]?.isEditable
                              ? "none"
                              : "unset",
                          }}
                          required
                          onInvalid={(e) =>
                            props.travelDetailsState.groupType === "individual"
                              ? e.target.setCustomValidity(
                                  "Birthday do not match the travelling applicant."
                                )
                              : e.target.setCustomValidity(
                                  "Birthday do not match the travelling applicant."
                                )
                          }
                        />
                      </div>
                    </div>
                    {props.travelDetailsState.coverType === "international" && (
                      <div className="mb-3">
                        <label className="inline-block text-gray-500 text-sm font-bold">
                          Passport Number
                        </label>

                        <div className="block relative w-full">
                          <input
                            className="input-border-none focus:outline-none focus:shadow-outline"
                            type="text"
                            name="passport"
                            onChange={handleChange}
                            defaultValue={
                              i === 1
                                ? assured.assured?.[0]?.passport
                                : assured.assured[cnt]?.passport
                            }
                            maxLength="15"
                            index={i}
                            required
                          />
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {i === 1 && (
                      <>
                        <div className="mb-3">
                          <label className="inline-block text-gray-500 text-sm font-bold">
                            Civil Status
                          </label>
                          <div className="block relative w-full">
                            <select
                              name="civilstatus"
                              id="civilstatus"
                              className="input-border-none focus:outline-none focus:shadow-outline"
                              onChange={handleChange}
                              defaultValue={
                                i === 1
                                  ? assured.assured?.[0]?.maritalStatus
                                  : assured.assured[cnt]?.maritalStatus
                              }
                              required
                            >
                              <option value="">Select Civil Status</option>
                              <option code="S" value="S">
                                Single
                              </option>
                              <option code="M" value="M">
                                Married
                              </option>
                              <option code="W" value="W">
                                Widowed
                              </option>
                              <option code="L" value="L">
                                Separated
                              </option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="inline-block text-gray-500 text-sm font-bold">
                            Gender
                          </label>
                          <div className="block relative w-full">
                            <select
                              name="gender"
                              id="gender"
                              className="input-border-none focus:outline-none focus:shadow-outline"
                              prevData=""
                              onChange={handleChange}
                              defaultValue={
                                i === 1
                                  ? assured.assured?.[0]?.gender
                                  : assured.assured[cnt]?.gender
                              }
                              required
                            >
                              <option value="">Select Gender</option>
                              <option code="M" value="M">
                                Male
                              </option>
                              <option code="F" value="F">
                                Female
                              </option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3" hidden>
                          <label className="inline-block text-gray-500 text-sm font-bold">
                            Beneficiary
                          </label>

                          <div className="block relative w-full">
                            <input
                              className="input-border-none focus:outline-none focus:shadow-outline"
                              type="text"
                              name="beneficiary"
                              onChange={handleChange}
                              // defaultValue="COMPULSARY HEIRS"
                              value="COMPULSARY HEIRS"
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="inline-block text-gray-500 text-sm font-bold">
                            Email Address
                          </label>

                          <div className="block relative w-full">
                            <input
                              className="input-border-none focus:outline-none focus:shadow-outline"
                              type="email"
                              name="emailaddress"
                              onChange={handleChange}
                              defaultValue={
                                i === 1
                                  ? assured.assured?.[0]?.email
                                  : assured.assured[cnt]?.email
                              }
                              disabled={
                                assured.assured?.[cnt]?.isEditable
                                  ? true
                                  : false
                              }
                              maxLength="60"
                              onBlur={(e) => {
                                validateEmail(e, false);
                                validateEmailViaAPI(e, false);
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="inline-block text-gray-500 text-sm font-bold">
                            Contact Number
                          </label>

                          <div className="block relative w-full">
                            <input
                              className="input-border-none focus:outline-none focus:shadow-outline"
                              type="text"
                              name="contanctno"
                              onChange={handleChange}
                              defaultValue={
                                i === 1
                                  ? formatNumber(
                                      assured.assured?.[0]?.contactNo
                                    )
                                  : formatNumber(
                                      assured.assured?.[cnt]?.contactNo
                                    )
                              }
                              disabled={
                                assured.assured?.[cnt]?.isEditable
                                  ? true
                                  : false
                              }
                              onBlur={(e) => {
                                //formatContact(e, false);
                                validateMobileViaAPI(e, false);
                              }}
                              maxLength="11"
                              minLength="11"
                              required
                            />
                          </div>

                          
                          
                        </div>
                      </>
                    )}
                    <div className="mb-3">
                      <label className="inline-block text-gray-500 text-sm font-bold">
                        Do you have any pre-existing conditions/s?
                      </label>
                      <div className="block relative w-full">
                        <select
                          name="preexFlag"
                          className="input-border-none focus:outline-none focus:shadow-outline"
                          required
                          defaultValue={preExFieldFlags[i-1]}
                          onChange={(e)=>handlePreexFlags(e,i-1)}
                        >
                          <option value="">Please select</option>
                          <option value="Y">YES</option>
                          <option value="N">NO</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {isLoadingFlag !==1 && (preExFieldFlags[i-1]!=="" && preExFieldFlags[i-1]!=="N") && (
                      <div className="mb-3">
                        <MutatingDropdown
                          valueKey={"preExCode"}
                          labelKey={"preExDesc"}
                          data={preExList}
                          setSelection={updatedSelectedArrayList}
                          setSelectionTo={(cnt)}
                          maxSelections={10}
                          minSelections={1}
                          selectedList={selectedListArray[cnt]}
                        />
                      </div>
                    )}
                   
                    
                  </div>
                );
                cnt++;
              }
              return rows;
            })()}
             <div className="mb-3 mt-3">
                <label className="inline-block text-gray-500 text-sm font-bold text-blue-500">
                  Note: SICI reserves the right NOT to respond to preexisiting condition/s whether known or not.
                </label>
              </div>
          </main>
          <button type="submit" style={{ display: "none" }} id="btnSubmit">
            submit
          </button>
        </form>
        <Modal open={openPreex} setOpen={setOpenpreex} 
          isDisabled={Cache.getItem("flag")}
          disableButton={DisableButton}
          isLoading={isLoading}
          buttonTitle={"Submit"}
          title="Pre-existing Condition Confirmation"
          saveData={()=>{props.setPreexDetailsState(selectedListArray);props.saveData(Cache.getItem("saveDataDetails"));}}
          >
            <div>
            <p className="my-5">
                The information indicated herein is correct and complete. All prospective Policyholder/s
                understand that any assistance or compensation for events in any of the following circumstance are NOT
                covered:
                <ul>
                  <li className={"px-4"}>a) With the intention of receiving medical treatment;</li>
                  <li className={"px-4"}>b) Pre-existing Illnesses or injuries arising from chronic ailments or from those that existed
                prior to the inception date of the policy;</li>
                <li className={"px-4"}>c) After the diagnosis of a terminal illness; or</li>
                <li className={"px-4"}>d) Without prior medical authorization, after the prospective Policyholder had been under treatment or medical supervision during the twelve (12) months prior to the start of the trip.</li>
                
                </ul>
                </p>
            </div>
            <div className="flex my-1">
           
              <div className="pr-3">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  onChange={handleDataConfirmation}
                />
              </div>
              <div>
                
                <p className="">I agree with the information as stated above.</p>
              </div>
            </div>
        </Modal>
      </div>
    </>
  );
};

export default TravelAssuredDetails;
