import { useState } from "react";
import { platenumberAPI, authAPI, authDetails } from "../../../api/fetch-api";
import Modal from "../../molecules/Modal";
import { useHistory } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { Cache } from "aws-amplify";

const PlateNumberValidation = (props) => {
  let history = useHistory();
  const plateNumber = props.plateNumber;
  const [open, setOpen] = useState(false);
  const [openMalus, setOpenMalus] = useState(false);
  const [isLoading, setIsLoading] = useState(0);
  const validatePlateNo = (event) => {
    event.preventDefault();

    const checkingPlateNumber = event.target.plateNumber.value.replace(
      /[^A-Z0-9]/gi,
      ""
    );
    props.setIsLoading(1);

    const handlePlateNumberValidation = async () => {
      try {
        const authData = await authAPI.getAuth(authDetails);
        const plateData = await platenumberAPI.getExisting(
          checkingPlateNumber,
          authData.token
        );

        if (
          plateData.branch === "SI" ||
          !plateData.branch?.trim() ||
          plateData.branch === null
        ) {
          const upperPlateNumber = checkingPlateNumber.toUpperCase();
          const malusData = await platenumberAPI.getMalus(upperPlateNumber);
          console.log("malusData", malusData);
          setIsLoading(0);
          props.setIsLoading(0);

          if (
            malusData.mallusRecom === "Non Renewable" &&
            malusData.message == "With Mallus Rate"
          ) {
            setTimeout(() => {
              props.setOpenMalus(true);
            }, 500);
          } else {
            console.log("check");
            SubmitForm();
          }
        } else {
          setIsLoading(0);
          props.setIsLoading(0);
          setTimeout(() => {
            props.setOpen(true);
          }, 500);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    handlePlateNumberValidation();
  };

  let plateNumberData = {
    plateNumber: plateNumber.plateNumber,
  };

  const handleUpdates = (e) => {
    plateNumberData[e.target.name] = e.target.value;
    //console.log(plateNumberData);
  };
  const SubmitForm = () => {
    props.saveData(plateNumberData);
    setIsLoading(1);
  };
  return (
    <>
      <div className="mb-40">
        <div className="text-left mb-10">
          <div className="m-5">
            <div className="pb-5">
              <h1 className="text-xl font-bold title-font">
                Motorcar Insurance
              </h1>
              <span className="block text-gray-500 text-base font-bold">
                Let's start!
              </span>
            </div>

            <form onSubmit={validatePlateNo}>
              <div className="text-center my-10">
                <div>
                  <img
                    alt="motorcar"
                    src={"/images/plateno.png"}
                    className="mx-auto"
                  />
                </div>
                <div className="mt-2">
                  <h3 className="text-lg font-bold">
                    Please enter your Plate Number
                  </h3>
                </div>
                <div className="my-2">
                  <div className="block relative w-full">
                    <input
                      type="text"
                      name="plateNumber"
                      placeholder="ABC 1234"
                      onChange={handleUpdates}
                      defaultValue={plateNumber.plateNumber}
                      className="input-form focus:outline-none focus:shadow-outline text-center"
                      required
                      maxLength={15}
                    />
                  </div>
                </div>
              </div>
              <button type="submit" style={{ display: "none" }} id="btnSubmit">
                submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlateNumberValidation;