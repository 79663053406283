import React, { useState } from "react";

const FaqPolicy = () => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I make changes to my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <p className="text-sm">
              If you have any changes in your policy, please contact us at the
              following:
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
            <p className="text-sm mt-3">
              Any change or modification in your insured vehicle (i.e. engine
              no., color, etc.) must be declared in the policy. New details that
              may be found contrary to the policy that are not properly endorsed
              may be used as grounds for the denial of the claim.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I renew my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              Register your existing policy for your online access{" "}
              <a
                href="https://bit.ly/MyStandardOnline"
                target="_blank"
                className="text-yellow-500"
              >
                https://bit.ly/MyStandardOnline
              </a>
              . Registered policies get a renewal prompt 90 days before policy
              expiration. You may log-in to your online access to initiate. You
              may contact us at the following:
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel3" className="hidden" />
        <label
          htmlFor="panel3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I request for a refund?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              You may request for a refund and cancel your policy within the day
              after you received it via email. All premiums shall be returned to
              your GCASH wallet provided it is cancelled before 12 midnight of
              the same day. If the cancellation period falls on the following
              day, the premium shall be returned via bank transfer or check.
              Please expect a call from our representative.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel4" className="hidden" />
        <label
          htmlFor="panel4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I cancel my policy if my unit was sold?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              You may request for the cancellation of your policy if your unit
              has been sold. If you did not file a claim during the policy year,
              a refund on the basic premium, excluding taxes, will be processed
              based on the Short Period Rate Scale stipulated in your policy.
              However, if you have already incurred a claim and collected the
              proceeds or in process before your request for cancellation, no
              premium and taxes will be returned to you.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel5" className="hidden" />
        <label
          htmlFor="panel5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I transfer the Insurance if I have plans to sell my vehicle?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              Yes, provided that you secure the approval of the Insurer by
              contact us at the following:
            </p>
            <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPolicy;
