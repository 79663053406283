import Header from "../Header";
import Footer from "../Footer";
import { Cache } from "aws-amplify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  travelLocalAPI,
  travelAPI,
  gcashAPI,
  basePath,
  authAPI,
  authDetails,
} from "../../../api/fetch-api";
import { formatNumber } from "../../utils/helper";

function TravelReview(props) {
  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState("");
  let params = {};

  //
  const [travelDetailsState, setTraveDetailsState] = useState([]);
  const [assuredDetailsState, setAssuredDetailsState] = useState([]);
  const [preexDetailsState, setPreexDetailsState] = useState(["", "", "", ""]);
  const [destinations, setDestinations] = useState([]);
  const [codeDestinations, setCodeDestinations] = useState([]);
  const [coverType, setCoverType] = useState("");
  const [origin, setOrigin] = useState("");

  const [accountId, setAccountId] = useState("");
  let history = useHistory();

  //gcash api - get clientinfo
  const [clientInfo, setClientInfo] = useState([]);

  //from wendell useEffect
  useEffect(() => {
    try {
      setIsLoading(0);
      setIsDisabled(0);
    } catch (err) {}

    authAPI.getAuth(authDetails).then((data) => {
      setAuth(data);
    });

    //from, gcash api
    setClientInfo(JSON.parse(Cache.getItem("clientInfo")));
  }, []);

  useEffect(() => {
    setTraveDetailsState(Cache.getItem("travelDetailsState"));
    setAssuredDetailsState(Cache.getItem("assuredDetailsState"));
    setPreexDetailsState(Cache.getItem("preexDetailsState"));
    setAccountId(Cache.getItem("customerId"));

    Cache.removeItem("benefitFlag");
  }, []);

  useEffect(() => {
    let dest = travelDetailsState.destinations ?? [];
    let arr = [];
    let arr2 = [];
    dest.map((i, d) => {
      if (travelDetailsState.coverType === "asean" && d !== 0) {
        return arr.push(i.value);
      }
      if (travelDetailsState.coverType === "international") {
        return arr.push(i.value);
      }
    });
    dest.map((i, d) => {
      return arr2.push(i.code);
    });

    setDestinations(arr.join(" - "));
    setCodeDestinations(arr2.join("-"));
  }, [travelDetailsState.destinations, destinations]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cover = urlParams.get("type");
    setCoverType(cover);
  }, [coverType]);

  //function
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const gotoTerms = () => {
    setIsLoading(1);
    window.location.replace(
      "https://docs.google.com/gview?embedded=true&url=" +
        process.env.REACT_APP_TERMS +
        ""
    );
  };

  const SubmitButton = (e) => {
    document.getElementById("btnSubmit").click();
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    setIsLoading(1);
    const travelInt = false;
    //address from gcash api

    console.log("assuredDetailsState?.assured", assuredDetailsState?.assured);
    let clientAdd = [
      {
        address1: clientInfo.resultData.address.street,
        address2:
          clientInfo.resultData.address.town !== null &&
          clientInfo.resultData.address.town !== ""
            ? clientInfo.resultData.address.town
            : " ",
        bday: assuredDetailsState?.assured?.[0]?.bday,
        beneficiary: assuredDetailsState?.assured?.[0]?.beneficiary,
        contactType: "M",
        contactNo: formatNumber(assuredDetailsState?.assured?.[0]?.contactNo),
        email: assuredDetailsState?.assured?.[0]?.email,
        gender: assuredDetailsState?.assured?.[0]?.gender,
        hasPa: false,
        name: assuredDetailsState?.assured?.[0]?.fullName,
        passport: assuredDetailsState?.assured?.[0]?.passport,
        postal: clientInfo.resultData.address.zipCode,
        primary: true,
        province: "Manila",
        relation: "Next of Kin",
        seq: 0,
        unid: 0,
        maritalStatus: assuredDetailsState?.assured?.[0]?.maritalStatus,
        preexList:
          assuredDetailsState?.assured?.[0]?.preexFlag !== "N" &&
          Array.isArray(assuredDetailsState?.assured?.[0]?.preexList)
            ? assuredDetailsState?.assured?.[0]?.preexList.join(",")
            : "",
        preexFlag: assuredDetailsState?.assured?.[0]?.preexFlag,
        uuid: assuredDetailsState?.assured?.[0]?.uuid,
      },
    ];
    console.log("Preex"+[0],assuredDetailsState?.assured?.[0]?.preexList);
    console.log("CLIENTAADD", clientAdd);
    // let test = {
    //   address1:"28F Petron MegaPlaza Building",
    //   address2:"Sen Gil Puyat Ave",
    //   bday:assuredDetailsState?.assured?.[0]?.bday,
    //   beneficiary:assuredDetailsState?.assured?.[0]?.beneficiary,
    //   contactType:"H",
    //   contactNo:assuredDetailsState?.assured?.[0]?.contactNo,
    //   email:assuredDetailsState?.assured?.[0]?.email,
    //   gender:assuredDetailsState?.assured?.[0]?.gender,
    //   hasPa:false,
    //   name:assuredDetailsState?.assured?.[0]?.fullName,
    //   passport:assuredDetailsState?.assured?.[0]?.passport,
    //   postal:"1200",
    //   primary:true,
    //   province:"Makati",
    //   relation:"Next of Kin",
    //   seq:0,
    //   unid:0,
    //   maritalStatus:assuredDetailsState?.assured?.[0]?.maritalStatus,
    //   test:"test"
    // }
    let arr = [];
    for (let i = 0; i < assuredDetailsState?.assured.length; i++) {
      arr.push({
        address1: "The Enterprise Center, 26th Floor, Tower 1, 6766",
        address2: "Ayala Avenue corner Paseo de Roxas",
        bday: assuredDetailsState?.assured?.[i]?.bday,
        beneficiary: assuredDetailsState?.assured?.[i]?.beneficiary,
        contactType: "M",
        contactNo: formatNumber(assuredDetailsState?.assured?.[i]?.contactNo),
        email: assuredDetailsState?.assured?.[i]?.email,
        gender: assuredDetailsState?.assured?.[i]?.gender,
        hasPa: false,
        name: assuredDetailsState?.assured?.[i]?.fullName,
        passport: assuredDetailsState?.assured?.[i]?.passport,
        postal: "1200",
        primary: i === 0 ? true : false,
        province: "Makati",
        relation: "Next of Kin",
        seq: 0,
        unid: 0,
        maritalStatus: assuredDetailsState?.assured?.[i]?.maritalStatus,
        preexList: assuredDetailsState?.assured?.[i]?.preexFlag !== "N" && Array.isArray(assuredDetailsState?.assured?.[i]?.preexList)
            ? assuredDetailsState?.assured?.[i]?.preexList.join(",")
            : "",
        preexFlag: assuredDetailsState?.assured?.[i]?.preexFlag,
        uuid: assuredDetailsState?.assured?.[i]?.uuid,
      });
      console.log("Preex"+[i],assuredDetailsState?.assured?.[i]?.preexList);
    }

    console.log("ARR", arr);
    //Travel International
    if (travelDetailsState.coverType === "international") {
      params = {
        assuredList:
          travelDetailsState.howAreYou === "I am traveling alone."
            ? clientAdd
            : arr,
        destinationType: "",
        frequentTraveler: false,
        arrival: travelDetailsState.arrival,
        below75: 0,
        between66n75: 0,
        between76n85: 0,
        contestFlag: travelDetailsState.sports,
        departure: travelDetailsState.departure,
        destination: codeDestinations,
        hazardFlag: travelDetailsState.hazardous,
        packageType: travelDetailsState.groupType === "individual" ? "I" : "G",
        zone: travelDetailsState.zone,
        groupCount: travelDetailsState.groupCount,
        packageVersion: travelDetailsState.packageVersion,
        covidCoverFlag: true,
        merchantId: "" + process.env.REACT_APP_TRAVEL_ID + "",
        insuranceAccountId: accountId,
      };
    } else if (travelDetailsState.coverType === "asean") {
      //Travel Asean
      params = {
        assuredList:
          travelDetailsState.howAreYou === "I am traveling alone."
            ? clientAdd
            : arr,
        // [
        //   {
        //     address1:"28F Petron MegaPlaza Building",
        //     address2:"Sen Gil Puyat Ave",
        //     bday:assuredDetailsState?.assured?.[0]?.bday,
        //     beneficiary:assuredDetailsState?.assured?.[0]?.beneficiary,
        //     contactType:"H",
        //     contactNo:assuredDetailsState?.assured?.[0]?.contactNo,
        //     email:assuredDetailsState?.assured?.[0]?.email,
        //     gender:assuredDetailsState?.assured?.[0]?.gender,
        //     hasPa:false,
        //     name:assuredDetailsState?.assured?.[0]?.fullName,
        //     passport:"",
        //     postal:"1200",
        //     primary:true,
        //     province:"Makati",
        //     relation:"Next of Kin",
        //     seq:0,
        //     unid:0,
        //     maritalStatus: assuredDetailsState?.assured?.[0]?.maritalStatus,
        //   }
        // ],
        // destinationType:travelDetailsState.coverType,
        destinationType: "",
        frequentTraveler: false,
        arrival: travelDetailsState.arrival,
        below65: travelDetailsState.ageBracket1,
        between66n75: travelDetailsState.ageBracket2,
        departure: travelDetailsState.departure,
        destination: codeDestinations,
        packageType: travelDetailsState.groupType === "individual" ? "I" : "G",
        purpose: travelDetailsState.purposes,
        zone: travelDetailsState.zone,
        merchantId: "" + process.env.REACT_APP_TRAVEL_ID + "",
        insuranceAccountId: accountId,
      };
    }
    // return false;
    //ASEAN
    if (travelDetailsState.coverType === "asean") {
      if (
        //ASEAN PUT
        Cache.getItem("unid") !== undefined &&
        Cache.getItem("unid") !== 0 &&
        Cache.getItem("unid") !== null
      ) {
        params.unid = Cache.getItem("unid");
        //params.uuid = Cache.getItem("uuid");
        fetch(
          basePath.baseUrl + "/quotation/travel/local/" + Cache.getItem("unid"),
          {
            method: "PUT",
            body: JSON.stringify(params),
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.token,
            },
          }
        )
          .then((response) => {
            let response_status = response.status;
            if (response_status == 200) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", auth.token);

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
              };

              fetch(
                basePath.baseUrl + "/order/" + Cache.getItem("unid"),
                requestOptions
              )
                .then((response) => response.text())
                .then((result) => {
                  setTimeout(() => {
                    window.location.href = JSON.parse(result).checkoutUrl;
                  }, 1000);
                })
                .catch((error) => {
                  console.log("error", error);
                  setIsDisabled(false);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
            setIsDisabled(false);
          });
      } else {
        //ASEAN POST
        travelLocalAPI
          .postLocalTravel(params)
          .then((data) => {
            console.log("DAATA", data);
            Cache.setItem("quoteState", data);
            Cache.setItem("unid", data.unid);
            // Cache.setItem("uuid", data.uuid);

            var myHeaders = new Headers();
            myHeaders.append("Authorization", auth.token);

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              redirect: "follow",
            };

            fetch(
              basePath.baseUrl + "/order/" + Cache.getItem("unid"),
              requestOptions
            )
              .then((response) => response.text())
              .then((data1) => {
                setIsLoading(1);
                setTimeout(() => {
                  window.location.href = JSON.parse(data1).checkoutUrl;
                }, 1000);
              });
          })
          .catch((err) => console.log("Model Error: ", err));
      }
      console.log("Params",params);
    } else {
      //INTL
      //INTL PUT
      if (
        Cache.getItem("unid") !== undefined &&
        Cache.getItem("unid") !== 0 &&
        Cache.getItem("unid") !== null
      ) {
        params.unid = Cache.getItem("unid");
        //params.uuid =  Cache.getItem("uuid");
        //postData.merchantId =  "0003" ;
        fetch(
          basePath.baseUrl +
            "/quotation/travel/international/" +
            Cache.getItem("unid"),
          // basePath.baseUrl + "/quotation/travel/"+travelDetailsState.coverType+"/"+ Cache.getItem("unid"),
          // fetch(
          //   "https://1cr450kc61.execute-api.ap-southeast-1.amazonaws.com/dev/quotation/motor/ctpl/"+Cache.getItem("unid") ,
          {
            method: "PUT",
            body: JSON.stringify(params),
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.token,
            },
          }
        )
          .then((response) => {
            console.log("RES---", response);
            let response_status = response.status;
            if (response_status == 200) {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", auth.token);

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
              };
              //INTL ORDER
              fetch(
                basePath.baseUrl + "/order/" + Cache.getItem("unid"),
                requestOptions
              )
                .then((response) => response.text())
                .then((result) => {
                  setTimeout(() => {
                    window.location.href = JSON.parse(result).checkoutUrl;
                  }, 1000);
                })
                .catch((error) => {
                  console.log("error", error);
                  setIsDisabled(false);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
            setIsDisabled(false);
          });
      } else {
        //INTL POST
        travelAPI
          .postIntTravel(params)
          .then((data) => {
            console.log("DATA", data);

            Cache.setItem("quoteState", data);
            Cache.setItem("unid", data.unid);
            //Cache.setItem("uuid", data.uuid);

            var myHeaders = new Headers();
            myHeaders.append("Authorization", auth.token);

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              redirect: "follow",
            };

            //INTL ORDER
            fetch(
              basePath.baseUrl + "/order/" + Cache.getItem("unid"),
              requestOptions
            )
              .then((response) => response.text())
              .then((data1) => {
                setIsLoading(1);
                setTimeout(() => {
                  window.location.href = JSON.parse(data1).checkoutUrl;
                }, 1000);
              });
          })
          .catch((err) => console.log("Model Error: ", err));
      }
      console.log("Params",params);

    }
  };

  return (
    <div>
      <Header headerTitle={props.headerTitle} />

      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img
          alt="review"
          src={"/images/travel/review-banner.png"}
          className="image"
        />
      </div>
      <main className=" p-5">
        <form onSubmit={SubmitForm}>
          <div className="mt-44">
            <div className="text-left mb-15">
              {/* <div>
                <img alt="payment-banner" />
              </div> */}
              <div className="mt-5">
                <span className="text-base font-bold">Package</span>
                <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        Package Plan
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {travelDetailsState.packageDesc}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        Travel Date
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {travelDetailsState.departure}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        No. of Travel of Days
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="text-sm">{travelDetailsState.days}</span>
                    </div>
                  </div>

                  {travelDetailsState.coverType === "asean" && (
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Origin
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">
                          {travelDetailsState.origin}
                        </span>
                      </div>
                    </div>
                  )}

                  {travelDetailsState.coverType === "international" && (
                    <div className="flex">
                      <div className="flex-1 text-left">
                        <span className="text-right text-sm font-bold">
                          Origin
                        </span>
                      </div>

                      <div className="flex-1 text-right">
                        <span className="text-sm">PHILIPPINES</span>
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        Travel Destination
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="text-sm">{destinations}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <span className="text-base font-bold">Premium</span>
                <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        Premium
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {formatter
                          .format(travelDetailsState.premium)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <span className="text-base font-bold">Person(s) Included</span>
                <div className="mt-2 bg-blue-50 p-3 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">
                        Number of Person/s
                      </span>
                    </div>

                    <div className="flex-1 text-right">
                      <span className="text-sm font-bold">
                        {travelDetailsState.totalPax}
                      </span>
                    </div>
                  </div>

                  {(() => {
                    const rows = [];
                    let cnt = 0;
                    for (let i = 1; i <= travelDetailsState.totalPax; i++) {
                      // for(let i=1; i<=1; i++){

                      rows.push(
                        <>
                          <div className="flex mt-4">
                            <div className="flex-1 text-left">
                              <span className="text-right text-sm font-bold">
                                Name
                              </span>
                            </div>

                            <div className="flex-1 text-right">
                              {/* <span className="text-sm font-bold">{assuredDetailsState.assured[0]?.fullName}</span> */}
                              <span className="text-sm font-bold">
                                {assuredDetailsState?.assured?.[cnt]?.fullName}
                              </span>
                            </div>
                          </div>

                          <div className="flex mt-2">
                            <div className="flex-1 text-left">
                              <span className="text-right text-sm font-bold">
                                Age
                              </span>
                            </div>

                            <div className="flex-1 text-right">
                              <span className="text-sm font-bold">
                                {assuredDetailsState?.assured?.[cnt]?.age}
                              </span>
                            </div>
                          </div>

                          <div className="flex mt-2">
                            <div className="flex-1 text-left">
                              <span className="text-right text-sm font-bold">
                                Primary
                              </span>
                            </div>

                            <div className="flex-1 text-right mb-3">
                              <span className="text-sm font-bold">
                                {assuredDetailsState?.assured?.[cnt]?.primary}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                      cnt = cnt + 1;
                    }
                    return rows;
                  })()}
                </div>
              </div>

              <hr className="mt-10" />

              <span className="text-base font-bold mt-10 block">
                Terms and Conditions
              </span>
              <div className="flex mt-5">
                <div className="px-5">
                  <input type="checkbox" name="terms" required />
                </div>
                <div>
                  {/* <p className="text-sm">
                    I have read{" "}
                    <span className="text-blue-500 font-bold">
                      {" "}
                      terms and agreements{" "}
                    </span>{" "}
                    of Standard Insurance Co., Inc.
                  </p> */}
                  <p className="text-sm">
                    I have read{" "}
                    <span className="text-blue-600 font-bold">
                      <a onClick={gotoTerms} style={{ cursor: "pointer" }}>
                        {" "}
                        terms and agreements
                      </a>{" "}
                    </span>
                    of Standard Insurance Co., Inc.
                  </p>
                </div>
              </div>

              <div className="relative flex mt-16">
                <div className="flex-1 flex">
                  <div className="pr-5">
                    <div className="h-10 w-10">
                      <img
                        src={"/images/help.png"}
                        alt="help"
                        className="covered-image"
                      />
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg> */}
                      {/* <svg xmlns="http://www.w3.org/2000/svg" 
                        fill="currentColor" 
                        className="bi bi-question-circle h-10 w-10 text-blue-500" 
                        viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg> */}
                    </div>
                  </div>
                  <div className="mb-24">
                    <h4 className="font-black text-left text-sm">Need help?</h4>
                    <p className="text-left text-xs text-gray-400">
                      Should you need any assistance or have any inquiries,
                      please contact{" "}
                      <span className="text-blue-600 font-bold">
                        ginsure@standard-insurance.com
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" style={{ display: "none" }} id="btnSubmit">
            submit
          </button>
        </form>
      </main>
      {/* {loading && <Loading />} */}
      <Footer
        buttonType="button"
        saveData={SubmitButton}
        isLoading={isLoading}
        isDisabled={isDisabled}
        buttonTitle={"CONFIRM AND PAY"}
      />
    </div>
  );
}

export default TravelReview;
