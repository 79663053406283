import Review from "../../components/organisms/Motor-Insurance/Review";

const MotorcarReview = () => {
    return (
     <>
        <Review />
     </>
    );
  };
  
  export default MotorcarReview;
  