import CTPLCoverSection from "../../components/organisms/CTPLCoverSection";
import Header from "../../components/organisms/Header";

const CTPLCoverPage = () => {
  return (
    <>
      <div>
        <Header  />
        <div
          className="absolute w-full"
          // style={{
          //   backgroundImage: `url("/images/bg.png")`,
          //   backgroundRepeat: "no-repeat",
          //   width: "250px",
          // }}
        >
          <img alt="motorcar" src={"/images/bg.png"} className="image" />
        </div>
        <div className={"main p-5 pt-12"}>
          <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
        </div>
        <main className={"main p-5"}>
          <CTPLCoverSection />
        </main>
      </div>
    </>
  );
};
export default CTPLCoverPage;
