import React from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const today = dayjs();

const maxDate = dayjs().subtract(56, "day");
const minDate = dayjs().subtract(9, "year");

const CustomDatePicker = ({ name, value, onChange, disabled, minDate }) => {
  const handleChange = (date) => {
    // Pass both the date and the name to the parent component
    onChange(name, date);
  };

  return (
    <DatePicker
      defaultValue={dayjs(value)}
      onChange={handleChange}
      disabled={disabled}
      minDate={minDate}
    />
  );
};

export default CustomDatePicker;
