import PetFaqList from "../Pet-Faq/Pet-FaqList";
import Header from "../Header";

const PetFaqPage = ({ ...props }) => {
  return (
    <div>
      <Header
        headerTitle={props.headerTitle}
        subHeader={props.subheaderTitle}
      />

      <div className="absolute w-full">
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className="p-5 pt-12">
        <img src={"/images/sici-logo.png"} width="170" height="50" alt="logo" />
      </div>
      <main className="p-5">
        <div className="pb-5 font-bold text-lg">
          <h3 className="mb-3">Frequently Asked Question</h3>
        </div>
        <PetFaqList />
      </main>
    </div>
  );
};

export default PetFaqPage;
