import React, { useState } from "react";

const FaqPolicyCTPL = () => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I make changes to my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <p className="text-sm">
              If you have any changes in your policy, please contact us at the
              following:
            </p>
            <p className="mt-3 text-sm">
              Mobile Hotline Number: (632) 917-828-3984 (Avail from 8am to 5pm)
            </p>
            <p className="text-sm">
              24-Hour Customer Hotline Number: (632) 8845-1111
            </p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
            <p className="text-sm mt-3">
              Any change or modification in your insured vehicle (i.e., engine
              no., color, etc.) must be declared in the policy. New details that
              may be found contrary to the policy that is not endorsed correctly
              may be used to deny the claim.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I renew my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              You may renew your policy using these available options:
            </p>

            <ul className="text-sm mt-3">
              <li>
                Register your policy at{" "}
                <a
                  href="https://bit.ly/MyStandardOnline"
                  target="_blank"
                  className="text-yellow-500"
                >
                  https://bit.ly/MyStandardOnline
                </a>
                . Registered policies get a renewal prompt 90 days before policy
                expiration.
              </li>
              <li>
                Call the Standard Insurance 24-Hour Customer Hotline at (632)
                8845-1111
              </li>
            </ul>

            <p className="mt-3 text-sm">
              Mobile Hotline Number: (632) 917-828-3984 (Avail from 8am to 5pm)
            </p>
            <p className="text-sm">
              24-Hour Customer Hotline Number: (632) 8845-1111
            </p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel3" className="hidden" />
        <label
          htmlFor="panel3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I request a refund or cancellation?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              You may request a refund and cancel your policy within seven (7)
              days after receiving it via email. All premiums, except the
              verification fee (Php50.40), shall be returned to your GCash
              wallet provided it is made before 12midnight of the same day. A
              representative of Standard Insurance shall process cancellation
              requests made after the cut-off. Refund shall be credited back to
              your nominated bank account.
            </p>

            <p className="mt-3 text-sm">
              Cancellation requests made after seven (7) days shall no longer be
              accepted, and CTPL will be deemed used. No premium shall be
              returned to you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPolicyCTPL;
