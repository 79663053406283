import { Cache } from "aws-amplify";
import { useEffect, useState } from "react";
import Header from "../../../components/organisms/Header";
import WizardCTPL from "../../../components/organisms/WizardCTPL";

const TravelFormSummary = (props) => {
  const { prop } = props

  const [destinations, setDestinations] = useState()

  const urlParams = new URLSearchParams(window.location.search);
  const cover = urlParams.get("type");


  useEffect(() => {
    let dest = props.travelDetailsState.destinations || [];
    let arr = [];
    dest.map((i, d) => {
      if (props.travelDetailsState.coverType === "asean" && d !== 0) {
        return arr.push(i.value)
      }
      if (props.travelDetailsState.coverType === "international") {
        return arr.push(i.value)
      }
    })

    setDestinations(arr.join(" - "))
  }, [destinations])

  //functions
  const SaveForm = (e) => {
    e.preventDefault();
    props.saveData()
  }
  return (
    <div>
      <main>
        {/* <WizardCTPL /> */}

        <h3 className="text-lg font-bold title-font">
        </h3>

        <div className="my-5">
          <form onSubmit={SaveForm}>
            <div>
              <div className="text-left mb-24">
                <div className="mb-5">
                  <h3 className="text-lg font-bold">
                    Kindly review the information below before proceeding
                  </h3>
                </div>
                <span className="inline-block text-gray-600 text-lg font-semibold text-sm mb-3">Travel Details</span>
                <div className="flex">

                  <div className="flex-2 text-left">
                    <span className="text-lg font-semibold block">{props.travelDetailsState.howAreYou}</span>
                  </div>
                  <div className="flex-1 text-right">
                    <button type="button" onClick={props.editTravelDetails}>
                      <span className="text-blue-500 font-bold text-xs">
                        Edit
                      </span>
                    </button>
                  </div>
                </div>

                <div className="mt-5">
                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Package Type
                      </label>
                      <span className="text-lg font-semibold block">
                        {props.travelDetailsState.groupType === "group" ?"Group":"Individual"}
                      </span>
                    </div>
                  </div>
                  {
                    props.travelDetailsState.coverType === "international" && (
                      <>
                        <div className="mb-3">
                          <div className="block relative w-full">
                            <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                              Hazardous Sports
                            </label>
                            <span className="text-lg font-semibold block">{props.travelDetailsState.hazardous ? "Yes" : "None"}</span>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="block relative w-full">
                            <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                              Sports Competition
                            </label>
                            <span className="text-lg font-semibold block">{props.travelDetailsState.sports ? "Yes" : "None"}</span>
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    props.travelDetailsState.coverType === "asean" && (
                      <div className="mb-3">
                        <div className="block relative w-full">
                          <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                            Origin
                          </label>
                          <span className="text-lg font-semibold block">
                            {props.travelDetailsState.origin}
                          </span>
                        </div>
                      </div>
                    )
                  }

                  {
                    props.travelDetailsState.coverType === "international" && (
                      <div className="mb-3">
                        <div className="block relative w-full">
                          <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                            Origin
                          </label>
                          <span className="text-lg font-semibold block">
                            PHILIPPINES
                          </span>
                        </div>
                      </div>
                    )
                  }


                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Destination
                      </label>
                      <span className="text-lg font-semibold block">
                        {destinations}
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Date of Departure
                      </label>
                      <span className="text-lg font-semibold block">{props.travelDetailsState.departure}</span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Date of Return
                      </label>
                      <span className="text-lg font-semibold block">
                        {props.travelDetailsState.arrival}
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Number of Passengers
                      </label>
                      <span className="text-lg font-semibold block">
                        {props.travelDetailsState.totalPax}
                      </span>
                    </div>
                  </div>
                  {
                    props.travelDetailsState.coverType === "asean" && (
                      <div className="mb-3">
                        <div className="block relative w-full">
                          <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                            Purpose of Travel
                          </label>
                          <span className="text-lg font-semibold block">

                            {
                              (() => {
                                let purpose = props.travelDetailsState.purposes
                                if (purpose === "LE") {
                                  return "Leisure";
                                } else if (purpose === "VA") {
                                  return "Vacation";
                                } else if (purpose === "HO") {
                                  return "Holiday";
                                } else if (purpose === "SC") {
                                  return "School / Field Trip";
                                } else if (purpose === "BU") {
                                  return "Business";
                                } else if (purpose === "CO") {
                                  return "Company Event / Outing";
                                } else if (purpose === "TR") {
                                  return "Training / Seminar / Conference";
                                } else {
                                  return "Study";
                                }
                              })()
                            }
                          </span>
                        </div>
                      </div>
                    )
                  }

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Package
                      </label>
                      <span className="text-lg font-semibold block">
                        {props.travelDetailsState.packageDesc}
                      </span>
                    </div>
                  </div>

                  <span className="inline-block text-gray-600 text-lg font-semibold mt-10 mb-5">Assured Details</span>
                  {(() => {
                    const rows = [];
                    let cnt = 0;
                    for (let i = 1; i <= props.travelDetailsState.totalPax; i++) {
                      // for(let i=1; i<=1; i++){

                      rows.push(
                        <div>
                          <div className="flex mb-3 mt-3">
                            <div className="flex-1 text-left">
                              {/* <span className="font-semibold">Primary Traveler</span> */}
                              {(i==1)?<span className="font-semibold">Primary Traveler</span>:<span className="font-semibold">Traveler {i}</span>}
                            </div>

                          </div>

                          <div className="mb-3 ml-3">
                            <div className="block relative w-full">
                              <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                Full Name
                              </label>
                              <span className="text-lg font-semibold block">{props.assuredDetailsState.assured[cnt].fullName}</span>
                            </div>
                          </div>

                          <div className="mb-3 ml-3">
                            <div className="block relative w-full">
                              <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                Birthday
                              </label>
                              <span className="text-lg font-semibold block">
                                {props.assuredDetailsState.assured[cnt].bday}
                              </span>
                            </div>
                          </div>
                          {
                            props.travelDetailsState.coverType === "international" && (
                              <div className="mb-3 ml-3">
                                <div className="block relative w-full">
                                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                    Passport Number
                                  </label>
                                  <span className="text-lg font-semibold block">
                                    {props.assuredDetailsState.assured[cnt].passport}
                                  </span>
                                </div>
                              </div>
                            )
                          }


                          {/* <div className="mb-3">
                            <div className="block relative w-full">
                              <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                Beneficiary
                              </label>
                              <span className="text-lg font-semibold block">
                              COMPULSARY HEIRS
                              </span>
                            </div>
                          </div> */}
                          { i === 1 && (
                            <>
                              <div className="mb-3 ml-3">
                                <div className="block relative w-full">
                                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                    Civil Status
                                  </label>
                                  <span className="text-lg font-semibold block">
                                    {
                                      (() => {
                                        let stat = props.assuredDetailsState.assured[i-1].maritalStatus
                                        if (stat === "S") {
                                          return "Single";
                                        } else if (stat === "M") {
                                          return "Married";
                                        } else if (stat === "W") {
                                          return "Widowed";
                                        } else {
                                          return "Separated";
                                        }
                                      })()
                                    }
                                  </span>
                                </div>
                              </div>

                              <div className="mb-3 ml-3">
                                <div className="block relative w-full">
                                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                    Gender
                                  </label>
                                  <span className="text-lg font-semibold block">
                                    {props.assuredDetailsState.assured[cnt].gender === "M" ? "Male" : "Female"}
                                  </span>
                                </div>
                              </div>

                              <div className="mb-3 ml-3">
                                <div className="block relative w-full">
                                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                    Email Address
                                  </label>
                                  <span className="text-lg font-semibold block">
                                    {props.assuredDetailsState.assured[cnt].email}
                                  </span>
                                </div>
                              </div>

                              <div className="mb-3 ml-3">
                                <div className="block relative w-full">
                                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                    Contact Number
                                  </label>
                                  <span className="text-lg font-semibold block">
                                    {props.assuredDetailsState.assured[cnt].contactNo}
                                  </span>
                                </div>
                              </div>

                             
                            </>
                            )
                          }
                           <div className="mb-3 ml-3">
                                <div className="block relative w-full">
                                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                                  With pre-existing condition/s
                                  </label>
                                  <span className="text-lg font-semibold block">
                                   {(props.assuredDetailsState.assured[cnt].preexFlag=="Y")?"Yes":"No"}
                                  </span>
                                </div>
                              </div>
                        </div>
                      )
                      cnt = cnt+1;
                    }
                    return rows;
                  })()}
                  {/*  */}
                  <br />
                  <div className="">
                    <div className="flex my-1">
                      <div className="pr-3">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          required
                        // onChange={handleDataConfirmation}
                        />
                      </div>
                      <div>
                        <p className="text-xs">
                          I agree to share my personal information to Standard Insurance for the purpsose of processing my application and managing my policy.
                        </p>

                        <p className="text-xs mt-3">
                          For more information on how Standard Insurance protects its
                          data, you may visit our Privacy Policy at{" "}
                          <a
                            className={"text-blue-500"}
                            href="https://standard-insurance.com/privacy-policy.html"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://standard-insurance.com/privacy-policy.html
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/*  */}

                  <div className="mt-16">
                    <p className="block text-center text-xs text-gray-400">
                      By clicking this button, I agree to share my personal
                      information to Standard Insurance for the purpose of
                      processing my application and managing my policy.
                    </p>

                    <br />
                    {/* <p>
                      For more information on how Standard Insurance protect its
                      data, you may visit our Privacy Policy at{" "}
                      <a className="text-blue-700">
                        {" "}
                        https://www.standard-insurance.com
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" style={{ display: "none" }} id="btnSubmit">
              submit
            </button>

          </form>
        </div>
      </main>
    </div>
  );
};

export default TravelFormSummary;
