import { useEffect } from "react";
import { useState } from "react";
import { Cache } from "aws-amplify";
import Modal from "../../molecules/Modal";
import Footer from "../../organisms/Footer";
import {
  manageAPI,
  basePath,
  authAPI,
  authDetails,
  travelLocalAPI,
  travelAPI,
  petAPI
} from "../../../api/fetch-api";
import { Loading } from "../../atoms/loading";
import Header from "../Header";
import { useHistory } from "react-router-dom";

export default function CancelPolicy(props) {
  const [reason, setReason] = useState("");
  const [open, setOpen] = useState(false);
  const [openCancelled, setOpenCancelled] = useState(false);
  const [polStatus, setPolStatus] = useState("A");
  const [productTypeID, setProductTypeID] = useState(
    Cache.getItem("productType")
  );
  let history = useHistory();
  const handleReason = (event) => {
    console.log(event.target.value);
    setReason(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(1);
  useEffect(() => {
    const customerID = Cache.getItem("customerId")
      .replaceAll("+", " ")
      .replaceAll(" ", "%2B");
    const productType = Cache.getItem("productType");
    if (productType === "SITRVL01" || productType === "SITRVL02") {
      if (productType === "SITRVL02") {
        var params = {
          unid: Cache.getItem("unid"),
          email: Cache.getItem("emailAddress"),
        };
        travelLocalAPI
          .getLocalTravelPolicy(params)
          .then((data) => {
            setTimeout(() => {
              setIsLoading(0);
            }, 500);
            setPolStatus(data.status);
            if (data.status === "R" || data.status === "C") {
              setPolStatus(data.status);
              setTimeout(() => {
                setOpenCancelled(true);
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
          });
      } //If travel ASEAN
      else if (productType === "SITRVL01") {
        var params = {
          unid: Cache.getItem("unid"),
          email: Cache.getItem("emailAddress"),
        };
        travelAPI
          .getTravelPolicy(params)
          .then((data) => {
            setTimeout(() => {
              setIsLoading(0);
            }, 500);
            //CANCEL STATUS CHECKER
            if (data.status === "R" || data.status === "C") {
              setPolStatus(data.status);
              setTimeout(() => {
                setOpenCancelled(true);
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
          });
      }
    }
    else if(productType==="SIPETI01"){
      petAPI
        .getPetQuotation(Cache.getItem("unid"),customerID)
        .then((data) => {
          setTimeout(() => {
            setIsLoading(0);
          }, 500);
          //CANCEL STATUS CHECKER
          if (data.status === "R" || data.status === "C") {
            setPolStatus(data.status);
            setTimeout(() => {
              setOpenCancelled(true);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("There was an error!");
        });
    } 
    else {
      authAPI
        .getAuth(authDetails)
        .then((data1) => {
          Cache.setItem("ginsure_token", data1.token);
          manageAPI
            .getPolicy(Cache.getItem("unid"), customerID, data1.token)
            .then((data) => {
              setTimeout(() => {
                setIsLoading(0);
              }, 500);
              //CANCEL STATUS CHECKER
              if (data.status === "R" || data.status === "C") {
                setPolStatus(data.status);
                setTimeout(() => {
                  setOpenCancelled(true);
                }, 700);
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
        })
        .catch((error) => {
          console.log(error);
          console.log("There was an error!");
        });
    }
  }, []);
  //Add Loading
  const cancel = () => {
    const token_id = Cache.getItem("ginsure_token");
    const params = {
      insuranceAccountId: Cache.getItem("customerId"), // dynamic
      reason: reason,
      unid: Cache.getItem("unid"), //dynamic
    };
    setIsLoading(1);
    fetch(basePath.baseUrl + "/support/cancellation", {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        Authorization: token_id,
      },
    })
      .then((response) => {
        let response_status = response.status;
        if (response_status == 200) {
          setTimeout(() => {
            setIsLoading(0);
          }, 200);
          setTimeout(() => {
            setOpen(true);
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("There was an error!");
      });
  };
  const backToManagement = () => {
    history.goBack();
  };

  return (
    <>
      <Header headerTitle={props.headerTitle} />
      {!isLoading || <Loading />}
      {!isLoading && (
        <>
          <div className="p-5">
            {productTypeID !== "SITRVL01" && productTypeID !== "SITRVL02" && (
              <>
                <h3 className="font-bold text-lg title-font">
                  We're sad to see you go
                </h3>
                <p className="mt-5 mb-10 text-sm manage-policy-font">
                  Before you go please tell us the reason for cancelling.
                </p>
              </>
            )}

            {(productTypeID === "SITRVL01" || productTypeID === "SITRVL02") && (
              <>
                <h3 className="font-bold text-lg title-font">
                  We are sorry you have to cancel your policy.
                </h3>
                <p className="mt-5 mb-10 text-sm manage-policy-font">
                  We will appreciate you sharing with us your 
                  reason for cancellation. Thank you
                </p>
              </>
            )}

            <h3 className="text-base mb-1 manage-policy-font">
              Why are you cancelling?
            </h3>
            <textarea
              className="input-form input-textarea text-sm"
              type="text"
              name="message"
              maxLength="200"
              onChange={handleReason}
              defaultValue={reason}
              required
            />
            {productTypeID !== "SITRVL01" && productTypeID !== "SITRVL02" && (
              <p className="text-xs font-bold text-gray-700 mt-5 manage-policy-font">
                Note: Your request to cancel your policy will be handled by our
                representative. Kindly wait for them to contact you to assist
                you further.
              </p>
            )}
            {(productTypeID === "SITRVL01" || productTypeID === "SITRVL02") && (
              <>
                <p className="text-xs font-bold text-gray-700 mt-5 manage-policy-font">
                  Note:{" "}
                </p>{" "}
                <p className="text-xs font-bold text-gray-700 mt-5 manage-policy-font">
                  Our associate will process your request to cancel your policy.
                  They will contact you to assist you further. Thank you.
                </p>
              </>
            )}
          </div>
          <Modal
            open={open}
            setOpen={setOpen}
            title=""
            buttonTitle={"BACK TO POLICY MANAGEMENT"}
            saveData={backToManagement}
            disableButton={backToManagement}
          >
            {polStatus !== "R" && (
              <div>
                <p className="font-bold mb-5 text-center title-font">
                  We have received your request to
                  <br />
                  cancel your policy.
                </p>
                <p className="text-xs text-center">
                  Kindly wait for an email or a representative
                  <br />
                  to contact you for an update.
                </p>
              </div>
            )}
          </Modal>
          <Modal
            open={openCancelled}
            setOpen={setOpenCancelled}
            title=""
            buttonTitle={"CLOSE"}
            saveData={backToManagement}
            disableButton={backToManagement}
          >
            <div>
              <p className="font-bold mb-5 title-font">
                Your request to cancel your policy 
                has already been submitted.
              </p>
              <p className="text-xs manage-policy-font">
                For concerns, please contact us at:
              </p>
              <p className="mt-5 text-xs manage-policy-font">
                8am to 5pm: (917) 828-3984
                <br />
                5pm to 8am: (632) 8845-1111
                <br />
                Email: ginsure@standard-insurance.com
              </p>
            </div>
          </Modal>
        </>
      )}

      <Footer
        buttonType="button"
        saveData={cancel}
        isLoading={isLoading}
        // isDisabled={isDisabled}
        buttonTitle={"SUBMIT"}
      />
    </>
  );
}
