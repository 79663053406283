import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import { useLocation } from 'react-router-dom';

function LayoutPlain(props) {
  const location = useLocation();
  
  console.log(location.pathname);
  const title = (props.GATitle!==undefined)?props.GATitle:"";
  if(title!=""){
    props.sendGTM(String(location.pathname),props.GATitle);
  }
  
  return (
    <>
      {props.children}
    </>
  );
}

export default LayoutPlain;
