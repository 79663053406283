import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import logo from "./logo.svg";
import { Route, Switch } from "react-router-dom";
import "./index.css";
import Layout from "./components/templates/Layout";

import MainPage from "./pages/MainPage";
import PlateNumber from "./pages/motor-insurance/plate-number";
import CarDetails from "./pages/motor-insurance/car-details";
import MotorcarQuotation from "./pages/motor-insurance/quotation";
import MotorcarOtherDetails from "./pages/motor-insurance/car-other-details";
import MotorcarSummary from "./pages/motor-insurance/summary.js";
import MotorcarReview from "./pages/motor-insurance/review.js";
import Wizard from "./pages/motor-insurance/step-wizard.js";
import LayoutNoFooter from "./components/templates/LayoutNoFooter";
import FormPage from "./pages/motor-insurance/form";
import LayoutPlain from "./components/templates/LayoutPlain";
import FaqPage from "./pages/FaqPage";
import FaqPageTravel from "./pages/FaqPageTravel";
import ContactUsPage from "./pages/ContactUsPage";
import Inclusions from "./pages/Inclusions";
import ExclusionPage from "./pages/Exclusions";
import PaymentSuccess from "./components/organisms/Payment-Success";
import ComingSoon from "./pages/ComingSoon";
import Maintenance from "./pages/Maintenance";
//ManagePolicy
import ManagePolicy from "./pages/ManagePolicy";
import CancelPage from "./components/organisms/ManagePolicy/CancelPolicy";
import PolicyList from "./components/organisms/ManagePolicy/PolicyList";
import PageNotFound from "./pages/404";
import PageError from "./pages/500";

import MainPageTravel from "./pages/MainPageTravel";
import { Loading } from "./components/atoms/loading.js";
// import InitialPage from "./pages/InitialPage";
//CTPL
// import CTPLForm from "./pages/ctpl/packages";
// import CTPLFormReview from "./pages/ctpl/review";
// import CTPLFormCarDetails from "./pages/ctpl/car-details";
// import CTPLFormOwnerDetails from "./pages/ctpl/owner-details";
// import CTPLFormSummary from "./pages/ctpl/summary";
import MainPageCTPL from "./pages/MainPageCTPL";
import FaqPageCTPL from "./pages/FaqPageCTPL";
//CTPL
import CTPLForm from "./pages/ctpl/packages";
import CTPLFormReview from "./pages/ctpl/review";
import CTPLFormCarDetails from "./pages/ctpl/form";
import CTPLCoverPage from "./pages/ctpl/covered";

//Travel
import TravelIndex from "./pages/travel";
import TravelForm from "./pages/travel/form";
import TravelAssuredDetails from "./pages/travel/assured";
import TravelFormSummary from "./pages/travel/summary";
import TravelFormReview from "./pages/travel/review";
import TravelCovers from "./pages/travel/covered";
import CovidPage from "./pages/Covid";
import TravelBenefits from "./components/organisms/Travel-Insurance/Benefits";

//Pet
import PetIndex from "./pages/pet-insurance/index";
import PetInsurance from "./pages/pet-insurance/covered";
import PetReview from "./pages/pet-insurance/review";
import PetInsuranceFormsPage from "./pages/pet-insurance/pet-forms";
import PetFaq from "./pages/pet-insurance/pet-faq";
import CTPLCoverType from "./components/organisms/CTPL/cover-type";

import { openlAPI } from "./api/fetch-api.js";
import { Height } from "@mui/icons-material";

const tagManagerArgs = {
  gtmId: "GTM-MGF68PX",
};

// const getProductStatus = (product) => {
//   console.log("PRODUCT", product);
//   const statusEntry = checker.find(
//     (item) => item.type === product && item.module === "ginsure"
//   );

//   console.log("statusEntry", statusEntry);

//   return statusEntry
//     ? {
//         isAvailable: statusEntry.status === "on",
//         message: statusEntry.message || null,
//       }
//     : { isAvailable: false, message: null };
// };

const sendGTM = (path, pageTitle, product, eventName) => {
  if (
    product != "" &&
    product != undefined &&
    eventName !== undefined &&
    eventName !== ""
  ) {
    window.dataLayer.push({
      event: eventName, //"virtualPage", //eventTrigger
      pageUrl: window.location.hostname + path,
      product: product,
      pageTitle: pageTitle,
    });
    console.log("GTM:", path, " | ", pageTitle, " | ", product);
  }
};

async function getProductStatus(module, type) {
  const postData = {
    System: module,
    productLOB: type,
  };

  try {
    const data = await openlAPI.getDisabledPage(postData);
    console.log("DATA", data);
    return {
      isAvailable: data.Status === "On",
      message: data.Message,
    };
  } catch (error) {
    console.error("Error: ", error);
    return { isAvailable: false, message: "Error fetching data" };
  }
};

function App() {
  TagManager.initialize(tagManagerArgs);
  // const motorStatus = getProductStatus("Motor");
  // const travelStatus = getProductStatus("Travel");

  // const ctplStatus = getProductStatus("CTPL");
  // console.log("ctpl", ctplStatus);
  // const petStatus = getProductStatus("Pet");

  const [motorStatus, setMotorStatus] = useState({
    isAvailable: false,
    message: "",
  });
  const [travelStatus, setTravelStatus] = useState({
    isAvailable: false,
    message: "",
  });
  const [ctplStatus, setCtplStatus] = useState({
    isAvailable: false,
    message: "",
  });
  const [petStatus, setPetStatus] = useState({
    isAvailable: false,
    message: "",
  });

  const [loading, setLoading] = useState(true); // Loading state
  // const [travelStatus, setTravelStatus] = useState({
  //   isAvailable: false,
  //   message: "",
  // });

  useEffect(() => {
    const fetchStatus = async () => {
      setLoading(true); // Set loading to true when fetching starts

      const motor = await getProductStatus("GINSURE", "Motor");
      const travel = await getProductStatus("GINSURE", "Travel");
      const ctpl = await getProductStatus("GINSURE", "CTPL");
      const pet = await getProductStatus("GINSURE", "Pet");

      setMotorStatus(motor);
      setTravelStatus(travel);
      setCtplStatus(ctpl);
      setPetStatus(pet);

      setLoading(false);
    };

    fetchStatus();
  }, []);

  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <LayoutPlain
            main="main"
            buttonType="1"
            link="/motor-insurance/form"
            GATitle={"MotorCoverPage"}
            sendGTM={sendGTM}
          >
            {loading ? ( // Check loading state
              <Loading />
            ) : motorStatus.isAvailable ? (
              <Maintenance
                headerTitle="Standard Insurance"
                message={motorStatus.message}
              />
            ) : (
              <MainPage headerTitle="GCash x Standard Insurance" />
            )}
          </LayoutPlain>
        </Route>

        {/* Pages Motor Insurance */}
        <Route path="/motor-insurance/plate-number" exact>
          <LayoutPlain
            headerTitle="Motorcar details"
            GATitle={"MotorFormsPlatenumber"}
            sendGTM={sendGTM}
          >
            <PlateNumber />
          </LayoutPlain>
        </Route>

        <Route path="/motor-insurance/car-details" exact>
          <Layout
            main="car-details"
            link="/motor-insurance/quotation"
            headerTitle="Motorcar details"
            GATitle={"MotorFormsCardetails"}
            sendGTM={sendGTM}
          >
            <CarDetails />
          </Layout>
        </Route>

        <Route path="/motor-insurance/quotation" exact>
          <Layout
            main="quotation"
            link="/motor-insurance/other-details"
            headerTitle="Premium Breakdown"
            GATitle={"MotorFormsQuotation"}
            sendGTM={sendGTM}
          >
            <MotorcarQuotation />
          </Layout>
        </Route>

        <Route path="/motor-insurance/other-details" exact>
          <Layout
            main="other-details"
            link="/motor-insurance/summary"
            headerTitle="Other Details"
            GATitle={"MotorFormsOtherDetails"}
            sendGTM={sendGTM}
          >
            <MotorcarOtherDetails />
          </Layout>
        </Route>

        <Route path="/motor-insurance/summary" exact>
          <Layout
            main="summary"
            headerTitle="Summary"
            GATitle={"MotorFormsSummary"}
            sendGTM={sendGTM}
          >
            <MotorcarSummary />
          </Layout>
        </Route>

        <Route path="/motor-insurance/step-wizard" exact>
          <Layout
            main="step-wizard"
            buttonType="1"
            GATitle={""}
            sendGTM={sendGTM}
          >
            <Wizard />
          </Layout>
        </Route>

        <Route path="/motor-insurance/review" exact>
          <LayoutPlain GATitle={"MotorFormsReview"} sendGTM={sendGTM}>
            <MotorcarReview />
          </LayoutPlain>
        </Route>

        <Route path="/motor-insurance/form" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <FormPage sendGTM={sendGTM} />
          </LayoutPlain>
        </Route>

        {/* subpages */}

        <Route path="/faq" exact>
          <LayoutPlain GATitle={"FAQsPages"} sendGTM={sendGTM} main="summary">
            <FaqPage
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="FAQs"
            />
          </LayoutPlain>
        </Route>

        {/* asean/intl faq */}
        <Route path="/faq/:id" exact>
          <LayoutPlain
            GATitle={"TravelFAQsPages"}
            sendGTM={sendGTM}
            main="summary"
          >
            <FaqPageTravel
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="FAQs"
            />
          </LayoutPlain>
        </Route>

        <Route path="/contact" exact>
          <LayoutPlain GATitle={"ContactFormPage"} sendGTM={sendGTM}>
            <ContactUsPage
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Contact Us"
            />
          </LayoutPlain>
        </Route>

        <Route path="/exclusions" exact>
          <LayoutPlain GATitle={"CoverExclusions"} sendGTM={sendGTM}>
            <ExclusionPage
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Exclusions"
            />
          </LayoutPlain>
        </Route>

        <Route path="/inclusions" exact>
          <LayoutPlain GATitle={"CoverInclusions"} sendGTM={sendGTM}>
            <Inclusions
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Inclusions"
            />
          </LayoutPlain>
        </Route>
        <Route path="/payment-success" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <PaymentSuccess
              headerTitle="Standard Insurance"
              sendGTM={sendGTM}
            />
          </LayoutPlain>
        </Route>

        <Route path="/coming-soon" exact>
          <ComingSoon headerTitle="Standard Insurance" />
        </Route>
        <Route path="/manage-policy/:id/cancel-request" exact>
          <CancelPage headerTitle="Cancel Policy" />
        </Route>
        <Route path="/manage-policy/:id">
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <ManagePolicy headerTitle="Policy Page" sendGTM={sendGTM} />
          </LayoutPlain>
        </Route>

        <Route path="/manage-policy/policy-list" exact>
          <PolicyList headerTitle="Policy Page" />
        </Route>
        <Route path="/500" exact>
          <PageError headerTitle="500" />
        </Route>

        <Route path="/404" exact>
          <PageNotFound headerTitle="404" />
        </Route>

        {/* <Route path="/ctpl" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM} main="main">
            <InitialPage headerTitle="GCash x Standard Insurance" />
          </LayoutPlain>
        </Route> 
        
        <Route path="/ctpl/packages" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM} main="main">
            <CTPLForm headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>


        <Route path="/ctpl/car-details" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <CTPLFormCarDetails headerTitle="Motorcar Details" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/owner-details" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <CTPLFormOwnerDetails headerTitle="Owner Details" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/summary" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <CTPLFormSummary headerTitle="Summary" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/review" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <CTPLFormReview headerTitle="Review" />
          </LayoutPlain>
        </Route> */}

        {/*  */}
        <Route path="/ctpl/" exact>
          <LayoutPlain
            GATitle={"CTPLMainCover"}
            sendGTM={sendGTM}
            main="main"
            buttonType="1"
          >
            {loading ? ( // Check loading state
              <Loading />
            ) : ctplStatus.isAvailable ? (
              <Maintenance
                headerTitle="Standard Insurance"
                message={ctplStatus.message}
              />
            ) : (
              // <Loading />
              <MainPageCTPL headerTitle="GCash x Standard Insurance" />
              // <Loading />
            )}
          </LayoutPlain>
        </Route>
        <Route path="/ctpl/type" exact>
          {/* <LayoutPlain GATitle={""} sendGTM={sendGTM} main="main">
            <InitialPage headerTitle="Gcash x Standard Insurance" />
          </LayoutPlain> */}
          <LayoutPlain
            GATitle={"CTPLCoverTypes"}
            sendGTM={sendGTM}
            main="main"
            buttonType="1"
          >
            <CTPLCoverType headerTitle="GCash x Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/type" exact>
          {/* <LayoutPlain GATitle={""} sendGTM={sendGTM} main="main">
            <InitialPage headerTitle="Gcash x Standard Insurance" />
          </LayoutPlain> */}
          <LayoutPlain
            GATitle={"CTPLCoverTypes"}
            sendGTM={sendGTM}
            main="main"
            buttonType="1"
          >
            <CTPLCoverType headerTitle="GCash x Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/faq" exact>
          <LayoutPlain GATitle={"CTPLFAQPage"} sendGTM={sendGTM} main="summary">
            <FaqPageCTPL
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="FAQs"
            />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/packages" exact>
          <LayoutPlain
            GATitle={"CTPLFormPackagesPage"}
            sendGTM={sendGTM}
            main="main"
          >
            <CTPLForm headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/form" exact>
          <LayoutPlain sendGTM={sendGTM}>
            <CTPLFormCarDetails
              headerTitle="Motorcar Details"
              sendGTM={sendGTM}
            />
          </LayoutPlain>
        </Route>
        <Route path="/ctpl/review" exact>
          <LayoutPlain GATitle={"CTPLFormsReview"} sendGTM={sendGTM}>
            <CTPLFormReview headerTitle="Review" />
          </LayoutPlain>
        </Route>

        <Route path="/ctpl/covered" exact>
          <LayoutPlain GATitle={"CTPLCoveredPage"} sendGTM={sendGTM}>
            <CTPLCoverPage headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>
        {/*  */}

        <Route path="/travel/:id" exact>
          <LayoutPlain GATitle={"TravelMainCover"} sendGTM={sendGTM}>
            {/* <Maintenance headerTitle="Standard Insurance" /> */}
            {/* <TravelIndex headerTitle="GCash X Standard Insurance" />   */}

            {loading ? ( // Check loading state
              <Loading />
            ) : travelStatus.isAvailable ? (
              <Maintenance
                headerTitle="Standard Insurance"
                message={travelStatus.message}
              />
            ) : (
              <MainPageTravel headerTitle="GCash X Standard Insurance" />
            )}
          </LayoutPlain>
        </Route>

        <Route path="/travel/:id/cover" exact>
          <LayoutPlain GATitle={"TravelCoveredPages"} sendGTM={sendGTM}>
            <TravelCovers headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/travel/:id/form" exact>
          <LayoutPlain>
            <TravelForm headerTitle="Standard Insurance" sendGTM={sendGTM} />
          </LayoutPlain>
        </Route>

        <Route path="/travel/assured" exact>
          <LayoutPlain GATitle={"TravelFormsAssuredDetails"} sendGTM={sendGTM}>
            <TravelAssuredDetails headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/travel/summary" exact>
          <LayoutPlain GATitle={"TravelFormsSummary"} sendGTM={sendGTM}>
            <TravelFormSummary headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/travel/:id/review" exact>
          <LayoutPlain GATitle={"TravelFormsReview"} sendGTM={sendGTM}>
            <TravelFormReview headerTitle="Standard Insurance" />
          </LayoutPlain>
        </Route>

        <Route path="/covid" exact>
          <LayoutPlain GATitle={"TravelCovidPage"} sendGTM={sendGTM}>
            <CovidPage
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="COVID-19 Coverage"
            />
          </LayoutPlain>
        </Route>

        <Route path="/travel/:id/benefits" exact>
          <LayoutPlain GATitle={"TravelBenefitsPage"} sendGTM={sendGTM}>
            <TravelBenefits
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Travel Benefits"
            />
          </LayoutPlain>
        </Route>

        <Route path="/pet-insurance/" exact>
          <LayoutPlain
            GATitle={"PetMainCover"}
            sendGTM={sendGTM}
            main="main"
            buttonType="1"
            link="/pet-insurance/covered"
          >
            {loading ? ( // Check loading state
              <Loading />
            ) : petStatus.isAvailable ? (
              <Maintenance
                headerTitle="Standard Insurance"
                message={petStatus.message}
              />
            ) : (
              // <Loading />
              <PetIndex headerTitle="GCash x Standard Insurance" />
              // <Loading />
            )}
          </LayoutPlain>
        </Route>

        <Route path="/pet-insurance/covered" exact>
          <LayoutPlain GATitle={"PetCoveredPage"} sendGTM={sendGTM}>
            {/* <Maintenance headerTitle="Standard Insurance" /> */}
            <PetInsurance
              headerTitle="GCash x Standard Insurance"
              subheaderTitle=""
            />
          </LayoutPlain>
        </Route>
        <Route path="/pet-insurance/forms" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <PetInsuranceFormsPage
              headerTitle="GCash x Standard Insurance"
              subheaderTitle=""
              sendGTM={sendGTM}
            />
          </LayoutPlain>
        </Route>
        {/* <Route path="/pet-insurance/details" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <PetDetails
              headerTitle="GCash x Standard Insurance"
              subheaderTitle=""
            />
          </LayoutPlain>
        </Route>

        <Route path="/pet-insurance/quotation" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <PetQuotation
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Quotation"
            />
          </LayoutPlain>
        </Route>

        <Route path="/pet-insurance/other-details" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <PetOtherDetails
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Other Details"
            />
          </LayoutPlain>
        </Route>

        <Route path="/pet-insurance/client-details" exact>
          <LayoutPlain GATitle={""} sendGTM={sendGTM}>
            <PetClientDetails
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Client Details"
            />
          </LayoutPlain>
        </Route>

        <Route path="/pet-insurance/summary" exact>
        <Layout main="summary" headerTitle="Summary">
            <PetSummary
              headerTitle="GCash x Standard Insurance"
              subheaderTitle=""
            />
          </Layout>
        </Route> */}

        <Route path="/pet-insurance/review" exact>
          <LayoutPlain GATitle={"PetFormsReview"} sendGTM={sendGTM}>
            <PetReview
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="Review"
            />
          </LayoutPlain>
        </Route>

        <Route path="/pet-faq" exact>
          <LayoutPlain GATitle={"PetFAQPage"} sendGTM={sendGTM} main="faq">
            <PetFaq
              headerTitle="GCash x Standard Insurance"
              subheaderTitle="FAQs"
            />
          </LayoutPlain>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
