import { useLocation } from "react-router-dom";
import Header from "../components/organisms/Header";
import ExclusionList from "../components/organisms/Motor-Covered/Exclusion-List";
import ExclusionListPet from "../components/organisms/Pet-Covered/Exclusion-List";

const ExclusionPage = ({...props}) => {
  const location = useLocation();

  const ExclusionDetails = () => {
    //console.log('pet',product)
    if (location.state.lob == "motor") {
      return <ExclusionList />;
    } else if (location.state.lob == "pet") {
      return <ExclusionListPet />;
    }
  };
  return (
    <div>
      <Header headerTitle={props.headerTitle} subHeader={props.subheaderTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image"/>
      </div>
      <div className="p-5 pt-12">
        <img
          src={"/images/sici-logo.png"}
          width="170"
          height="50"
          alt="logo"
        />
      </div>
      <main className="p-5">
        <div className="">
          <ExclusionDetails />
        </div>
      </main>
    </div>
  );
};
export default ExclusionPage;
