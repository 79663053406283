import Modal from "../../molecules/Modal";
import { useState, useEffect } from "react";
import { motorAPI } from "../../../api/fetch-api";

const CarDetails = (props) => {
  const carDetails = props.carDetails;
  const years = [];
  var lowerYear = new Date().getFullYear() - 14; // Lower bound: Current year - 13
  var upperYear = new Date().getFullYear() + 1; // Upper bound: Current year + 1
  while (upperYear >= lowerYear) {
    years.push(upperYear);
    upperYear--;
  }

  const [loading, setLoading] = useState(true);
  const [noModel, setNoModel] = useState(false);
  const [makelist, setMakelist] = useState([]);
  useEffect(() => {
    motorAPI
      .getMakeList()
      .then((data) => {
        setLoading(false);
        setMakelist(data);
      })
      .catch((err) => console.log("FMV Error: ", err));
    if (year !== "" && make !== "") {
      setTimeout(() => {
        GetModelList(make, year);
      }, 700);
    }
  }, []);

  const [loadingModel, setLoadingModel] = useState(true);
  const [modellist, setModellist] = useState([]);

  const GetModelList = (make, year) => {
    //setMakelist([]);
    setLoadingModel(true);
    setNoModel(false);
    let data = {
      year: year,
      manufacturer: make,
    };
    motorAPI
      .getModelList(data)
      .then((data) => {
        setModellist(data);
        setTimeout(() => {
          setLoadingModel(false);
        }, 100);
      })
      .catch((err) => {
        console.log("Model Error: ", err);
        setNoModel(true);
        setTimeout(() => {
          setLoadingModel(false);
        }, 100);
      });
  };
  const [year, setYear] = useState(carDetails.year);
  const [make, setMake] = useState(carDetails.make);
  const [model, setModel] = useState(carDetails.model);
  const [modelid, setModelid] = useState(carDetails.modelid);
  const [engineType, setEngineType] = useState(carDetails.engineType);
  const [fmv, setFmv] = useState(carDetails.fmv);
  const [bodyType, setBodyType] = useState(carDetails.bodyType);
  const [seats, setSeats] = useState(carDetails.seats);
  const [carDisplay, setCarDisplay] = useState(carDetails.carDisplay);
  // const [color, setColor] = useState(carDetails.color);
  // const [chassisNumber, setChassisNumber] = useState(carDetails.chassisNumber);
  // const [engineNumber, setEngineNumber] = useState(carDetails.engineNumber);
  // const [open, setOpen] = useState(false);
  // const [openEngine, setOpenEngine] = useState(false);

  const handleUpdates = (e) => {
    let objName = e.target.name;
    if (objName === "year") {
      setYear(e.target.value);
    } else if (objName === "make") {
      setMake(e.target.value);
    } else if (objName === "model") {
      setModelid(e.target.value);
      var index = e.target.selectedIndex;
      setModel(e.target[index].text);
      setEngineType(e.target[index].dataset.enginetype);
      setFmv(e.target[index].dataset.fmv);
      setBodyType(e.target[index].dataset.bodytype);
      setSeats(e.target[index].dataset.seats);
      setCarDisplay(
        e.target[index].dataset.model +
          " " +
          (e.target[index].dataset.variant != null
            ? e.target[index].dataset.variant
            : "") +
          " " +
          e.target[index].dataset.transmission +
          " " +
          e.target[index].dataset.enginedispl
      );
    }
    
    //GetModelList(make, year);
  };

  useEffect(() => {
    setYear(year);
    // if (year !== "" && make !== "") {
    //   GetModelList(make, year);
    // }
    if (year === undefined && make === undefined){
      //do nothing
    }else if(year === "" && make === "") {
      //do nothing
    }else {
      GetModelList(make, year);
    }
    
  }, [year]);
  useEffect(() => {
    setMake(make);
    // if (year !== "" && make !== "") {
    //   GetModelList(make, year);
    // }
    if (year === undefined && make === undefined){
      //do nothing
    }else if(year === "" && make === "") {
      //do nothing
    }else {
      GetModelList(make, year);
    }

  }, [make]);

  const SaveForm = (e) => {
    e.preventDefault();
    const carDetailsData = {
      year: year,
      make: make,
      model: model,
      modelid: modelid,
      engineType: engineType,
      fmv: fmv,
      bodyType: bodyType,
      seats: seats,
      carDisplay: carDisplay,
      // color: color,
      // chassisNumber: chassisNumber,
      // engineNumber: engineNumber,
    };
    props.saveData(carDetailsData);
  };
  // const handleInfoChassis = (event) => {
  //   setOpen(true);
  // };

  // const handleInfoEngine = (event) => {
  //   setOpenEngine(true);
  // };
  return (
    <>
      <form onSubmit={SaveForm}>
        <div>
          <div className="text-left mb-24">
            <div className="mb-5">
              <h3 className="text-lg font-bold title-font">
                To create a quotation for you, please provide the details of
                your vehicle below:
              </h3>
            </div>
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Year:
              </label>
              <div className="block relative w-full">
                <select
                  name="year"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                  onChange={handleUpdates}
                  defaultValue={carDetails.year}
                >
                  <option value="">Select Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {/* <div className="block relative w-full">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-base">Year:</label>
                    <input type="text" name="plateNumber" placeholder="ABC 1234" className="input-border-none focus:outline-none focus:shadow-outline"/>
                  </div> */}
            </div>

            <div className="mb-5">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Make:
              </label>
              <div className="block relative w-full">
                {loading && (
                  <select
                    name="make"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.make}
                    required
                  >
                    <option value="">Select Make</option>
                    <option>Loading data...</option>
                  </select>
                )}

                {!loading && (
                  <select
                    name="make"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.make}
                    required
                  >
                    <option value="">Select Make</option>
                    {makelist.map((page) => {
                      try {
                        if (carDetails.make === page.name) {
                          return (
                            <option value={page.name} key={page.name} selected>
                              {page.description}
                            </option>
                          );
                        } else {
                          return (
                            <option value={page.name} key={page.name}>
                              {page.description}
                            </option>
                          );
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    })}
                  </select>
                )}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Model:
              </label>
              <div className="block relative w-full">
                <div className="block relative w-full">
                  {loadingModel !== true && noModel !== false && (
                    <select
                      name="make"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      onChange={handleUpdates}
                      defaultValue={carDetails.make}
                      required
                      disabled
                    >
                      <option value="">No Make list found.</option>
                    </select>
                  )}
                  {loadingModel === true && (
                    <select
                      name="model"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      onChange={handleUpdates}
                      defaultValue={carDetails.modelid}
                      required
                    >
                      <option value="">Select Model</option>

                      <option>Loading data...</option>
                    </select>
                  )}
                  {loadingModel !== true && noModel === false && (
                    <select
                      name="model"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      onChange={handleUpdates}
                      defaultValue={carDetails.modelid}
                      required
                    >
                      {carDetails.make !== make && (
                        <option value="" selected>
                          Select Model
                        </option>
                      )}
                      {carDetails.make === make && (
                        <option value="">Select model</option>
                      )}

                      {modellist.map((page) => {
                        try {
                          if (carDetails.modelid === page.modelid) {
                            return (
                              <option
                                value={page.model}
                                data-enginetype={page.engineType}
                                data-fmv={page.fmv}
                                data-bodytype={page.bodyType}
                                data-seats={page.seats}
                                data-model={page.model}
                                data-variant={page.variant}
                                data-transmission={page.transmission}
                                data-enginedispl={page.engineDispl}
                                selected
                              >
                                {page.model +
                                  " " +
                                  (page.variant != null ? page.variant : "") +
                                  " " +
                                  page.transmission +
                                  " " +
                                  page.engineDispl}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={page.unid}
                                data-enginetype={page.engineType}
                                data-fmv={page.fmv}
                                data-bodytype={page.bodyType}
                                data-seats={page.seats}
                                data-model={page.model}
                                data-variant={page.variant}
                                data-transmission={page.transmission}
                                data-enginedispl={page.engineDispl}
                              >
                                {page.model +
                                  " " +
                                  (page.variant != null ? page.variant : "") +
                                  " " +
                                  page.transmission +
                                  " " +
                                  page.engineDispl}
                              </option>
                            );
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      })}
                    </select>
                  )}

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mb-5">
              <div className="block relative w-full">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Color:
                </label>
                <input
                  type="text"
                  name="color"
                  onChange={handleUpdates}
                  defaultValue={carDetails.color}
                  placeholder="e.g."
                  className="input-border-none focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="mb-5">
              <div className="block relative w-full">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Chassis Number:
                  <button
                    type="button"
                    onClick={handleInfoChassis}
                    value="chassis"
                  >
                    <div className="icon-info">
                      <img
                        src={"/images/info.png"}
                        alt="info"
                        className="icon-info-image"
                      />
                    </div>
                  </button>
                </label>
                <input
                  type="text"
                  placeholder="e.g."
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="chassisNumber"
                  onChange={handleUpdates}
                  defaultValue={carDetails.chassisNumber}
                />
              </div>
            </div>

            <div className="mb-5">
              <div className="block relative w-full">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Engine Number:
                  <button
                    type="button"
                    value="engine"
                    onClick={handleInfoEngine}
                  >
                    <div className="icon-info">
                      <img
                        src={"/images/info.png"}
                        alt="info"
                        className="icon-info-image"
                      />
                    </div>
                  </button>
                </label>
                <input
                  type="text"
                  name="engineNumber"
                  onChange={handleUpdates}
                  defaultValue={carDetails.engineNumber}
                  placeholder="e.g."
                  className="input-border-none focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>*/}
          </div>
        </div>
        <button type="submit" style={{ display: "none" }} id="btnSubmit">
          submit
        </button>
      </form>
      {/* <Modal open={open} setOpen={setOpen} title="Chassis Number">
        <div>
          <img src={"/images/chassisno.png"} className="image" />
        </div>
      </Modal>

      <Modal open={openEngine} setOpen={setOpenEngine} title="Engine Number">
        <div>
          <img src={"/images/engineno.png"} className="image" />
        </div>
      </Modal> */}
    </>
  );
};

export default CarDetails;
