import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../organisms/Header";
import WizardCTPL from "../../organisms/WizardCTPL";
import {
  formatContact,
  formatNumber,
  subtractYears,
  validateEmail,
} from "../../utils/helper";
import { validationAPI } from "../../../api/fetch-api";
import { inputDateFormat } from "../../utils/helper";
import { Cache } from "aws-amplify";

const PetClientDetails = (props) => {
  // const [firstName, setFirstName] = useState(props.clientDetailsState.firstName);
  // const [middleName, setMiddleName] = useState(props.clientDetailsState.middleName);
  // const [lastName, setLastName] = useState(props.clientDetailsState.lastName);
  // const [bday, setBday] = useState(props.clientDetailsState.bday);
  // const [gender, setGender] = useState(props.clientDetailsState.gender);
  // const [contactNumber, setContactNumber] = useState(props.clientDetailsState.contactNumber);
  // const [emailAddress, setEmailAddress] = useState(props.clientDetailsState.emailAddress);

  const [firstName, setFirstName] = useState(
    props.clientDetailsState.firstName
  );
  const [middleName, setMiddleName] = useState(
    props.clientDetailsState.middleName
  );
  const [lastName, setLastName] = useState(props.clientDetailsState.lastName);
  const [bday, setBday] = useState(props.clientDetailsState.bday);
  const [gender, setGender] = useState(props.clientDetailsState.gender);
  const [contactNumber, setContactNumber] = useState(
    props.clientDetailsState.contactNumber
  );
  const [emailAddress, setEmailAddress] = useState(
    props.clientDetailsState.emailAddress
  );

  const [minBday, setMinBday] = useState("");

  const [clientInfo, setClientInfo] = useState(
    JSON.parse(Cache.getItem("clientInfo"))
  );
  // setClientInfo(JSON.parse(Cache.getItem("clientInfo")))
  let history = useHistory();

  useEffect(() => {
    // set max for bday
    let minimBday = subtractYears(18, new Date()); //
    minimBday = inputDateFormat(minimBday); //
    setMinBday(minimBday);
    //end max bday
    console.log(clientInfo);
    // document.getElementsByName("firstname")[0].value = clientInfo.resultData.firstName
    // document.getElementsByName("middlename")[0].value = clientInfo.resultData.middleName
    // document.getElementsByName("lastname")[0].value = clientInfo.resultData.lastName
    // document.getElementsByName("birthday")[0].value = clientInfo.resultData.birthday
    // document.getElementsByName("contactNo")[0].value = clientInfo.resultData.mobileNumber
    // document.getElementsByName("emailAddress")[0].value = clientInfo.resultData.emailAddress
  }, []);

  //FUNCTIONS
  const validateEmailViaAPI = async (event, isForm) => {
    let emailInputs = isForm ? event : event.target;
    let emailValue = isForm ? event.value : emailInputs.value;

    Cache.setItem("petReturnSubmit", null);
    Cache.setItem("petReturnSubmitAgent", null);

    await validationAPI.getEmailValidation(emailValue).then((data) => {
      if (data.statusCode == "ISAGENT") {
        emailInputs.setCustomValidity(
          "Email Address matches with our agent records. Please provide a new email address."
        ); // Set the custom error message
        emailInputs.reportValidity();

        Cache.setItem("petReturnSubmit", true);
        Cache.setItem("petReturnSubmitAgent", true);
        return;

        return;
      } else if (
        data.statusCode == "UNDELIVERABLE" ||
        data.statusCode == "INVALID"
      ) {
        emailInputs.setCustomValidity(
          "Invalid email address format. Please provide a valid email address."
        ); // Set the custom error message
        emailInputs.reportValidity();
        Cache.setItem("petReturnSubmit", true);
        Cache.setItem("petReturnSubmitAgent", false);

        return;
      }

      emailInputs.setCustomValidity("");
      Cache.setItem("petReturnSubmit", false);
      Cache.setItem("petReturnSubmitAgent", false);
    });
  };

  const validateMobileViaAPI = async (event, isForm) => {
    let mobileInputs = isForm ? event : event.target;
    let mobileValue = isForm ? event.value : mobileInputs.value;

    Cache.setItem("petReturnSubmitMobile", null);
    Cache.setItem("petReturnSubmitAgentMobile", null);

    await validationAPI.getMobileValidation(mobileValue).then((data) => {
      if (data.statusCode == "ISAGENT") {
        mobileInputs.setCustomValidity(
          "Contact Number matches with our agent records. Please provide a new Mobile Number."
        ); // Set the custom error message
        mobileInputs.reportValidity();

        Cache.setItem("petReturnSubmitMobile", true);
        Cache.setItem("petReturnSubmitAgentMobile", true);

        return;
      } else if (data.statusCode == "INVALID") {
        mobileInputs.setCustomValidity(data.message); // Set the custom error message
        mobileInputs.reportValidity();

        Cache.setItem("petReturnSubmitMobile", true);
        Cache.setItem("petReturnSubmitAgentMobile", false);
        return;
      } else if (data.statusCode == "NONZERO") {
        mobileInputs.setCustomValidity(data.message); // Set the custom error message
        mobileInputs.reportValidity();

        Cache.setItem("petReturnSubmitMobile", true);
        Cache.setItem("petReturnSubmitAgentMobile", false);
        return;
      }

      mobileInputs.setCustomValidity("");
      Cache.setItem("petReturnSubmitMobile", false);
      Cache.setItem("petReturnSubmitAgentMobile", false);
      return;
    });
  };

  const SaveForm = async (e) => {
    e.preventDefault();

    const clientDetailsData = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      bday: bday,
      gender: gender,
      contactNumber: contactNumber,
      emailAddress: emailAddress,
    };

    const forceEmailhtml = e.target.querySelector('input[name="emailAddress"]');
    const forceMobilehtml = e.target.querySelector('input[name="contactNo"]');

    await validateEmailViaAPI(forceEmailhtml, true);
    await validateMobileViaAPI(forceMobilehtml, true);

    const getPetReturnValue = Cache.getItem("petReturnSubmit");
    const getPetReturnValueMobile = Cache.getItem("petReturnSubmitMobile");

    var getReturnSubmitAgent = Cache.getItem("petReturnSubmitAgent");
    var getReturnSubmitAgentMobile = Cache.getItem(
      "petReturnSubmitAgentMobile"
    );

    if (!getPetReturnValue && !getPetReturnValueMobile) {
      props.saveData(clientDetailsData);
    } else if (!getReturnSubmitAgent && !getReturnSubmitAgentMobile) {
      alert(
        "Email Address and Contact Number match with our agent records.Please provide a new Email Address and Contact Number."
      );
      return false;
    }
  };

  const handleChange = (e) => {
    let evt = e.target;

    if (evt.name === "firstname") {
      setFirstName(evt.value);
    } else if (evt.name === "middlename") {
      setMiddleName(evt.value);
    } else if (evt.name === "lastname") {
      setLastName(evt.value);
    } else if (evt.name === "birthday") {
      setBday(evt.value);
    } else if (evt.name === "cGender") {
      setGender(evt.value);
    } else if (evt.name === "contactNo") {
      setContactNumber(evt.value);
    } else if (evt.name === "emailAddress") {
      setEmailAddress(evt.value);
    }
  };
  return (
    <form onSubmit={SaveForm}>
      <main className="mb-24">
        <h3 className="text-lg font-bold title-font">
          Please confirm your details below.
        </h3>

        <div className="my-5">
          <h3 className="text-lg font-bold my-3">Client Details</h3>
          <div>
            {/* Should Display if Asean*/}
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                First Name
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="firstname"
                  placeholder="Enter your First Name"
                  onChange={handleChange}
                  defaultValue={firstName}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Middle Name
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="middlename"
                  placeholder="Enter your Middle Name"
                  onChange={handleChange}
                  defaultValue={middleName}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Last Name
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="lastname"
                  placeholder="Enter your Last Name"
                  onChange={handleChange}
                  defaultValue={lastName}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Birthday <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="date"
                  name="birthday"
                  placeholder="MM-DD-YYYY"
                  onChange={handleChange}
                  defaultValue={bday}
                  max={minBday}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Gender <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="cGender"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  onChange={handleChange}
                  defaultValue={gender}
                  required
                >
                  <option value="">Select gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <h3 className="text-lg font-bold my-3">Contact Details</h3>
          <div>
            {/* Should Display if Asean*/}
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Contact Number
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="contactNo"
                  placeholder="Enter your contact number"
                  onChange={handleChange}
                  defaultValue={formatNumber(contactNumber)}
                  maxLength="11"
                  minLength="11"
                  onBlur={(e) => {
                    //formatContact(e, false);
                    validateMobileViaAPI(e, false);
                  }}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Email Address
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="emailAddress"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  defaultValue={emailAddress}
                  onBlur={(e) => {
                    validateEmail(e, false);
                    validateEmailViaAPI(e, false);
                  }}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <button type="submit" style={{ display: "none" }} id="btnSubmit">
        submit
      </button>
    </form>
  );
};

export default PetClientDetails;
