import CancelPolicy from "../../organisms/ManagePolicy/CancelPolicy";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Cache } from "aws-amplify";
import { manageAPI, authAPI, authDetails } from "../../../api/fetch-api";
import { Loading } from "../../atoms/loading";

export default function Services(props) {
  let history = useHistory();
  const gotoCancelPolicy = () => {
    Cache.setItem("productType",props.productType);
    history.push("/manage-policy/" + Cache.getItem("unid") + "/cancel-request");
  };
  const [polStatus, setPolStatus] = useState("A");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const customerID = Cache.getItem("customerId").replaceAll(" ", "%2B");
    if(props.productType==="SITRVL01" || props.productType==="SITRVL02"){
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      //CANCEL STATUS CHECKER
      //if (travelDetails === "C") {
        setPolStatus("");
      //}
    }
    else if(props.productType==="SIPETI01"){
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      //CANCEL STATUS CHECKER
      //if (travelDetails === "C") {
        setPolStatus("");
      //}
    }
    else{
      authAPI
      .getAuth(authDetails)
      .then((data1) => {
        Cache.setItem("ginsure_token", data1.token);
        manageAPI
          .getPolicy(Cache.getItem("unid"),customerID, data1.token)
          .then((data) => {
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
            //CANCEL STATUS CHECKER
            if (data.status === "C") {
              setPolStatus(data.status);
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
          });
      })
      .catch((error) => {
        console.log(error);
        console.log("There was an error!");
      });
    }
   
  }, []);
  return (
    <>
      <div className="mt-5">
        {/* <div className="border-b-2 border-gray-200 py-5 px-8">
          <div className="grid grid-cols-2">
            <div>
              <img
                src={"/images/file-icon.png"}
                alt="file"
                className="manage-icon"
              />
              <span>File A Claim</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 float-right"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div> */}

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {polStatus != "C" && (
              <>
              <div
                className="border-b-2 border-gray-200 py-5 px-8"
                onClick={gotoCancelPolicy}
              >
                <div className="grid grid-cols-3">
                  <div className="col-span-2">
                    <button>
                      <img
                        src={"/images/cancel-icon.png"}
                        alt="cancel"
                        className="manage-icon"
                      />
                      <span className="manage-policy-font">Request Cancel Policy</span>
                    </button>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 float-right"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                  
                </div>
              </div>
              {(props.productType==="SITRVL01" || props.productType==="SITRVL02") && (
                <div className=" py-5 px-8">
                <p className="text-xs  manage-policy-font">Please take note of the following if you wish to cancel your policy.</p>
                <br/>
                <p  className="text-xs  manage-policy-font mb-5">1. If the policy cancellation is within the day before<br/>11:00 PM, the full premium refund will be automatically credited to your GCash wallet.</p>
                <p  className="text-xs  manage-policy-font">2. If the policy cancellation is beyond 11:00 PM, our<br/> representative will manually process your refund.</p>

              </div>
              )}
              
            </>
            )}
          </>
        )}
      </div>
    </>
  );
}
