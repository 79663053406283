const Wizard = (props) => {
  let activeClass1 = (props.activeStep>=1) ? "bg-blue-600":"border-gray-100 bg-gray-100";
  let activeClass2 = (props.activeStep>=2)? "bg-blue-600":"border-gray-100 bg-gray-100";
  let activeClass3 = (props.activeStep>=3)? "bg-blue-600":"border-gray-100 bg-gray-100";
  let activeClass4 = (props.activeStep>=4)? "bg-blue-600":"border-gray-100 bg-gray-100";
  let barActiveClass2 = (props.activeStep>=2)? "bg-blue-100":"bg-gray-200";
  let barActiveClass3 = (props.activeStep>=3)? "bg-blue-100":"bg-gray-200";
  let barActiveClass4 = (props.activeStep>=4)? "bg-blue-100":"bg-gray-200";
  return (
    <>
      <div className="w-full mb-10">
        <div className="flex">
          <div className="w-1/4">
            <div className="relative mb-2">
              <div className={
                  `w-5 h-5 mx-auto border-2 rounded-full text-lg text-white flex items-center `+activeClass1}></div>
            </div>

            <div className="text-xs text-center font-bold">Details</div>
          </div>

          <div className="w-1/4">
            <div className="relative mb-2">
              <div className="absolute flex align-center items-center align-middle content-center wizard">
                <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div
                    className={`w-full ${barActiveClass2} py-1 rounded`}
                  ></div>
                </div>
              </div>

              <div className={`w-5 h-5 mx-auto border-2 rounded-full text-lg text-white flex items-center ` +activeClass2}></div>
            </div>

            <div className="text-xs text-center font-bold">Quotation</div>
          </div>

          <div className="w-1/4">
            <div className="relative mb-2">
              <div
                className="absolute flex align-center items-center align-middle content-center wizard"
                // style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)"
              >
                <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div
                    className={`w-full ${barActiveClass3} py-1 rounded`}
                    // style="width: 33%;"
                  ></div>
                </div>
              </div>

              <div className={`w-5 h-5 mx-auto rounded-full border-2 text-lg text-white flex items-center `+activeClass3}>
                {/* <span className="text-center text-gray-600 w-full">
                  <svg
                    className="w-full fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      className="heroicon-ui"
                      d="M9 4.58V4c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v.58a8 8 0 0 1 1.92 1.11l.5-.29a2 2 0 0 1 2.74.73l1 1.74a2 2 0 0 1-.73 2.73l-.5.29a8.06 8.06 0 0 1 0 2.22l.5.3a2 2 0 0 1 .73 2.72l-1 1.74a2 2 0 0 1-2.73.73l-.5-.3A8 8 0 0 1 15 19.43V20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-.58a8 8 0 0 1-1.92-1.11l-.5.29a2 2 0 0 1-2.74-.73l-1-1.74a2 2 0 0 1 .73-2.73l.5-.29a8.06 8.06 0 0 1 0-2.22l-.5-.3a2 2 0 0 1-.73-2.72l1-1.74a2 2 0 0 1 2.73-.73l.5.3A8 8 0 0 1 9 4.57zM7.88 7.64l-.54.51-1.77-1.02-1 1.74 1.76 1.01-.17.73a6.02 6.02 0 0 0 0 2.78l.17.73-1.76 1.01 1 1.74 1.77-1.02.54.51a6 6 0 0 0 2.4 1.4l.72.2V20h2v-2.04l.71-.2a6 6 0 0 0 2.41-1.4l.54-.51 1.77 1.02 1-1.74-1.76-1.01.17-.73a6.02 6.02 0 0 0 0-2.78l-.17-.73 1.76-1.01-1-1.74-1.77 1.02-.54-.51a6 6 0 0 0-2.4-1.4l-.72-.2V4h-2v2.04l-.71.2a6 6 0 0 0-2.41 1.4zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                    />
                  </svg>
                </span> */}
              </div>
            </div>

            <div className="text-xs text-center font-bold">Other</div>
          </div>

          <div className="w-1/4">
            <div className="relative mb-2">
              <div
                className="absolute flex align-center items-center align-middle content-center wizard"
                // style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)"
              >
                <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div
                    className={`w-full ${barActiveClass4} py-1 rounded`}
                    // style="width: 0%;"
                  ></div>
                </div>
              </div>

              <div className={`w-5 h-5 mx-auto rounded-full border-2 text-lg text-white flex items-center `+activeClass4}>
                {/* <span className="text-center text-gray-600 w-full">
                  <svg
                    className="w-full fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      className="heroicon-ui"
                      d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                    />
                  </svg>
                </span> */}
              </div>
            </div>

            <div className="text-xs text-center font-bold">Summary</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wizard;
