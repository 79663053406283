import { useState, useEffect } from "react";
import FaqProductInternational from "../components/organisms/Travel-Faq/FaqProductInternational";
import FaqProductAsean from "../components/organisms/Travel-Faq/FaqProductAsean";
// import FaqClaimInternational from "../components/organisms/Travel-Faq/FaqClaimInternational";
// import FaqPolicyInternational from "../components/organisms/Travel-Faq/FaqPolicyInternational";
// import FaqContactTravel from "../components/organisms/Travel-Faq/FaqContact";
import FaqClaimTravel from "../components/organisms/Travel-Faq/FaqClaim";
import FaqPolicyTravel from "../components/organisms/Travel-Faq/FaqPolicy";
import FaqContactTravel from "../components/organisms/Travel-Faq/FaqContact";
import Header from "../components/organisms/Header";

const FaqPage = (props) => {
  const cover = window.location.href.split("/").pop();

  //const cover = window.location.href.indexOf("international") > -1 ? "international":"asean"
  const [page, setPage] = useState("product");
  const [active, setActive] = useState("product");
  useEffect(() => {
    window.scrollTo(0, 0);
    //setPage("product")
    console.log("page", page);
  }, []);

  const handleFAQ = (detail) => {
    console.log("detail", detail);
    setPage(detail);
  };

  const FaqDetails = () => {
    if (page === "product" && cover === "international") {
      setActive("product");
      return <FaqProductInternational />;
    } else if (page === "product" && cover === "asean") {
      setActive("product");
      return <FaqProductAsean />;
    } else if (page == "claim") {
      setActive("claim");
      return <FaqClaimTravel cover={cover} />;
    } else if (page == "policy") {
      setActive("policy");
      return <FaqPolicyTravel cover={cover} />;
    } else if (page == "contact") {
      setActive("contact");
      return <FaqContactTravel cover={cover} />;
    } else {
      setActive("notActive");
    }

    // if (cover === "international") {
    //   if (page === "product") {
    //     setActive("product");
    //     return <FaqProductInternational />;
    //   }
    // } else {
    //   if (page === "product") {
    //     setActive("product");
    //     return <FaqProductAsean  />;
    //   } else if (page == "claim") {
    //     setActive("claim");
    //     return <FaqClaimAsean />;
    //   } else if (page == "policy") {
    //     setActive("policy");
    //     return <FaqPolicyAsean />;
    //   } else if (page == "contact") {
    //     setActive("contact");
    //     return <FaqContactTravel />;
    //   } else {
    //     setActive("notActive");
    //   }
    // }
  };

  return (
    <div>
      <Header
        headerTitle={props.headerTitle}
        subHeader={props.subheaderTitle}
      />

      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className="p-5 pt-12">
        <img src={"/images/sici-logo.png"} width="170" height="50" alt="logo" />
      </div>
      <main className="p-5">
        <div className="pb-10 font-bold text-lg">
          <h3 className="mb-5">Frequently Asked Question</h3>

          <div className="grid grid-cols-4 gap-4">
            <button onClick={() => handleFAQ("product")}>
              <div
                className={
                  active == "product"
                    ? "px-3 py-5 rounded-lg faq-tabs active"
                    : "px-3 py-5 rounded-lg faq-tabs"
                }
              >
                <div>
                  <img
                    src={
                      active == "product"
                        ? "/images/travel/airplane-white.png"
                        : "/images/travel/airplane-black.png"
                    }
                    alt="faq-product"
                    className="mx-auto mb-2"
                  />
                  <span
                    className={
                      active == "product"
                        ? "text-white text-xs text-center block"
                        : "text-xs text-center block"
                    }
                  >
                    Product
                  </span>
                </div>
              </div>
            </button>
            <button onClick={() => handleFAQ("claim")}>
              <div
                className={
                  active == "claim"
                    ? "px-3 py-5 rounded-lg faq-tabs active"
                    : "px-3 py-5 rounded-lg faq-tabs"
                }
              >
                <div>
                  <img
                    src={
                      active == "claim"
                        ? "/images/faq-claim-active.png"
                        : "/images/faq-claim.png"
                    }
                    alt="faq-claim"
                    className="mx-auto mb-2"
                  />
                  <span
                    className={
                      active == "claim"
                        ? "text-white text-xs text-center block"
                        : "text-xs text-center block"
                    }
                  >
                    Claim
                  </span>
                </div>
              </div>
            </button>
            <button onClick={() => handleFAQ("policy")}>
              <div
                className={
                  active == "policy"
                    ? "px-3 py-5 rounded-lg faq-tabs active"
                    : "px-3 py-5 rounded-lg faq-tabs"
                }
              >
                <div>
                  <img
                    src={
                      active == "policy"
                        ? "/images/faq-policy-active.png"
                        : "/images/faq-policy.png"
                    }
                    alt="faq-policy"
                    className="mx-auto mb-2"
                  />
                  <span
                    className={
                      active == "policy"
                        ? "text-white text-xs text-center block"
                        : "text-xs text-center block"
                    }
                  >
                    Policy
                  </span>
                </div>
              </div>
            </button>
            <button onClick={() => handleFAQ("contact")}>
              <div
                className={
                  active == "contact"
                    ? "px-3 py-5 rounded-lg faq-tabs active"
                    : "px-3 py-5 rounded-lg faq-tabs"
                }
              >
                <div>
                  <img
                    src={
                      active == "contact"
                        ? "/images/faq-contact-active.png"
                        : "/images/faq-contact.png"
                    }
                    alt="faq-contact"
                    className="mx-auto mb-2"
                  />
                  <span
                    className={
                      active == "contact"
                        ? "text-white text-xs text-center block"
                        : "text-xs text-center block"
                    }
                  >
                    Contact
                  </span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="mb-20">
          <FaqDetails />
        </div>
      </main>
    </div>
  );
};
export default FaqPage;
