export const Loading = ({className, ...props}) => {
  return (
    <div className=" flex justify-center items-center">
      <div
        className={"loader animate-spin ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 mt-20 "+className}
        style={{ borderTopColor: "#0159DE" }} {...props}
      ></div>
    </div>
  );
};
