import { Cache } from "aws-amplify";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../../components/organisms/Footer";
import Header from "../../components/organisms/Header";
import TravelCoverSection from "../../components/organisms/TravelCoverSection";

function TravelCovers (props) {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  const [open, setOpen] = useState(false);

  const [cover, setCover] = useState("");

  // const urlQuery = window.location.search;
  // const params = new URLSearchParams(urlQuery);
  // const coverType = params.get('type');
  let coverType = "";
  (window.location.href.indexOf("international") > -1)?coverType="international":coverType="asean"

  useEffect(() => {
    setCover(coverType);
  }, [coverType]);
  

  const EnableButton = () => {
    setIsDisabled(0);
  };
  const DisableButton = () => {
    setIsDisabled(1);
  };

  const SubmitForm = () => {
      console.log('saa');
      Cache.setItem("fromCoverPage", true)
      setOpen(true)
  }


  return (
    <div>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className={"main p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div>
      <main className={"main p-5"}>
        <div className="mb-16">
          <TravelCoverSection
            enableButton={EnableButton}
            disableButton={DisableButton}
            open={open}
            setOpen={setOpen}
            cover={cover}
          />
        </div>
      </main>
      <Footer
        isMain={"1"}
        buttonType="button"
        saveData={SubmitForm}
        buttonTitle={"Get Started"}
      />
    </div>
  );
}
export default TravelCovers;
