import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { petAPI } from "../../../api/fetch-api";
import { getAge, subtractYears } from "../../utils/helper";
import { subtractMonths } from "../../utils/helper";
import { inputDateFormat } from "../../utils/helper";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

const PetDetails = (props) => {
  let history = useHistory();

  const today = dayjs();

  const maxDate = dayjs().subtract(56, "day");
  const minDate = dayjs().subtract(9, "year");

  const [petName, setPetName] = useState(props.petDetailsState.petName);
  const [petGender, setPetGender] = useState(props.petDetailsState.petGender);
  const [petSpecies, setPetSpecies] = useState(props.petDetailsState.species);
  const [petType, setPetType] = useState(props.petDetailsState.type);
  const [breed, setBreed] = useState(props.petDetailsState.breed);
  const [petBday, setPetBday] = useState(props.petDetailsState.bday);

  const [breeds, setBreeds] = useState(props.petDetailsState?.breeds || []);
  const [sireDamBreeds, setSireDamBreeds] = useState(
    props.petDetailsState?.sireDamBreeds || []
  );
  // const [breeds, setBreeds] = useState([]);
  const [selectedSire, setSelectedSire] = useState(props.petDetailsState.sire);
  const [selectedDam, setSelectedDam] = useState(props.petDetailsState.dam);
  const [state, setState] = useState(props.petDetailsState.petState);
  const [parentBreedDisabled, setParentBreedDisabled] = useState(
    props.petDetailsState.parentBreedDisabled
  );
  const [agePet, setAgePet] = useState(props.petDetailsState.petAge);
  const [ageGroup, setAgeGroup] = useState(props.petDetailsState.petAgeGroup);
  const [ageGroupDesc, setAgeGroupDesc] = useState(
    props.petDetailsState.petAgeGroupDesc
  );
  const [minBday, setMinBday] = useState("");
  const [maxBday, setMaxBday] = useState("");

  useEffect(() => {
    // set max for bday
    let minimBday = subtractMonths(2, new Date()); //minus 2mos from today
    minimBday = inputDateFormat(minimBday); //format only for input date
    setMinBday(minimBday);

    let maximBday = subtractYears(9, new Date()); //minus 9yrs from today
    maximBday = inputDateFormat(maximBday);
    setMaxBday(maximBday);
    //end max bday

    petAPI
      .getBreeds({})
      .then((data) => {})
      .catch((err) => console.log("ERROR", err));
  }, []);

  // useEffect(() => {
  //   //re-set ageGroup if pet type is changed
  //   if (document.getElementsByName("petBday")[0].value !== "") {
  //     handleBirthday(
  //       new Date(document.getElementsByName("petBday")[0].value),
  //       true
  //     );
  //   }
  // }, [petSpecies]);

  /**FUNCTIONS */
  const handleSpecies = (e) => {
    console.log("SPECIES", e.target.value);

    const speciesVal = e.target.value;
    // breedApi(speciesVal);
    document.getElementsByName("petType")[0].value = "";
    setPetType("");
    setPetSpecies(speciesVal);
    setBreeds("");
  };

  const breedApi = (speciesVal, type) => {
    if (speciesVal === "C") {
      petAPI
        .getCanineBreeds({}, type)
        .then((data) => {
          const getBreed = { ...data };

          console.log("CANINE", getBreed);
          setBreeds(getBreed);
        })
        .catch((err) => console.log("ERROR", err));

      petAPI
        .getCanineBreeds({}, type == "P" ? type : "")
        .then((data) => {
          const getBreed = { ...data };

          console.log("CANINE", getBreed);
          setSireDamBreeds(getBreed);
        })
        .catch((err) => console.log("ERROR", err));
    } else {
      petAPI
        .getFelineBreeds({}, type)
        .then((data) => {
          const getBreed = { ...data };
          console.log("FELINE", getBreed);
          setBreeds(getBreed);
        })
        .catch((err) => console.log("ERROR", err));

      petAPI
        .getFelineBreeds({}, type == "P" ? type : "")
        .then((data) => {
          const getBreed = { ...data };
          console.log("FELINE", getBreed);
          setSireDamBreeds(getBreed);
        })
        .catch((err) => console.log("ERROR", err));
    }
  };

  const handleBreed = (e) => {
    console.log("BREED", e.target.value);

    if (state === "P") {
      //set value on parent breeds (dam and sire)
      setSelectedSire(e.target.value);
      setSelectedDam(e.target.value);
    }
    setBreed(e.target.value);
  };

  const handleSire = (e) => {
    console.log("SIRE", e.target.value);
    setSelectedSire(e.target.value);
  };

  const handleDam = (e) => {
    console.log("DAM", e.target.value);
    setSelectedDam(e.target.value);
  };

  const handleType = (e) => {
    console.log("TYPE", e.target.value);

    let oldTypeBreed = state;
    let newTypeBreed = e.target.value;
    document.getElementsByName("breeds")[0].value = "";
    setState(newTypeBreed);
    console.log("STATE", state);
    setSelectedSire("");
    setSelectedDam("");

    if (newTypeBreed == "P") {
      setParentBreedDisabled(true);
    } else {
      setParentBreedDisabled(false);
    }
    breedApi(petSpecies, newTypeBreed);
    setPetType(e.target.value);
  };

  const handleBirthday = (e, t) => {
    const age = t ? getAge(e) : getAge(e.target.value);

    setAgePet(age);

    handlePetGroup(age);
    setPetBday(t ? e : e.target.value);
  };

  const handlePetGroup = (age) => {
    if (age == 0) {
      setAgeGroup("P");
      setAgeGroupDesc("Young");
    } else if (age >= 1 && age <= 6) {
      setAgeGroup("A");
      setAgeGroupDesc("Adult");
    } else if (age >= 7) {
      setAgeGroup("S");
      setAgeGroupDesc("Senior");
    } else {
      setAgeGroup("");
      setAgeGroupDesc("");
    }
  };
  const handleChange = (e) => {
    let evt = e.target;
    if (evt.name === "petname") {
      setPetName(evt.value);
    } else if (evt.name === "petSpecies") {
    } else if (evt.name === "breeds") {
      setBreed(evt.value);
    } else if (evt.name === "gender") {
      setPetGender(evt.value);
    }
  };

  const SaveForm = (e) => {
    e.preventDefault();

    if(petBday === '' || petBday === undefined) {
      alert('Birthday is required')
      return false;
    }
   
    const petDetailsData = {
      petName: petName,
      petGender: petGender,
      species: petSpecies,
      type: petType,
      breed: breed,
      bday: petBday,
      petAge: agePet,
      petAgeGroup: ageGroup,
      petAgeGroupDesc: ageGroupDesc,
      sire: selectedSire,
      dam: selectedDam,
      breeds: breeds,
      petState: state,
      parentBreedDisabled: parentBreedDisabled,
    };

    const cleanedBreed = breed.replace("Mixed/Cross Breed", "").trim();

    if (
      (petType === "C" && selectedDam.includes(cleanedBreed)) ||
      selectedSire.includes(cleanedBreed)
    ) {
      props.saveData(petDetailsData);
    } else {
      alert(
        "Breed classification of Sire and/or Dam are not related to current selected Pet Breed"
      );
    }
  };
  return (
    <form onSubmit={SaveForm}>
      <main className="mb-24">
        <h3 className="text-lg font-bold title-font">
          Please provide details of the insured below.
        </h3>

        <div className="my-5">
          <div>
            {/* Should Display if Asean*/}
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Pet's Name <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="text"
                  name="petname"
                  placeholder="Enter your pet's name"
                  maxLength="50"
                  defaultValue={petName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Gender <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="gender"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  defaultValue={petGender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select your pet's gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Species <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="petSpecies"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  defaultValue={petSpecies}
                  onChange={handleSpecies}
                  required
                >
                  <option value="">Select your pet's species</option>
                  <option value="C">Canine</option>
                  <option value="F">Feline</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Type <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="petType"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                  defaultValue={petType}
                  onChange={handleType}
                >
                  <option value="">Select your pet's type</option>
                  <option value="P">Pure Breed</option>
                  <option value="C">Cross Breed</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Breed <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="breeds"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                  defaultValue={breed}
                  onChange={handleBreed}
                >
                  <option value="">Select your pet's breed</option>
                  {Object.entries(breeds).map(([key, value], i) => (
                    <option key={i} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Birthday <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                {/* <input
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  type="date"
                  name="petBday"
                  defaultValue={petBday}
                  onChange={handleBirthday}
                  max={minBday}
                  min={maxBday}
                  required
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className={"customDatepickerBorder"}>
                    <DatePicker
                      minDate={minDate}
                      maxDate={maxDate}
                      defaultValue={dayjs(petBday)}
                      // format="MM/dd/yyyy"
                      onChange={handleBirthday}
                      

                      // onChange={(value) => {
                      //   onChange(value); // Assuming onChange is a function passed as a prop
                      // }}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Age <span className="text-red-700">*</span>
                <span className="text-gray-900 ml-2 font-bold">{agePet}</span>
              </label>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Age Group <span className="text-red-700">*</span>
                <span className="text-gray-900 ml-2 font-bold">
                  {ageGroupDesc}
                </span>
              </label>
            </div>

            {/* <div className="mb-3">
                    <label className="inline-block text-gray-500 text-sm font-bold">
                      Age<span className="text-red-700">*</span>
                    </label>
    
                    <div className="block relative w-full">
                      <input
                        className="input-border-none focus:outline-none focus:shadow-outline"
                        type="text"
                        name="age"
                        disabled
                      />
                    </div>
                  </div> */}

            {/* <div className="mb-3">
                    <label className="inline-block text-gray-500 text-sm font-bold">
                      Age Group <span className="text-red-700">*</span>
                    </label>
    
                    <div className="block relative w-full">
                      <input
                        className="input-border-none focus:outline-none focus:shadow-outline"
                        type="text"
                        name="ageGroup"
                        disabled
                      />
                    </div>
                  </div> */}

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Sire (Paternal Breed) <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="sireBreed"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                  value={selectedSire}
                  onChange={handleSire}
                  disabled={parentBreedDisabled}
                >
                  <option value="">Select your Sire's breed</option>
                  {Object.entries(sireDamBreeds).map(([key, value], i) => (
                    <option key={i} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Dam (Maternal Breed) <span className="text-red-700">*</span>
              </label>

              <div className="block relative w-full">
                <select
                  name="damBreed"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  required
                  value={selectedDam}
                  onChange={handleDam}
                  disabled={parentBreedDisabled}
                >
                  <option value="">Select your Dam's breed</option>
                  {Object.entries(sireDamBreeds).map(([key, value], i) => (
                    <option key={i} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <button type="submit" style={{ display: "none" }} id="btnSubmit">
        submit
      </button>
    </form>
  );
};

export default PetDetails;
