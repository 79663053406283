import { Link } from "react-router-dom";
import Button from "../atoms/Button";
import ButtonAction from "../atoms/ButtonAction";

const Footer = (props) => {
  return (
    <div className="fixed bottom-0 bg-white justify-center text-center mt-10 w-full">
     
      <div className="footer-shadow py-5">
        {props.buttonType !== "button" && (
          <Button link={props.link} isDisabled={props.isDisabled} />
        )}
        {props.buttonType === "button" && (
          <ButtonAction
            saveData={props.saveData}
            buttonTitle={props.buttonTitle}
            isLoading={props.isLoading}
            isDisabled={props.isDisabled}
          />
        )}
      </div>
    </div>
  );
};
export default Footer;
