import Header from "../components/organisms/Header";
//import ExclusionList from "../components/organisms/Motor-Covered/Exclusion-List";

const CovidPage = (props) => {
  return (
    <div>
      <Header
        headerTitle={props.headerTitle}
        subHeader={props.subheaderTitle}
      />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className="p-5 pt-12">
        <img src={"/images/sici-logo.png"} width="170" height="50" alt="logo" />
      </div>
      <main className="p-5">
        <div className="">
          {/* <ExclusionList /> */}
          <>
            <h3 className="text-lg font-bold my-3">SCOPE OF COVER</h3>

            <p className="text-sm text-gray-600">
              The Policy shall commence: when the Policyholder leaves his/her
              home in the Usual Country of Residence to commence the travel, but
              not earlier than twenty-four (24) hours before the start date
              shown on the policy schedule as evidenced by the original
              departure time shown on the travel ticket, and ends under all
              other sections:
            </p>

            <ul className="mx-4 my-3 text-sm list-disc text-gray-600">
              <li>
                no more than 24 hours from actual date of return as evidenced
                by the original arrival time shown on the travel ticket; or
              </li>
              <li>
                no later than end date of the policy as shown in the policy
                schedule; or
              </li>
              <li>
                when the Policyholder is in his/her home address in the Usual
                Country of Residence.
              </li>
            </ul>

            <p className="text-sm text-gray-600">
              whichever is earlier. Furthermore, the Insured person is covered
              if diagnosed with COVID-19 during the insured trip or within
              fifteen (15) days prior to the scheduled trip departure date,
              provided travel insurance is taken prior to such diagnosis.
            </p>

            <h3 className="text-lg font-bold my-5">ELIGIBILITY</h3>
            <p className="text-sm text-gray-600 mb-2">
              The package is intended for persons travelling on vacation, study
              or seminars/conferences for trips with a maximum of ninety (90)
              days only. This package excludes cover for skilled persons/
              skilled laborers when travelling for the purpose of employment or
              in the course of employment.
            </p>
            <p className="text-sm text-gray-600">
              High risk occupation such as healthcare workers, nursing
              professions are also excluded.
            </p>

            <h3 className="text-lg font-bold my-5">AGE OF LIMIT</h3>
            <p className="text-sm text-gray-600">
              Individuals from 0 months old up to 75 years old, subject to
              additional premium/surcharge as follows:
            </p>
            <ul className="mx-4 my-3 text-sm list-disc text-gray-600">
              <li>
                0 – 59 years old: standard rate as shown on the schedule of
                premiums
              </li>
              <li>60 – 69 years old: 100% add-on</li>
              <li>70 – 75 years old: 150% add-on</li>
            </ul>

            <h3 className="text-lg font-bold my-5">SPECIFIC EXCLUSIONS</h3>
            <ul className="mx-4 my-3 text-sm list-disc text-gray-600">
              <li>
                Trip cancellation or termination solely due to epidemic-related
                / pandemic-related travel advisories issued by governments,
                health authorities or the World Health Organization, by or for
                destination country or origin country.
              </li>
              <li>
                Trip cancellation or termination resulting solely from border
                closures, quarantine or other government orders, advisories,
                regulations or directives.
              </li>
              <li>
                Trip cancellation or termination, if trip is cancelled or
                terminated because of disinclination to travel, change of mind
                or fear of travelling.
              </li>
              <li>
                Trip cancellation or termination, if the hotel, travel agent or
                any other provider of travel and/or accommodation has offered a
                voucher or credit or re-booking of the trip for cancellation
                refund or compensation.
              </li>
              <li>
                Travelling against a medical practitioner’s or doctor's advice,
                or any claim arising from the insured person acting in a way
                that goes against the advice of a medical practitioner or
                doctor.
              </li>
            </ul>
          </>
        </div>
      </main>
    </div>
  );
};
export default CovidPage;
