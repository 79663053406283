import Quotation from "../../components/organisms/Motor-Insurance/Quotation";

const MotorcarQuotation = () => {
  return (
    <>
      <Quotation />
    </>
  );
};

export default MotorcarQuotation;
