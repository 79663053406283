import { Cache } from "aws-amplify";
import { useEffect, useState } from "react";
import { motorCtplAPI } from "../../../api/fetch-api";

const CTPLFormSummary = (props) => {
  const [carDetailsData, setCarDetailsData] = useState([]);
  const [clientDetailsData, setClientDetailsData] = useState([]);
  const [idtype, setIdtype] = useState("");

  const idTypes = [
    { code: "AC", name: "ACR Identity Card (I-Card)" },
    { code: "SS", name: "Social Security System ID / UMID" },
    { code: "TI", name: "TIN ID" },
    { code: "CI", name: "Consular ID" },
    { code: "DL", name: "DRIVER'S LICENSE" },
    { code: "DV", name: "Digitized Voter's ID" },
    { code: "GS", name: "Government Service Insurance System (GSIS)" },
    {
      code: "OS",
      name: "Office of Senior Citizen's Affairs (OSCA) / Senior Citizen's Card",
    },
    { code: "OF", name: "OFW ID" },
    { code: "OW", name: "Overseas Worker's Welfare Administration (OWWA)" },
    { code: "PS", name: "Passport" },
    { code: "PC", name: "Philhealth Card" },
    { code: "PO", name: "Philippine Overseas Employment Association (POEA)" },
    { code: "PI", name: "Postal ID" },
    { code: "PR", name: "Professional Regulation (PRC) ID" },
    { code: "SI", name: "Student's ID" },
  ];
  let ids; //idtype
  useEffect(() => {
    // console.log(Cache.getItem("carDetailsState"))
    // console.log(Cache.getItem("clientDetailsState"))

    //setCarDetailsData(Cache.getItem("carDetailsState"));
    //setClientDetailsData(Cache.getItem("clientDetailsState"));
    setCarDetailsData(props.carDetails);
    setClientDetailsData(props.clientDetail);

    // if(ids[0].name !== undefined){
    //   setIdtype(ids[0].name);
    // }
  }, []);
  useEffect(() => {
    ids = idTypes.filter(function (id) {
      return id.code === clientDetailsData.idType;
    });
    // console.log("ID", ids[0], ids.length)
    if (ids.length > 0) {
      setIdtype(ids[0].name);
    }
  }, [clientDetailsData]);

  useEffect(() => {
    // console.log("IDTYPE", idtype)
  }, [idtype]);
  const SaveForm = (e) => {
    e.preventDefault();
    // console.log(carDetailsData.regType)
    const carDetailsDatas = {
      // year: year,
      // make: make,
      // model: model,
      // modelid: modelid,
      // engineType: engineType,
      // fmv: fmv,
      // bodyType: bodyType,
      // seats: seats,
      // carDisplay: carDisplay,
      // color: color,
      // chassisNumber: chassisNumber,
      // engineNumber: engineNumber,
    };
    let ctplPremiumApi;
    if (carDetailsData.mvPremtype !== "1") {
      // ctplPremiumApi = motorCtplAPI.getMotorCtplPremium(carDetailsData.engineType, carDetailsData.bodyType, carDetailsData.plateNumber, "" ,carDetailsData.startDate)
      ctplPremiumApi =
        `engineType=${carDetailsData.engineType}` +
        `&bodyType=${carDetailsData.bodyType}` +
        `&plateNumber=${carDetailsData.plateNumber}` +
        `&startDate=${carDetailsData.startDate}` +
        `&regType=${carDetailsData.regType}`;
    } else {
      // ctplPremiumApi = motorCtplAPI.getMotorCtplPremium(carDetailsData.engineType, carDetailsData.bodyType, carDetailsData.plateNumber,carDetailsData.modelid ,carDetailsData.startDate)
      ctplPremiumApi =
        `engineType=${carDetailsData.engineType}` +
        `&bodyType=${carDetailsData.bodyType}` +
        `&plateNumber=${carDetailsData.plateNumber}` +
        `&modelid=${carDetailsData.modelid}` +
        `&startDate=${carDetailsData.startDate}` +
        `&regType=${carDetailsData.regType}`;
    }

    // console.log(ctplPremiumApi)
    motorCtplAPI
      .getMotorCtplPremium(ctplPremiumApi)
      .then((data) => {
        Cache.setItem("premiumData", data);
        props.saveData({
          ...carDetailsData,
          client: props.clientDetail,
          merchantId: "0003",
          isError: false,
        });
      })
      .catch((error) => {
        console.log(error);
        console.log("There was an error!");
        props.saveData({
          ...carDetailsData,
          client: props.clientDetail,
          merchantId: "0003",
          isError: true,
        });
      });
  };
  // console.log("cardetailsdata", carDetailsData)
  // console.log("clientDetailsState", clientDetailsData)
  return (
    <form onSubmit={SaveForm}>
      <div>
        <div className="text-left mb-24">
          <div className="mb-5">
            <h3 className="text-lg font-bold">
              Kindly review the information below before proceeding
            </h3>
          </div>

          <div className="flex">
            <div className="flex-1 text-left">
              <h3 className="font-semibold" style={{ fontSize: 1.17 + "em" }}>
                Motorcar Details
              </h3>
            </div>
            <div className="flex-1 text-right">
              <button type="button" onClick={props.editCarDetails}>
                <span className="text-blue-500 font-bold text-xs">Edit</span>
              </button>
            </div>
          </div>

          <div className="mt-5">
            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Year
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.year}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Make
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.make}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Model
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.model}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Plate Number
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.plateNumber}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Color
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.color}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Chassis Number
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.chassisNumber}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Engine Number
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.engineNumber}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  MV File Number
                </label>
                <span className="text-lg font-semibold block">
                  {carDetailsData.mvFileNumber}
                </span>
              </div>
            </div>
            {clientDetailsData.clientType === 1 && (
              <>
                <div className="flex mt-10 mb-5">
                  <div className="flex-1 text-left">
                    <span
                      className="font-semibold"
                      style={{ fontSize: 1.17 + "em" }}
                    >
                      Owner Details
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    {/* <button type="button">
                      <span className="text-blue-500 font-bold text-xs">Edit</span>
                    </button> */}
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      First Name
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.firstName}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      Middle Name
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.middleName}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      Last Name
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.lastName}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      Birthday
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.bday}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      Nationality
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.nationality}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      Gender
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.gender}
                    </span>
                  </div>
                </div>
              </>
            )}

            {clientDetailsData.clientType === 2 && (
              <>
                <div className="flex mt-10 mb-5">
                  <div className="flex-1 text-left">
                    <span
                      className="font-semibold"
                      style={{ fontSize: 1.17 + "em" }}
                    >
                      Company Details
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    {/* <button type="button">
                      <span className="text-blue-500 font-bold text-xs">Edit</span>
                    </button> */}
                  </div>
                </div>
                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                      Company Name
                    </label>
                    <span className="text-lg font-semibold block">
                      {clientDetailsData.fullName}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="flex mt-10 mb-5">
              <div className="flex-1 text-left">
                <span
                  className="font-semibold"
                  style={{ fontSize: 1.17 + "em" }}
                >
                  Contact Details
                </span>
              </div>
              {/* <div className="flex-1 text-right">
                <button type="button" onClick={props.editContactDetails}>
                  <span className="text-blue-500 font-bold text-xs">Edit</span>
                </button>
              </div> */}
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Address Type
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.addressType === "H" ? "Home" : "Office"}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Address 1
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.address1}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Address 2
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.address2}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Province/City
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.province}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Contact Type
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.contactType === "M" && "Mobile"}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Contact Number
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.contactNumber}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  ID Type
                </label>
                <span className="text-lg font-semibold block">{idtype}</span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  ID Number
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.idNo}
                </span>
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  Email Address
                </label>
                <span className="text-lg font-semibold block">
                  {clientDetailsData.email}
                </span>
              </div>
            </div>

            <div className="mt-16">
              <p className="block text-center text-xs text-gray-400">
                By clicking this button, I agree to share my personal
                information to Standard Insurance for the purpose of processing
                my application and managing my policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" style={{ display: "none" }} id="btnSubmit">
        submit
      </button>
    </form>
  );
};

export default CTPLFormSummary;
