import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Cache } from "aws-amplify";
// import Header from "../Header";
// import Footer from "../Footer";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";
import TravelReview from "../../components/organisms/Travel-Insurance/TravelReview";

import { Link } from "react-router-dom";

function TravelFormReview(props) {
  
  return (
    <TravelReview/>
  );
}
export default TravelFormReview;
