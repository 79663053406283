import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Header from "../Header";
import { Cache } from "aws-amplify";
const image = [
  {
    imageUrl: "/images/travel/emergency-call_2.png"
  },
  {
    imageUrl: "/images/travel/helicopter.png"
  },
  {
    imageUrl: "/images/travel/no-traveling_5.png"
  },
  {
    imageUrl: "/images/travel/professional_1.png"
  },
  {
    imageUrl: "/images/travel/slide_1.png"
  },

]
const TravelBenefits = (props) => {
  const location = useLocation();
  const benefits = location.state.benefits;

  return (
    <>
      <div>
        <Header
          headerTitle={props.headerTitle}
          subHeader={props.subheaderTitle}
        />
        <div
          className="absolute w-full"
          // style={{
          //   backgroundImage: `url("/images/bg.png")`,
          //   backgroundRepeat: "no-repeat",
          //   width: "250px",
          // }}
        >
          <img alt="motorcar" src={"/images/bg.png"} className="image" />
        </div>
        <div className="p-5 pt-12">
          <img
            src={"/images/sici-logo.png"}
            width="170"
            height="50"
            alt="logo"
          />
        </div>
        {location.state.coverType === "asean" && (
          <div className="pl-5 grid grid-cols-6 gap-4 mt-5">
            
            <h3 className="self-center col-span-5 font-bold">{location.state.packageTitle} Benefits</h3>
          </div>
        )}
        {location.state.coverType === "international" && (
          <div className="pl-5 grid grid-cols-6 gap-4 mt-5">
            <h3 className="self-center col-span-5 font-bold">{location.state.packageTitle}</h3>
          </div>
        )}
        
        <main className="p-5">
          <div>
            {benefits?.map(function (benefit, index) {
              return (
                <>
                  <div>
                  {/* <div className="grid grid-cols-6 gap-1 mt-5"> */}
                  <div className="flex">
                    <img
                      src={(location.state.coverType !== "asean")?image[index].imageUrl:"/images/travel/travelling_1.png"}
                      width="170"
                      height="50"
                      alt="covered-notcovered"
                      className="self-center"
                      style={{width: "20px", height: "20px"}}
                    />
                   <h3 className="my-5 font-bold text-lg  pl-2 col-span-5">
                      {benefit.header}
                    </h3>
                  </div>
                    {/* <h3 className="my-5 font-bold text-lg border-l-4 border-blue-300 pl-2 col-span-5">
                        {benefit.header}
                    </h3> */}

                    <ul className="grid grid-cols-3 gap-4">
                      {benefit.contents.sort((a, b) =>{
                        let d1, d2;
                        
                        // d1 = (a.coverage1 === "0.00" || a.coverage1 === 0.00)? " ": a.coverage1.replace(/\D/g,'')
                        // d2 = (b.coverage1 === "0.00" || b.coverage1 === 0.00)? " ": b.coverage1.replace(/\D/g,'')
                        // if(d1 > d2) {
                        //   console.log(a)
                        //   return -1;
                        // }else {
                        //   console.log(a)
                        //   return 1;
                        // }
                        if(/^\D+$/.test(a.coverage1)) {
                          d1 = a.coverage1;
                          d2 = b.coverage1;
                        } else {
                          d1 = parseInt(a.coverage1.replace(/\D/g,'')) 
                          d2 = parseInt(b.coverage1.replace(/\D/g,'')) 
                          console.log(parseInt(a.coverage1.replace(/\D/g,'')), a.benefit, b)
                        }

                        if(d1 > d2) {
                          return -1;
                        }else {
                          return 1;
                        }
                      //  return parseInt(a.coverage1.replace(/\D/g,'')) > parseInt(b.coverage1.replace(/\D/g,'')) ? -1 : 1
                      //  return a.order > b.order ? 1 : -1
                      } )
                      .map(function (data, index) {
                        return (
                          <>
                            <li className="font-bold col-span-2 text-sm">{data.benefit}</li>
                            <li className="text-right">
                              {" "}
                             
                              <span className="inline-block align-top ml-2 text-sm font-bold text-gray-600 text-right">
                                {data.coverage1 !== "0.00" ? data.coverage1 : ""}
                                {/* {data.coverage1 !== "0.00" ? data.coverage1.replace(/\D/g,'') : ""} */}
                              </span>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>

                  <hr className="my-5" style={{ "--tw-text-opacity": 1, "background-color": "rgb(59 130 246 / 60%)", "height": "1px", "border": 0 }}/>
                </>
              );
            })}
          </div>
        </main>
      </div>
    </>
  );
};

export default TravelBenefits;
