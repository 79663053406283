import React, { useState } from "react";

const FaqPolicyTravel = ({ ...props }) => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            How do I make changes to my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <p className="text-sm">
              If you have any changes in your policy, please contact us at the
              following:
            </p>
            <p className="mt-3 text-sm">
              Mobile Hotline Number: (632) 916-6929-000 (Avail from 8am to 5pm)
            </p>
            <p className="text-sm">
              24-Hour Customer Hotline Number: (632) 8845-1111
            </p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
            <p className="text-sm mt-3">
              Should there be any change in your personal information or
              itinerary, please notify us before the actual date of coverage so
              that we can make the necessary adjustments.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            My travel was extended, can I extend my{" "}
            {props.cover === "international"
              ? "Travel International "
              : "Travel Protect Saver Plus "}
            policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              Yes, you can extend the coverage of your{" "}
              {props.cover === "international"
                ? "Travel International"
                : "Travel Protect Saver Plus"}{" "}
              subject to payment of an additional premium. The insured must
              prepare and submit a written request for a cover extension before
              the policy’s expiration date, stating the reason for such
              extension.
            </p>

            <p className="mt-3 text-sm">
              The insured may send the written request via email to
              <span className="text-blue-500">
                {" "}
                ginsure@standard- insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel3" className="hidden" />
        <label
          htmlFor="panel3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            How do I request a refund?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              To request for a refund, amendments or cancellations, please send
              a written request to{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>{" "}
              before the Inception date of the coverage/policy. All requests
              will be subject to review and approval.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel4" className="hidden" />
        <label
          htmlFor="panel4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            How do I cancel my{" "}
            {props.cover === "international"
              ? "Travel Protect International"
              : "Travel Saver Plus"}{" "}
            policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              To request for a refund, amendments or cancellations, please send
              a written request to ginsure@standard-insurance.com. before the
              Inception date of the coverage/policy. All requests will be
              subject to review and approval.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPolicyTravel;
