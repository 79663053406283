import { useState, useEffect } from "react";
import CTPLCovered from "./CTPL-Covered/Covered";
import CTPLPremium from "./CTPL-Covered/Premium";
import { TabButtons } from "../molecules/TabButtons";
import { Cache } from "aws-amplify";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Modal from "../molecules/Modal";
import { useHistory } from "react-router-dom";
import {DataPrivacyAct} from "../molecules/DataPrivacy" 

const covered = [
  {
    id: "1",
    description: "Death",
    imageUrl: "/images/ctpl/lifeline.png",
  },
  {
    id: "2",

    description: "Burial and funeral",
    imageUrl: "/images/ctpl/tomb.png",
  },
  {
    id: "3",

    description: "Bodily injuries and fractures",
    imageUrl: "/images/ctpl/plaster.png",
  },
  {
    id: "4",

    description: "Permanent disablement",
    imageUrl: "/images/ctpl/crutch.png",
  },
  {
    id: "5",

    description: "Other incindental expenses",
    imageUrl: "/images/ctpl/danger.png",
  },
];

const notCovered = [
  {
    id: "1",
    description:
      "Unlicensed driver and/or driving not in accordance with the law",
    imageUrl: "/images/ctpl/unlicensed.png",
  },
  {
    id: "2",
    description: "Damage to the insured car",
    imageUrl: "/images/ctpl/collision.png",
  },
  {
    id: "3",
    description: "Material damage to any Third Party property",
    imageUrl: "/images/ctpl/car-accident.png",
  },
  {
    id: "4",
    description:
      "Expenses for injuries for family and close relatives, passengers of public transportation, passenger of the insured vehicle",
    imageUrl: "/images/ctpl/list.png",
  },
  {
    id: "5",
    description: "Any expenses in excess of the scheduled limits of liability",
    imageUrl: "/images/ctpl/non-payment.png",
  },
];

const CTPLCoverSection = ({ ...props }) => {
  let history = useHistory();

  const [coveredState, setCoveredState] = useState(0); // 0 - question , 1 - user
  const [tabSectionState, setTabSectionState] = useState([]);

  const coveredSubTitle =
    "Your Compulsory Third Party Liability (CTPL) will cover you in the following cases.";
  const notCoveredSubTitle =
    "Your Compulsory Third Party Liability (CTPL) will not cover you in the following cases.";

  const premiumsSubTitle =
    "Your Compulsory Third Party Liability (CTPL) offers the following premiums.";

  useEffect(() => {
    let tabList = [];
    Cache.setItem("flag", 0);
    tabList = [
      {
        title: "Covered",
        keyId: 0,
        status: "active",
      },
      {
        title: "Not Covered",
        keyId: 1,
        status: "inactive",
      },
      {
        title: "Premium",
        keyId: 2,
        status: "inactive",
      },
    ];

    setTabSectionState(tabList);
  }, []);

  const changeActiveTab = (e) => {
    let activeButton = e.target.value;
    let newList = [];
    tabSectionState.forEach((element) => {
      const tab = { ...element };
      tab.status =
        parseInt(element.keyId) == parseInt(activeButton)
          ? "active"
          : "inactive";
      newList.push(tab);
    });
    setTabSectionState(newList);
  };

  useEffect(() => {
    tabSectionState.forEach((element) => {
      if (element.status === "active") setCoveredState(element.keyId);
    });
  }, [tabSectionState]);

  const SectionState = () => {
    if (coveredState === 0) {
      return (
        <CTPLCovered
          covered={covered}
          subTitle={coveredSubTitle}
          title="covered"
        />
      );
    } else if (coveredState === 1) {
      return (
        <CTPLCovered
          covered={notCovered}
          subTitle={notCoveredSubTitle}
          title="notCovered"
        />
      );
    } else {
      return <CTPLPremium subTitle={premiumsSubTitle} />;
    }
  };
  const [isLoading, setIsLoading] = useState(0);
  const handleDataConfirmation = (event) => {
    const checked = event.target.checked;
    setIsLoading(1);
    if (checked === true) {
      Cache.setItem("flag", 1);
    } else {
      Cache.setItem("flag", 0);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  
  const DisableButton = (event) => {
    Cache.setItem("flag", 0);
  };
 const gotoPackages=()=>{
  Cache.setItem("cptl_year", 3);
  history.push("/ctpl/type/?customerId="+Cache.getItem("customerId"));
 }
  const ctpl = (val) => {
    console.log(val);
    if (val == "1") {
      Cache.setItem("cptl_year", 1);
      //history.push("/ctpl/covered")
      history.push("/ctpl/packages/?year=1&customerId="+Cache.getItem("customerId"));
    }else{
      Cache.setItem("cptl_year", 3);
      history.push("/ctpl/packages/?year=3&customerId="+Cache.getItem("customerId"));
    }
  };

  return (
    <>
      <div className="mb-8">
        <h3>
          CTPL protects you from any possible liability to a third party in the
          event of death or bodily injury caused by motor accident. It is
          required by law upon vehicle registration
        </h3>
      </div>
      <TabButtons ListData={tabSectionState} onClick={changeActiveTab} />
      <SectionState />

      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg mt-5">
        <div className="flex">
          <div className=" text-sm">
            <p className="font-bold mb-3">
              Please prepare the following documents as reference:
            </p>
            <ul className="mb-5">
              <li>1. Certificate of Registration (CR) for Vehicle Details</li>
              <li>2. Valid Government ID</li>
            </ul>
            <p className="font-bold text-xs text-gray-500">
              Please also make sure you have enough GCash Balance to pay for the
              insurance premium.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white p-5 mt-5 more-questions">
        <div className="relative flex">
          <div className="flex-1 flex">
            <div className="pl-3 pr-3">
              <div className="w-full">
                <img
                  src={"/images/help.png"}
                  alt="help"
                  className="covered-image"
                />
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
              </div>
            </div>
            <div className="w-full">
              <h4 className="font-black text-left text-sm">
                Have More Questions
              </h4>
              <p className="text-left text-xs text-gray-400">
                We're here to help
              </p>

              <div className="mt-8 mb-3 text-left">
                <ul>
                  <Link
                    to={"/ctpl/faq"}
                    className="border-b border-gray-300 pb-2 block"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Frequently Asked Questions
                    </span>
                    <div className="absolute footer-position">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                  <Link
                    to={"/contact"}
                    className="border-b border-gray-300 pb-2 block mt-2"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Contact Us
                    </span>
                    <div className="absolute footer-position-contact">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={props.open}
        setOpen={props.setOpen}
        title="Data Privacy"
        saveData={gotoPackages}
        isDisabled={Cache.getItem("flag")}
        disableButton={DisableButton}
        isLoading={isLoading}
      >
        <div>
          {/* <div className="bg-gray-100 rounded-lg text-center p-5 mt-5 shadow border border-gray-100"  onClick={() => ctpl("3")}>
            <h4 className="text-xl font-bold">Brand New</h4>
            <span className="text-sm">3 year coverage</span>
          </div>

          <div className="bg-gray-100 rounded-lg text-center p-5 mt-5 shadow" onClick={() => ctpl("1")}>
            <h4 className="text-xl font-bold">Renewal</h4>
            <span className="text-sm">1 year coverage</span>
          </div> */}
          <form>
          <div className="">
            <div className="flex my-1">
              <div className="pr-3">
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  onChange={handleDataConfirmation}
                />
              </div>
              <DataPrivacyAct />
            </div>
          </div>
        </form>
          
        </div>
      </Modal>
    </>
  );
};

export default CTPLCoverSection;
