import { useState, useEffect, useRef } from "react";
import WizardCTPL from "../WizardCTPL";
import Header from "../Header";
import Modal from "../../molecules/Modal";
import { Cache } from "aws-amplify";
import { motorAPI, fmvAPI } from "../../../api/fetch-api";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomDatePicker from "../../atoms/CustomDatePicker";
import dayjs from "dayjs";

const today = dayjs();

const maxDate = dayjs().subtract(56, "day");
const minDate = dayjs().subtract(9, "year");

const mvPrivateCars = [
  { value: "C", text: "Car" },
  { value: "NC", text: "Non-Conventional MC (Car)" },
  { value: "NV", text: "Non-Conventional MV (UV)" },
  { value: "SV", text: "Sports Utility Vehicle" },
  { value: "UV", text: "Utility Vehicle" },
  { value: "LR", text: "Light Electric Vehicle" },
];

const mvLightTrucks = [
  { value: "NV", text: "Non-Conventional MV (UV)" },
  { value: "SV", text: "Sports Utility Vehicle" },
  { value: "UV", text: "Utility Vehicle" },
  { value: "OB", text: "Tourist Bus" },
  { value: "SB", text: "School Bus" },
  { value: "HB", text: "Shuttle Bus" },
  { value: "TK", text: "Truck" },
];

const mvHeavyTrucks = [
  { value: "HB", text: "Shuttle Bus" },
  { value: "TB", text: "Truck Bus" },
  { value: "OB", text: "Tourist Bus" },
  { value: "SB", text: "School Bus" },
  { value: "TK", text: "Truck" }, //--till here - heavy trucks
  { value: "NV", text: "Non-Conventional MV (UV)" },
  { value: "SV", text: "Sports Utility Vehicle" },
  { value: "UV", text: "Utility Vehicle" },
  { value: "OB", text: "Tourist Bus" },
  { value: "SB", text: "School Bus" },
  { value: "HB", text: "Shuttle Bus" },
  { value: "TK", text: "Truck" },
];

const mvMotorcycles = [
  { value: "M", text: "Motorcycle without Side Car" },
  { value: "MO", text: "Mopeds (0-49 cc)" },
  { value: "MS", text: "Motorcycle with Side Car" },
  { value: "TC", text: "Tricycle" },
  { value: "TL", text: "Trailer" },
];

const truckBodyTypes = [
  { mvPremtype: "1", code: "84", bodyType: "HATCHBACK", mvType: "C" },
  { mvPremtype: "1", code: "203", bodyType: "HYBRID", mvType: "C" },
  { mvPremtype: "1", code: "150", bodyType: "SEDAN", mvType: "C" },
  { mvPremtype: "1", code: "161", bodyType: "SPORTS CAR", mvType: "C" },
  {
    mvPremtype: "1",
    code: "201",
    bodyType: "ASIAN UTILITY VEHICLE (AUV)",
    mvType: "UV",
  },
  {
    mvPremtype: "1",
    code: "204",
    bodyType: "MPV / STATION WAGON",
    mvType: "UV",
  },
  { mvPremtype: "1", code: "133", bodyType: "PICK-UP", mvType: "UV" },
  {
    mvPremtype: "1",
    code: "162",
    bodyType: "SPORTS UTILITY VEHICLE (SUV)",
    mvType: "SV",
  },
  {
    mvPremtype: "1",
    code: "205",
    bodyType: "JEEP / JEEPNEY / JITNEY",
    mvType: "UV",
  },
  { mvPremtype: "1", code: "186", bodyType: "VAN", mvType: "UV" },

  {
    mvPremtype: "2",
    code: "201",
    bodyType: "ASIAN UTILITY VEHICLE (AUV)",
    mvType: "UV",
  },
  { mvPremtype: "2", code: "32", bodyType: "BUS", mvType: "OB" },
  { mvPremtype: "2", code: "198", bodyType: "CAB AND CHASSIS", mvType: "UV" },
  {
    mvPremtype: "2",
    code: "215",
    bodyType: "CARGO VEHICLE (CLOSED)",
    mvType: "UV",
  },
  {
    mvPremtype: "2",
    code: "216",
    bodyType: "CARGO VEHICLE (OPEN)",
    mvType: "UV",
  },
  {
    mvPremtype: "2",
    code: "206",
    bodyType: "CEMENT MIXER / CARRIER",
    mvType: "TK",
  },
  { mvPremtype: "2", code: "68", bodyType: "DUMP TRUCK", mvType: "TK" },
  {
    mvPremtype: "2",
    code: "205",
    bodyType: "JEEP / JEEPNEY / JITNEY",
    mvType: "UV",
  },
  {
    mvPremtype: "2",
    code: "217",
    bodyType: "SPECIAL PURPOSE VEHICLE",
    mvType: "UV",
  },
  {
    mvPremtype: "2",
    code: "170",
    bodyType: "TANKER / TANK LORRY",
    mvType: "TK",
  },
  { mvPremtype: "2", code: "186", bodyType: "VAN", mvType: "UV" },
  {
    mvPremtype: "2",
    code: "209",
    bodyType: "PRIME MOVER / TRACTOR HEAD",
    mvType: "TK",
  },

  { mvPremtype: "3", code: "32", bodyType: "BUS", mvType: "TB" },
  { mvPremtype: "3", code: "198", bodyType: "CAB AND CHASSIS", mvType: "TK" },
  {
    mvPremtype: "3",
    code: "215",
    bodyType: "CARGO VEHICLE (CLOSED)",
    mvType: "TK",
  },
  {
    mvPremtype: "3",
    code: "216",
    bodyType: "CARGO VEHICLE (OPEN)",
    mvType: "TK",
  },
  {
    mvPremtype: "3",
    code: "206",
    bodyType: "CEMENT MIXER / CARRIER",
    mvType: "TK",
  },
  { mvPremtype: "3", code: "68", bodyType: "DUMP TRUCK", mvType: "TK" },
  {
    mvPremtype: "3",
    code: "209",
    bodyType: "PRIME MOVER / TRACTOR HEAD",
    mvType: "TK",
  },
  {
    mvPremtype: "3",
    code: "217",
    bodyType: "SPECIAL PURPOSE VEHICLE",
    mvType: "TK",
  },
  {
    mvPremtype: "3",
    code: "170",
    bodyType: "TANKER / TANK LORRY",
    mvType: "TK",
  },

  { mvPremtype: "7", code: "177", bodyType: "TRAILER", mvType: "TL" },
];

function CTPLCarDetails(props) {
  const [open, setOpen] = useState(false);
  const [openEngine, setOpenEngine] = useState(false);
  const [openMV, setOpenMV] = useState(false);
  const [openMVType, setOpenMVType] = useState(false);

  //const mvType = Cache.getItem("mvType");
  const urlParams = new URLSearchParams(window.location.search);
  const mvType = urlParams.get("mvType");

  const [product, setProduct] = useState(Cache.getItem("product"));

  const [loadingManufacturer, setLoadingManufacturer] = useState(true);
  const [loadingModels, setLoadingModels] = useState(true);
  const [loadingMVType, setLoadingMVType] = useState(true);
  const [loadingDate, setLoadingDate] = useState(0);

  const [makelist, setMakeList] = useState([]);
  const [modellist, setModelList] = useState([]);
  const [mvTypes, setMVTypes] = useState([]);

  const [aboveEarlyReg, setAboveEarlyReg] = useState(false);

  const handleInfoChassis = (event) => {
    setOpen(true);
  };

  const handleInfoEngine = (event) => {
    setOpenEngine(true);
  };

  const handleInfoMV = (event) => {
    setOpenMV(true);
  };

  const handleInfoMVType = (event) => {
    setOpenMVType(true);
  };

  const carDetails = props.carDetails;
  const years = [];
  var lowerYear = new Date().getFullYear() - 16; // Lower bound: Current year - 11
  var upperYear = new Date().getFullYear() + 1; // Upper bound: Current year + 1
  while (upperYear >= lowerYear) {
    years.push(upperYear);
    upperYear--;
  }
  const [year, setYear] = useState(carDetails.year);
  const [make, setMake] = useState(carDetails.make);
  const [model, setModel] = useState(carDetails.model);
  const [modelid, setModelid] = useState(carDetails.modelid);
  const [engineType, setEngineType] = useState(carDetails.engineType);
  const [fmv, setFmv] = useState(carDetails.fmv);
  const [bodyType, setBodyType] = useState(carDetails.bodyType);
  const [seats, setSeats] = useState(carDetails.seats);
  const [carDisplay, setCarDisplay] = useState(carDetails.carDisplay);
  const [color, setColor] = useState(carDetails.color);
  const [chassisNumber, setChassisNumber] = useState(carDetails.chassisNumber);
  const [engineNumber, setEngineNumber] = useState(carDetails.engineNumber);
  const [plateNumber, setPlateNumber] = useState(carDetails.plateNumber);
  const [mvFileNumber, setmvFileNumber] = useState(carDetails.mvFileNumber);
  const [mvFileType, setmvFileType] = useState(carDetails.mvType);
  const [getPlateNumberValidation, setGetPlateNumberValidation] = useState("");
  const [startDate, setStartDate] = useState(carDetails.startDate);
  const [endDate, setEndDate] = useState(carDetails.endDate);
  const [regType, setRegType] = useState(carDetails.regType);
  const [defaultMake, setDefaultMake] = useState(carDetails.make);
  const [insuranceAccountId, setInsuranceAccountId] = useState(
    carDetails.insuranceAccountId
  );

  const today = dayjs();

  let carCondition = "";
  const urlYearParams = new URLSearchParams(window.location.search);
  const yearValue = urlYearParams.get("year");
  const [isAdvanced, setIsAdvanced] = useState(false);

  let currentYear = new Date().getFullYear();
  let currentYearPlus1;

  if (yearValue === "1") {
    carCondition = "R";
    currentYearPlus1 = currentYear + 1;
  } else {
    carCondition = "N";
    currentYearPlus1 = currentYear + 3;
  }

  const SaveForm = (e) => {
    e.preventDefault();
    const carDetailsData = {
      year: year,
      make: make,
      model: model,
      modelid: modelid,
      engineType: engineType,
      fmv: fmv,
      bodyType: bodyType,
      seats: seats,
      carDisplay: carDisplay,
      color: color,
      chassisNumber: chassisNumber,
      engineNumber: engineNumber,
      plateNumber: plateNumber,
      mvFileNumber: mvFileNumber,
      mvType: mvFileType,
      startDate: startDate,
      endDate: endDate,
      regType: regType,
      mvPremtype: mvType,
      insuranceAccountId: props.accountId,
    };
    props.saveData(carDetailsData);
    props.productState(product);
  };
  function is_valid_plate_number(plateNumber, target) {
    // function validatePlateNumber(plateNumber, target) {
    // Check the length of the plate number
    if (plateNumber.length < 5 || plateNumber.length > 8) {
      target.setCustomValidity("Invalid Plate Number");
      return false;
    }

    // Check if the plate number contains only alphanumeric characters
    let previousNumber = "";
    let numericCount = 0;
    for (let i = 0; i < plateNumber.length; i++) {
      if (!/[a-zA-Z0-9]/.test(plateNumber[i])) {
        target.setCustomValidity("Invalid Plate Number");
        return false;
      }

      if (plateNumber[i] >= 0 && plateNumber[i] <= 9) {
        numericCount++;
      }

      if (numericCount === 7 || numericCount === 8) {
        let isAscending = true;
        let isDescending = true;
        for (let j = 1; j < plateNumber.length; j++) {
          if (plateNumber[j] < plateNumber[j - 1]) {
            isAscending = false;
          }
          if (plateNumber[j] > plateNumber[j - 1]) {
            isDescending = false;
          }
        }

        if (isAscending || isDescending) {
          target.setCustomValidity("Invalid Plate Number");
          return false;
        }
      }

      // if (plateNumber[i] === previousNumber) {
      //   if (/[0-9]/.test(plateNumber[i]) && (numericCount === 7 || numericCount === 8)) {
      //     console.log("3:", plateNumber, "--", plateNumber[i], previousNumber)
      //     target.setCustomValidity("Invalid Plate Number");
      //     return false;
      //   }
      // }

      if (plateNumber[i] === previousNumber) {
        let repetitiveCount = 1;
        for (let j = i + 1; j < plateNumber.length; j++) {
          if (plateNumber[j] === plateNumber[i]) {
            repetitiveCount++;
          }
        }

        if (repetitiveCount > 7 || repetitiveCount > 8) {
          target.setCustomValidity("Invalid Plate Number");
          return false;
        }
      }
      previousNumber = plateNumber[i];
    }

    // The plate number is valid
    target.setCustomValidity("");
    return true;
  }
  const handleDateChange = (name, date) => {
    // Here you get both the name and the selected date
    console.log("Name:", name);
    console.log("Date:", dayjs(date));
    const customEvent = {
      type: "customEvent",
      detail: {
        value: date,
      },
      target: {
        name: name,
      },
    };

    // // Dispatch the custom event
    handleUpdates(
      new CustomEvent("startDate", {
        detail: customEvent,
        target: {
          name: name,
        },
      })
    );
  };
  const handleUpdates = (e) => {
    let objName =
      e.target?.name != null && e.target?.name != undefined
        ? e.target.name
        : "";
    if (e?.detail?.target?.name === "startDate") {
      if (
        carCondition == "R" &&
        plateNumber != undefined &&
        plateNumber != ""
      ) {
        GetPeriodType(
          plateNumber,
          dayjs(e?.detail?.detail?.value).format("MM/DD/YYYY")
        );
      } else {
        //START

        const inputDate = new Date(
          dayjs(e?.detail?.detail?.value).format("MM/DD/YYYY")
        );

        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;

        //document.getElementsByName("startDate")[0].value = formattedDate;
        setStartDate(
          dayjs(String(formattedDate + " 00:00"), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        );

        inputDate.setFullYear(inputDate.getFullYear() + 3);
        const yearEnd = inputDate.getFullYear();
        const monthEnd = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const dayEnd = String(inputDate.getDate()).padStart(2, "0");

        const formattedEndDate = `${yearEnd}-${monthEnd}-${dayEnd}`;

        setEndDate(
          dayjs(String(formattedEndDate + " 00:00"), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        );

        
      }
      //fixed start end date if brandnew
      // let startDate = new Date(e.target.value);
      // let currentYear = new Date(startDate).getFullYear();
      // let endDateYear = currentYear + 3;
      // let getMonth = startDate.getMonth() + 1;
      // startDate =
      //   startDate.getDate() < 10
      //     ? "0" + startDate.getDate()
      //     : startDate.getDate();
      // getMonth = getMonth < 10 ? "0" + getMonth : getMonth;
      // if (carCondition !== "N") {
      //   if (/[0-9]/i.test(plateNumber)) {
      //     GetPeriodType(plateNumber, e.target.value);
      //     setStartDate(e.target.value);
      //     carDetails.startDate = e.target.value;
      //   }
      // } else {
      //   let end_ = endDateYear + "-" + getMonth + "-" + startDate;
      //   setEndDate(endDateYear + "-" + getMonth + "-" + startDate);
      //   //document.getElementsByName("endDate")[0].value = end_.toString();
      // }
    } else if (objName === "year") {
      setYear(e.target.value);
      setMake("");
    } else if (objName === "make") {
      setModelid("");
      setMake(e.target.value);
    } else if (objName === "model") {
      let defaultMvType;
      setModelid(e.target.value);
      var index = e.target.selectedIndex;
      setModel(e.target[index].text);
      setEngineType(e.target[index].dataset.enginetype);

      setFmv(e.target[index].dataset.fmv);

      let bodyTypes = truckBodyTypes.filter((d) => {
        return (
          d.bodyType == e.target[index].dataset.bodytype &&
          mvType === d.mvPremtype
        );
      });

      if (mvType === "1" || mvType === "7") {
        setBodyType(e.target[index].dataset.bodytype);
      } else {
        setBodyType(bodyTypes[0]?.code);
      }

      setSeats(e.target[index].dataset.seats);
      setCarDisplay(
        e.target[index].dataset.model +
          " " +
          (e.target[index].dataset.variant != null
            ? e.target[index].dataset.variant
            : "") +
          " " +
          e.target[index].dataset.transmission +
          " " +
          e.target[index].dataset.enginedispl
      );

      //mvtype dropdown
      let m;
      if (mvType === "1") {
        m = modellist.find(function (m) {
          // console.log(m);
          return m.unid.toString() === e.target.value;
        });
        if (m !== undefined) {
          defaultMvType = truckBodyTypes.find(function (mv) {
            return mv.bodyType === m.bodyType;
          });
          document.getElementsByName("mvFileType")[0].value =
            defaultMvType.mvType;
          setmvFileType(defaultMvType.mvType);
        }
      } else {
        // if()
        m = modellist.find(function (m) {
          return m.id.toString() === e.target.value;
        });
        if (m !== undefined) {
          if (mvType === "7" && m.BODY_TYPE !== "TRAILER") {
            defaultMvType = m.BODY_TYPE != "TRAILER" ? "M" : "TL";
            document.getElementsByName("mvFileType")[0].value = defaultMvType;
            setmvFileType(defaultMvType);
          } else {
            defaultMvType = truckBodyTypes.find(function (mv) {
              return mv.bodyType === m.BODY_TYPE && mv.mvPremtype === mvType;
            });
            document.getElementsByName("mvFileType")[0].value =
              defaultMvType?.mvType;
            setmvFileType(defaultMvType?.mvType);
            if (mvType == "2" || mvType == "3" || mvType == "7") {
              //
            }
          }
        }
      }
    } else if (objName === "plateNumber") {
      e.target.setCustomValidity("");

      is_valid_plate_number(e.target.value, e.target);
      // const pattern = /^(?!^\d{7,8}$)(?!^(?:\d{1,6}|[A-Za-z]{1,6})\1$)(?!^\d{0,6}[A-Za-z]{1,6}$)[A-Za-z0-9]{5,8}$/;
      // const pattern = /^(?!(\d{7,8})$)(?!^(\d{1,6}|[A-Za-z]{1,6})\1$)(?!^\d*(?:012345|123456|234567|345678|876543|987654)\d*$)\w{5,8}$/;
      // const pattern = /^(?!(\d{7,8})$)(?!^(\d{1,6}|[A-Za-z]{1,6})\1$)(?!^\d+(?:012345|123456|234567|345678|876543|987654)\d*$)\w{5,8}$/;
      // const value = e.target.value;
      // console.log("pattern: ", pattern.test(value)+"--"+pattern.test("11111111"))
      // if (!pattern.test(value)) {
      //   e.target.setCustomValidity("Invalid Plate Numbersssss");
      // } else {
      //   e.target.setCustomValidity("");
      // }

      function areAllNumbers(str) {
        for (var i = 0; i < str.length; i++) {
          if (isNaN(Number(str[i]))) {
            return false;
          }
        }

        return true;
      }
      const checkString = e.target.value;
      if (areAllNumbers(checkString)) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        setStartDate(
          dayjs(formattedDate + " 00:00", "YYYY-MM-DD").format("YYYY-MM-DD")
        );
        //setStartDate(formattedDate);
        //document.getElementsByName("startDate")[0].value = formattedDate;
        const getStartDate = formattedDate;

        const currentEndDate = new Date(getStartDate);
        currentEndDate.setFullYear(currentEndDate.getFullYear() + 1);

        const yearEnd = currentEndDate.getFullYear();
        const monthEnd = (currentEndDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const dayEnd = currentEndDate.getDate().toString().padStart(2, "0");

        const formattedEndDate = `${yearEnd}-${monthEnd}-${dayEnd}`;
        setEndDate(formattedEndDate);
        //document.getElementsByName("endDate")[0].value = formattedEndDate;
      } else {
        if (carCondition == "R") {
          GetPlateStartDate(e.target.value);

          // const year = inputDate.getFullYear();
          // const month = String(inputDate.getMonth() + 1).padStart(2, "0");
          // const day = String(inputDate.getDate()).padStart(2, "0");

          // const formattedDate = `${year}-${month}-${day}`;

          // setStartDate(dayjs(formattedDate+' 00:00', 'YYYY-MM-DD').format('YYYY-MM-DD'));

          //document.getElementsByName("startDate")[0].value = formattedDate;

          console.log("checkkk", GetPlateStartDate(e.target.value));
          const inputEndDate = GetPlateStartDate(e.target.value);
          inputEndDate.setFullYear(inputEndDate.getFullYear() + 1);

          const yearEnd = inputEndDate.getFullYear();
          const monthEnd = String(inputEndDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const dayEnd = String(inputEndDate.getDate()).padStart(2, "0");

          const formattedEndDate = `${yearEnd}-${monthEnd}-${dayEnd}`;
          setEndDate(
            dayjs(String(formattedEndDate + " 00:00"), "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            )
          );
          //document.getElementsByName("endDate")[0].value = formattedEndDate;
        }
      }

      setPlateNumber(e.target.value);

      //sample regex for platenumber [a-zA-Z]{2,3}[0-9]{3,4}|[0-9]{3,4}[a-zA-Z]{2,3}
      //ex 2-3 alpha and 3-4 numeric
      //true - if contains digit
      // if (/[0-9]/i.test(e.target.value) && carCondition === "R") {
      //   console.log("test platenumber", plateNumber);
      //   console.log(/[0-9]/i.test(e.target.value));
      //   if (carCondition === "R") {
      //     if (mvType == "7") {
      //       const getNumber = e.target.value;
      //       getPlateNumber(getNumber.replace(/\D/g, ""));
      //     } else {
      //       getPlateNumber(e.target.value);
      //     }
      //   } else {
      //     if (mvType == "7") {
      //       const getNumber = e.target.value;
      //       getPlateNumber(getNumber.replace(/\D/g, ""));
      //     } else {
      //       getPlateNumber(e.target.value);
      //     }
      //   }

      //   let newDate = new Date(GetPlateStartDate(e.target.value)); //endplate date
      //   let todays = new Date();

      //   //diff = endplate - current date
      //   //limit date from endplate to current date  - if more than 60days = limit to 60days
      //   let endPlatetoToday = GetDateDiff(todays, newDate);
      //   if (endPlatetoToday > 60) {
      //     //limit to max 60days if current date exceeds 60days
      //     newDate = newDate.setDate(newDate.getDate() - 60);

      //     let endPlateDate = new Date(GetPlateStartDate(e.target.value));
      //     let todays_ = new Date(
      //       endPlateDate.setFullYear(endPlateDate.getFullYear())
      //     );

      //     todays_ = todays_.setDate(todays_.getDate() - 60);
      //     todays_ = new Date(todays_);
      //     let today_mm = todays_.getMonth() + 1;
      //     today_mm = today_mm < 10 ? "0" + today_mm : today_mm;
      //     let today_dd =
      //       todays_.getDate() < 10
      //         ? "0" + todays_.getDate()
      //         : todays_.getDate();
      //     document.getElementsByName("startDate")[0].value =
      //       todays_.getFullYear() + "-" + today_mm + "-" + today_dd;
      //     setStartDate(todays_.getFullYear() + "-" + today_mm + "-" + today_dd);
      //     carDetails.startDate = new Date(
      //       todays_.getFullYear() + "-" + today_mm + "-" + today_dd
      //     );
      //     setAboveEarlyReg(true);
      //   } else {
      //     //below 60 days limited until current date
      //     newDate = newDate.setDate(newDate.getDate() - endPlatetoToday);

      //     //set startdate
      //     let todays_ = new Date(todays);
      //     let today_mm = todays_.getMonth() + 1;
      //     today_mm = today_mm < 10 ? "0" + today_mm : today_mm;
      //     let today_dd =
      //       todays_.getDate() < 10
      //         ? "0" + todays_.getDate()
      //         : todays_.getDate();
      //     document.getElementsByName("startDate")[0].value =
      //       todays_.getFullYear() + "-" + today_mm + "-" + today_dd;
      //     setStartDate(todays_.getFullYear() + "-" + today_mm + "-" + today_dd);
      //     carDetails.startDate = new Date(
      //       todays_.getFullYear() + "-" + today_mm + "-" + today_dd
      //     );
      //     setAboveEarlyReg(false);
      //   }
      //   newDate = new Date(newDate).toISOString().split("T")[0];
      //   let maxStartDate = GetPlateStartDate(e.target.value);
      //   maxStartDate = maxStartDate.setDate(maxStartDate.getDate() + 1);
      //   maxStartDate = new Date(maxStartDate).toISOString().split("T")[0];
      //   document.getElementsByName("startDate")[0].setAttribute("min", newDate);
      // } else {
      //   if (carCondition === "R") {
      //     document.getElementsByName("startDate")[0].value = "";
      //   }
      // }
    } else if (objName === "color") {
      setColor(e.target.value);
    } else if (objName === "chassisNumber") {
      setChassisNumber(e.target.value);
    } else if (objName === "engineNumber") {
      setEngineNumber(e.target.value);
    } else if (objName === "mvFileNumber") {
      setmvFileNumber(e.target.value);
    } else if (objName === "mvFileType") {
      setmvFileType(e.target.value);
    } else if (objName === "engineType") {
      //
    }
  };

  useEffect(() => {
    setRegType(carCondition);
    //fixed start end date if brandnew
    let d_today = new Date();
    let currentYear = new Date().getFullYear();
    let endDateYear = currentYear + 3;
    let getMonth = d_today.getMonth() + 1;
    let getDate =
      d_today.getDate() < 10 ? "0" + d_today.getDate() : d_today.getDate();
    getMonth = getMonth < 10 ? "0" + getMonth : getMonth;
    if (carCondition === "N") {
      setStartDate(
        dayjs(
          currentYear + "-" + getMonth + "-" + getDate + " 00:00",
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")
      );
      // document.getElementsByName("startDate")[0].value =
      //   currentYear + "-" + getMonth + "-" + getDate;
      let minDate = new Date(d_today).toISOString().split("T")[0];
      //document.getElementsByName("startDate")[0].setAttribute("min", minDate);
      setEndDate(
        dayjs(
          endDateYear + "-" + getMonth + "-" + getDate + " 00:00",
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")
      );
      //setEndDate(endDateYear + "-" + getMonth + "-" + getDate);
      // //document.getElementsByName("endDate")[0].value =
      //   endDateYear + "-" + getMonth + "-" + getDate;
      // document.getElementsByName("endDate")[0].disabled = true;
    }
  }, []);
  useEffect(() => {
    setLoadingMVType(false);
    if (mvType == "1") {
      //Private Car
      setMVTypes(mvPrivateCars);
    } else if (mvType == "7") {
      //Motorcycle
      setProduct("motorcycle");
      setMVTypes(mvMotorcycles);
    } else if (mvType == "2" || mvType == "3") {
      //Light Trucks
      setProduct("truck-bus");
      if (mvType == "3") {
        setMVTypes(mvHeavyTrucks);
      } else {
        setMVTypes(mvLightTrucks);
      }
    }
  }, [mvType]);

  useEffect(() => {
    setLoadingManufacturer(true);
    if (year !== undefined && year !== "") {
      setTimeout(() => {
        if (mvType !== "1" && product !== "") {
          GetCTPLManufacturer(product, year);
        }
        if (mvType == "1") {
          GetCTPLPrivatecarManufacturer();
        }
      }, 500);
    }
  }, [year, product]);

  useEffect(() => {
    setLoadingModels(true);
    if (year !== "" && make !== "") {
      if (
        (year !== undefined && make !== undefined) ||
        year !== null ||
        make !== null
      ) {
        setTimeout(() => {
          if (
            mvType !== "1" &&
            typeof year !== "undefined" &&
            year &&
            typeof make !== "undefined" &&
            make &&
            typeof product !== "undefined" &&
            product
          ) {
            GetCTPLModel(product, year, make);
          }
          if (
            mvType == "1" &&
            typeof year !== "undefined" &&
            year &&
            typeof make !== "undefined" &&
            make
          ) {
            console.log(
              "year make test",
              year !== undefined && make !== undefined,
              "-",
              make,
              "-",
              year
            );
            GetCTPLPrivatecarModel(year, make);
          }
        }, 500);
      }
    }
  }, [year, make, product]);

  useEffect(() => {
    setLoadingDate(true);
    setTimeout(() => {
      setLoadingDate(false);
    }, 500);
  }, [startDate, endDate]);

  const GetCTPLManufacturer = (product, year) => {
    fmvAPI
      .getCTPLManufacturerList(product, year)
      .then((data) => {
        const manufacturersList = data.Items;
        const manufacturer = [
          ...new Set(manufacturersList.map((m) => m.MAKE).sort()),
        ];

        setMakeList(manufacturer);
        setLoadingManufacturer(false);
        if (make !== undefined) {
          document.getElementsByName("make")[0].value = make;
        }
      })
      .catch((err) => console.log("FMV Error: ", err));
  };

  const GetCTPLModel = (product, year, make) => {
    fmvAPI
      .getCTPLModelList(product, year, make)
      .then((data) => {
        setLoadingModels(false);
        const modelList = data.Items;
        setModelList([]);

        setModelList(modelList);
        if (model !== undefined) {
          document.getElementsByName("model")[0].value = modelid;
        }
      })
      .catch((err) => console.log("FMV Error: ", err));
  };

  const GetCTPLPrivatecarManufacturer = () => {
    motorAPI
      .getMakeList()
      .then((data) => {
        setLoadingManufacturer(false);
        setMakeList(data);
        if (make !== undefined) {
          document.getElementsByName("make")[0].value = make;
        }
      })
      .catch((err) => console.log("FMV Error: ", err));
  };

  const GetCTPLPrivatecarModel = (year, make) => {
    let data = {
      year: year,
      manufacturer: make,
    };

    motorAPI
      .getModelList(data)
      .then((data) => {
        setModelList([]);
        setModelList(data);
        setLoadingModels(false);
        if (model !== undefined) {
          //
        }
      })
      .catch((err) => console.log("FMV Error: ", err));
  };
  const getPlateNumber = (plateNo) => {
    let txt = plateNo;
    let numb = txt.match(/\d/g);
    if (numb) {
      setGetPlateNumberValidation(numb[numb.length - 1]);
    }
  };

  ///////////////////////////

  const [periodType, setPeriodType] = useState("");
  const [premiumPay, setPremiumPay] = useState(0);
  const [basic, setBasic] = useState(0);
  const [evat, setEvat] = useState(0);
  const [dst, setDst] = useState(0);
  const [lgt, setLgt] = useState(0);
  const fee = 50.4;
  const [tax, setTax] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);

  useEffect(() => {}, [
    periodType,
    premiumPay,
    basic,
    evat,
    dst,
    lgt,
    tax,
    totalPayment,
  ]);

  function GetTotalPayment(basicPay, startDate, endDate) {
    var bp = basicPay;

    var days = 0;

    if (typeof startDate !== "undefined") {
      const d1 = new Date(startDate);

      const d2 = new Date(endDate);

      const diffTime = Math.abs(d2 - d1);

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      days = diffDays;
    }

    setBasic(() => {
      return (bp / 365) * days;
    });

    setEvat(() => {
      return basic * 0.12;
    });

    setDst(() => {
      return basic * 0.125;
    });

    setLgt(() => {
      return basic * 0.002;
    });

    setTax(() => {
      return evat + dst + lgt;
    });

    setTotalPayment(() => {
      return basic + tax + fee;
    });
  }

  function GetDateDiff(startDate, endDate) {
    const d1 = new Date(startDate);
    const d2 = new Date(endDate);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  function areAllNumbers(str) {
    for (var i = 0; i < str.length; i++) {
      if (isNaN(Number(str[i]))) {
        return false;
      }
    }

    return true;
  }

  function GetPlateStartDate(platenumber) {
    let plateNumberRegex = platenumber;
    let plateStartDate = "";
    if (plateNumberRegex !== undefined) {
      plateNumberRegex = plateNumberRegex.replace(/\D/g, "");

      if (mvType == "7") {
        const getNumber = plateNumberRegex;
        plateNumberRegex = getNumber.replace(/\D/g, "");
      }

      const lastDigit = parseInt(
        plateNumberRegex.substr(plateNumberRegex.length - 1)
      );

      console.log("LAST-DIGIT", lastDigit);

      //Get StartDate
      if (typeof lastDigit !== "number") return "";
      if (lastDigit == 1) {
        plateStartDate = "02-01-" + new Date().getFullYear();
      } else if (lastDigit == 1) {
        plateStartDate = "02-01-" + new Date().getFullYear();
      } else if (lastDigit == 2) {
        plateStartDate = "03-01-" + new Date().getFullYear();
      } else if (lastDigit == 3) {
        plateStartDate = "04-01-" + new Date().getFullYear();
      } else if (lastDigit === 4) {
        plateStartDate = "05-01-" + new Date().getFullYear();
      } else if (lastDigit === 5) {
        plateStartDate = "06-01-" + new Date().getFullYear();
      } else if (lastDigit === 6) {
        plateStartDate = "07-01-" + new Date().getFullYear();
      } else if (lastDigit === 7) {
        plateStartDate = "08-01-" + new Date().getFullYear();
      } else if (lastDigit === 8) {
        plateStartDate = "09-01-" + new Date().getFullYear();
      } else if (lastDigit === 9) {
        plateStartDate = "10-01-" + new Date().getFullYear();
      } else if (lastDigit === 0) {
        plateStartDate = "11-01-" + new Date().getFullYear();
      }
      // const startDate = new Date(plateStartDate);

      console.log("plateaaa", new Date());
      console.log("plat11", plateStartDate);

      // Parse the date string
      let parts = plateStartDate.split("-");
      let month = parseInt(parts[0], 10) - 1; // months are zero-indexed
      let day = parseInt(parts[1], 10);
      let year = parseInt(parts[2], 10);

      // Create a new Date object
      const result = new Date(year, month, day);

      result.setDate(result.getDate() - 60);

      console.log("RESULT", result);
      //alert(result);
      //setStartDate(dayjs('2024-03-27 11:49', 'YYYY-MM-DD').format('YYYY-MM-DD'));

      if (result < new Date() && new Date() > result) {
        //$scope.startDate = moment(new Date()).format("YYYY-MM-DD");
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        console.log("startDate: 1===11", String(formattedDate + " 00:00"));
        setStartDate(
          dayjs(String(formattedDate + " 00:00"), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        );
        //setStartDate(formattedDate);

        //document.getElementsByName("startDate")[0].value = formattedDate;
      } else {
        const currentDate = new Date(result);
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        //setStartDate(formattedDate);
        setStartDate(
          dayjs(String(formattedDate + " 00:00"), "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )
        );

        console.log("startDate: 2====", formattedDate);
      }
      console.log("startDate: ====", startDate);

      // if (result < new Date() && new Date() > result) {
      //   $scope.startDate = moment(new Date()).format("YYYY-MM-DD");
      //   $scope.earlyMessage = "";

      // } else {
      //   console.log('HERE 61')
      //   $scope.startDate = moment(result).format("YYYY-MM-DD");
      //   $scope.earlyMessage =
      //     "We are only allowing 60 days early registration based on the vehicle's plate number assigned period of coverage.";
      // }

      //document.getElementsByName("startDate")[0].value = startDate;

      const _startDate = new Date(year, month, day);
      return _startDate;
    }
  }

  function GetPremiumPay(vehicle, vehicleType) {
    let premium = 0;

    if (vehicle == "Private Cars") {
      if (vehicleType == "Brand New") {
        premium = 1610;
      } else if (vehicleType == "Renewal") {
        premium = 610.4;
      }
    } else if (vehicle == "Motorcycles") {
      if (vehicleType == "Brand New") {
        premium = 720;
      } else if (vehicleType == "Renewal") {
        premium = 300.4;
      }
    } else if (vehicle == "Light Medium Trucks") {
      if (vehicleType == "Brand New") {
        premium = 1750;
      } else if (vehicleType == "Renewal") {
        premium = 660.4;
      }
    } else if (vehicle == "Heavy Trucks") {
      if (vehicleType == "Brand New") {
        premium = 3440;
      } else if (vehicleType == "Renewal") {
        premium = 1250.4;
      }
    }

    return premium;
  }

  const GetPeriodType = (plateNumber, startDate) => {
    const plateStartDate = GetPlateStartDate(plateNumber);
    let startD = new Date(startDate);

    let endDate = new Date(
      new Date(plateStartDate).setFullYear(plateStartDate.getFullYear() + 1)
    );

    if (plateStartDate === "") {
      alert("Invalid Plate number!");
    }

    console.log("START DATE-----", startD);

    //Diff from Start Date and platenumber startdate
    const StartDiffDays = GetDateDiff(startD, plateStartDate);
    if (StartDiffDays <= 60 && StartDiffDays >= 1) {
      console.log("EARLY");
      // setEndDate(end_.getFullYear() + "-" + end_mm + "-" + end_dd);
      const currentDate = new Date(startD);
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      setStartDate(
        dayjs(formattedDate + " 00:00", "YYYY-MM-DD").format("YYYY-MM-DD")
      );
      //setStartDate(formattedDate);
      //document.getElementsByName("startDate")[0].value = formattedDate;
    } else {
      let diffDays = GetDateDiff(startDate, endDate);
      const currentDate = new Date(startD);
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      setStartDate(
        dayjs(formattedDate + " 00:00", "YYYY-MM-DD").format("YYYY-MM-DD")
      );
      //setStartDate(formattedDate);
      //document.getElementsByName("startDate")[0].value = formattedDate;

      if (diffDays >= 183) {
        //short
      } else {
        let end_ = new Date(endDate.setFullYear(endDate.getFullYear() + 1));
        let end_mm = end_.getMonth() + 1;
        end_mm = end_mm < 10 ? "0" + end_mm : end_mm;
        let end_dd =
          end_.getDate() < 10 ? "0" + end_.getDate() : end_.getDate();
        console.log(
          "EXTENDED DATE",
          new Date(endDate.setFullYear(endDate.getFullYear() + 1))
        );
        setEndDate(end_.getFullYear() + "-" + end_mm + "-" + end_dd);
        //document.getElementsByName("endDate")[0].value = end_.getFullYear() + "-" + end_mm + "-" + end_dd;
      }
    }
    //Get Premium
    setPremiumPay(() => {
      return GetPremiumPay("Private Cars", "Brand New");
    });
    //Compute Total
    setTotalPayment(() => {
      return GetTotalPayment(premiumPay, startDate, endDate);
    });
  };

  const plateNumberOnBlur = (e) => {};
  ///////////////////////////

  // const getStartEnd = (e) => {
  //   if (carCondition === "R") {
  //     plateNumberOnBlur(e);
  //     if (getPlateNumberValidation == "1") {
  //       setEndDate(currentYearPlus1 + "-02-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-02-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-02-01");
  //     } else if (getPlateNumberValidation == "2") {
  //       setEndDate(currentYearPlus1 + "-03-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-03-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-03-01");
  //     } else if (getPlateNumberValidation == "3") {
  //       setEndDate(currentYearPlus1 + "-04-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-04-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-04-01");
  //     } else if (getPlateNumberValidation == "4") {
  //       setEndDate(currentYearPlus1 + "-05-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-05-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-05-01");
  //     } else if (getPlateNumberValidation == "5") {
  //       setEndDate(currentYearPlus1 + "-06-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-06-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-06-01");
  //     } else if (getPlateNumberValidation == "6") {
  //       setEndDate(currentYearPlus1 + "-07-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-07-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-07-01");
  //     } else if (getPlateNumberValidation == "7") {
  //       setEndDate(currentYearPlus1 + "-08-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-08-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-08-01");
  //     } else if (getPlateNumberValidation == "8") {
  //       setEndDate(currentYearPlus1 + "-09-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-09-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-09-01");
  //     } else if (getPlateNumberValidation == "9") {
  //       setEndDate(currentYearPlus1 + "-10-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-10-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-10-01");
  //     } else if (getPlateNumberValidation == "0") {
  //       setEndDate(currentYearPlus1 + "-11-01");
  //       //document.getElementsByName("endDate")[0].value =
  //         currentYearPlus1 + "-11-01";
  //       carDetails.endDate = new Date(currentYearPlus1 + "-11-01");
  //     }
  //     GetPeriodType(plateNumber, new Date());
  //   }

  //   let dateToday = new Date();
  //   const plateStartDate = GetPlateStartDate(plateNumber);
  //   let diff = GetDateDiff(dateToday, new Date(plateStartDate));
  //   if (diff > 60) {
  //     setIsAdvanced(true);
  //   } else {
  //     setIsAdvanced(false);
  //   }
  // };

  const minDate = () => {
    var today = new Date();
    var tods = today.setDate(today.getDate() + 1);

    console.log(new Date(tods).toString());
    return tods;
  };

  const gotoAdvisory = () => {
    window.location.replace(
      "http://docs.google.com/gview?embedded=true&url=https://sici-staging-v2.s3.ap-southeast-1.amazonaws.com/files/SI_CTPL_Advisory_2022.pdf"
    );
  };

  return (
    <>
      <main>
        <form onSubmit={SaveForm}>
          <div className="text-left mb-32">
            <div className="mb-5">
              <h3 className="text-lg font-bold">
                Please provide details of your vehicle below:
              </h3>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Year:
              </label>
              <div className="block relative w-full">
                <select
                  name="year"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  onChange={handleUpdates}
                  defaultValue={carDetails.year}
                  required
                >
                  <option value="">Select Year</option>
                  {years
                    .filter((year) => {
                      if (carCondition == "N") {
                        // if(currentYear-2 == year){
                        //   return true;
                        // }
                        if (mvType === "1") {
                          if (
                            currentYear + 1 == year ||
                            currentYear == year ||
                            currentYear - 1 == year
                          ) {
                            return true;
                          }
                        }
                        if (
                          mvType === "2" ||
                          mvType === "3" ||
                          mvType === "7"
                        ) {
                          if (currentYear + 1 == year || currentYear == year) {
                            return true;
                          }
                        }
                      } else {
                        if (
                          mvType === "1" ||
                          mvType === "2" ||
                          mvType === "3" ||
                          mvType === "7"
                        ) {
                          if (
                            currentYear - 1 >= year &&
                            currentYear - 16 <= year
                          )
                            return true;
                        }
                      }
                    })
                    .map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Make:
              </label>
              <div className="block relative w-full">
                {loadingManufacturer || (
                  <select
                    name="make"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.make}
                    required
                  >
                    <option value="">Select Make</option>
                    {mvType == "1" &&
                      makelist.map((page, index) => (
                        <option value={page.name} key={index}>
                          {page.description}
                        </option>
                      ))}
                    {mvType !== "1" &&
                      makelist.map((page, index) => (
                        <option value={page} key={index}>
                          {page}
                        </option>
                      ))}
                  </select>
                )}

                {loadingManufacturer && (
                  <select
                    name="make"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.make}
                    required
                  >
                    <option value="">No Data</option>
                  </select>
                )}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Model:
              </label>
              <div className="block relative w-full">
                {loadingModels || (
                  <select
                    name="model"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.modelid}
                    required
                  >
                    <option value="">Select Model</option>
                    {mvType == "1" &&
                      modellist.map((m, index) => (
                        <option
                          value={m.unid}
                          key={index}
                          data-bodytype={m.bodyType}
                          data-enginetype={m.engineType}
                          data-fmv={m.fmv}
                          data-seats={m.seats}
                        >
                          {m.model +
                            " " +
                            m.variant +
                            " " +
                            m.transmission +
                            " " +
                            m.engineDispl}
                        </option>
                      ))}
                    {mvType !== "1" &&
                      modellist.map((m, index) => (
                        <option
                          value={m.id}
                          key={m.id}
                          data-bodytype={m.BODY_TYPE}
                          data-code={m.code}
                          data-enginetype={m.ENGINE_TYPE}
                          data-fmv={m.FMV}
                          data-seats={m.seats || m.SEATING_CAPACITY}
                        >
                          {m.MODEL +
                            " " +
                            m.VARIANT +
                            " " +
                            m.TRANSMISSION +
                            " " +
                            m.ENGINE_DISPLACEMENT}
                        </option>
                      ))}
                  </select>
                )}

                {loadingModels && (
                  <select
                    name="model"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.modelid}
                    required
                  >
                    <option value="">No Data</option>
                  </select>
                )}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Plate Number:
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="plateNumber"
                  placeholder="e.g. ABC1298"
                  onChange={handleUpdates}
                  // onBlur={getStartEnd}
                  defaultValue={carDetails.plateNumber}
                  required
                  maxLength="8"
                  minLength="5"
                  // pattern="^(?!(\d)\1{6,7}$)(?!(\d)(\d{0,1}-1){6,7}$)([A-Za-z0-9]{5,8})$" //latest
                  // pattern="^([A-Za-z0-9]{5,8})(?!\d{7,8}(?!.\d{1,6}\1{6,7}|\d{0,1}-\d{1,6}\1{6,7}|\d-\d{1,6}\1{6,7}))(?!.[1-9]{7,8})$"
                  title="&nbsp;"
                  // onInvalid={(e) => {
                  //   const pattern = /^[A-Za-z0-9]{5,8}(?!\d{7,8}(?!.\d{1,6}\1{6,7}|\d{0,1}-\d{1,6}\1{6,7}|\d-\d{1,6}\1{6,7}))(?!.[1-9]{7,8})$/;
                  //   const value = e.target.value;

                  //   if (pattern.test(value)) {
                  //     e.target.setCustomValidity("Invalid Plate Number");
                  //   } else {
                  //     e.target.setCustomValidity("");
                  //   }
                  // }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Color:
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="color"
                  placeholder="e.g. Solar Red"
                  onChange={handleUpdates}
                  defaultValue={carDetails.color}
                  required
                  maxLength="50"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Chassis Number:
                <button
                  type="button"
                  onClick={handleInfoChassis}
                  value="chassis"
                  onChange={handleUpdates}
                  defaultValue={carDetails.chassis}
                >
                  <div className="icon-info">
                    <img
                      src={"/images/info.png"}
                      alt="info"
                      className="icon-info-image"
                    />
                  </div>
                </button>
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="chassisNumber"
                  placeholder="Enter Chassis Number"
                  onChange={handleUpdates}
                  defaultValue={carDetails.chassisNumber}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Engine Number:
                <button type="button" value="engine" onClick={handleInfoEngine}>
                  <div className="icon-info">
                    <img
                      src={"/images/info.png"}
                      alt="info"
                      className="icon-info-image"
                    />
                  </div>
                </button>
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="engineNumber"
                  placeholder="Enter Engine Number"
                  onChange={handleUpdates}
                  defaultValue={carDetails.engineNumber}
                  required
                  maxLength="30"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                MV File Number:
                <button type="button" value="engine" onClick={handleInfoMV}>
                  <div className="icon-info">
                    <img
                      src={"/images/info.png"}
                      alt="info"
                      className="icon-info-image"
                    />
                  </div>
                </button>
              </label>
              <div className="block relative w-full">
                <input
                  type="text"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="mvFileNumber"
                  placeholder="Enter MV File Number"
                  onChange={handleUpdates}
                  defaultValue={carDetails.mvFileNumber}
                  required
                  maxLength="30"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                MV Type:
                <button type="button" value="engine" onClick={handleInfoMVType}>
                  <div className="icon-info">
                    <img
                      src={"/images/info.png"}
                      alt="info"
                      className="icon-info-image"
                    />
                  </div>
                </button>
              </label>
              <div className="block relative w-full">
                {loadingMVType || (
                  <select
                    name="mvFileType"
                    className="input-border-none focus:outline-none focus:shadow-outline"
                    onChange={handleUpdates}
                    defaultValue={carDetails.mvType}
                    required
                  >
                    <option value="">Select MV Type</option>
                    {mvTypes.map((mt, index) => (
                      <option value={mt.value} key={index}>
                        {mt.text}
                      </option>
                    ))}
                  </select>
                )}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                Start Date:
              </label>
              <div className="block relative w-full">
                {/* <input
                  type="date"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="startDate"
                  onChange={handleUpdates}
                  defaultValue={carDetails.startDate}
                  required
                /> */}
                {!loadingDate && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div
                      className={
                        "input-border-none focus:outline-none focus:shadow-outline"
                      }
                    >
                      {/* <DatePicker
                      // minDate={minDate}
                      // maxDate={maxDate}
                      defaultValue={dayjs(carDetails.startDate)}
                      name="startDate"
                      onChange={()=>handleUpdates(event)}
                    /> */}

                      <CustomDatePicker
                        name="startDate"
                        value={startDate}
                        onChange={handleDateChange}
                        minDate={today}
                      />
                    </div>
                  </LocalizationProvider>
                )}

                {isAdvanced && (
                  <sup>
                    We are only allowing 60 days early registration based on the
                    vehicle's plate number assigned period of coverage.
                  </sup>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label className="inline-block text-gray-500 text-sm font-bold">
                End Date:
              </label>
              <div className="block relative w-full">
                {/* <input
                  type="date"
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="endDate"
                  onChange={handleUpdates}
                  defaultValue={carDetails.endDate}
                  disabled
                /> */}

                {!loadingDate && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div
                      className={
                        "input-border-none focus:outline-none focus:shadow-outline"
                      }
                    >
                      <CustomDatePicker
                        name="endDate"
                        value={dayjs(endDate)}
                        onChange={handleDateChange}
                        disabled={true}
                      />
                    </div>
                  </LocalizationProvider>
                )}
                <sup>
                  <b>Note</b>: For vehicles with Plate Numbers, the period of
                  coverage of CTPL policy will synchronize with the date of the
                  motor vehicle registration. For the guidelines, click{" "}
                  <span className="text-blue-600 font-bold">
                    <a onClick={gotoAdvisory}>here</a>
                  </span>
                  .
                </sup>
              </div>
            </div>
          </div>
          <button type="submit" style={{ display: "none" }} id="btnSubmit">
            submit
          </button>
        </form>
      </main>

      <Modal open={open} setOpen={setOpen} title="Chassis Number">
        <div>
          <img src={"/images/ctpl/chassisno.png"} className="image" />
        </div>
      </Modal>

      <Modal open={openEngine} setOpen={setOpenEngine} title="Engine Number">
        <div>
          <img src={"/images/ctpl/engineno.png"} className="image" />
        </div>
      </Modal>

      <Modal open={openMV} setOpen={setOpenMV} title="MV File Number">
        <div>
          <img src={"/images/ctpl/mvfileno.png"} className="image" />
        </div>
      </Modal>

      <Modal open={openMVType} setOpen={setOpenMVType} title="MV Type">
        <div>
          <img src={"/images/ctpl/mvtype.png"} className="image" />

          <p className="text-xs text-gray-700 mt-5">
            Use the reference image above to locate the MV Type in your
            Certificate of Registration (CR).
          </p>
        </div>
      </Modal>
    </>
  );
}
export default CTPLCarDetails;
