import Wizard from "../Wizard";
import CarDetails from "./Car-Details";
import OtherDetails from "./Other-Details";
import Quotation from "./Quotation";
import Summary from "./Summary";
import { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import Benefits from "../Motor-Benefits/Benefits";
import PlateNumberValidation from "./Plate-Number";
import { motorAPI, basePath } from "../../../api/fetch-api";
import { Cache } from "aws-amplify";
import { Loading } from "../../atoms/loading";
import Modal from "../../molecules/Modal";
import WizardComponent from "../WizardComponent";
import { formatNumber } from "../../utils/helper";

function IndexPage(props) {
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubPage, setActiveSubPage] = useState(1);
  const [activeMain, setActiveMain] = useState(1);
  const [isLoading, setIsLoading] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerSubTitle, setSubHeaderTitle] = useState("");
  const [buttonTitle, setButtonTitle] = useState("Next");

  const [plateNumberState, setPlateNumberState] = useState([]);
  const [carDetailsState, setCarDetailsState] = useState([]);
  const [quoteState, setQuoteState] = useState({});
  const [carDisplay, setCarDisplay] = useState("");
  const [otherDetailsState, setOtherDetailsState] = useState([]);
  const stepsLabel = ["Details", "Quotation", "Other", "Summary"];

  //Main USEEFFECT
  useEffect(() => {
    if (
      Cache.getItem("plateNumberState") === undefined ||
      Cache.getItem("plateNumberState") === null
    ) {
      setPlateNumberState({ plateNumber: "" });
      setCarDetailsState({
        year: "",
        make: "",
        model: "",
        modelid: "",
        engineType: null,
        fmv: null,
        bodyType: null,
        seats: null,
        carDisplay: null,
      });
      setOtherDetailsState({
        isOwner: "Y",
        ownerName: "",
        isMortgage: "N",
        mortgagee: null,
        color: null,
        chassisNumber: null,
        engineNumber: null,
        contactNumber: "",
        contactPerson: "",
        emailAddress: "",
        officeAddress: "",
      });
    } else {
      const plateNumberState_cached = Cache.getItem("plateNumberState");
      const carDetailsState_cached = Cache.getItem("carDetailsState");
      const quoteState_cached = Cache.getItem("quoteState");
      const carDisplay_cached = Cache.getItem("carDisplay");
      const otherDetailsState_cached = Cache.getItem("otherDetailsState");
      setPlateNumberState(plateNumberState_cached);
      setCarDetailsState(carDetailsState_cached);
      setQuoteState(quoteState_cached);
      setCarDisplay(carDisplay_cached);
      setOtherDetailsState(otherDetailsState_cached);
      setActiveStep(4);
    }
  }, []);

  const SectionState = () => {
    if (activeStep === 0) {
      setHeaderTitle("Motorcar Details");
      setButtonTitle("Next");
      return (
        <PlateNumberValidation
          saveData={FormLogic}
          plateNumber={plateNumberState}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          open={open}
          setOpen={setOpen}
          openMalus={openMalus}
          setOpenMalus={setOpenMalus}

        />
      );
    } else if (activeStep === 1) {
      setHeaderTitle("Motorcar Details");
      setButtonTitle("Next");
      return <CarDetails saveData={FormLogic} carDetails={carDetailsState} />;
    } else if (activeStep === 2) {
      setHeaderTitle("Premium Breakdown");
      setButtonTitle("Get Policy");
      return (
        <Quotation
          saveData={nextStep}
          setSubPage={SetSubPage}
          quoteData={quoteState}
          carDisplay={carDisplay}
        />
      );
    } else if (activeStep === 3) {
      setHeaderTitle("Other Details");
      setButtonTitle("Next");
      return (
        <OtherDetails saveData={FormLogic} otherDetails={otherDetailsState} />
      );
    } else if (activeStep === 4) {
      setHeaderTitle("Summary");
      setButtonTitle("Next");

      return (
        <Summary
          saveData={FormLogic}
          backtoStart={BacktoStart}
          plateNumber={plateNumberState}
          carDetails={carDetailsState}
          carDisplay={carDisplay}
          otherDetails={otherDetailsState}
        />
      );
    } else {
      //setActiveStep(5);
      return <>aaaa</>;
    }
  };

  const SubmitForm = () => {
    //API
    if (activeStep === 1) {
      document.getElementById("btnSubmit").click();
    } else if (activeStep === 0) {
      document.getElementById("btnSubmit").click();
    } else {
      document.getElementById("btnSubmit").click();
    }
  };

  //date formatter
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const FormLogic = (data) => {
    if (activeStep === 0) {
      props.sendGTM(
        "/motor-insurance/form?form=PlateNumberDetails",
        "PlateNumberDetails",
        "Motor",
        "motor_GInsure1"
      );
      setPlateNumberState(data);
      nextStep();
    } else if (activeStep === 1) {
      props.sendGTM(
        "/motor-insurance/form?form=MotorCarDetails",
        "CarDetails",
        "Motor",
        "motor_GInsure2"
      );
      console.log("Car Details: ", data);
      setCarDetailsState(data);
      setCarDisplay(data.carDisplay);
      var today = new Date();
      var next_year = today.setFullYear(today.getFullYear() + 1);
      let mname = "";
      try {
        mname = clientInfo.resultData.middleName.charAt(0);
        if (mname != "-" && mname != "") {
          mname += ".";
        }
      } catch (err) {
        mname = "";
      }
      const clientInfo = JSON.parse(Cache.getItem("clientInfo"));
      var client = {
        address1: clientInfo.resultData.address.street,
        address2: clientInfo.resultData.address.town,
        addressType: "H",
        bday: clientInfo.resultData.birthday,
        birthPlace: null,
        branchCode: "SI",
        clientType: "I",
        contactNumber: formatNumber(clientInfo.resultData.mobileNumber),
        contactType: "M",
        email: "test@gmail.com",
        firstName: clientInfo.resultData.firstName,
        fullName:
          clientInfo.resultData.firstName +
          " " +
          mname +
          " " +
          clientInfo.resultData.lastName,
        gender: "F",
        ginsureAccountId: "/mdIRBQt6g3IRRebxlkHqhrqG09gSyMAEjAtG7notTQ=",
        idNo: null,
        idType: "DL",
        industry: null,
        iosClientNumber: null,
        lastName: clientInfo.resultData.lastName,
        maritalStatus: "S",
        masterClientNo: null,
        middleName: clientInfo.resultData.middleName,
        nationality: clientInfo.resultData.nationality,
        postalCode: clientInfo.resultData.address.zipCode,
        province: "Metro Manila",
      };
      var postData = {
        bodyType: data.bodyType,
        chassisNumber: null,
        client: client,
        color: null,
        endDate: formatDate(next_year),
        engineNumber: null,
        engineType: data.engineType,
        fmv: data.fmv,
        make: data.make,
        merchantId: "0003",
        model: data.model,
        modelid: data.modelid,
        mvFileNumber: null,
        plateNumber: plateNumberState.plateNumber,
        questionnaire: null,
        seats: data.seats,
        startDate: formatDate(new Date()),
        year: data.year,
        mortgagee: null,
      };
      motorAPI
        .getQuotation(postData)
        .then((data) => {
          setQuoteState(data);
          nextStep();
        })
        .catch((err) => console.log("Model Error: ", err));
    } else if (activeStep === 2) {
      // window.dataLayer.push({
      //   event: "virtualPage", //eventTrigger
      //   pageUrl: "https://ginsure-uat.standard-insurance.com/motor-insurance/form", //domain + url
      //   pageTitle: "MotorFormsQuotation", //product + Module + title
      // });
      props.sendGTM(
        "/motor-insurance/form?form=MotorQuotationDetails",
        "QuotationDetails",
        "Motor",
        "motor_GInsure3"
      );
      nextStep();
    } else if (activeStep === 3) {
      // window.dataLayer.push({
      //   event: "virtualPage", //eventTrigger
      //   pageUrl: "https://ginsure-uat.standard-insurance.com/motor-insurance/form", //domain + url
      //   pageTitle: "MotorFormsOtherdetails", //product + Module + title
      // });
      props.sendGTM(
        "/motor-insurance/form?form=MotorSummaryDetails",
        "SummaryDetails",
        "Motor"
      );
      console.log("Other details: ", data);
      setOtherDetailsState(data);
      nextStep();
    } else if (activeStep === 4) {
      // window.dataLayer.push({
      //   event: "virtualPage", //eventTrigger
      //   pageUrl: "https://ginsure-uat.standard-insurance.com/motor-insurance/form", //domain + url
      //   pageTitle: "MotorFormsSummary", //product + Module + title
      // });
      props.sendGTM("/motor-insurance/review", "ReviewDetails", "Motor");
      nextStep();
    }
  };

  const SetSubPage = (page) => {
    setActiveSubPage(1);
    setActiveMain(0);
  };
  const SubSectionState = () => {
    if (activeSubPage === 1) {
      setHeaderTitle("Gcash x Standard Insurance");
      setSubHeaderTitle("Benefits");
      return <Benefits />;
    }
  };
  const backtoMain = () => {
    setActiveMain(1);
  };

  const nextStep = () => {
    let flag = true;
    if (activeStep === 4) {
      flag = false;
    }
    Cache.setItem("plateNumberState", plateNumberState);
    Cache.setItem("carDetailsState", carDetailsState);
    Cache.setItem("quoteState", quoteState);
    Cache.setItem("carDisplay", carDisplay);
    Cache.setItem("otherDetailsState", otherDetailsState);
    if (flag) {
      setActiveStep(activeStep + 1);
      setIsLoading(1);
      setTimeout(() => {
        if (activeStep !== 4) {
          setIsLoading(0);
        }
      }, 1000);
    } else {
      // window.dataLayer.push({
      //   event: "virtualPage", //eventTrigger
      //   pageUrl: "https://ginsure-uat.standard-insurance.com/motor-insurance/review", //domain + url
      //   pageTitle: "MotorFormsReview", //product + Module + title
      // });
      props.sendGTM(
        "/motor-insurance/review",
        "MotorFormsReview",
        "motor_GInsure4"
      );
      setIsLoading(1);
      setTimeout(() => {
        history.push("/motor-insurance/review");
      }, 1000);
    }
  };
  const backStep = () => {
    let flag = true;

    if (flag) {
      if (activeStep === 0) {
        history.goBack();
      }
      setActiveStep(activeStep - 1);
      setIsLoading(1);
      setTimeout(() => {
        setIsLoading(0);
      }, 1000);
    }
  };
  const BacktoStart = () => {
    setActiveStep(0);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const [open, setOpen] = useState(false);
  const [openMalus, setOpenMalus] = useState(false);
  return (
    <div>
      {/* Main Pages */}
      {activeMain === 1 && (
        <>
          <Header headerTitle={headerTitle} saveData={backStep} />

          <div className={props.main === "main" ? "main p-5" : "p-5"}>
            <img
              src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
              width="170"
              height="50"
              alt="logo"
            />
          </div>
          <main className={props.main === "main" ? "main p-5" : "p-5"}>
            {activeStep != 5 && activeStep != 0 && (
              <WizardComponent
                activeStep={activeStep}
                stepsLabel={stepsLabel}
              />
            )}
            {!isLoading && <SectionState />}
            {!isLoading || <Loading />}
          </main>
          <Modal open={open} setOpen={setOpen} title="">
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 icon-center text-red-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p className="text-center my-5">
              Your vehicle is already insured with Standard Insurance.
            </p>
          </Modal>

          <Modal open={openMalus} setOpen={setOpenMalus} title="">
            
            <p className="my-5">
              Your policy is non-renewable. If you need any assistance, you may
              call us at (0917) 828-3984 or send us an email at
              ginsure@standard-insurance.com
            </p>

            <p>Thank you.</p>
          </Modal>
          <Footer
            buttonType="button"
            saveData={SubmitForm}
            isLoading={isLoading}
            buttonTitle={buttonTitle}
          />
        </>
      )}
      {/* Sub Pages */}
      {activeMain !== 1 && (
        <>
          <div>
            <Header
              headerTitle={props.headerTitle}
              subHeader={headerSubTitle}
              subPage={"1"}
              saveData={backtoMain}
            />
            <div
              className="absolute w-full"
              // style={{
              //   backgroundImage: `url("/images/bg.png")`,
              //   backgroundRepeat: "no-repeat",
              //   width: "250px",
              // }}
            >
              <img alt="motorcar" src={"/images/bg.png"} className="image" />
            </div>
            <div className="p-5 pt-12">
              <img
                src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
                width="170"
                height="50"
                alt="logo"
              />
            </div>
            <main className="p-5">
              <div className="">
                <SubSectionState />
              </div>
            </main>
          </div>
        </>
      )}
    </div>
  );
}
export default IndexPage;
