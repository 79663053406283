import ContactUs from "../components/organisms/Contact-Us";
import Header from "../components/organisms/Header";
import { Disclosure } from "@headlessui/react";

const ComingSoonPage = (props) => {
  const close = () => {
    console.log("close");
  };
  return (
    <div>
      <Disclosure as="nav" className="sticky top-0 z-50 bg-blue-600">
        <>
          {/* <Header headerTitle={props.headerTitle} /> */}
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-12">
              <div className="items-center sm:justify-start">
                <button type="button" className="flex" onClick={close}>
                  <div className="items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className=" flex-1 block sm:ml-10 xs:ml-3 text-lg">
                    <div>
                      <span className="text-white text-base block ml-2 align-middle title-font">
                        Standard Insurance
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      </Disclosure>
      
      <main>
        <div className="coming-soon">
          <img
            src={"/images/sici-logo.png"}
            width="200"
            height="50"
            alt="logo"
            className="mx-auto"
          />
          <p className="text-sm font-bold text-center mt-2">
            Coming Soon in GInsure
            {/* On-going Maintenance, We'll be back shortly. */}
          </p>
        </div>
      </main>
    </div>
  );
};
export default ComingSoonPage;
