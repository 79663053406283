import { useState, useEffect } from "react";
import Header from "../components/organisms/Header";
import Modal from "../components/molecules/Modal";
import {
  manageAPI,
  authAPI,
  authDetails,
  motorAPI,
  gcashAPI,
  basePath,
  travelAPI,
  travelLocalAPI,
  petAPI
} from "../api/fetch-api";
import { Cache } from "aws-amplify";
import Policy from "../components/organisms/ManagePolicy/Policy";
import Services from "../components/organisms/ManagePolicy/Services";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "../components/atoms/loading";
const ManagePolicy = (props) => {
  const [page, setPage] = useState("overview");
  const [active, setActive] = useState("overview");
  const [detail, setDetail] = useState({});
  const [grid, setGrid] = useState("grid-cols-2");
  const [detailLoading, setDetailLoading] = useState(true);
  const [travelDetails, setTravelDetail] = useState({});

  const [policyList, setPolicyList] = useState([]);
  const [policyListLoading, setPolicyListLoading] = useState(true);
  const [motorPolicy, setMotorPolicy] = useState({});
  const [travel, setTravel] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("View Contract");

  const [productType, setProductType] = useState("");
  const [open, setOpen] = useState(false);

  const [policyStat, setPolicyStat] = useState("")

  let history = useHistory();

  const [policyDetail, setpolicyDetail] = useState({});
  console.log("policyDetail", policyDetail);
  const [isLoading, setIsLoading] = useState(1);
  const Dformat = (date1) => {
    var d = new Date(date1),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var m = strArray[d.getMonth()];
    return m + " " + day + ", " + year;
  };
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  //Add Loading
  useEffect(() => {
    // if(iOS()){
    //   history.push("/coming-soon");
    // }
    const search = window.location.search;
    const params = new URLSearchParams(search);

    let customerId = params.get("customerId");
    if (customerId === null) {
      history.push("/coming-soon");
    } else {
      customerId = customerId.replaceAll(" ", "%2B");
    }
    Cache.setItem("unid", null);
    authAPI
      .getAuth(authDetails)
      .then((data1) => {
        Cache.setItem("ginsure_token", data1.token);
        console.log("Token: ", data1.token);
        gcashAPI
          .getClient(customerId, data1.token)
          .then((data) => {
            setIsLoading(0);
            console.log("Customer information :", data);
            Cache.setItem("clientInfo", JSON.stringify(data));
            customerId = customerId.replaceAll("+", " ").replaceAll(" ", "%2B");
            Cache.setItem("customerId", customerId);
            if (
              (data.resultInfo.resultCode === "SYSTEM_ERROR" ||
                data.resultInfo.resultCode === "FAILED") &&
              customerId === null
            ) {
              history.push("/500");
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("There was an error!");
          });
      })
      .catch((error) => {
        console.log("error", error);
        console.log("There was an error!");
        history.push("/500");
      });

    Cache.setItem("customerId", customerId);

    //GET Manage data

    var loc = window.location.pathname; //manage-policy/1235/asdasdhagsdjkhaskdhas
    var loc_array = loc.split("/");
    loc_array = loc_array.filter((n) => n);
    console.log("URI", loc_array);
    setProductType(loc_array[3]);
    //TRAVEL //http://ginsure.standard-insurance.com/manage-policy/%d/base64(%s)?customerId=
  }, []);
  useEffect(() => {}, [travel]);
  useEffect(() => {
   if(productType!==""){
    try {
      
      GetData();
      Cache.setItem("productType", productType);
    } catch (err) {
      history.push("/coming-soon");
    }
   }
  }, [productType]);

  const GetData = () => {
    let customerID = Cache.getItem("customerId")
      .replaceAll("+", " ")
      .replaceAll(" ", "%2B");
    let unid = 0;
    let url_data = window.location.href.toLowerCase();
    url_data
      .replaceAll("https://", "")
      .replaceAll("http://", "")
      .replaceAll("https://www.", "")
      .replaceAll("http://www.", "");
    const myArray = url_data.split("/");
    console.log("URL:", myArray);
    // myArray.forEach((element) => {
    //   if (element.indexOf("?customerid") !== -1) {
    //     unid = element.replaceAll("?customerid=", "");
    //     Cache.setItem("unid", unid);
    //   }
    // });

    var loc = window.location.pathname;
    var loc_array = loc.split("/");
    loc_array = loc_array.filter((n) => n);
    unid = loc_array[1];
    Cache.setItem("unid", unid);
    Cache.setItem("emailAddress", atob(loc_array[2]));

    setpolicyDetail({
      customerId: customerID,
      unid: unid,
    });

    authAPI
      .getAuth(authDetails)
      .then((data1) => {
        Cache.setItem("ginsure_token", data1.token);
        //Cache.setItem("ginsure_token", customerID, data1.token);

        //Pet Details
        if (productType === "SIPETI01") {
          props.sendGTM("/manage-policy/?product=Pet","ManagePolicyPet","Pet","pet_GInsure8");
          var params = {
            unid: unid,
            customerId: customerID,
          };
          petAPI
            .getPetQuotation(unid,customerID)
            .then((data) => {
              console.log("PET POLICY: ", data);
              const dateStart = Dformat(data.inceptionDate); //sDate.toLocaleString("en-US", options);
              const dateEnd = Dformat(data.expiryDate);
              let polStatus = "Active";
              if (data.status === "A") {
                polStatus = "Active";
              } else if (data.status === "L") {
                polStatus = "Lapsed/Expired";
              } else if (data.status === "R") {
                polStatus = "Pending Cancel Request";
              } else if (data.status === "C") {
                polStatus = "Cancelled";
                setPolicyStat(polStatus)
              }

              if(data?.expiredFlag==="Y"){
                polStatus = "Inactive";
                setPolicyStat(polStatus)
              }
              
              setDetail({
                ginsureAccountId: customerID,

                //Header
                policy: data.branchCode +"-"+ data.prodcode +"-"+ data.polno,
                startDate: dateStart,
                endDate: dateEnd,
                policyStatus: polStatus,
                premium: data.premium,
                info: data.info


              });
              setDetailLoading(false);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
        } //If travel international

        //If Travel local
        else if (productType === "SITRVL02") {
          props.sendGTM("/manage-policy/?product=TravelInternational","ManagePolicyTravelInternational","TravelInternational","traveli_GInsure6");
          setTravel(true);
          var params = {
            unid: loc_array[1],
            email: atob(loc_array[2]),
          };
          travelLocalAPI
            .getLocalTravelPolicy(params)
            .then((data) => {
              console.log("TRAVEL POLICY: ", data);
              const dateStart = Dformat(data.departure); //sDate.toLocaleString("en-US", options);
              const dateEnd = Dformat(data.arrival);
              let polStatus = "Active";
              if (data.status === "A") {
                polStatus = "Active";
              } else if (data.status === "L") {
                polStatus = "Lapsed/Expired";
              } else if (data.status === "R") {
                polStatus = "Pending Cancel Request";
              } else if (data.status === "C") {
                polStatus = "Cancelled";
                setPolicyStat(polStatus)
              }

              if(data?.expiredFlag==="Y"){
                polStatus = "Inactive";
                setPolicyStat(polStatus)
              }
              setTravelDetail(data);
              setDetail({
                ginsureAccountId: customerID,

                //Header
                policy: data.branchCode + "-TVL-" + data.certificateNumber,
                startDate: dateStart,
                endDate: dateEnd,
                policyStatus: polStatus,
              });
              setDetailLoading(false);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
        } //If travel international
        else if (productType === "SITRVL01") {
          props.sendGTM("/manage-policy/?product=TravelAsean","ManagePolicyTravelAsean","TravelAsean","travela_GInsure6");
          setTravel(true);
          var params = {
            unid: loc_array[1],
            email: atob(loc_array[2]),
          };
          travelAPI
            .getTravelPolicy(params)
            .then((data) => {
              console.log("TRAVEL POLICY: ", data);
              const dateStart = Dformat(data.departure); //sDate.toLocaleString("en-US", options);
              const dateEnd = Dformat(data.arrival);
              let polStatus = "Active";
              if (data.status === "A") {
                polStatus = "Active";
              } else if (data.status === "L") {
                polStatus = "Lapsed/Expired";
              } else if (data.status === "R") {
                polStatus = "Pending Cancel Request";
              } else if (data.status === "C") {
                polStatus = "Cancelled";
                setPolicyStat(polStatus)
              }
              if(data?.expiredFlag==="Y"){
                polStatus = "Inactive";
                setPolicyStat(polStatus)
              }
              setTravelDetail(data);
              setDetail({
                ginsureAccountId: customerID,

                //Header
                policy: data.branchCode + "-TPP-" + data.certificateNumber,
                startDate: dateStart,
                endDate: dateEnd,
                policyStatus: polStatus,
              });
              setDetailLoading(false);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
        } else {
          manageAPI
            .getPolicy(unid, customerID, data1.token)
            .then((data) => {
              console.log("data", data);
              //Cache.setItem("policy", data);

              let sDate = new Date(data.startDate);
              let options = { month: "short", day: "numeric", year: "numeric" };
              const dateStart = Dformat(data.startDate); //sDate.toLocaleString("en-US", options);

              let eDate = new Date(data.endDate);
              const dateEnd = Dformat(data.endDate); //eDate.toLocaleString("en-US", options);
              //CHECKER IF POLICY IS EQUAL TO UNID
              console.log(
                "Policy Checker: ",
                data.client.ginsureAccountId +
                  " | " +
                  customerID.replaceAll("%2B", "+")
              );
              if (
                data.client.ginsureAccountId !==
                customerID.replaceAll("%2B", "+")
              ) {
                history.push("/404");
              }
              let polStatus = "Active";
              if (data.status === "A") {
                polStatus = "Active";
              } else if (data.status === "L") {
                polStatus = "Lapsed/Expired";
              } else if (data.status === "R") {
                polStatus = "Pending Cancel Request";
              } else if (data.status === "C") {
                polStatus = "Cancelled";
                setPolicyStat(polStatus)
              }
              if(data?.expiredFlag==="Y"){
                polStatus = "Inactive";
                setPolicyStat(polStatus)
              }

              if (data.coverType === "07") {
                props.sendGTM("/manage-policy/?product=CTPL","ManagePolicyCTPL","CTPL","CTPL_GInsure6");
                setGrid("grid-cols-1");
              }
              else{
                props.sendGTM("/manage-policy/?product=Motor","ManagePolicyMotor","Motor","motor_GInsure6");
              }
              setDetail({
                plateNumber: data.plateNumber,
                year: data.year,
                make: data.make,
                model: data.model,
                color: data.color,
                authNumber: data.authNumber,
                coverType: data.coverType,
                cocNumber: data.cocNumber,
                chassisNumber: data.chassisNumber,
                engineNumber: data.engineNumber,
                mortgaged: data.mortgagee,
                mortgageeDesc: data.mortgageeDesc,
                isMortaged: data.mortgageeFlag,

                insured: data.client.fullName,
                ginsureAccountId: data.client.ginsureAccountId,

                basicPremium: data.premium.premium,
                vat: data.premium.evat,
                dst: data.premium.dst,
                lgt: data.premium.lgt,
                totalAmount: data.premium.total,
                deductible: data.deductible,

                //Header
                policy: data.policyNumber,
                startDate: dateStart,
                endDate: dateEnd,
                policyStatus: polStatus,
              });

              setDetailLoading(false);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("There was an error!");
      });
  };

  const handleTab = (tab) => {
    console.log("tab", tab);
    setPage(tab);
  };

  const gotoFAQ = () => {
    if(productType === "SIPETI01"){
      history.push("/pet-faq");
    }
    else if (productType === "SITRVL02"){
      history.push("/faq/asean");
    }
    else if (productType === "SITRVL01"){
      history.push("/faq/international");
    }
    else if(detail.coverType === "07"){
      history.push("/ctpl/faq");
    }
    else{
      history.push("/faq");
    }
  };

  const ViewComponent = () => {
    if (page === "overview") {
      setActive("overview");
      return (
        <Policy
          detailLoading={detailLoading}
          detail={detail}
          travelDetails={travelDetails}
          productType={productType}
        />
      );
    } else if (page == "services") {
      setActive("services");
      return (
        <Services
          productType={productType}
          detail={detail}
          travelDetails={travelDetails}
        />
      );
    }
  };
  const viewPolicy = () => {
    let customerID = Cache.getItem("customerId")
      .replaceAll("+", " ")
      .replaceAll(" ", "%2B");
    let encoder_URL =
      "https://gymp3t84o3.execute-api.ap-southeast-1.amazonaws.com/prod/policy/quotation/" +
      encodeURIComponent(Cache.getItem("unid") + "?") +
      "customerid=" +
      customerID;
      console.log(policyStat)
    
    if (policyStat === "Cancelled") {
      console.log(policyStat)
      setOpen(true);
    } else {
      setDetailLoading(true);
      setTimeout(() => {
        window.location.href =
          "http://docs.google.com/gview?embedded=true&url=" +
          basePath.docsUrl +
          "/policy/ginsure/quotation/" +
          Cache.getItem("unid") +
          "/" +
          btoa(
            Cache.getItem("customerId")
              .replaceAll("+", " ")
              .replaceAll(" ", "%2B")
          );
      }, 1000);
    }
  };

  return (
    <div>
      <Header headerTitle={props.headerTitle} />
      {detailLoading && <Loading />}
      {!detailLoading && (
        <main className="">
          <div className="p-5 main">
            <div className="grid grid-cols-3 gap-3">
              <div className="col-span-2">
                <h3 className="font-bold text-xl manage-policy-font">
                  {detail.coverType === "07" && "CTPL"}
                  {productType !== "SITRVL01" && productType !== "SITRVL02" && productType !== "SIPETI01" &&
                    "Car Protect"}
                    {productType === "SIPETI01" &&
                    "Pet Protect"}
                  {productType === "SITRVL01" && "Travel Protect International"}
                  {productType === "SITRVL02" &&
                    "Travel Protect Saver Plus"}
                </h3>
                <h3 className="font-bold text-lg manage-policy-font">
                  {detail.policy}
                </h3>
                <span className="block text-xs mb-5 title-font">
                  COVER STATUS:{" "}
                  <span className="font-bold text-yellow-500">
                    {detail.policyStatus}
                  </span>
                </span>

                <div className="mb-5 ">
                  <span className="block text-xs title-font">
                    Start Date:{" "}
                    <span className="font-bold manage-policy-font">
                      {detail.startDate}
                    </span>
                  </span>
                  <span className="block text-xs title-font">
                    End Date:{" "}
                    <span className="font-bold manage-policy-font">
                      {detail.endDate}
                    </span>
                  </span>
                </div>
                {(typeof travelDetails.destination !== "undefined" ||
                  (typeof detail.authNumber === "object" &&
                    detail.coverType !== "07") ||
                  (typeof detail.authNumber !== "object" &&
                    detail.coverType === "07") || productType==="SIPETI01") && (
                  <span
                    className="text-blue-500 font-bold text-sm manage-policy-font"
                    onClick={viewPolicy}
                  >
                    {buttonTitle}
                  </span>
                )}
              </div>
              <Modal open={open} setOpen={setOpen} title="">
              <div className="flex">
                </div>
                <p className="text-center my-5">
                  Your Travel Policy is already Cancelled.
                </p>
              </Modal>
              <div>
                <span className="text-blue-500 font-bold text-sm manage-policy-font">
                  <button onClick={gotoFAQ}>Need Help?</button>
                </span>

                <div>
                {/* <img
                    src={"/images/logo-icon.png"}
                    className="manage-logo-icon"
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white py-3 text-center">
            <div
              className={`grid ` + grid + ` gap-3 border-b-2 border-gray-200`}
            >
              <button onClick={() => handleTab("overview")}>
                <div
                  className={
                    active == "overview"
                      ? "font-bold border-b-4 border-blue-500 pb-2 manage-policy-font"
                      : "font-bold manage-policy-font"
                  }
                >
                  Overview
                </div>
              </button>
              {((typeof detail.authNumber === "object" &&
                detail.coverType !== "07") ||
                productType === "SITRVL01" ||
                productType === "SITRVL02" || productType === "SIPETI01") && (
                <button onClick={() => handleTab("services")}>
                  <div
                    className={
                      active == "services"
                        ? "border-b-4 border-blue-500 pb-2 font-bold manage-policy-font"
                        : "font-bold manage-policy-font"
                    }
                  >
                    Services
                  </div>
                </button>
              )}
              {/* <button onClick={() => handleTab("activity")}>
              <div className={active == "activity" ? "font-bold border-b-4 border-blue-500 pb-2" : "font-bold"}>Activity</div>
            </button> */}
            </div>
          </div>

          <div>
            <ViewComponent />
          </div>
        </main>
      )}
      <div className="bottom-0 pt-16 pb-10 relative flex px-5 bg-white">
        <div className="flex-1 flex">
          <div className="pl-3 pr-3">
            <div className="w-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-question-circle h-10 w-10 text-blue-600"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
              </svg>
              {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg> */}
            </div>
          </div>
          <div className="ml-3">
            <h4 className="font-black text-left font-bold">Need help?</h4>
            <p className="text-left text-xs text-gray-400 manage-policy-font">
              Should you need any assistance or have any inquiries, please
              contact{" "}
              <span className="text-blue-600 font-bold">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManagePolicy;
