import Wizard from "../Wizard";
import { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import Benefits from "../Motor-Benefits/Benefits";
import { Cache } from "aws-amplify";
import { Loading } from "../../atoms/loading";
import Modal from "../../molecules/Modal";
import WizardComponent from "../WizardComponent";

import TravelDetails from "../Travel/Travel-Details";
import TravelAssuredDetails from "../Travel/Assured-Details";
import TravelFormSummary from "../Travel/Summary";
import { camelizeText } from "../../utils/helper";
import { travelAPI } from "../../../api/fetch-api";

function IndexPage(props) {
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubPage, setActiveSubPage] = useState(1);
  const [activeMain, setActiveMain] = useState(1);
  const [isLoading, setIsLoading] = useState(0);
  const [isDisabled, setIsDisabled] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerSubTitle, setSubHeaderTitle] = useState("");
  const [buttonTitle, setButtonTitle] = useState("Next");

  //page steps
  const stepsLabel = ["Travel Details", "Assured Details", "Summary"];

  //page form states
  const [premiumState, setPremiumState] = useState(0);
  const [travelDetailsState, setTravelDetailsState] = useState([]);
  const [assuredDetailsState, setAssuredDetailsState] = useState([]);
  const [preexDetailsState, setPreexDetailsState] = useState([
    [""],
    [""],
    [""],
    [""],
  ]);
  const [preexDetailsStateFlags, setPreexDetailsStateFlags] = useState([
    "",
    "",
    "",
    "",
  ]);
  const cover =
    window.location.href.indexOf("international") > -1
      ? "International"
      : "Asean";
  const coverType =
    window.location.href.indexOf("international") > -1 ? "i" : "a";

  const [preExListArray, setPreExListArray] = useState([]);
  // const preExListArray = [
  //   { preExCode: "HYPER", preExDesc: "Hypertension" },
  //   { preExCode: "COARD", preExDesc: "Coronary Artery Disease" },
  //   { preExCode: "ACMYI", preExDesc: "Acute Myocardial Infarction" },
  //   { preExCode: "UNANG", preExDesc: "Unstable Angina" },
  //   { preExCode: "CSANP", preExDesc: "Chronic Stable Angina Pectoris" },
  //   { preExCode: "AORAN", preExDesc: "Aortic Aneurysm" },
  //   { preExCode: "COHED", preExDesc: "Congenital Heart Disease" },
  //   { preExCode: "RHHED", preExDesc: "Rheumatic Heart Disease" },
  //   { preExCode: "ESOVA", preExDesc: "Esophageal Varices" },
  //   { preExCode: "GASUL", preExDesc: "Duodenal or Gastric Ulcers" },
  //   {
  //     preExCode: "INBOD",
  //     preExDesc:
  //       "Inflammatory Bowel Diseases OR Crohns Disease OR Ulcerative Colitis",
  //   },
  //   {
  //     preExCode: "CHLID",
  //     preExDesc: "Chronic Liver Disease OR Liver Cirrhosis",
  //   },
  //   { preExCode: "CHVIH", preExDesc: "Chronic Viral Hepatitis" },
  //   { preExCode: "ALLID", preExDesc: "Alcoholic Liver Disease" },
  //   { preExCode: "DIAME", preExDesc: "Diabetes Mellitus Types 1 2" },
  //   { preExCode: "CHTHD", preExDesc: "Chronic Thyroid Diseases" },
  //   { preExCode: "GRADI", preExDesc: "Hyperthyroidism OR Graves disease" },
  //   { preExCode: "MYXED", preExDesc: "Hypothyroidism OR Myxedema" },
  //   { preExCode: "GOITE", preExDesc: "Goiter" },
  //   {
  //     preExCode: "DYSLI",
  //     preExDesc: "Dyslipidemia/ Hyperlipidemia/ Hypercholesterolemia",
  //   },
  //   { preExCode: "LEUKE", preExDesc: "Leukemias" },
  //   {
  //     preExCode: "IRDEA",
  //     preExDesc:
  //       "Iron Deficiency Anemia and other chronic hypoproliferative anemias",
  //   },
  //   { preExCode: "HEMOG", preExDesc: "Hemoglobinopathies OR Thalassemias" },
  //   { preExCode: "APANE", preExDesc: "Aplastic Anemia" },
  // ];

  //Main USEEFFECT
  useEffect(async () => {
    await travelAPI
      .getOpenlPreex({})
      .then((data) => {
        setPreExListArray(
          data.sort((a, b) => a.preExDesc.localeCompare(b.preExDesc))
        );
      })
      .catch((err) => console.log("errr", err));
  }, []);

  useEffect(() => {
    if (Cache.getItem("benefitFlag")) {
      setTravelDetailsState(Cache.getItem("travelDetailsState"));
      setAssuredDetailsState(Cache.getItem("assuredDetailsState"));
      setPreexDetailsState(Cache.getItem("preexDetailsState"));
      setPreexDetailsStateFlags(Cache.getItem("preexDetailsStateFlags"));
    }
    if (
      (Cache.getItem("plateNumberState") === undefined ||
        Cache.getItem("plateNumberState") === null ||
        Cache.getItem("plateNumberState") === 0) &&
      !Cache.getItem("benefitFlag")
    ) {
      setTravelDetailsState({
        covid: true,
        days: 0,
        hazardous: false,
        sports: false,
        destinations: null,
        departure: "",
        arrival: "",
        groupCount: 0,
        groupCount2: 0,
        groupCountArr: [],
        zone: null,
        premium: 0,
        ageBracket: [],
        totalPax: 0,
        numPax: 99,
        destCount: 0,
        packageList: "",
        packageVersion: "",
        arrivalMin: "",
        arrivalMax: "",
        packageDesc: "",
        groupAgeMin: "",
        groupAgeMax: "",
        coverType: "",
        checkedInd: false,
        checkedGroup: false,
        checkedForSomeone: false,
        groupType: "individual",
        disabledGroupType: true,
        howAreYou: "",
      });

      setAssuredDetailsState({
        assured: [
          {
            bdayMin: "",
            bdayMax: "",
            fullName: "",
            bday: "",
            passport: "",
            beneficiary: "",
            email: "",
            contactNo: "",
            age: "",
            primary: "Yes",
            maritalStatus: "",
            gender: "",
            paxOne: "",
            paxTwo: "",
            paxThree: "",
            preexList: [],
            preexFlag: "",
          },
        ],
      });
      setPreexDetailsState([[""], [""], [""], [""]]);
      setPreexDetailsStateFlags(["", "", "", ""]);
    } else {
      setTravelDetailsState(Cache.getItem("travelDetailsState"));
      setAssuredDetailsState(Cache.getItem("assuredDetailsState"));
      setPreexDetailsState(Cache.getItem("preexDetailsState"));
      setPreexDetailsStateFlags(Cache.getItem("preexDetailsStateFlags"));
      if (Cache.getItem("benefitFlag") === true) {
        setActiveStep(0);
        setAssuredDetailsState({
          assured: [
            {
              bdayMin: "",
              bdayMax: "",
              fullName: "",
              bday: "",
              passport: "",
              beneficiary: "",
              email: "",
              contactNo: "",
              age: "",
              primary: "Yes",
              maritalStatus: "",
              gender: "",
              preexList: [],
              preexFlag: "",
            },
          ],
        });
        setPreexDetailsState([[""], [""], [""], [""]]);
        setPreexDetailsStateFlags(["", "", "", ""]);
      } else if (Cache.getItem("fromCoverPage") === true) {
        setActiveStep(0);
      } else {
        setActiveStep(2);
      }
    }
  }, []);

  const SectionState = () => {
    if (activeStep === 0) {
      setHeaderTitle("Travel Details");
      setButtonTitle("Next");

      return (
        <TravelDetails
          setIsDisabled={setIsDisabled}
          travelDetailsState={travelDetailsState}
          saveData={FormLogic}
          saveTravelDetails={saveTravelDetails}
          assuredDetailsState={assuredDetailsState}
        />
      );
    } else if (activeStep === 1) {
      setHeaderTitle("Assured Details");
      setButtonTitle("Next");
      return (
        <TravelAssuredDetails
          preExList={preExListArray}
          preexDetailsState={preexDetailsState}
          setPreexDetailsState={setPreexDetailsState}
          preexDetailsStateFlags={preexDetailsStateFlags}
          setPreexDetailsStateFlags={setPreexDetailsStateFlags}
          setAssuredDetailsState={setAssuredDetailsState}
          saveData={FormLogic}
          assuredDetailsState={assuredDetailsState}
          bdayMin={travelDetailsState.groupAgeMin}
          bdayMax={travelDetailsState.groupAgeMax}
          travelDetailsState={travelDetailsState}
        />
      );
    } else if (activeStep === 2) {
      setHeaderTitle("Summary");
      setButtonTitle("Get Policy");

      return (
        <TravelFormSummary
          saveData={FormLogic}
          travelDetailsState={travelDetailsState}
          assuredDetailsState={assuredDetailsState}
          editTravelDetails={editTravelDetails}
        />
      );
    } else {
      return <>aaaa</>;
    }
  };

  const SubmitForm = () => {
    //API

    document.getElementById("btnSubmit").click();
  };
  //clear assured
  const clearDataAssured = (data) => {
    let text = "I am buying insurance for someone.";
    if (
      Cache.getItem("travelDetailsState")?.howAreYou !== undefined ||
      Cache.getItem("travelDetailsState")?.howAreYou !== null ||
      Cache.getItem("travelDetailsState")?.howAreYou !== ""
    ) {
      if (Cache.getItem("travelDetailsState")?.howAreYou !== data.howAreYou) {
        setAssuredDetailsState({
          assured: [
            {
              bdayMin: "",
              bdayMax: "",
              fullName: "",
              bday: "",
              passport: "",
              beneficiary: "",
              email: "",
              contactNo: "",
              age: "",
              primary: "Yes",
              maritalStatus: "",
              gender: "",
              preexList: [],
              preexFlag: "",
            },
          ],
        });
        setPreexDetailsState([[""], [""], [""], [""]]);
        setPreexDetailsStateFlags(["", "", "", ""]);
      }
    }
  };

  //FROM BENEFITS, also reused for covid coverage
  const saveTravelDetails = (data, data2) => {
    setTravelDetailsState(data);
    Cache.setItem("travelDetailsState", data);
  };
  //END FROM BENEFITS
  const FormLogic = (data) => {
    if (activeStep === 0) {
      setPremiumState(data.premium);
      setTravelDetailsState(data);
      clearDataAssured(data);

      props.sendGTM(
        "/travel/" + cover + "/form?form=Quotation",
        "Details",
        "Travel" + camelizeText(cover),
        "travel" + coverType + "_GInsure1"
      );
      nextStep();
    } else if (activeStep === 1) {
      props.sendGTM(
        "/travel/" +
          cover +
          "/form?form=Travel" +
          camelizeText(cover) +
          "AssuredDetails",
        "AssuredDetails",
        "Travel" + camelizeText(cover),
        "travel" + coverType + "_GInsure2"
      );
      setAssuredDetailsState(data);
      //console.log("Assured",data);
      nextStep();
    } else if (activeStep === 2) {
      props.sendGTM(
        "/travel/" +
          cover +
          "/form?form=Travel" +
          camelizeText(cover) +
          "SummaryDetails",
        "SummaryDetails",
        "Travel" + camelizeText(cover),
        "travel" + coverType + "_GInsure3"
      );
      nextStep();
    } else if (activeStep === 3) {
      nextStep();
    }
  };
  const SubSectionState = () => {
    if (activeSubPage === 1) {
      setHeaderTitle("Gcash x Standard Insurance");
      setSubHeaderTitle("Benefits");
      return <Benefits />;
    }
  };
  const backtoMain = () => {
    setActiveMain(1);
  };

  const nextStep = () => {
    let flag = true;
    if (activeStep === 2) {
      flag = false;
    }
    Cache.setItem("plateNumberState", premiumState);
    Cache.setItem("travelDetailsState", travelDetailsState);
    Cache.setItem("assuredDetailsState", assuredDetailsState);
    Cache.setItem("preexDetailsState", preexDetailsState);
    Cache.setItem("preexDetailsStateFlags", preexDetailsStateFlags);
    if (flag) {
      setActiveStep(activeStep + 1);
      setIsLoading(1);
      setTimeout(() => {
        if (activeStep !== 4) {
          setIsLoading(0);
        }
      }, 1000);
    } else {
      setIsLoading(1);
      props.sendGTM(
        "/travel/" + cover + "/form?form=TravelReviewDetails",
        "ReviewDetails",
        "Travel" + camelizeText(cover),
        "travel" + coverType + "_GInsure4"
      );
      setTimeout(() => {
        history.push(`/travel/${travelDetailsState.coverType}/review`);
      }, 1000);
    }
  };
  const backStep = () => {
    let flag = true;

    if (flag) {
      if (activeStep === 0) {
        history.goBack();
      }
      setActiveStep(activeStep - 1);
      setIsLoading(1);
      setTimeout(() => {
        setIsLoading(0);
      }, 1000);
    }
  };

  const editTravelDetails = () => {
    setActiveStep(0);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };

  const [open, setOpen] = useState(false);
  return (
    <div>
      {/* Main Pages */}
      {activeMain === 1 && (
        <>
          <Header headerTitle={headerTitle} saveData={backStep} />
          <div className={props.main === "main" ? "main p-5" : "p-5"}>
            <img
              src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
              width="170"
              height="50"
              alt="logo"
            />
          </div>
          <main className={props.main === "main" ? "main p-5" : "p-5"}>
            {activeStep != 5 && activeStep >= 0 && (
              <WizardComponent
                activeStep={activeStep + 1}
                stepsLabel={stepsLabel}
              />
            )}
            {!isLoading && <SectionState />}
            {!isLoading || <Loading />}
          </main>
          <Modal open={open} setOpen={setOpen} title="">
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 icon-center text-red-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p className="text-center my-5">
              Your vehicle is already insured with Standard Insurance.
            </p>
          </Modal>
          <Footer
            buttonType="button"
            saveData={SubmitForm}
            isLoading={isLoading}
            buttonTitle={buttonTitle}
            isDisabled={isDisabled}
          />
        </>
      )}
      {/* Sub Pages */}
      {activeMain !== 1 && (
        <>
          <div>
            <Header
              headerTitle={props.headerTitle}
              subHeader={headerSubTitle}
              subPage={"1"}
              saveData={backtoMain}
            />
            <div className="absolute w-full">
              <img alt="motorcar" src={"/images/bg.png"} className="image" />
            </div>
            <div className="p-5 pt-12">
              <img
                src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
                width="170"
                height="50"
                alt="logo"
              />
            </div>
            <main className="p-5">
              <div className="">
                <SubSectionState />
              </div>
            </main>
          </div>
        </>
      )}
    </div>
  );
}
export default IndexPage;
