import { useState } from "react";
import Modal from "../../molecules/Modal";

const Quotation = (props) => {
  const quoteData = props.quoteData;

  const [open, setOpen] = useState(false);

  const SaveForm = (e) => {
    e.preventDefault();
    props.saveData();
  };
  const SetSubPage = () => {
    props.setSubPage(1);
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const handleInfoDeductible = () => {
    setOpen(true)
  }

  return (
    <form onSubmit={SaveForm}>
      <div>
        <div className="text-left mb-24">
          <div className="text-left mb-10">
            <div className="flex">
              <div className="flex-1 text-left">
                <span className="font-bold text-lg title-font">Premium Breakdown</span>
              </div>
              <div className="float-right text-right">
                <button type="button" onClick={SetSubPage}>
                  <span className="text-blue-500 font-bold text-xs">
                    Benefits
                  </span>
                </button>
              </div>
            </div>

            <div className="mt-5">
              <span className="text-base font-bold title-font">{props.carDisplay}</span>

              <div className="mt-8">
                <span className="text-base font-bold text-gray-500 title-font">
                  Annual Premium:
                </span>
                <div className="mt-3 bg-gray-50 p-3 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">Basic Premium</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {formatter
                          .format(quoteData.premium.premium)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">VAT</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {formatter
                          .format(quoteData.premium.evat)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">DST</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {formatter
                          .format(quoteData.premium.dst)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">LGT</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {formatter
                          .format(quoteData.premium.lgt)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <hr className="my-5" />

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm font-bold">Total Amount</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm font-bold">
                        {formatter
                          .format(quoteData.premium.total)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex mt-5">
                    <div className="flex-1 text-left">
                      <span className="text-right text-sm">Deductible </span>

                      <button
                        type="button"
                        onClick={handleInfoDeductible}
                        value="chassis"
                      >
                        <div className="icon-info-deductible">
                          <img
                            src={"/images/info.png"}
                            alt="info"
                            className="icon-info-image-deductible"
                          />
                        </div>
                      </button>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-sm">
                        {formatter
                          .format(quoteData.deductible)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 mb-20">
                <span className="text-base font-bold text-gray-500 title-font">
                  Your comprehensive insurance policy includes the following
                  coverages:
                </span>
                <div className="mt-3 bg-gray-50 p-3 rounded-lg">
                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-xs leading-none">
                        Own Damages/Theft
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">
                        {formatter
                          .format(quoteData.odCoverage)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-xs leading-none">
                        Third Party Property Damage
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">
                        {formatter
                          .format(quoteData.pdCoverage)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-xs leading-none">
                        Third Party Bodily Injury
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">
                        {formatter
                          .format(quoteData.pdCoverage)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-xs leading-none">
                        Auto Personal Accident
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">
                        {formatter
                          .format(quoteData.paCoverage)
                          //.format("300000")
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-xs leading-none">
                        Acts of Nature
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">PHP</span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">
                        {formatter
                          .format(quoteData.aonCoverage)
                          .replace("₱", "")}
                      </span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-1 text-left">
                      <span className="text-right text-xs leading-none">
                        Emergency Road Assistance
                      </span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none"></span>
                    </div>
                    <div className="flex-1 text-right">
                      <span className="text-xs leading-none">FREE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} setOpen={setOpen} title="Deductible">
        <div>
          <p>Is an amount of money that you yourself are responsible for paying toward an insured loss.</p>
        </div>
      </Modal>
      
      <button type="submit" style={{ display: "none" }} id="btnSubmit">
        submit
      </button>
    </form>
  );
};

export default Quotation;
