import Modal from "../../molecules/Modal";
import { motorAPI } from "../../../api/fetch-api";
import { useState, useEffect } from "react";
import { formatContact, validateEmail } from "../../utils/helper";

const OtherDetails = (props) => {
  const otherDetails = props.otherDetails;
  //isOwner:"Y",ownerName:"",isMortgage:"N",mortgagee:null

  const [owner, setOwner] = useState(false);
  const [btnValue, setBtnValue] = useState(otherDetails.isOwner);

  const [mortgagee, setMortgagee] = useState(false);
  const [btnMortgagee, setBtnMortgagee] = useState(otherDetails.isMortgage);

  const [open, setOpen] = useState(false);
  const [openEngine, setOpenEngine] = useState(false);

  const [color, setColor] = useState(otherDetails.color);
  const [chassisNumber, setChassisNumber] = useState(
    otherDetails.chassisNumber
  );
  const [engineNumber, setEngineNumber] = useState(otherDetails.engineNumber);
  const [ownerName, setOwnerName] = useState(otherDetails.ownerName);
  const [ownerFName, setOwnerFName] = useState(otherDetails.ownerFName);
  const [ownerMName, setOwnerMName] = useState(otherDetails.ownerMName);
  const [ownerLName, setOwnerLName] = useState(otherDetails.ownerLName);
  const [mortgageeid, setMortgageeid] = useState(otherDetails.mortgagee);
  const [mortgageeName, setMortgageeName] = useState(otherDetails.mortgageeName);

  const [contactNumber, setContactNumber] = useState(otherDetails.contactNumber);
  const [contactPerson, setContactPerson] = useState(otherDetails.contactPerson);
  const [emailAddress, setEmailAddress] = useState(otherDetails.emailAddress);
  const [officeAddress, setOfficeAddress] = useState(otherDetails.officeAddress);

  const handleClickOwner = (event) => {
    console.log(event.target.value);

    if (event.target.value === "N") {
      setOwner(true);
      setBtnValue("N");
    } else {
      setOwner(false);
      setBtnValue("Y");
    }
  };

  const handleClickMortgagee = (event) => {
    console.log(event.target.value);
    if (event.target.value === "Y") {
      setMortgagee(true);
      setBtnMortgagee("Y");
    } else {
      setMortgagee(false);
      setBtnMortgagee("N");
    }
  };

  const handleInfoChassis = (event) => {
    setOpen(true);
  };

  const handleInfoEngine = (event) => {
    setOpenEngine(true);
  };

  const [loading, setLoading] = useState(true);
  const [mortgagelist, setMortgagelist] = useState([]);
  useEffect(() => {
    motorAPI
      .getMortgagee()
      .then((data) => {
        setLoading(false);
        setMortgagelist(data);
      })
      .catch((err) => console.log("Mortgage Error: ", err));

    if (btnMortgagee === "Y") {
      setMortgagee(true);
      setBtnMortgagee("Y");
    } else {
      setMortgagee(false);
      setBtnMortgagee("N");
    }

    if (btnMortgagee === "Y") {
      setMortgagee(true);
      setBtnMortgagee("Y");
    } else {
      setMortgagee(false);
      setBtnMortgagee("N");
      setContactNumber(null);
      setContactPerson(null);
      setEmailAddress(null);
      setOfficeAddress(null);
      setMortgageeName(null);
      setMortgageeid(null);
    }

    if (btnValue === "N") {
      setOwner(true);
      setBtnValue("N");
    } else {
      setOwner(false);
      setBtnValue("Y");
    }
  }, []);

  const handleUpdates = (e) => {
    let objName = e.target.name;
    if (objName === "color") {
      setColor(e.target.value);
    } else if (objName === "chassisNumber") {
      setChassisNumber(e.target.value);
    } else if (objName === "engineNumber") {
      setEngineNumber(e.target.value);
    } else if (objName === "ownerName") {
      setOwnerName(e.target.value);
    } else if (objName === "ownerFName") {
      setOwnerFName(e.target.value);
    } else if (objName === "ownerMName") {
      setOwnerMName(e.target.value);
    } else if (objName === "ownerLName") {
      setOwnerLName(e.target.value);
    } else if (objName === "mortgageeid") {
      setMortgageeid(e.target.value);
      var index = e.target.selectedIndex;
      setMortgageeName(e.target[index].text);
    }
    else if (objName === "contactNumber") {
      setContactNumber(e.target.value);
    }
    else if (objName === "contactPerson") {
      setContactPerson(e.target.value);
    }
    else if (objName === "emailAddress") {
      setEmailAddress(e.target.value);
    }
    else if (objName === "officeAddress") {
      setOfficeAddress(e.target.value);
    }
  };
  const SaveForm = (e) => {
    e.preventDefault();
    const data = {
      isOwner: btnValue,
      ownerName: ownerName,
      ownerFName: ownerFName,
      ownerMName: ownerMName,
      ownerLName: ownerLName,
      isMortgage: btnMortgagee,
      mortgagee: mortgageeid,
      mortgageeName: mortgageeName,
      color: color,
      chassisNumber: chassisNumber,
      engineNumber: engineNumber,
      contactNumber: contactNumber,
      contactPerson: contactPerson,
      emailAddress: emailAddress,
      officeAddress: officeAddress
    };
    props.saveData(data);
  };
  return (
    <>
      <form onSubmit={SaveForm}>
        <div>
          <div className="text-left mb-32">
            <div className="mb-5">
              <h3 className="text-lg font-bold title-font">
                A few more question about your car...
              </h3>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Color:
                </label>
                <input
                  type="text"
                  name="color"
                  onChange={handleUpdates}
                  defaultValue={otherDetails.color}
                  placeholder="e.g."
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  maxLength="50"
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Chassis Number:
                  <button
                    type="button"
                    onClick={handleInfoChassis}
                    value="chassis"
                  >
                    <div className="icon-info">
                      <img
                        src={"/images/info.png"}
                        alt="info"
                        className="icon-info-image"
                      />
                    </div>
                  </button>
                </label>
                <input
                  type="text"
                  placeholder="e.g."
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  name="chassisNumber"
                  onChange={handleUpdates}
                  defaultValue={otherDetails.chassisNumber}
                  maxLength="30"
                  required
                />
              </div>
            </div>

            <div className="mb-14">
              <div className="block relative w-full">
                <label className="inline-block text-gray-500 text-sm font-bold">
                  Engine Number:
                  <button
                    type="button"
                    value="engine"
                    onClick={handleInfoEngine}
                  >
                    <div className="icon-info">
                      <img
                        src={"/images/info.png"}
                        alt="info"
                        className="icon-info-image"
                      />
                    </div>
                  </button>
                </label>
                <input
                  type="text"
                  name="engineNumber"
                  onChange={handleUpdates}
                  defaultValue={otherDetails.engineNumber}
                  placeholder="e.g."
                  className="input-border-none focus:outline-none focus:shadow-outline"
                  maxLength="30"
                  required
                />
              </div>
            </div>

            <div>
              <span className="block text-center font-semibold text-gray-500">
                Are you the owner of this vehicle?
              </span>

              <div>
                <img
                  alt="car-owner"
                  src={"/images/owner.png"}
                  className="mx-auto"
                />
              </div>
              <div className="flex mt-10">
                <div className="flex-1 text-center">
                  <button
                    type="button"
                    className={
                      btnValue === "Y"
                        ? "bg-blue-600 text-white py-3 w-11/12 rounded-full"
                        : "bg-white text-gray-500 py-3 w-11/12 rounded-full border border-gray-500"
                    }
                    onClick={handleClickOwner}
                    value="Y"
                  >
                    YES
                  </button>
                </div>
                <div className="flex-1 text-center">
                  <button
                    type="button"
                    className={
                      btnValue === "N"
                        ? "bg-blue-600 text-white py-3 w-11/12 rounded-full"
                        : "bg-white text-gray-500 py-3 w-11/12 rounded-full border border-gray-500"
                    }
                    onClick={handleClickOwner}
                    value="N"
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>

            {owner && (
              <div className="my-10">
                <span className="block text-center font-semibold">
                  Please provide us the name of the vehicle's owner as shown in
                  the OR/CR
                </span>
                <input
                  className="text-center input-form mt-5"
                  name="ownerFName"
                  onChange={handleUpdates}
                  defaultValue={otherDetails.ownerFName}
                  required
                  placeholder="First Name"
                />
                <input
                  className="text-center input-form mt-5"
                  name="ownerMName"
                  onChange={handleUpdates}
                  defaultValue={otherDetails.ownerMName}
                  placeholder="Middle Name"
                />
                <input
                  className="text-center input-form mt-5"
                  name="ownerLName"
                  onChange={handleUpdates}
                  defaultValue={otherDetails.ownerLName}
                  placeholder="Last Name"
                  required
                />
              </div>
            )}

            <div className="mt-10">
              <span className="block text-center font-semibold text-gray-500">
                Is the car mortgaged?
              </span>

              <div>
                <img
                  alt="car-mortgagee"
                  src={"/images/mortgage.png"}
                  className="mx-auto"
                />
              </div>
              <div className="flex mt-10">
                <div className="flex-1 text-center">
                  <button
                    type="button"
                    className={
                      btnMortgagee === "Y"
                        ? "bg-blue-600 text-white py-3 w-11/12 rounded-full"
                        : "bg-white text-gray-500 py-3 w-11/12 rounded-full border border-gray-500"
                    }
                    onClick={handleClickMortgagee}
                    value="Y"
                  >
                    YES
                  </button>
                </div>
                <div className="flex-1 text-center">
                  <button
                    type="button"
                    className={
                      btnMortgagee === "N"
                        ? "bg-blue-600 text-white py-3 w-11/12 rounded-full"
                        : "bg-white text-gray-500 py-3 w-11/12 rounded-full border border-gray-500"
                    }
                    onClick={handleClickMortgagee}
                    value="N"
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>

            {mortgagee && (
              <div className="my-10">
                <span className="block text-center font-semibold">
                  Please select mortgagee
                </span>
                <div className="block relative w-full mt-5">
                  {loading && (
                    <select
                      name="mortgageeid"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      onChange={handleUpdates}
                      defaultValue={otherDetails.mortgagee}
                      required
                    >
                      <option value="">Select mortgagee</option>
                      <option>Loading data...</option>
                    </select>
                  )}

                  {!loading && (
                    <select
                      name="mortgageeid"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      onChange={handleUpdates}
                      defaultValue={otherDetails.mortgagee}
                      required
                    >
                      <option value="">Select mortgagee</option>
                      {mortgagelist.map((page, index) => {
                        try {
                          if (otherDetails.mortgagee === page.code) {
                            return (
                              <option
                                value={page.code}
                                key={page.code}
                                selected
                              >
                                {page.name}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={page.code}
                                key={page.code + "_" + index}
                              >
                                {page.name}
                              </option>
                            );
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      })}
                    </select>

                    //Additional info for mortgaged:
                  )}
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>

                <div className="mt-8 mb-3">
                  <p className="title-font">
                    If you wish us to send your insurance policy to your bank,
                    please fill in these details:
                  </p>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-500 text-sm font-bold">
                      Contact Person:
                    </label>
                    <input
                      type="text"
                      name="contactPerson"
                      onChange={handleUpdates}
                      defaultValue={otherDetails.contactPerson}
                      placeholder="e.g. Juan Cruz"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      maxLength="50"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-500 text-sm font-bold">
                      Contact Number:
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      onChange={handleUpdates}
                      defaultValue={otherDetails.contactNumber}
                      placeholder="e.g. 09171234567"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      maxLength="11"
                      minLength="11"
                      onBlur={formatContact}
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-500 text-sm font-bold">
                      Office Address:
                    </label>
                    <input
                      type="text"
                      name="officeAddress"
                      onChange={handleUpdates}
                      defaultValue={otherDetails.officeAddress}
                      placeholder="e.g. Office Address"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      maxLength="50"
                      required
                    />
                  </div>
                </div>

                <div className="mb-40">
                  <div className="block relative w-full">
                    <label className="inline-block text-gray-500 text-sm font-bold">
                      Email Address:
                    </label>
                    <input
                      type="text"
                      name="emailAddress"
                      onChange={handleUpdates}
                      defaultValue={otherDetails.emailAddress}
                      placeholder="e.g. juancruz@gmail.com"
                      className="input-border-none focus:outline-none focus:shadow-outline"
                      emailAddress="30"
                      onBlur={validateEmail}
                      required
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <button type="submit" style={{ display: "none" }} id="btnSubmit">
          submit
        </button>
      </form>

      <Modal open={open} setOpen={setOpen} title="Chassis Number">
        <div>
          <img src={"/images/ctpl/chassisno.png"} className="image" />
        </div>
      </Modal>

      <Modal open={openEngine} setOpen={setOpenEngine} title="Engine Number">
        <div>
          <img src={"/images/ctpl/engineno.png"} className="image" />
        </div>
      </Modal>
    </>
  );
};

export default OtherDetails;
