import Summary from "../../components/organisms/Motor-Insurance/Summary";

const MotorcarSummary = () => {
  return (
    <>
      <Summary />
    </>
  );
};

export default MotorcarSummary;
