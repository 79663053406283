import { useEffect } from "react";
import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import Footer from "../../organisms/Footer";
import { manageAPI } from "../../../api/fetch-api";
import { Cache } from "aws-amplify";

import { useHistory } from "react-router-dom";

export default function PolicyList(props) {
  const [policyList, setPolicyList] = useState([]);
  const [policyListLoading, setPolicyListLoading] = useState(true);

  let history = useHistory();

  useEffect(() => {
    manageAPI
      .getPolicyList("/mdIRBQt6g3IRRebxlkHqkri4FzPD7yQQhhqtXoTRas=") //dynamic
      .then((data) => {
        console.log("data", data);
        //Cache.setItem("policy", data);

        setPolicyList(data);
        setPolicyListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        console.log("There was an error!");
      });
  }, []);

  const goToPolicyDetail = (policies) => {
    console.log("gotoPolicyDetails", policies.unid);
    history.push("/manage-policy/");
    Cache.setItem("policyDetails", policies);
  };
  return (
    <>
      <div className="py-8 px-5 main">
        <h3 className="font-bold text-lg mb-8">My Insurance Product</h3>

        {policyListLoading || (
          <div>
            {policyList.map((data, index) => (
              <button onClick={() => goToPolicyDetail(data)} key={index}>
                <div className="bg-white border p-2 rounded mb-5 shadow">
                  <div className="grid grid-cols-6">
                    <div>
                      <div className="manage-image-container">
                        <img
                          src={"/images/logo-icon.png"}
                          className="manage-policy-logo"
                        />
                        <span className="block text-center">SICI</span>
                      </div>
                    </div>

                    <div className="col-span-5 p-5 text-left">
                      <span className="text-lg block">
                        {data.lob == "Motor" ? "Car Protect" : ""}
                      </span>
                      <span className="text-xs manage-date">
                        Purchased Date: {data.inception}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
