const TitleCards = (props) => {
  return (
    <>
      <h3 className="font-bold text-lg">{props.Title}</h3>
      <div className="grid grid-cols-2 main p-5 rounded-lg mt-5">
        {props.Contents}
      </div>
    </>
  );
};

export default TitleCards;
