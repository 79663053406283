import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/organisms/Header";
import Footer from "../components/organisms/Footer";
import Modal from "../components/molecules/Modal";
import { Cache } from "aws-amplify";
import CTPLCoverSection from "../components/organisms/CTPLCoverSection";
import { gcashAPI, authAPI, authDetails } from "../api/fetch-api";

function InitialPage(props) {
  let history = useHistory();

  const [open, setOpen] = useState(false);

  
  const SubmitForm = () => {
    setOpen(true);
  };

  const IdChecker = (id)=>{
    id = id.replaceAll(" ", "+");
    const whitelists = [
      "Sq399m+Gawv0W30F/oV2LgIFP2DeSU8nEjRdbMWa8rM=",
      "/mdIRBQt6g3IRRebxlkHqtRyhAnaQcYw/efCUXEqFM4=", 
      "/mdIRBQt6g3IRRebxlkHqkSN6jO6a3GkiE2L8SlZ5F4=", 
      "/mdIRBQt6g3IRRebxlkHqsCspIKy1YfCF2wXulTotIY=",
      "/mdIRBQt6g3IRRebxlkHqhrqG09gSyMAEjAtG7notTQ=",
      "/mdIRBQt6g3IRRebxlkHquF5Po7Q/RJh9i4K2XOEQgQ=",
      "/mdIRBQt6g3IRRebxlkHqqTA78E05xYfgRr407L7GP8=",
      "/mdIRBQt6g3IRRebxlkHqn+8GeL4uBlB3FSZ7YXJ29E=",
      "/mdIRBQt6g3IRRebxlkHqneLu6tOJDt5axRzyMdJeb8="];
    for (let index = 0; index < whitelists.length; index++) {
      const element = whitelists[index];
      if(id===element){
        return true;
      }
    }
    
    return false;
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let customerId = params.get("customerId");
    console.log(customerId);
    if (customerId === null){
    // if (customerId === null || IdChecker(customerId)===false) {
      history.push("/coming-soon");
      //history.push("/ctpl/packages");
    } else {
      customerId = customerId.replaceAll(" ", "%2B");

      //
      authAPI
      .getAuth(authDetails)
      .then((data1) => {
        Cache.setItem("ginsure_token", data1.token);
      })
    }
    Cache.setItem("customerId", customerId);
    Cache.setItem("cptl_year", null);
  }, []);

  const product = (val) => {
    console.log("product", val);

    if (val == "ctpl") {
      setOpen(true);
    }else{
      history.push("/motor-insurance/?customerId="+Cache.getItem("customerId"));
    }
  };
  
  return (
    <div>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className={"main p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div>
      <main className={"main p-5"}>
        <div className="mb-16">
          <CTPLCoverSection
            open={open}
            setOpen={setOpen}
          />
        </div>
      </main>
      <Footer
        isMain={"1"}
        buttonType="button"
        buttonTitle={"Get Started"}
        saveData={SubmitForm}
      />
    </div>
  );
}
export default InitialPage;
