import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Cache } from "aws-amplify";

import { Link } from "react-router-dom";

function CTPLPackages(props) {
  let history = useHistory();
  const IdChecker = (id) => {
    if (id === "/mdIRBQt6g3IRRebxlkHqhrqG09gSyMAEjAtG7notTQ=") {
      return true;
    }
    return false;
  };
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let customerId = params.get("customerId");
  useEffect(() => {
    Cache.removeItem("carDetailsState");
    Cache.removeItem("clientDetailsState");
    Cache.removeItem("unid");
    Cache.removeItem("product");
    console.log("customerId", customerId);

    // if (customerId === null || IdChecker(customerId) === false) {
    //   //history.push("/coming-soon");
    // } else {
    //   customerId = customerId.replaceAll(" ", "%2B");
    // }
    // Cache.setItem("customerId", customerId);

    // if (Cache.getItem("cptl_year") === null) {
    //   history.push("/");
    // }
  }, []);

  const [mvType, setMvType] = useState("");
  const [year, setYear] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const goToMVtype = (myType) => {
    // history.push("/ctpl/form?mvType=" + myType+"&customerId="+customerId);
    history.push(
      "/ctpl/form?mvType=" +
        myType +
        "&year=" +
        Cache.getItem("cptl_year") +
        "&customerId=" +
        customerId
    );
  };

  const ctplCoverFor = (product) => {
    console.log("product", product);
    setIsDisabled(false);
    if (product == "cars") {
      //setMvType("1");
      //Go To CTPL-Detail

      // setMvType(() => {
      //   return "1";
      // });
      goToMVtype(1);
    } else if (product == "motorcycles") {
      // setMvType(() => {
      //   return "7";
      // });
      goToMVtype(7);
    } else if (product == "lightMediumTrucks") {
      // setMvType(() => {
      //   return "2";
      // });
      goToMVtype(2);
    } else if (product == "heavyTrucks") {
      // setMvType(() => {
      //   return "3";
      // });
      goToMVtype(3);
    }
  };
  useEffect(() => {
    Cache.setItem("mvType", mvType);
  }, [mvType]);

  useEffect(() => {
    setYear(Cache.getItem("cptl_year"));
    console.log("year", year);
  }, []);
  const [isLoading, setIsLoading] = useState(0);
  const ctpl = (val) => {
    setIsLoading(1);
    if (val == "1") {
      setYear(1);
      Cache.setItem("cptl_year", 1);
    }else{
      setYear(3);
      Cache.setItem("cptl_year", 3);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 700);
  };
  return (
    <div>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className={"main p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div>
      <main className={"main p-5"}>
        <p className="font-bold text-lg">Compulsary Third Party Liability</p>

        <div className="p-5 bg-white mt-10 shadow rounded-lg mb-10">
          <p className="mb-5 text-center">
            CTPL protects you from any possible liability from a third party
            caused by bodily injury and/or death in an accident.
          </p>
         
          <p className="mb-5 font-bold text-center">
            Please select the vehicle you want to cover
          </p>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <button
                className="w-full mx-auto"
                type="button"
                onClick={() => ctplCoverFor("cars")}
              >
                <div
                  className={"text-center bg-white border shadow rounded-lg"}
                >
                  <div
                    className={
                      mvType === "1" ? "bg-blue-200 p-3" : "bg-gray-100 p-3"
                    }
                  >
                    <p className="font-bold">Private Car</p>
                  </div>
                  <img
                    src={"/images/ctpl-car.png"}
                    alt="cover-ctpl"
                    className="ctpl-image ctpl-image-car"
                  />
                  <span className="font-bold mt-2 mb-5 block">
                    {(isLoading) ? "Loading...":""}
                    {!isLoading && (<>
                      {year === 1 ? "PHP 610.40" : "PHP 1,660.40"}
                    </>)}
                  </span>
                </div>
              </button>
            </div>

            <div>
              <button
                type="button"
                className="w-full mx-auto"
                onClick={() => ctplCoverFor("motorcycles")}
              >
                <div
                  className={"text-center bg-white border shadow rounded-lg"}
                >
                  <div
                    className={
                      mvType === "7" ? "bg-blue-200 p-3" : "bg-gray-100 p-3"
                    }
                  >
                    <p className="font-bold">Motorcycles</p>
                  </div>
                  <img
                    src={"/images/ctpl-motorcycles.png"}
                    alt="cover-ctpl"
                    className="p-5 ctpl-image"
                  />
                  <span className="font-bold mt-2 mb-5 block">
                    {(isLoading) ? "Loading...":""}
                    {!isLoading && (<>
                      {year === 1 ? "PHP 300.40" : "PHP 770.40"}
                    </>)}
                    
                  </span>
                </div>
              </button>
            </div>

            <div>
              <button
                type="button"
                className="w-full mx-auto"
                onClick={() => ctplCoverFor("lightMediumTrucks")}
              >
                <div
                  className={"text-center bg-white border shadow rounded-lg"}
                >
                  <div
                    className={
                      mvType === "2" ? "bg-blue-200 p-3" : "bg-gray-100 p-3"
                    }
                  >
                    <p className="font-bold">Light Medium</p>
                    <p className="font-bold">Trucks</p>
                  </div>
                  <img
                    src={"/images/ctpl-lightTruck.png"}
                    alt="cover-ctpl"
                    className="p-5 ctpl-image"
                  />
                  <span className="font-bold mt-2 mb-5 block">
                  {(isLoading) ? "Loading...":""}
                    {!isLoading && (<>
                      {year === 1 ? "PHP 660.40" : "PHP 1,800.40"}
                    </>)}
                    
                  </span>
                </div>
              </button>
            </div>

            <div>
              <button
                type="button"
                className="w-full mx-auto"
                onClick={() => ctplCoverFor("heavyTrucks")}
              >
                <div
                  className={"text-center bg-white border shadow rounded-lg"}
                >
                  <div
                    className={
                      mvType === "3" ? "bg-blue-200 p-3" : "bg-gray-100 p-3"
                    }
                  >
                    <p className="font-bold">Heavy</p>
                    <p className="font-bold">Trucks</p>
                  </div>
                  <img
                    src={"/images/ctpl-heavyTruck.png"}
                    alt="cover-ctpl"
                    className="p-5 ctpl-image"
                  />
                  <span className="font-bold mt-2 mb-5 block">
                  {(isLoading) ? "Loading...":""}
                    {!isLoading && (<>
                      {year === 1 ? "PHP 1,250.40" : "PHP 3,490.40"}
                    </>)}
                    
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </main>

      <div className="bg-white p-5 mt-5">
        <div className="relative flex">
          <div className="flex-1 flex">
            <div className="pl-3 pr-3">
              <div className="w-full">
                <img
                  src={"/images/help.png"}
                  alt="help"
                  className="covered-image"
                />
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
              </div>
            </div>
            <div className="w-full">
              <h4 className="font-black text-left text-sm">
                Have More Questions
              </h4>
              <p className="text-left text-xs text-gray-400">
                We're here to help
              </p>

              <div className="mt-8 mb-3 text-left">
                <ul>
                  <Link
                    to={"/ctpl/faq"}
                    className="border-b border-gray-300 pb-2 block"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Frequently Asked Questions
                    </span>
                    <div className="absolute footer-position">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                  <Link
                    to={"/contact"}
                    className="border-b border-gray-300 pb-2 block mt-2"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Contact Us
                    </span>
                    <div className="absolute footer-position-contact">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer
        isMain={"1"}
        buttonType="button"
        saveData={() => goToMVtype(mvType)}
        // isLoading={isLoading}
        isDisabled={isDisabled}
        buttonTitle={"Get Started"}
      /> */}
    </div>
  );
}
export default CTPLPackages;
