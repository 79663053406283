import React, { useState } from "react";

const FaqContact = () => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">Who can I contact if I have more concerns about my GInsure account or
          policy?</span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <ul className="text-sm mt-3">
              <li>A. For Gcash app concerns, please submit a ticket to our Help Center.</li>
              <li className="mt-2">
                B. For any concerns regarding your insurance policy, you may contact us at the following,
                <p className="mt-3 text-sm">8am to 5pm: (917) 828-3984</p>
            <p className="text-sm">5pm to 8am: (632) 88845-1111</p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqContact;
