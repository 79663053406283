import React, { useState } from "react";

const FaqContactTravel = () => {
  return (
    <>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel" className="hidden" />
        <label
          htmlFor="panel"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Who should I call if I need a medical assistance while I am
            traveling?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel">
            <p className="text-sm">
              For medical assistance while traveling, you may reach the
              following:
            </p>
            <ul className="text-sm mt-3">
              <li>Send a message thru Viber: (63) 998-845-1111 or</li>
              <li className="mt-2">
                Call our Emergency Hotline: (+632)8 845-1111 or
              </li>
              <li className="mt-2">
                Send a message to Email Address: inquiry@standard-insurance.com
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel2" className="hidden" />
        <label
          htmlFor="panel2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            Who can I contact if I have more concerns about my GInsure account
            or policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <ul className="text-sm mt-3">
              <li>A. For GCash app concerns you may file a ticket via the GCash help center.</li>
              <li className="mt-2">
                B. For any concerns regarding your insurance policy, please call our hotline number at       09166929000 from 8am to 5pm or (632) 8845-1111 from 5pm onwards, or message us in our FB page @StandardInsurancePH.
                {/* B. For concerns regarding your insurance policy, please call the
                following:
                <p className="mt-3 text-sm">
                  Mobile Hotline Number: (632) 917-3255-818 (Avail from 8am to
                  5pm)
                </p>
                <p className="text-sm">
                  24-Hour Customer Hotline Number: (632) 8845-1111
                </p>
                <p className="text-sm">
                  Email Address:{" "}
                  <span className="text-blue-500">
                    ginsure@standard-insurance.com
                  </span>
                </p>
                <p className="text-sm">
                  Facebook:{" "}
                  <span className="text-blue-500">
                    @StandardInsurancePH
                  </span>
                </p> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqContactTravel;
