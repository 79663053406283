import ContactUs from "../components/organisms/Contact-Us";
import Header from "../components/organisms/Header";
import { Disclosure } from "@headlessui/react";

const PageNotFound = (props) => {
  const close = () => {
    console.log("close");
  };
  return (
    <div>
      <Header headerTitle={props.headerTitle} />

      <main>
        <div className="page-not-found">
         
          <h1 className="text-9xl text-gray-600">404</h1>
          <p className="text-xl font-bold text-center text-gray-600">
           Page Not Found!
          </p>
        </div>
      </main>
    </div>
  );
};
export default PageNotFound;
