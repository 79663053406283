import { fetchWrapper } from "./fetch";

const baseAuthUrl =
  "https://razq5vlxwl.execute-api.ap-southeast-1.amazonaws.com/api";

// const baseUrl ="https://1cr450kc61.execute-api.ap-southeast-1.amazonaws.com/prod";
// const baseUrl = "https://gymp3t84o3.execute-api.ap-southeast-1.amazonaws.com/sit1";

const docsUrl =
  "https://gymp3t84o3.execute-api.ap-southeast-1.amazonaws.com/prod"; //"https://gymp3t84o3.execute-api.ap-southeast-1.amazonaws.com/api2";
const baseUrl =
  "https://1cr450kc61.execute-api.ap-southeast-1.amazonaws.com/prod";

const blacklistURL =
  "https://1cr450kc61.execute-api.ap-southeast-1.amazonaws.com/prod";
const fmvUrl =
  "https://7ls2uxl2ma.execute-api.ap-southeast-1.amazonaws.com/live"; //truck and motorcycle

export const basePath = {
  baseUrl,
  baseAuthUrl,
  docsUrl,
};

export const authDetails = {
  authkey: "c2ljaV9tc298U3RhbmRhcmRAMjg=",
};

/* =======================
    GCASH API
========================== */

export const gcashAPI = {
  getClient,
  postOrder,
};

function getClient(gInsureId) {
  return fetchWrapper.get(baseUrl + `/client?insuranceAccountId=${gInsureId}`);
}

function postOrder(orderId) {
  // return fetchWrapper.post(baseUrl + `/order/${orderId}`);
  // return fetchWrapper.post(baseUrl + `/ginsure/order/${orderId}`);
  return fetchWrapper.post(baseUrl + `/order/${orderId}`);
}

/* =======================
    AUTH API
========================== */

export const authAPI = {
  getAuth,
};
function getAuth(key) {
  console.log("KEY", key);
  return fetchWrapper.post(`${baseAuthUrl}/auth`, key);
}

/* =======================
    PLATENUMBER API
========================== */
export const platenumberAPI = {
  getExisting,
  getMalus,
};

function getExisting(plateNumber) {
  return fetchWrapper.get(
    `https://4zi6siomq4.execute-api.ap-southeast-1.amazonaws.com/prod/ref/motor/${plateNumber}/existing`
  );
}

function getMalus(plateNumber) {
  return fetchWrapper.get(`${baseUrl}/misc/motor/malus/${plateNumber}`);
}

/* =======================
    BLACKLIST API
========================== */
export const blacklistAPI = {
  checkBlacklist,
};

function checkBlacklist(name, bday) {
  return fetchWrapper.get(
    `${blacklistURL}/misc/travel/redflag?name=${name}&bday=${bday}`
  );
}

/* =======================
    MOTOR API
========================== */
export const motorAPI = {
  getMakeList,
  getModelList,
  getMortgagee,
  getQuotation,
  postMotorPolicy,
  putMotorPolicy,
  getMotorPolicy,
  getPrintPolicySchedule,
};

function getMakeList() {
  return fetchWrapper.get(`${baseUrl}/makemodel/manufacturer/list`);
}

function getModelList(data) {
  return fetchWrapper.get(
    `${baseUrl}/makemodel/list?` +
      new URLSearchParams({
        year: data.year,
        manufacturer: data.manufacturer,
      })
  );
}

function getMortgagee() {
  return fetchWrapper.get(`${baseUrl}/misc/bank/list`);
}

function getQuotation(data) {
  //return fetchWrapper.post(`${baseUrl}/motor/quote/get`, data);
  return fetchWrapper.post(`${baseUrl}/quotation/motor/request`, data);
}

function postMotorPolicy(data) {
  return fetchWrapper.post(`${baseUrl}/quotation/motor`, data);
}

function putMotorPolicy(unid, data) {
  return fetchWrapper.put(`${baseUrl}/quotation/motor/${unid}`, data);
}

function getMotorPolicy(policyId, customerId, key) {
  return fetchWrapper.get(
    `${baseUrl}/quotation/motor/${policyId}?customerId=${customerId}`,
    key
  );
}

function getPrintPolicySchedule(unid) {
  return fetchWrapper.get(`${baseUrl}/policy/quotation/${unid}`);
}

/* =======================
    TRAVEL API
========================== */
export const travelAPI = {
  getCountries,
  getBenefits,
  getPackages,
  getAgeTravelVersion,
  getTravelPremium,
  postIntTravel,
  getTravelPolicy,
  getOpenlPreex,
};

function getCountries() {
  // return fetchWrapper.get(`${baseUrl}/countries/list?covid-flag=true`);
  return fetchWrapper.get(`${baseUrl}/misc/travel/countries?covid-flag=true`);
}

function getPackages(countries, packageType, packageVersion) {
  return fetchWrapper.get(
    // `${baseUrl}/travel/package/list?countries=${countries}&packageType=${packageType}&packageVersion=${packageVersion}`
    `${baseUrl}/package/travel/international?countries=${countries}&packageType=${packageType}&packageVersion=${packageVersion}`
  );
}

function getBenefits(zone) {
  // return fetchWrapper.get(`${baseUrl}/travel/package/${zone}/benefits`);
  return fetchWrapper.get(
    `${baseUrl}/package/travel/international/${zone}/benefits`
  );
}

function getAgeTravelVersion() {
  //packageversion
  // return fetchWrapper.get(`${baseUrl}/travel/latest/version`);
  return fetchWrapper.get(
    `${baseUrl}/package/travel/international/version/latest`
  );
}

function getTravelPremium(params) {
  //travel/premium?covid=true&days=20&groupCount=00590001&hazardous=false&packageType=I&sports=false&zone=WA22
  console.log(params);
  // return fetchWrapper.get(`${baseUrl}/travel/premium`, { params: params });
  return fetchWrapper.get(
    // `${baseUrl}/travel/premium?covid=${params.covid}&days=${params.days}&groupCount=${params.groupCount}&hazardous=${params.hazardous}&packageType=${params.packageType}&sports=${params.sports}&zone=${params.zone}`
    `${baseUrl}/quotation/travel/international/premium?covid=${params.covid}&days=${params.days}&groupCount=${params.groupCount}&hazardous=${params.hazardous}&packageType=${params.packageType}&sports=${params.sports}&zone=${params.zone}`
  );
}

function postIntTravel(data) {
  // return fetchWrapper.post(`${baseUrl}/travel/policy`,data);
  return fetchWrapper.post(`${baseUrl}/quotation/travel/international`, data);
}
function getTravelPolicy(params) {
  //return fetchWrapper.get(`${baseUrl}/quotation/travel/international/${params.unid}?email=${params.email}`);
  return fetchWrapper.get(
    `${baseUrl}/quotation/travel/international/${params.unid}?email=${params.email}`
  );
}

function getOpenlPreex(data) {
  return fetchWrapper.post(
    `${baseUrl}/misc/openl/REST/TRAVEL_OPENL/TRAVEL_OPENL/getDataPreExIllness`,
    data
  );
}

/** TRAVEL ASEAN */

export const travelLocalAPI = {
  getOriginList,
  getLocalDestinations,
  getLocalPackageList,
  getLocalTravelPremium,
  postLocalTravel,
  getLocalTravelPolicy,
};

function getOriginList() {
  // return fetchWrapper.get(`${baseUrl}/local/city/list`);
  return fetchWrapper.get(`${baseUrl}/misc/travel/cities`);
}

function getLocalDestinations() {
  // return fetchWrapper.get(`${baseUrl}/local/asean/list`);
  return fetchWrapper.get(`${baseUrl}/misc/travel/asean`);
}

function getLocalPackageList() {
  // return fetchWrapper.get(`${baseUrl}/local/package/list`);
  return fetchWrapper.get(`${baseUrl}/package/travel/local`);
}

function getLocalTravelPremium(params) {
  // return fetchWrapper.get(`${baseUrl}/local/premium?days=${params.days}&packageType=${params.packageType}&zone=${params.zone}&ageBracket1=${params.ageBracket1}&ageBracket2=${params.ageBracket2}`);
  return fetchWrapper.get(
    `${baseUrl}/quotation/travel/local/premium?days=${params.days}&packageType=${params.packageType}&zone=${params.zone}&ageBracket1=${params.ageBracket1}&ageBracket2=${params.ageBracket2}`
  );
}

function postLocalTravel(data) {
  // return fetchWrapper.post(`${baseUrl}/local/policy`,data);
  return fetchWrapper.post(`${baseUrl}/quotation/travel/local`, data);
}

function getLocalTravelPolicy(params) {
  //return fetchWrapper.get(`${baseUrl}/quotation/travel/local/${params.unid}?email=${params.email}`);

  // return fetchWrapper.get(`${baseUrl}/local/policy/${params.unid}?email=${params.email}`);
  return fetchWrapper.get(
    `${baseUrl}/quotation/travel/local/${params.unid}?email=${params.email}`
  );
}

/* =======================
    CONTACT US API
========================== */

export const contactusAPI = {
  postContactUs,
};

function postContactUs(data) {
  return fetchWrapper.post(`${baseUrl}/support`, data);
}

/* =======================
  MANAGE API
========================== */

export const manageAPI = {
  getPolicy,
  cancelPolicy,
  getPolicyList,
};

function getPolicy(policyId, customerId, key) {
  return fetchWrapper.get(
    `${baseUrl}/quotation/motor/${policyId}?customerId=${customerId}`,
    key
  );
}

function cancelPolicy(params) {
  return fetchWrapper.post(`${baseUrl}/support/cancellation`, params);
}

function getPolicyList(ginsureId) {
  return fetchWrapper.get(
    `${baseUrl}/policy/list?insuranceAccountId=${ginsureId}`
  );
}

/* =======================
  CTPL and FMV API
========================== */

export const fmvAPI = {
  getTruckBusList,
  getMotorcycleList,
  getTruckBusModel,
  getMotorcycleModel,
  getCTPLManufacturerList,
  getCTPLModelList,
};

function getCTPLManufacturerList(product, year) {
  return fetchWrapper.get(`${fmvUrl}/${product}?year=${year}`);
}

function getCTPLModelList(product, year, make) {
  return fetchWrapper.get(`${fmvUrl}/${product}?year=${year}&make=${make}`);
}

function getTruckBusList(year) {
  return fetchWrapper.get(`${fmvUrl}/truck-bus?year=${year}`);
}

function getMotorcycleList(year) {
  return fetchWrapper.get(`${fmvUrl}/motorcycle?year=${year}`);
}

function getTruckBusModel(year, make) {
  return fetchWrapper.get(`${fmvUrl}/truck-bus?year=${year}make=${make}`);
}

function getMotorcycleModel(year, make) {
  return fetchWrapper.get(`${fmvUrl}/motorcycle?year=${year}make=${make}`);
}

/* =======================
  CITY/Province, Postal API
========================== */

export const cityPostalAPI = {
  getCity,
  getPostal,
};

function getCity() {
  return fetchWrapper.get(`${baseUrl}/misc/city/list`);
}

function getPostal(city) {
  return fetchWrapper.get(`${baseUrl}/misc/city/${city}/postals`);
}

/* =======================
  Motor CTPL API
========================== */
export const motorCtplAPI = {
  postMotor,
  putMotorQuot,
  getMotorCtplPremium,
};

function postMotor(data) {
  //return fetchWrapper.post(`${baseUrl}/motor/ctpl`, data);
  return fetchWrapper.post(`${baseUrl}/quotation/motor/ctpl`, data);
}

function putMotorQuot(data, unid) {
  //return fetchWrapper.put(`${baseUrl}/motor/ctpl/${unid}`, data);
  return fetchWrapper.put(`${baseUrl}/quotation/motor/ctpl/${unid}`, data);
}

function getMotorCtplPremium(param) {
  // return fetchWrapper.get(`${baseUrl}/motor/ctpl/premium?engineType=${engineType}&bodyType=${bodyType}&plateNumber=${plateNumber}${modelid_}${startDate_}`);
  //return fetchWrapper.get(`${baseUrl}/motor/ctpl/premium?${param}`);
  return fetchWrapper.get(`${baseUrl}/quotation/motor/ctpl/request?${param}`);
}

/* =======================
 PET API
========================== */
export const petAPI = {
  getBreeds,
  getParentBreeds,
  getCanineBreeds,
  getFelineBreeds,
  getPetPremium,
  getPetQuotation,
  postPetQuotation,
  putPetQuotation,
};

function getParentBreeds(data) {
  return fetchWrapper.post(`${baseUrl}/misc/openl/pet/getParentBreeds`, data);
}

function getBreeds(data) {
  return fetchWrapper.post(`${baseUrl}/misc/openl/pet/getBreeds`, data);
}

function getCanineBreeds(data, type) {
  return fetchWrapper.post(
    `${baseUrl}/misc/openl/pet/getCanineBreeds?type=${type}`,
    data
  );
  ///misc/openl/pet/getFelineBreeds
}

function getFelineBreeds(data, type) {
  return fetchWrapper.post(
    `${baseUrl}/misc/openl/pet/getFelineBreeds?type=${type}`,
    data
  );
  ///misc/openl/pet/getFelineBreeds
}

function getPetPremium(data) {
  return fetchWrapper.post(
    `${baseUrl}/misc/openl/pet/DeterminePolicyPremium`,
    data
  );
}

function getPetQuotation(unid, customerId) {
  return fetchWrapper.get(
    `${baseUrl}/quotation/pet/${unid}?customerid=${customerId}`
  );
}

function postPetQuotation(data) {
  return fetchWrapper.post(`${baseUrl}/quotation/pet`, data);
}

function putPetQuotation(unid, data) {
  return fetchWrapper.put(`${baseUrl}/quotation/pet/${unid}`, data);
}
// travel saver api - OPENL - orig x dest
export const travelSaverAPI = {
  getTravelSaver,
};

function getTravelSaver(data) {
  return fetchWrapper.post(
    `${baseUrl}/misc/openl/REST/TRAVEL_SAVER/TRAVEL_SAVER/Label`,
    data
  );
}

/* =======================
VALIDATION API
========================== */

export const validationAPI = {
  getEmailValidation,
  getMobileValidation,
};

function getEmailValidation(email) {
  return fetchWrapper.get(`${baseUrl}/client/validation/email?email=${email}`);
}

function getMobileValidation(mobile) {
  return fetchWrapper.get(
    `${baseUrl}/client/validation/mobile?mobileNo=${mobile}`
  );
}

export const openlAPI = {
  getDisabledPage,
};

//Disabled
function getDisabledPage(data) {
  return fetchWrapper.post(
    `${baseUrl}/misc/openl/REST/FE_System_Status/FE_System_Status/FrontEndSystemStatus`,
    data
  );
}
