import React, { useState } from "react";

const FaqClaimTravel = ({ ...props }) => {
  return (
    <>
      <div className="w-full">
        <input type="checkbox" name="panel" id="panel1" className="hidden" />
        <label
          htmlFor="panel1"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">How do I make a claim?</span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel1">
            <p className="text-sm">
              Once you have all the documents prepared, you may:
            </p>
            <ul className="text-sm mt-3">
              <li>Send a message thru Viber: (63) 998-845-1111 or</li>
              <li>Call our Emergency Hotline: (+632) 8845-1111 or</li>
              <li>
                Send a message to email address:{" "}
                <span class="text-blue-500">
                  inquiry@standard-insurance.com
                </span>
              </li>
            </ul>
            <p className="text-sm mt-3">
              We will guide you through process. Our representative will assist
              you and direct you of the claim procedure.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-2" className="hidden" />
        <label
          htmlFor="panel-2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What should I do if my luggage was delayed or damaged?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              <strong>If your luggage is delayed, please:</strong>

              <ul className="text-sm ml-3 list-disc">
                <li className="mt-3">
                  Go to your nearest airline representative and secure a
                  property irregularity report.
                </li>
                <li>
                  Make sure that you have the original, if not at least a copy,
                  of the delivery receipt once your baggage has been delivered
                  to you.
                </li>
              </ul>
            </p>

            <p className="mt-3 text-sm">
              <strong>If your luggage was damaged:</strong>
              <ul className="text-sm ml-3 list-disc">
                <li className="mt-3">
                  Go to your nearest airline representative and secure a
                  property irregularity report/damaged baggage report.
                </li>
                <li>
                  Ensure that you secure a certification of settlement or non-
                  settlement (for the damages) from the airline.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-3" className="hidden" />
        <label
          htmlFor="panel-3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What should I do if I get sick or have an accident while traveling
            {props.cover === "international" ? " abroad?" : " domestically?"}
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              Please contact the Emergency Hotline No. (+63 2 8845-1111) so we
              can assist you.
              <ul className="text-sm ml-3 list-disc mt-3">
                <li className="mt-3">
                  Kindly keep documents, bills, and receipts related to your
                  hospitalization as they may be needed when you file claim.
                </li>
                <li>
                  Pre-existing Illnesses or injuries arising from chronic
                  ailments or from those that existed prior to the inception
                  date of the policy is excluded from this guarantee.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-4" className="hidden" />
        <label
          htmlFor="panel-4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What should I do if my flight was delayed, canceled, or diverted?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              If the reason for the delay, cancellation or diversions is covered
              by the policy, please:
              <ul className="text-sm ml-3 list-disc mt-3">
                <li className="">
                  Go to your nearest airline representative and secure a
                  certification from the airline.
                </li>
                <li>Save and keep your old and new boarding passes.</li>
                <li>
                  Keep the original receipts of the food you purchased during
                  the delay.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-5" className="hidden" />
        <label
          htmlFor="panel-5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What should I do if I get robbed or while staying in a hotel?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              As soon as you discover the incident, immediately go to the
              nearest police station where the loss happened and secure a police
              report. Make sure you declare to the police a detailed list of
              your stolen items.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-6" className="hidden" />
        <label
          htmlFor="panel-6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2 border-blue-300"
        >
          <span className="inline-block w-11/12">
            What document should I prepare If I have a claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <p className="text-sm">
              You may also contact the Emergency Hotline No. (+63 2 8845-1111)
              and we will assist you on the required Information in filing a
              claim.
            </p>
            <p className="text-sm mt-3">
              Kindly keep documents, bills, and receipts related to the loss or
              Inconvenience as they may be needed when you file claim.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqClaimTravel;
