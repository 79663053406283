import { useEffect } from "react";

export default function InclusionListPet() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="text-left">
        <div className="mb-5">
          <h3 className="text-lg font-bold leading-tight text-yellow-500">Coverage</h3>
        </div>

        <p className="text-sm leading-tight pb-8">
          Get up to 100% of eligible vet bills up to the maximum benefit limit
          of P50,000 when you fur baby gets sick or is injured. No Deductible or
          additional fees to claim. No per condition limit.
        </p>

        <h3 className="font-bold text-base mb-5">
          Examples of Covered Conditions and Treatments
        </h3>

        <div>
          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Serious Illness</h4>
            <ul className="text-sm ml-5 p-2 list-disc">
              <li>Cancer</li>
              <li>Heart Disease</li>
              <li>Gastric Dilation Volvolus</li>
              <li>Pyometra</li>
              <li>Heart Stress</li>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Common Illness</h4>
            <ul className="text-sm ml-5 p-2 list-disc"> 
              <li>Diarrhea</li>
              <li>Vomiting</li>
              <li>Ear Infections</li>
              <li>Anal Gland Disorders</li>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Chronic Illness</h4>
            <ul className="text-sm ml-5 p-2 list-disc"> 
              <li>Allergies</li>
              <li>Arthritis</li>
              <li>Diabetes</li>
              <li>Pancretitis</li>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Accident & Injuries</h4>
            <ul className="text-sm ml-5 p-2 list-disc"> 
              <li>Fractures</li>
              <li>Sprains</li>
              <li>ACL Rupture</li>
              <li>Wound</li>
              <li>Snake and Insect Bite</li>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Hereditary Condition</h4>
            <ul className="text-sm ml-5 p-2 list-disc"> 
              <li>Hip dysplasia</li>
              <li>Eye disorders</li>
              <li>Blood disorders</li>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Testing & Diagnostics</h4>
            <ul className="text-sm ml-5 p-2 list-disc"> 
              <li>X-Rays, MRIs, CT Scans</li>
              <li>Ultrasound</li>
              <li>Blood Tests</li>
              <li>Biopsy</li>
              <li>Snake and Insect Bite</li>
              <li>Endoscopies</li>
              <li>Culture & Sensitivity Tests</li>
            </ul>
          </div>

          <div className="mb-3">
            <h4 className="bg-blue-100 rounded p-2">Procedure</h4>
            <ul className="text-sm ml-5 p-2 list-disc"> 
              <li>Surgery</li>
              <li>Chemotheraphy</li>
              <li>Confinement</li>
              <li>Tonometry</li>
              <li>Fecalysis</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
