import { useState, useEffect } from "react";

export default function InclusionList({product, ...props}) {
  
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <>
      <div className="text-left">
        <div className="mb-5">
          <h3 className="text-lg font-bold leading-tight">Inclusions</h3>
        </div>

        <p className="text-sm leading-tight pb-8">
          More reasons to drive with peace of mind with the following
          additional coverages:
        </p>

        <div>
          <ol className="list-decimal text-xs text-gray-500 px-3">
            <li className="pb-3">
              <strong>Loss of Use</strong>
              <p className="py-2">
                Subject to proof of Loss of Use and presentation of
                evidence/proofs, provided that liability has been determined
                first, subject to SICI own evaluation.
              </p>
              <p className="py-2">
                Php300.00 per day per unit, maximum of Php1,500.00 and in
                aggregate of 5 days, subject to submission of original Official
                Receipts. In lieu of ORs, settlement to be based on the
                reasonable amount of transportation fare consumed by the Insured
                by submitting a Summary of Expenses.
              </p>
              <ol className="list-disc pl-3">
                <li>Annual aggregate limit of Php1,500.00</li>
                <li>Allowance starts:</li>

                <ol className="list-decimal pt-3 pl-3">
                  <li>
                    3 days from the commencement of repairs per occurrence
                  </li>
                  <li>
                    {" "}
                    On the date the company has declared the Scheduled Vehicle a
                    constructive total loss due to accident and will cease upon
                    release of settlement check or limit of Php5,000.00
                    whichever comes first.
                  </li>
                  <li>
                    In case of theft/carnap and catastrophic claim, Loss of use
                    will not respond.
                  </li>
                </ol>
              </ol>
            </li>
            <li className="pb-3">
              Standard Insurance Technical And Training Center (TTC)
              <p className="pt-3">
                With the use of our technologically advanced repair and
                restoration facility located in Naic, Cavite, assured can avail
                the following benefits:
              </p>
              <ol className="list-decimal pl-3 pt-3">
                <li>
                  Free pickup and delivery with our towing service provider and
                  our very own tow trucks and boom truck (within Metro Manila
                  and nearby provinces).
                </li>
                <li>
                  With TTC's utilization of multi- sourced parts, deductibles
                  and depreciation are waived on all valid claims.
                </li>
                <li>
                  TTC may provide a loaner car while the insured unit is being
                  repaired with us.
                </li>
              </ol>
            </li>
            <li className="pb-3">
              Auto Road Assistance Program
              <p className="pt-3">
                Free 24 hours emergency roadside assistance (For Private Cars
                including SUVs/AUVs up to fifteen (15) years of age and
                Motorcycle not less than 400cc.
              </p>
            </li>
            <li className="pb-3">
              Loss of, or damage, to accessories or spare parts by burglary,
              housebreaking, or theft, unless the schedule vehicle is stolen at
              the same time.
            </li>
            <li className="pb-3">
              Add-on Accessories
              <p className="pt-3">
                Automatic cover for all standard built-in accessories.
              </p>
              <p className="pt-3">
                All accessories installed in addition to the standard built-in
                accessories (non-standard) are subject to declaration and
                additional premium.
              </p>
            </li>
            <li className="pb-3">
              Automatic Extension of Period of Insurance
              <p className="pt-3">
                30 days subject to additional pro-rata premium based on expiring
                terms and conditions.
              </p>
            </li>
            <li className="pb-3">
              Good Driver Bonus
              <p className="pt-3">
                5% of the premium shall be refunded and deducted from the
                renewal policy with zero claim in the previous year.
              </p>
            </li>
            <li className="pb-3">
              Repair Shop Clause
              <p className="pt-3">
                If the age of the insured vehicle at the time of loss is:
              </p>
              <ol className="pt-3 pl-3 list-decimal">
                <li>Below twelve (12) years old – Casa.</li>
                <li>
                  Above twelve (12) years old – non-dealer / non-casa accredited
                  repair shop.
                </li>
              </ol>
            </li>
            <li className="pb-3">
              Importation Clause
              <p className="pt-3">
                Warranted that in the event of loss to the whole vehicle or
                losses necessitating importation of parts not locally
                manufactured, the Company’s liability shall be limited to the
                actual cost of the vehicle or the imported parts concerned less
                cost of taxes, freight and other miscellaneous charges relative
                thereto.
              </p>
            </li>

            <li className="pb-3">
              Personal Accident
              <p className="pt-3">
                Covers the Assured against Accidental Death with limit up to
                Php100,000. Scope is anywhere in the world, 24/7 excluding
                whilst on board the insured vehicle.
              </p>
            </li>

            <li className="pb-3">
              Immediate Repair
              <p className="pt-3">
                Of Windshield, Windshield Molding, Sunroofs, Back Glass, Window
                Glasses, Side View Mirror (subject to reimbursement of actual
                charges; not subject to depreciation). Insured may also choose
                to have the repairs done in Standard Insurance Technical and
                Training Center.
              </p>
            </li>

            <li className="pb-3">
              Airbag Clause (if applicable)
              <p className="pt-3">
                The company will pay and/or replace the airbag including the
                cost of re-installation following its deployment or discharge
                consequent upon a sudden and accidental impact to the insured
                vehicle provided that due to such impact the said vehicle
                sustained damages that are compensable under Section III of the
                policy.
              </p>
            </li>
          </ol>
        </div>
        {/* <Accordion questionsAnswers={questionsAnswers}/> */}
      </div>
    </>
  );
}
