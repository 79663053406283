export const DataPrivacyAct = () => {
  return (
    <div>
      <p className="text-xs">
        In compliance with the Data Privacy Act (DPA) of 2012, and its
        Implementing Rules and Regulations (IRR) effective September 9, 2016, I
        allow Standard Insurance to collect, store and process my information to
        provide me certain services declared in relation to the insurance
        policy/ies I am purchasing.
      </p>

      <p className="text-xs mt-3">
        For more information on how Standard Insurance protects its data, you
        may visit our Privacy Policy at{" "}
        <a
          className={"text-blue-500"}
          href="https://standard-insurance.com/privacy-policy.html"
          target="_blank"
        >
          https://standard-insurance.com/privacy-policy.html
        </a>
      </p>
    </div>
  );
};
