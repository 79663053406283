import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CTPLCovered = ({ ...props }) => {
  const [coveredData, setCoveredData] = useState();
  const [coveredLoading, setCoveredLoading] = useState(true);

  useEffect(() => {
    setCoveredLoading(false);
    setCoveredData(props.covered);
  }, []);

  return (
    <>
      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg">
        <div>
          <h2 className="text-gray-800 text-sm font-bold leading-snug">
            {props.subTitle}
          </h2>

          {coveredLoading || (
            <>
              {coveredData.map((data, index) => (
                <div className="relative flex" key={index}>
                  <div className="grid grid-cols-6 gap-4 mt-5">
                    <div className="covered-image self-center">
                      <img
                        src={data.imageUrl}
                        alt="covered-notcovered"
                        className="covered-icon"
                      />
                    </div>
                    <div key={index} className="self-center col-span-5">
                      
                      
                        <p className="text-gray-600 text-sm leading-snug ml-3 font-medium">
                          {data.description}
                        </p>
                      
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CTPLCovered;
