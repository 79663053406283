import "tw-elements";
const Accordion = (props) => {
  console.log("Props", props);

  let classes = 
  `accordion-button
  relative
  flex
  items-center
  w-full
  text-base text-gray-800 text-left
  border-0
  rounded-none
  transition
  focus:outline-none;`;
  if (
    props.AccordionLocation === "summary" ||
    props.AccordionLocation === "pet"
  ) {
    classes += " p-3 bg-blue-100";
  } else {
    classes += " pb-3 mb-3 border-b-2 border-gray-100";
  }

  return (
    <>
      <div
        class="accordion accordion-flush"
        id={"accordionContainer_" + props.AccordionLocation}
      >
        {props.AccordionTitles.map((item, index) => {
          if (index != 0) {
            classes += " collapsed";
          }
          return (
            <>
              <div class="accordion-item" key={index}>
                <h2 class="accordion-header mb-0" id={"heading" + index}>
                  <button
                    class={classes}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapse" + index}
                    aria-expanded={index == 0 ? "true" : "false"}
                    aria-controls={"collapse" + index}
                  >
                    <span className="font-bold">{item}</span>
                  </button>
                </h2>
                <div
                  id={"collapse" + index}
                  class={
                    index == 0
                      ? "accordion-collapse collapse show"
                      : "accordion-collapse collapse "
                  }
                  aria-labelledby={"heading" + index}
                  data-bs-parent={
                    "#accordionContainer_" + props.AccordionLocation
                  }
                >
                  <div class="accordion-body  bg-white">
                    {props.AccordionContents(index)}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Accordion;
