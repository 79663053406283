import { Cache } from "aws-amplify";
import { useEffect, useState } from "react";
import Header from "../../components/organisms/Header";
import WizardCTPL from "../../components/organisms/WizardCTPL";

const TravelFormSummary = (props) => {
  useEffect(() => {}, []);
  return (
    <div>
      <Header headerTitle={props.headerTitle} />

      <div className={props.main === "main" ? "main p-5" : "p-5"}>
        <img
          src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
          width="170"
          height="50"
          alt="logo"
        />
      </div>
      <main className="p-5">
        <WizardCTPL />

        <h3 className="text-lg font-bold title-font">
          Please provide your travel details
        </h3>

        <div className="my-5">
          <form>
            <div>
              <div className="text-left mb-24">
                <div className="mb-5">
                  <h3 className="text-lg font-bold">
                    Kindly review the information below before proceeding
                  </h3>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="font-semibold">Travel Details</span>
                  </div>
                  <div className="flex-1 text-right">
                    <button type="button">
                      <span className="text-blue-500 font-bold text-xs">
                        Edit
                      </span>
                    </button>
                  </div>
                </div>

                <div className="mt-5">
                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Package Type
                      </label>
                      <span className="text-lg font-semibold block">2017</span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Sports Competition
                      </label>
                      <span className="text-lg font-semibold block">FORD</span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Origins
                      </label>
                      <span className="text-lg font-semibold block">
                        Philippines
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Destination
                      </label>
                      <span className="text-lg font-semibold block">
                        ABG 1280
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Date of Departure
                      </label>
                      <span className="text-lg font-semibold block">Gray</span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Date of Arrival
                      </label>
                      <span className="text-lg font-semibold block">
                        C20-3920-93294
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Number of Passengers
                      </label>
                      <span className="text-lg font-semibold block">
                        E93482093483
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Package
                      </label>
                      <span className="text-lg font-semibold block">
                        E93482093483
                      </span>
                    </div>
                  </div>

                  {/* <div className="mb-3">
              <div className="block relative w-full">
                <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                  MV File Number
                </label>
                <span className="text-lg font-semibold block">
                  ABC12345678912
                </span>
              </div>
            </div> */}

                  <div className="flex mt-10 mb-5">
                    <div className="flex-1 text-left">
                      <span className="font-semibold">Assured Details</span>
                    </div>
                    {/* <div className="flex-1 text-right">
                <button type="button">
                  <span className="text-blue-500 font-bold text-xs">Edit</span>
                </button>
              </div> */}
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Full Name
                      </label>
                      <span className="text-lg font-semibold block">Juan</span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Birthday
                      </label>
                      <span className="text-lg font-semibold block">
                        Flores
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Passport Number
                      </label>
                      <span className="text-lg font-semibold block">
                        Dela Cruz
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Beneficiary
                      </label>
                      <span className="text-lg font-semibold block">
                        1990-11-18
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Email Address
                      </label>
                      <span className="text-lg font-semibold block">
                        Filipino
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Contact Number
                      </label>
                      <span className="text-lg font-semibold block">Male</span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="block relative w-full">
                      <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                        Email Address
                      </label>
                      <span className="text-lg font-semibold block">
                        juandc@gmail.com
                      </span>
                    </div>
                  </div>

                  <div className="mt-16">
                    <p className="block text-center text-xs text-gray-400">
                      By clicking this button, I agree to share my personal
                      information to Standard Insurance for the purpose of
                      processing my application and managing my policy.
                    </p>

                    <br />
                    {/* <p>
                      For more information on how Standard Insurance protect its
                      data, you may visit our Privacy Policy at{" "}
                      <a className="text-blue-700">
                        {" "}
                        https://www.standard-insurance.com
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default TravelFormSummary;
