import CarDetails from "../../components/organisms/Motor-Insurance/Car-Details";

const MotorcarDetails= () => {
  return (
    <>
      <CarDetails />
    </>
  );
};

export default MotorcarDetails;
