import { Cache } from "aws-amplify";
import { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Modal from "../../molecules/Modal";
import { useHistory } from "react-router-dom";
import { motorAPI, gcashAPI ,basePath} from "../../../api/fetch-api";
import { Loading } from "../../atoms/loading";
import OtherDetails from "./Other-Details";
import { rule } from "postcss";
import { formatNumber } from "../../utils/helper";

const Review = (props) => {
  const history = useHistory();
  const plateNumberState = Cache.getItem("plateNumberState");
  const carDetailsState = Cache.getItem("carDetailsState");
  const quoteState = Cache.getItem("quoteState");
  const carDisplay = Cache.getItem("carDisplay");
  const otherDetailsState = Cache.getItem("otherDetailsState");
  const clientInfo = JSON.parse(Cache.getItem("clientInfo"));
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  });
  const Today = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var m = strArray[d.getMonth()];
    return m + " " + day + ", " + year;
  };
  const [mname, setMname] = useState("");
  const [mOwnername, setMOwnername] = useState("");

  //date formatter
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  const [open, setOpen] = useState(false);
  const EnableButton = () => {
    setIsDisabled(0);
  };
  const DisableButton = () => {
    setIsDisabled(1);
  };
  const handleConfirmation = (event) => {
    const checked = event.target.checked;

    if (checked === true) {
      //setOpen(true);
      EnableButton();
    } else {
      DisableButton();
    }
  };

  const SubmitForm = () => {
    setIsLoading(1);
    var today = new Date();
    var next_year = today.setFullYear(today.getFullYear() + 1);
    var client = {
      address1: clientInfo.resultData.address.street,
      address2: (clientInfo.resultData.address.town !==null && clientInfo.resultData.address.town !=="")? clientInfo.resultData.address.town : " ",
      addressType: "H",
      bday: clientInfo.resultData.birthday,
      birthPlace: null,
      branchCode: "SI",
      //clientNo: null,
      clientType: "I",
      contactNumber: formatNumber(clientInfo.resultData.mobileNumber),
      contactType: "M",
      email: clientInfo.resultData.emailAddress,
      firstName: (otherDetailsState.isOwner==="Y")?clientInfo.resultData != null && clientInfo.resultData.firstName :otherDetailsState.ownerFName ,
      fullName: (otherDetailsState.isOwner==="Y")?clientInfo.resultData != null && clientInfo.resultData.firstName + " " + clientInfo.resultData.middleName + " " + clientInfo.resultData.lastName:otherDetailsState.ownerFName + " " + otherDetailsState.ownerMName + " " + otherDetailsState.ownerLName,
      gender: "F",
      ginsureAccountId: Cache.getItem("customerId"),
      idNo: null,
      idType: null,
      industry: null,
      iosClientNumber: null,
      lastName: (otherDetailsState.isOwner==="Y")?clientInfo.resultData != null && clientInfo.resultData.lastName:otherDetailsState.ownerLName,
      maritalStatus: "S",
      masterClientNo: null,
      middleName: (otherDetailsState.isOwner==="Y")?clientInfo.resultData != null && clientInfo.resultData.middleName:otherDetailsState.ownerMName ,
      nationality: clientInfo.resultData.nationality,
      postalCode: clientInfo.resultData.address.zipCode,
      province: "Metro Manila"
    };

    var postData = {
      bodyType: carDetailsState.bodyType,
      chassisNumber: otherDetailsState.chassisNumber,
      client: client,
      color: otherDetailsState.color,
      endDate: formatDate(next_year),
      engineNumber: otherDetailsState.engineNumber,
      engineType: carDetailsState.engineType,
      fmv: carDetailsState.fmv,
      make: carDetailsState.make,
      model: carDetailsState.model,
      modelid: carDetailsState.modelid,
      mvFileNumber: null,
      plateNumber: plateNumberState.plateNumber,
      questionnaire: null,
      seats: carDetailsState.seats,
      startDate: formatDate(new Date()),
      year: carDetailsState.year,
      mortgagee: otherDetailsState.mortgagee,
      mortgageeDetail: null,
      merchantId: ""+ process.env.REACT_APP_MOTOR_ID+""
    };

    
    const token_id = Cache.getItem("ginsure_token");
    console.log("UNID:", Cache.getItem("unid"));

    if(otherDetailsState.isMortgage!=="N"){
      postData.mortgageeDetail = {
        contactNumber: formatNumber(otherDetailsState.contactNumber),
        contactPerson: otherDetailsState.contactPerson,
        emailAddress: otherDetailsState.emailAddress,
        officeAddress: otherDetailsState.officeAddress,
      };
    }

    if (
      Cache.getItem("unid") !== undefined &&
      Cache.getItem("unid") !== 0 &&
      Cache.getItem("unid") !== null
    ) {
      postData.unid =  Cache.getItem("unid");
      postData.merchantId =  "0003" ;
      fetch(
        basePath.baseUrl + "/quotation/motor/" + Cache.getItem("unid"),
        {
          method: "PUT",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            "Authorization" : token_id
          },
        }
      )
        .then((response) => {
          let response_status = response.status;
          if (response_status == 200) {
            gcashAPI
              .postOrder(Cache.getItem("unid"))
              .then((data1) => {
                setIsLoading(1);
                setTimeout(() => {
                  window.location.href = data1.checkoutUrl;
                  console.log("Checkout URL:", data1.checkoutUrl);
                }, 1000);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("There was an error!");
        });
    } else {
      motorAPI
        .postMotorPolicy(postData)
        .then((data) => {
          console.log("New Quote data:", data);
          Cache.setItem("quoteState", data);
          Cache.setItem("unid", data.unid);

          gcashAPI.postOrder(data.unid).then((data1) => {
            setIsLoading(1);
            setTimeout(() => {
              window.location.href = data1.checkoutUrl;
              console.log("Checkout URL:", data1.checkoutUrl);
            }, 1000);
          });
        })
        .catch((err) => console.log("Model Error: ", err));
    }
  };

  const gotoTerms = () =>{
    setIsLoading(1);
    window.location.replace("https://docs.google.com/gview?embedded=true&url="+ process.env.REACT_APP_MOTOR_TERMS+"");
    //window.location.href ="https://ginsure-dev.s3.ap-southeast-1.amazonaws.com/files/Terms+and+Conditions.pdf";
    // fetch("https://ginsure-dev.s3.ap-southeast-1.amazonaws.com/files/SICI+Private+Car+Terms+and+Conditions", {
    //     method: "GET",
    //     responseType: "blob",
    //     headers: {
    //       Accept: "application/pdf",
    //     },
    //   })
    //     .then((response) => response.blob())
    //     .then((data) => {
    //       //*********Download */
    //       var link = document.createElement('a');
    //       link.href = window.URL.createObjectURL(data);
    //       link.download = "Terms and Conditions.pdf";
    //       link.click();
    //     })
    //     .catch((error) => {
    //       console.log("There was an error printing: ", error);
    //     });
  }

  useEffect(() => {
    try {
      setIsLoading(0);
      setMname(clientInfo.resultData.middleName.charAt(0));
      if (
        clientInfo.resultData.middleName.charAt(0) != "-" &&
        clientInfo.resultData.middleName.charAt(0) != ""
      ) {
        setMname(clientInfo.resultData.middleName.charAt(0) + ".");
      }
      if (
        otherDetailsState.ownerMName.charAt(0) != "-" &&
        otherDetailsState.ownerMName.charAt(0) != ""
      ) {
        setMOwnername(otherDetailsState.ownerMName.charAt(0) + ".");
      }
    } catch (err) {
      setMname("");
      setMOwnername("");
    }
    if (quoteState.premium == null) {
      history.push("/motor-insurance/form");
    }
    console.log("Quote: ", quoteState);
  }, []);
  return (
    <div>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="review" src={"/images/review.png"} className="image" />
      </div>
      {/* <div className={"p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div> */}
      {!isLoading && (
        <main className={"p-5"}>
          <div className="mt-44 mb-24">
            <div className="text-left mb-10">
              {/* <div>
              <img alt="payment-banner" />
            </div> */}

              <div className="mt-5">
                <span className="text-base font-bold">Premium</span>
                <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Basic Premium
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatter
                        .format(quoteState.premium.premium)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">VAT</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatter
                        .format(quoteState.premium.evat)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">DST</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatter.format(quoteState.premium.dst).replace("₱", "")}
                    </span>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">LGT</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatter.format(quoteState.premium.lgt).replace("₱", "")}
                    </span>
                  </div>
                </div>

                <hr className="my-5" />

                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Total Amount
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm font-bold">
                      {formatter
                        .format(quoteState.premium.total)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>

                <div className="flex mt-5">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm">Deductible </span>

                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatter.format(quoteState.deductible).replace("₱", "")}
                    </span>
                  </div>
                </div>
              </div>
              </div>

              <div className="mt-5">
                <span className="text-base font-bold">Insured</span>
                <div className="mt-2 bg-blue-50 p-3 rounded-lg">
                  <div className="flex py-2">
                    <div className="flex-1 text-left">
                      <span className="text-right text-base">
                      {(otherDetailsState.isOwner==="Y")?clientInfo.resultData != null && clientInfo.resultData.firstName + " " + clientInfo.resultData.middleName + " " + clientInfo.resultData.lastName:otherDetailsState.ownerFName + " " + otherDetailsState.ownerMName + " " + otherDetailsState.ownerLName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative flex mt-16">
                <div className="flex-1 flex">
                  <div className="pl-5 pr-5">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <h4 className="font-black text-left text-sm title-font">Need help?</h4>
                    <p className="text-left text-xs text-gray-400">
                      Should you need any assistance or have any inquiries,
                      please contact{" "}
                      <span className="text-blue-600 font-bold">
                        ginsure@standard-insurance.com
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <hr className="my-5" />

              <span className="text-base font-bold">Declarations</span>
              <div className="flex mt-5">
                <div className="px-5">
                  <input type="checkbox" onChange={handleConfirmation} />
                </div>
                <div>
                  <p className="text-sm">
                    I have read{" "}
                    <span className="text-blue-600 font-bold">
                      <a
                        onClick={gotoTerms}
                        
                      >
                        {" "}
                        terms and agreements
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Modal open={open} setOpen={setOpen} title="">
          <div>
          
            <p>
              Terms and Conditions will be opened in your external browser.
            </p>

            <p>Would you like to proceed?</p>
          </div>
        </Modal>
        </main>
      )}
      {!isLoading || <Loading />}

      <Footer
        buttonType="button"
        saveData={SubmitForm}
        isLoading={isLoading}
        isDisabled={isDisabled}
        buttonTitle={"CONFIRM AND PAY"}
      />
    </div>
  );
};

export default Review;
