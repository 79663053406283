import { Cache } from "aws-amplify";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import { gcashAPI, authAPI, authDetails } from "../../../api/fetch-api";
const PetIndex = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  const [open, setOpen] = useState(false);
  const SubmitForm = () => {
    history.push("/pet-insurance/covered");
  };

  const IdChecker = (id)=>{
    id = id.replaceAll(" ", "+");
    const whitelists = [
      "/mdIRBQt6g3IRRebxlkHqlmv8me/NGq7TUE3A/Asa+o=",
      "/mdIRBQt6g3IRRebxlkHqqoOOqNlU58B11DDwvPiifc=",
      "/mdIRBQt6g3IRRebxlkHqqJwWEb1Nsb4Jp4+DpipNys=",
      "/mdIRBQt6g3IRRebxlkHqqTA78E05xYfgRr407L7GP8=",
      "/mdIRBQt6g3IRRebxlkHqhrqG09gSyMAEjAtG7notTQ=" //test id
    ];
    for (let index = 0; index < whitelists.length; index++) {
      const element = whitelists[index];
      if(id===element){
        return true;
      }
    }
    
    return false;
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    Cache.clear();
    let customerId = params.get("customerId");

    Cache.setItem("unid", null);
    if (customerId === null) {
    // if (customerId === null || IdChecker(customerId)===false) {
      history.push("/coming-soon");
    } else {
      customerId = customerId.replaceAll(" ", "%2B");
      console.log(customerId);
      authAPI
        .getAuth(authDetails)
        .then((data1) => {
          Cache.setItem("ginsure_token", data1.token);
          console.log("Token: ", data1.token);
          gcashAPI
            .getClient(customerId, data1.token)
            .then((data) => {
              setIsLoading(0);
              setIsDisabled(0);
              console.log("Customer information :", data);
              Cache.setItem("clientInfo", JSON.stringify(data));
              Cache.setItem("customerId", customerId);
              if (
                data.resultInfo.resultCode === "SYSTEM_ERROR" ||
                data.resultInfo.resultCode === "FAILED"
              ) {
                history.push("/500");
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
              setIsLoading(0);
              setIsDisabled(0);
            });
        })
        .catch((error) => {
          console.log("error", error);
          console.log("There was an error!");
          history.push("/500");
        });
    }
  }, []);
  return (
    <>
      <div>
        <Header headerTitle={props.headerTitle} />
        <div className="absolute w-full">
          <img alt="motorcar" src={"/images/bg.png"} className="image" />
        </div>
        <div className="p-5 pt-12">
          <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
        </div>
        <main className="p-5">
          <div className="mb-20">
            <p>
              Here are <strong>good reasons</strong> to get{" "}
              <span className="text-yellow-400">Pet Health</span> with Standard
              Insurance!
            </p>

            <div className="relative flex">
              <div className="grid grid-cols-3 mt-5">
                <div className="self-center">
                  <img src="/images/pet/index/pet-1.png" alt="pet-covered" />
                </div>
                <div className="self-center col-span-2">
                  <h5 className="ml-3 font-bold mb-1 text-sm leading-tight">
                    Peace of mind for Pet Parents
                  </h5>
                  <p className="text-xs ml-3 text-justify">
                    Standard Insurance Pet Health will provide peace of mind to
                    pet parents when unforseen circumtances arise such when pet
                    gets sick or gets into an accident where significant cost
                    treatment is involved.
                  </p>
                </div>
              </div>
            </div>

            <div className="relative flex">
              <div className="grid grid-cols-3 mt-5">
                <div className="self-center">
                  <img src="/images/pet/index/pet-2.png" alt="pet-covered" />
                </div>
                <div className="self-center col-span-2">
                  <h5 className="ml-3 font-bold mb-1 text-sm leading-tight">
                    Financial Protection
                  </h5>
                  <p className="text-xs ml-3 text-justify">
                    Standard Insurance Pet Health will provide coverage for the
                    majority of eligible vet expenses.
                  </p>
                </div>
              </div>
            </div>

            <div className="relative flex">
              <div className="grid grid-cols-3 mt-5">
                <div className="self-center">
                  <img src="/images/pet/index/pet-3.png" alt="pet-covered" />
                </div>
                <div className="self-center col-span-2">
                  <h5 className="ml-3 font-bold mb-1 text-sm leading-tight">
                    Better Decisions
                  </h5>
                  <p className="text-xs ml-3 text-justify">
                    {" "}
                    Clients will be in better position to deal with major
                    medical emergencies having the insurance to back them up.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer
          isMain={"1"}
          buttonType="button"
          saveData={SubmitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
          buttonTitle={"GET POLICY"}
        />
      </div>
    </>
  );
};

export default PetIndex;