export const formatDate = (date) => {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year; // mm/dd/yyyy
};

export const getAge = (date) => {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const subtractMonths = (months, date) => {
  date.setMonth(date.getMonth() - months);
  return date;
}

export const subtractYears = (year, date) => {
  date.setYear(date.getFullYear() - year);
  return date;
}

export const inputDateFormat = (date) => {
  return date.toISOString().split("T")[0]
}

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "PHP",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


//format mobile/contact from 63-9 to 09
export const formatNumber = (input) => {
  const regex = /^63-9/;
  if (regex.test(input)) {
    const replacedInput = input.replace(regex, "09");
    return replacedInput.replace("-", "");
  }else {
    return input;
  }
}

export const formatContact = (event) => {
  const input = event.target.value; // Get the current input value
  const lastDigit = input.substr(-1); // Get the last digit of the input
  const previousDigit = input.substr(-2, 1); // Get the second-to-last digit of the input

  // Check if the first digit is valid
  if (input.length === 1 && lastDigit !== '0') {
      event.target.setCustomValidity('Contact number must start with 0.'); // Set the custom error message
      event.target.reportValidity(); // Trigger the validation error message
      return;
  }

  // Check if the second digit is valid
  if (input.length === 2 && ((input[0] !== '0' ) || (input[1] !== '9' ))) {
      event.target.setCustomValidity('Contact number 2nd digit must start with 9.'); // Set the custom error message
      event.target.reportValidity(); // Trigger the validation error message
      return;
  }

  if (input.length >= 3 && (input[0] !== '0' || input[1] !== '9')) {
    event.target.setCustomValidity('Contact number must start with 09.');
    event.target.reportValidity();
    return;
  }
  
  // No repetitive number last (9 digit)
  if (input.length >= 11) {
      const lastNineDigits = input.substr(-7); // Get the last 9 digits of the input
      if (lastNineDigits === lastDigit.repeat(7)) {
          // Notify the user
          event.target.setCustomValidity('The last 7 digits cannot be the same.'); // Set the custom error message
          event.target.reportValidity(); // Trigger the validation error message
          return;
      }
  }

  // Clear the error message if the input is valid
  event.target.setCustomValidity('');
}
export const camelizeText = (str)=> {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const validateEmail = (emailInput, isForm) => {
  let emailTrim = (isForm)?emailInput.value:emailInput.target.value
  let emailInputs = (isForm)?emailInput:emailInput.target
  // Get the email address entered by the user
  const email = emailTrim.toString().trim();

  // Define a regular expression pattern to match valid email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Check that the email address is a valid format
  if (!emailRegex.test(email)) {
    emailInputs.setCustomValidity("Please enter a valid email address."); // Set the custom error message
    emailInputs.reportValidity();
    
    return;
  }



  // Split the email address into the email name and domain name
  const [emailName, emailDomain] = email.split("@");

  // Check that the email domain is not "standard-insurance.com"

  // If all checks pass, the email address is valid
  emailInputs.setCustomValidity('');
}