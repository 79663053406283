function ButtonAction(props) {
  if (props.isLoading!==undefined && (props.isLoading)) {
    return (
      <button
        type="button"
        className="bg-blue-600 text-white py-3 w-10/12 rounded-full opacity-50 manage-policy-font"
        disabled
      >
        Loading ...
      </button>
    );
  }
  if(props.isDisabled!==undefined && (props.isDisabled))
  return (
    <button
      type="button"
      className="bg-blue-600 text-white py-3 w-10/12 rounded-full opacity-50 manage-policy-font"
      disabled
    >
      {props.buttonTitle === undefined && "Next"}
      {props.buttonTitle !== undefined && props.buttonTitle}
    </button>
  );
  return (
    <button
      type="button"
      className="bg-blue-600 text-white py-3 w-10/12 rounded-full manage-policy-font"
      onClick={props.saveData}
    >
      {props.buttonTitle === undefined && "Next"}
      {props.buttonTitle !== undefined && props.buttonTitle}
    </button>
  );
}

export default ButtonAction;
