import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const TravelCoveredAbout = ({ ...props }) => {
  const [coveredData, setCoveredData] = useState([]);
  const [coveredDatas, setCoveredDatas] = useState({});
  const [coveredLoading, setCoveredLoading] = useState(true);

  useEffect(() => {
    setCoveredLoading(false);
    setCoveredData(props.covered);
    setCoveredDatas(props.covered)
  }, []);

  return (
    <>
      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg mb-5">
      
        <div>
          {/* <h2 className="text-gray-800 text-sm font-bold leading-snug">
            {props.subTitle}
          </h2> */}

          {coveredLoading || (
            <>
              {coveredData.map((data, index) => (
                <div className="" key={index}>
                  <div className="grid grid-cols-6 gap-4 mt-5">
                    <div className="covered-image self-center">
                      <img
                        src={data.imageUrl}
                        alt="covered-notcovered"
                        className="covered-icon"
                      />
                    </div>
                    <div key={index} className="self-center col-span-5">
                      <h5 className="ml-3 font-semibold mb-1 text-sm leading-tight">
                        {data.title}   
                      </h5>

                      {props.title === "notCovered" || (
                        <p className="text-gray-600 text-xs leading-snug ml-3">
                          {data.description}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="self-center">
                    </div>
                    {/* BULLETED ITEMS */}
                    <div className="self-center col-span-5"> 
                      {
                        (data.item !== undefined || data.item !== "") && (
                          <ul className="ml-3 font-semibold mb-1 text-sm leading-tight">
                            {
                              data.item?.map((data1, index) => (
                                <li style={{listStyle: "disc"}}>{data1}</li>
                              ))
                            }
                          </ul>
                        )
                      }
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {/* <div className="mt-10">
            <Link to={"/" + props.cover.toLowerCase()}>
              <span className="text-blue-500 text-xs font-bold underline">
                View complete list of {props.cover}
              </span>
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TravelCoveredAbout;
