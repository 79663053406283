const LabelledContent = (props) => {
  return (
    <>
      <div className="mt-5">
        {props.Labels.map((item, index) => {
          return (
            <>
              { item !== "" &&
              <div className="mb-3">
                <div className="block relative w-full">
                  <label className="inline-block text-gray-600 text-sm font-semibold text-sm">
                    {item}
                  </label>
                  <span className="text-lg font-semibold block">{props.Contents[index]}</span>
                </div>
              </div>
              }
            </>
          );
        })}
      </div>
    </>
  );
};

export default LabelledContent;
