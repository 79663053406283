import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PetPremium = ({ ...props }) => {
  return (
    <>
      <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg">
        <div>
          <h2 className="text-gray-800 text-sm font-bold leading-snug">
            Pet Health Insurance Sample Premium Table
          </h2>

          <h4 className="mt-5 text-sm font-bold">Dogs (1-year Coverage)</h4>
          <div className="relative border border-gray-200 mt-1 p-3 rounded">
            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-md font-bold">Breed</span>
              </div>
              <div className="flex text-right">
                <span className="text-md font-bold">Age</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-md font-bold">Premium</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Shih Tzu</span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">8 wks - 6 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 3,960.00</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Shih Tzu</span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">7 yrs - 9 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 5,860.00</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Pug</span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">8 wks - 6 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 5,160.00</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Pug</span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">7 yrs - 9 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 7,060.00</span>
              </div>
            </div>
          </div>

          <h4 className="mt-5 text-sm font-bold">Cats (1-year Coverage)</h4>
          <div className="relative border border-gray-200 mt-1 p-3 rounded">
            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-md font-bold">Breed</span>
              </div>
              <div className="flex text-right">
                <span className="text-md font-bold">Age</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-md font-bold">Premium</span>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Persian</span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">8 wks - 6 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 1, 500.00</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">Persian</span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">7 yrs - 9 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 2, 695.47</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">
                  Scottish Fold
                </span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">8 wks - 6 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 2, 062.10</span>
              </div>
            </div>

            <div className="flex">
              <div className="flex-1 text-left">
                <span className="text-right text-sm font-bold">
                  Scottish Fold
                </span>
              </div>
              <div className="flex text-right">
                <span className="text-sm">7 yrs - 9 yrs</span>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm">Php 3, 257.57</span>
              </div>
            </div>
          </div>

          <p className="mt-3 text-xs font-bold text-gray-500">
            Note: Premium depends on the pet's breed.
          </p>
        </div>
      </div>
    </>
  );
};

export default PetPremium;
