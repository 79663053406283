import OtherDetails from "../../components/organisms/Motor-Insurance/Other-Details";

const MotorcarOtherDetails = () => {
  return (
    <>
      <OtherDetails />
    </>
  );
};

export default MotorcarOtherDetails;
