import { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import Benefits from "../Motor-Benefits/Benefits";
import { motorAPI, basePath } from "../../../api/fetch-api";
import { Cache } from "aws-amplify";
import { Loading } from "../../atoms/loading";
import Modal from "../../molecules/Modal";
import WizardComponent from "../WizardComponent";
import PetDetails from "./form-details";
import PetQuotation from "./form-quotation";
import PetOtherDetails from "./form-other-details";
import PetClientDetails from "./form-client-details";
import PetSummary from "./form-summary";
import ProgressBar from "../ProgressBar";

const PetForms = (props) => {
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubPage, setActiveSubPage] = useState(1);
  const [activeMain, setActiveMain] = useState(1);
  const [isLoading, setIsLoading] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerSubTitle, setSubHeaderTitle] = useState("");
  const [buttonTitle, setButtonTitle] = useState("NEXT");
  const stepsLabel = [
    "Pet Details",
    "Quotation",
    "Other Pet Details",
    "Client Details",
    "Summary",
  ];

  //page form states
  const [premiumState, setPremiumState] = useState(0);
  const [petDetailsState, setPetDetailsState] = useState([]);
  const [quotationState, setQuotationState] = useState([]);
  const [otherDetailsState, setOtherDetailsState] = useState([]);
  const [clientDetailsState, setClientDetailsState] = useState([]);

  const [clientInfo, setClientInfo] = useState(
    JSON.parse(Cache.getItem("clientInfo"))
  );
  //Main USEEFFECT
  useEffect(() => {
    console.log(Cache.getItem("quotationState"));
    if (
      Cache.getItem("petDetailsState") === undefined ||
      Cache.getItem("petDetailsState") === null ||
      Cache.getItem("petDetailsState") === ""
    ) {
      setPetDetailsState({
        petName: "",
        petGender: "",
        species: "",
        type: "",
        breed: "",
        breeds: [],
        sireDamBreeds: [],
        bday: "",
        petAge: 0,
        petAgeGroup: "",
        petAgeGroupDesc: "",
        sire: "",
        dam: "",
        parentBreedDisabled: true,
      });

      setQuotationState({
        Value_RoundedBasicPremium: 0.0,
        Value_PremiumTax: 0.0,
        dst: 0.0,
        Value_PremiumAndTaxes: 0.0,
      });

      setOtherDetailsState({
        petColor: "",
        desexed: false,
        microchipped: false,
        dateOfInsertion: "",
        microchipNo: "",
        pcciNo: "",
        veterinaryClinic: "",
        petAcquired: "",
        dateAcquired: "",
        regularlyVaccinated: false,
        sufferedAccidental: false,
        preConditions: [],
        desexedYes: "",
        desexedNo: "",
        microchippedYes: "",
        microchippedNo: "",
        regularlyVaccinatedYes: "",
        regularlyVaccinatedNo: "",
        injuryIllnessYes: "",
        injuryIllnessNo: "",
        condCount: 1,
        petAcquiredDesc: "",
      });

      setClientDetailsState({
        firstName: clientInfo?.resultData?.firstName,
        middleName: clientInfo?.resultData?.middleName,
        lastName: clientInfo?.resultData?.lastName,
        bday: clientInfo?.resultData?.birthday,
        gender: "",
        contactNumber: clientInfo?.resultData?.mobileNumber,
        emailAddress: clientInfo?.resultData?.emailAddress,
      });
    } else {
      setPetDetailsState(Cache.getItem("petDetailsState"));
      setQuotationState(Cache.getItem("quotationState"));
      setOtherDetailsState(Cache.getItem("otherDetailsState"));
      setClientDetailsState(Cache.getItem("clientDetailsState"));
      if( Cache.getItem("coveredFlag")!==true){
        setActiveStep(4);
        // setActiveStep(Cache.getItem("cacheStep"));
      }
      else{
        Cache.setItem("coveredFlag",false);
      }
      
    }
  }, []);

  const SectionState = () => {
    setHeaderTitle(stepsLabel[activeStep]);
    if (activeStep === 0) {
      setButtonTitle("NEXT");
      return (
        <PetDetails petDetailsState={petDetailsState} saveData={FormLogic} pageTitle={"Pet Details"} />
      );
    } else if (activeStep === 1) {
      setButtonTitle("GET POLICY");
      return (
        <PetQuotation
          quotationState={quotationState}
          petDetailsState={petDetailsState}
          saveData={FormLogic}
        />
      );
    } else if (activeStep === 2) {
      setButtonTitle("NEXT");
      return (
        <PetOtherDetails
          otherDetailsState={otherDetailsState}
          saveData={FormLogic}
          pageTitle={"Quotation"}
        />
      );
    } else if (activeStep === 3) {
      setButtonTitle("NEXT");
      return (
        <PetClientDetails
          clientDetailsState={clientDetailsState}
          saveData={FormLogic}
          pageTitle={"Other Pet Details"}
        />
      );
    } else if (activeStep === 4) {
      setButtonTitle("CONTINUE");
      return (
        <PetSummary
          petDetailsState={petDetailsState}
          quotationState={quotationState}
          otherDetailsState={otherDetailsState}
          clientDetailsState={clientDetailsState}
          saveData={FormLogic}
          pageTitle={"Client Details"}
          BacktoStart = {BacktoStart}
          GotoStep={GotoStep}
        />
      );
    } else {
      return <></>;
    }
  };

  const SubmitForm = () => {
    //API
    document.getElementById("btnSubmit").click();

    // if (activeStep === 1) {
    //   document.getElementById("btnSubmit").click();
    // } else if (activeStep === 0) {
    //   document.getElementById("btnSubmit").click();
    // } else {
    //   document.getElementById("btnSubmit").click();
    // }
  };

  //date formatter
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const FormLogic = (data) => {
    if (activeStep === 0) {
      props.sendGTM("/pet-insurance/forms?form=PetDetails","PetDetails","Pet","pet_GInsure1");
      setPetDetailsState(data);
      // setPremiumState()
      nextStep();
    } else if (activeStep === 1) {
      props.sendGTM("/pet-insurance/forms?form=QuotationDetails","QuotationDetails","Pet","pet_GInsure2");
      setQuotationState(data);
      nextStep();
    } else if (activeStep === 2) {
      props.sendGTM("/pet-insurance/forms?form=PetOtherDetails","OtherDetails","Pet","pet_GInsure3");
      setOtherDetailsState(data);
      nextStep();
    } else if (activeStep === 3) {
      props.sendGTM("/pet-insurance/forms?form=PetClientDetails","ClientDetails","Pet","pet_GInsure4");
      setClientDetailsState(data);
      nextStep();
    } else if (activeStep === 4) {
      props.sendGTM("/pet-insurance/forms?form=PetSummaryDetails","SummaryDetails","Pet","pet_GInsure5");
      nextStep();
    }
  };

  const SetSubPage = (page) => {
    setActiveSubPage(1);
    setActiveMain(0);
  };
  const SubSectionState = () => {
    if (activeSubPage === 1) {
      setHeaderTitle("Gcash x Standard Insurance");
      setSubHeaderTitle("Benefits");
      return <Benefits />;
    }
  };
  const backtoMain = () => {
    setActiveMain(1);
  };

  const nextStep = () => {
    let flag = true;
    if (activeStep === 4) {
      flag = false;
    }
    Cache.setItem("quotationState", quotationState);
    Cache.setItem("petDetailsState", petDetailsState);
    Cache.setItem("otherDetailsState", otherDetailsState);
    Cache.setItem("clientDetailsState", clientDetailsState);
    // Cache.setItem("")
    if (flag) {
      setActiveStep(activeStep + 1);
      Cache.setItem("cacheStep", activeStep + 1)
      setIsLoading(1);
      setTimeout(() => {
        if (activeStep !== 5) {
          setIsLoading(0);
        }
      }, 1000);
    } else {
      props.sendGTM("/pet-insurance/forms?form=PetReviewDetails","ReviewDetails","Pet","pet_GInsure6");
      setIsLoading(1);
      setTimeout(() => {
        history.push("/pet-insurance/review");
      }, 1000);
    }
  };
  const backStep = () => {
    let flag = true;

    if (flag) {
      if (activeStep === 0) {
        history.goBack();
      }
      setActiveStep(activeStep - 1);
      Cache.setItem("cacheStep", activeStep - 1)
      setIsLoading(1);
      setTimeout(() => {
        setIsLoading(0);
      }, 1000);
    }
  };
  const BacktoStart = () => {
    setActiveStep(0);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const GotoStep = (step) => {
    setActiveStep(step);
    setIsLoading(1);
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const [open, setOpen] = useState(false);

  return (
    <div>
      {/* Main Pages */}
      {activeMain === 1 && (
        <>
          <Header headerTitle={headerTitle} saveData={backStep} />

          <div className={props.main === "main" ? "main p-5" : "p-5"}>
            <img
              src="https://res.cloudinary.com/standard-insurance/image/upload/v1623759087/sici/standard-insurance-logo_kjyvao.png"
              width="170"
              height="50"
              alt="logo"
            />
          </div>
          <main className={props.main === "main" ? "main p-5" : "p-5"}>
            {activeStep != 6 && (
              <ProgressBar
                activeStep={activeStep + 1}
                stepsLabel={stepsLabel}
                product={"pet"}
              />
            )}

            {!isLoading && <SectionState />}
            {!isLoading || <Loading />}
          </main>
          <Footer
            buttonType="button"
            saveData={SubmitForm}
            isLoading={isLoading}
            buttonTitle={buttonTitle}
          />
        </>
      )}
    </div>
  );
};

export default PetForms;
