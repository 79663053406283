import React, { useState } from "react";

const FaqProductCTPL = () => {
  return (
    <>
      <div className="w-full">
        <input type="checkbox" name="panel" id="panel1" className="hidden" />
        <label
          htmlFor="panel1"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What is a Compulsory Third Party Liability (CTPL)?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel1">
            <p className="text-sm">
              Compulsory Third Party Liability or CTPL is a type of insurance
              required by the Land Transportation Office upon vehicle
              registration. It covers bodily injury and death caused to a Third
              Party (person/s) by the Assured. Standard Insurance will settle a
              valid claim of up to PhP100,000, subject to the policy limits.
              CTPL is a standalone cover and responds first in the event of a
              third party's bodily injury, death, and medical expenses.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-3" className="hidden" />
        <label
          htmlFor="panel-3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How can I avail this product?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">
              To avail of the Standard Insurance CTPL, please follow these
              steps:
            </p>
            <ul className="text-sm mt-3">
              <li>
                <strong>STEP 1. </strong>Login to your GCash app, then click the
                GInsure icon
              </li>
              <li>
                <strong>STEP 2. </strong>Choose Standard Insurance product for
                "CTPL."
              </li>
              <li>
                <strong>STEP 3. </strong>PProvide the necessary customer and car
                details
              </li>
              <li>
                <strong>STEP 4. </strong>Review your policy details and confirm
              </li>
              <li>
                <strong>STEP 5. </strong>Click "Confirm and Pay"
              </li>
              <li>
                <strong>STEP 6. </strong> Get a copy of your insurance policy
                and official receipt via your registered email.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-4" className="hidden" />
        <label
          htmlFor="panel-4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What type of vehicles can be insured?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <ul className="text-sm">
              <li>
                Private Cars such as Sedan, AUV, MPV, Wagon, Van, Pick-up,
                Motorcycles, Trucks are required to have a CTPL coverage upon
                registration.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-5" className="hidden" />
        <label
          htmlFor="panel-5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          Am I eligible to avail this product?
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              You can avail of this product if you are a Fully Verified GCash
              user:
            </p>
            <ul className="text-sm mt-3">
              <li>• who owns a car that is insurable</li>
              <li>
                • who would like to purchase an insurance policy for somebody
                else
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-6" className="hidden" />
        <label
          htmlFor="panel-6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I buy a policy for someone else?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <p className="text-sm">
              Yes, you can buy a CTPL policy on behalf of someone else or if you
              are not the owner of the vehicle. You will be required to indicate
              the name of the vehicle's registered owner.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-7" className="hidden" />
        <label
          htmlFor="panel-7"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I pay for my insurance?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel7">
            <p className="text-sm">
              Annual premium payment will be deducted automatically from your
              GCash wallet upon purchase. Please ensure you have enough balance
              in your GCash wallet to complete a successful transaction.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-8" className="hidden" />
        <label
          htmlFor="panel-8"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            When will the policy be issued?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel8">
            <p className="text-sm">
              We will issue an e-Policy after you have completed the application
              process and payment. It will be sent to your registered email
              address within an hour from the time of your purchase. If you did
              not receive the e-Policy, please check your SPAM folder. You may
              also email or call the following:
            </p>
            <p className="mt-3 text-sm">
              Mobile Hotline Number: (632) 917-828-3984 (Avail from 8am to 5pm)
            </p>
            <p className="text-sm">
              24-Hour Customer Hotline Number: (632) 8845-1111
            </p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-9" className="hidden" />
        <label
          htmlFor="panel-9"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            When will be the start of my policy?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel9">
            <p className="text-sm">
              Your CTPL policy will commence based on the last digit of your
              vehicle's plate number:
            </p>
            <table className="mt-5 w-full border text-sm">
              <thead className="border">
                <tr className="border">
                  <th className="border p-2 font-bold">Plate Number</th>
                  <th className="border p-2 font-bold">Start Date</th>
                </tr>
              </thead>
              <tbody className="border">
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 1</td>
                  <td className="border p-2 text-center">February 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 2</td>
                  <td className="border p-2 text-center">March 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 3</td>
                  <td className="border p-2 text-center">April 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 4</td>
                  <td className="border p-2 text-center">May 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 5</td>
                  <td className="border p-2 text-center">June 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 6</td>
                  <td className="border p-2 text-center">July 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 7</td>
                  <td className="border p-2 text-center">August 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 8</td>
                  <td className="border p-2 text-center">September 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 9</td>
                  <td className="border p-2 text-center">October 1</td>
                </tr>
                <tr className="border">
                  <td className="border p-2 text-center">Ending in 0</td>
                  <td className="border p-2 text-center">November 1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-10" className="hidden" />
        <label
          htmlFor="panel-10"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What should I do if the CTPL I received is not authenticated?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel10">
            <p className="text-sm">
              To register your vehicle in LTO successfully, your CTPL must be
              authenticated. You will receive a notification that your CTPL
              needs further authentication in your email. A representative from
              Standard Insurance will contact you to facilitate the
              authentication. Additional information (i.e., updated plate no.,
              correct motor/serial number) may be required from you to proceed
              with the authentication.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-11" className="hidden" />
        <label
          htmlFor="panel-11"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How many CTPL policies can I purchase?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel11">
            <p className="text-sm">
              You can avail as many CTPL policies as you can, depending on the
              number of cars you own.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-12" className="hidden" />
        <label
          htmlFor="panel-12"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Who can I contact if I want the original copy of my policy delivered
            to me?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel12">
            <p className="text-sm">
              Our CTPL or Certificate of Cover (COC) will be provided to you
              electronically and in a printable format. Please print and present
              to LTO during vehicle registration.
            </p>

            <p className="mt-3 text-sm">
              If you prefer a hard copy of the CTPL, you may call our hotline
              numbers:
            </p>
            <p className="mt-3 text-sm">
              Mobile Hotline Number: (632) 917-828-3984 (Avail from 8am to 5pm)
            </p>
            <p className="text-sm">
              24-Hour Customer Hotline Number: (632) 8845-1111
            </p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqProductCTPL;
