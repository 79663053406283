const CTPLWizard = (props) => {
    let activeClass1 = (props.activeStep>=0) ? "bg-blue-600":"border-gray-100 bg-gray-100";
    let activeClass2 = (props.activeStep>=1)? "bg-blue-600":"border-gray-100 bg-gray-100";
    let activeClass3 = (props.activeStep>=2)? "bg-blue-600":"border-gray-100 bg-gray-100";
    // let activeClass4 = (props.activeStep>=4)? "bg-blue-600":"border-gray-100 bg-gray-100";
    let barActiveClass1 = (props.activeStep>=1)? "bg-blue-100":"bg-gray-200";
    let barActiveClass2 = (props.activeStep>=2)? "bg-blue-100":"bg-gray-200";
    let barActiveClass3 = (props.activeStep>=3)? "bg-blue-100":"bg-gray-200";
    return (
      <>
        <div className="w-full mb-10">
          <div className="flex">
            <div className="w-1/3">
              <div className="relative mb-2">
                <div className={
                    `w-5 h-5 mx-auto border-2 rounded-full text-lg text-white flex items-center `+activeClass1}></div>
              </div>
  
              <div className="text-xs text-center font-bold">Car Details</div>
            </div>
  
            <div className="w-1/3">
              <div className="relative mb-2">
                <div className="absolute flex align-center items-center align-middle content-center wizard">
                  <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                    <div
                      className={`w-full ${barActiveClass1} py-1 rounded`}
                    ></div>
                  </div>
                </div>
  
                <div className={`w-5 h-5 mx-auto border-2 rounded-full text-lg text-white flex items-center ` +activeClass2}></div>
              </div>
  
              <div className="text-xs text-center font-bold">Owner Details</div>
            </div>
  
            
  
            <div className="w-1/3">
              <div className="relative mb-2">
                <div
                  className="absolute flex align-center items-center align-middle content-center wizard"
                  // style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)"
                >
                  <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                    <div
                      className={`w-full ${barActiveClass2} py-1 rounded`}
                      // style="width: 0%;"
                    ></div>
                  </div>
                </div>
  
                <div className={`w-5 h-5 mx-auto rounded-full border-2 text-lg text-white flex items-center `+activeClass3}>
                  {/* <span className="text-center text-gray-600 w-full">
                    <svg
                      className="w-full fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        className="heroicon-ui"
                        d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                      />
                    </svg>
                  </span> */}
                </div>
              </div>
  
              <div className="text-xs text-center font-bold">Summary</div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default CTPLWizard;
  