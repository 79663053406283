import { useState, useEffect } from "react";
import { TabButtons } from "../../molecules/TabButtons";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Covered from "../../molecules/Covered";
import PetPremium from "../../organisms/Pet-Covered/Premium";
import Header from "../../organisms/Header";
import Footer from "../../organisms/Footer";
import Modal from "../../molecules/Modal";
import { DataPrivacyAct } from "../../molecules/DataPrivacy";

const covered = [
  {
    id: "1",

    title: "Consultation Fee",
    imageUrl: "/images/pet/consultation.png",
  },
  {
    id: "2",

    title: "Hospitalization",
    imageUrl: "/images/pet/hospital.png",
  },
  {
    id: "3",

    title: "Surgery",
    imageUrl: "/images/pet/doctor.png",
  },
  {
    id: "4",
    title: "Diagnostic Test",
    imageUrl: "/images/pet/diagnostic.png",
  },
  {
    id: "5",

    title: "X-Rays",
    imageUrl: "/images/pet/x-rays.png",
  },
  {
    id: "6",
    title: "Medical Reimbursement",
    description: "(For Accidents and Illness Treatments)",
    imageUrl: "/images/pet/refund.png",
  },
  {
    id: "7",
    title: "Cruciate Ligament",
    description: "(Annual Limit up to the Benefit of Php 50,000.00)",
    imageUrl: "/images/pet/x-ray.png",
  },
];

const notCovered = [
  {
    id: "1",
    title: "Pre-Existing Conditions",
    imageUrl: "/images/pet/clipboard.png",
  },
  {
    id: "2",
    title: "Behavioral Problems",
    imageUrl: "/images/pet/thinking.png",
  },
  {
    id: "3",
    title: "Food and Diet, Grooming, and Pregnancy",
    imageUrl: "/images/pet/grooming.png",
  },
  {
    id: "4",
    title: "Conditions caused by ectoparasites, worms, ticks and fleas",
    imageUrl: "/images/pet/flea.png",
  },
  {
    id: "5",
    title: "Preventive Care and Treatments",
    imageUrl: "/images/pet/heart-beat.png",
  },
  {
    id: "6",
    title: "Dental Conditions",
    imageUrl: "/images/pet/toothbrush.png",
  },
];

const PetCovered = (props) => {
  let history = useHistory();
  const [display, setDisplay] = useState(false);
  const [coveredState, setCoveredState] = useState(0);
  const [tabSectionState, setTabSectionState] = useState([]);
  const [open, setOpen] = useState(false);

  const coveredSubTitle =
    "Pet Health Insurance will cover your pet in the following.";
  const notCoveredSubTitle =
    "Pet Health Insurance will not cover your pet in the following.";
  const premiumsSubTitle = "Pet Health Insurance Sample Premium Table";

  useEffect(() => {
    setDisplay(true);
    
  }, [])

  useEffect(() => {
    let tabList = [];

    tabList = [
      {
        title: "Covered",
        keyId: 0,
        status: "active",
      },
      {
        title: "Not Covered",
        keyId: 1,
        status: "inactive",
      },
      {
        title: "Premium",
        keyId: 2,
        status: "inactive",
      },
    ];

    setTabSectionState(tabList);
    Cache.setItem("flag", 0);
    window.dataLayer.push({
      event: "virtualPage", //eventTrigger
      pageUrl: "https://ginsure-uat.standard-insurance.com/pet-cover", //domain + url
      pageTitle: "PetCoveredDetails", //product + Module + title
    });
  }, []);

  const changeActiveTab = (e) => {
    let activeButton = e.target.value;
    let newList = [];
    tabSectionState.forEach((element) => {
      const tab = { ...element };
      tab.status =
        parseInt(element.keyId) == parseInt(activeButton)
          ? "active"
          : "inactive";
      newList.push(tab);
    });
    setTabSectionState(newList);
  };

  useEffect(() => {
    tabSectionState.forEach((element) => {
      if (element.status === "active") setCoveredState(element.keyId);
    });
  }, [tabSectionState]);

  const SectionState = () => {
    if (coveredState === 0) {
      return (
        <Covered
          covered={covered}
          subTitle={coveredSubTitle}
          display={display}
          title="covered"
          cover="inclusions"
          product="pet"
        />
      );
    } else if (coveredState === 1) {
      return (
        <Covered
          covered={notCovered}
          subTitle={notCoveredSubTitle}
          display={display}
          title="notCovered"
          cover="exclusions"
          product="pet"
        />
      );
    } else {
      return <PetPremium subTitle={premiumsSubTitle} />;
    }
  };

  const SubmitForm = () => {
    setOpen(true);
    Cache.setItem("coveredFlag",true);
  };
  const gotoFormPage = () => {
    history.push("/pet-insurance/forms");
  };
  const [isLoading, setIsLoading] = useState(0);
  const handleDataConfirmation = (event) => {
    const checked = event.target.checked;
    setIsLoading(1);
    if (checked === true) {
      Cache.setItem("flag", 1);
    } else {
      Cache.setItem("flag", 0);
    }
    setTimeout(() => {
      setIsLoading(0);
    }, 1000);
  };
  const DisableButton = (event) => {
    Cache.setItem("flag", 0);
  };
  return (
    <>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className={"main p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div>
      <main className={"main p-5"}>
        <div className="mb-8">
          <p>
            With Standard Insurance Pet Health, you are assured of financial
            protection and peace of mind knowing that your pet will get the
            treatments when needed.
          </p>
        </div>
        <TabButtons ListData={tabSectionState} onClick={changeActiveTab} />
        <SectionState />
      </main>
      <div className="mb-20 bg-white p-5 mt-5">
        <div className="relative flex">
          <div className="flex-1 flex">
            <div className="pl-3 pr-3">
              <div className="w-full">
                <img
                  src={"/images/help.png"}
                  alt="help"
                  className="covered-image"
                />
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg> */}
              </div>
            </div>
            <div className="w-full">
              <h4 className="font-black text-left text-sm title-font">
                Have More Questions
              </h4>
              <p className="text-left text-xs text-gray-400">
                We're here to help
              </p>

              <div className="mt-8 mb-3 text-left">
                <ul>
                  <Link
                    to={"/pet-faq"}
                    className="border-b border-gray-300 pb-2 block"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Frequently Asked Questions
                    </span>
                    <div className="absolute footer-position">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                  <Link
                    to={"/contact"}
                    className="border-b border-gray-300 pb-2 block mt-2"
                  >
                    <span className="text-blue-500 font-black text-xs">
                      Contact Us
                    </span>
                    <div className="absolute footer-position-contact">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        isMain={"1"}
        buttonType="button"
        buttonTitle={"GET POLICY"}
        saveData={SubmitForm}
      />
      <Modal
        open={open}
        setOpen={setOpen}
        title="Data Privacy"
        buttonTitle="SUBMIT"
        saveData={gotoFormPage}
        isDisabled={Cache.getItem("flag")}
        disableButton={DisableButton}
        isLoading={isLoading}
      >
        <form>
          <div className="">
            <div className="flex my-1">
              <div className="pr-3">
                <input type="checkbox" className="w-4 h-4" onChange={handleDataConfirmation}/>
              </div>
              <DataPrivacyAct />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PetCovered;
