import PlateNumberValidation from "../../components/organisms/Motor-Insurance/Plate-Number";

const PlateNumber = () => {
  return (
    <>
      <PlateNumberValidation />
    </>
  );
};

export default PlateNumber;
