import Header from "../../organisms/Header";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { petAPI } from "../../../api/fetch-api";
import { formatCurrency } from "../../utils/helper";

const PetQuotation = (props) => {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(0);
  const [premium, setPremium] = useState(props.quotationState);
  const [premiumTotal, setPremiumTotal] = useState(
    props.quotationState.premium
  );

  useEffect(() => {
    const petDetails = props.petDetailsState;
    console.log("petDetails ", petDetails, props.petDetailsState);
    var data = {
      policy: {
        inceptionDate: new Date(),
        package: "Standard",
        pets: [
          {
            breed: petDetails.breed,
            maternalBreed: petDetails.dam,
            paternalBreed: petDetails.sire,
            dateOfBirth: petDetails.bday,
            gender: petDetails.gender,
          },
        ],
      },
    };
    console.log(petDetails);
    //  if(premium.Value_PremiumAndTaxes === 0.00) {
    petAPI
      .getPetPremium(data)
      .then((response) => {
        console.log("Response Premium :", response);
        setPremium(response);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => console.log("Model Error: ", err));
    //  }
  }, []);

  const SaveForm = (e) => {
    e.preventDefault();
    props.saveData(premium);
  };
  return (
    <form onSubmit={SaveForm}>
      <main className="mb-24">
        <h3 className="text-lg font-bold title-font">Premium Breakdown</h3>
        <div>
          <div
            className="mb-5"
            // style={{
            //   backgroundImage: `url("/images/bg.png")`,
            //   backgroundRepeat: "no-repeat",
            //   width: "250px",
            // }}
          >
            <img
              alt="review"
              src={"/images/pet/quotation-banner.png"}
              className="image mx-auto mt-8 pet-banner"
            />
          </div>

          {!isLoading && (
            <div className="mt-5">
              <span className="text-base font-bold">Annual Premium</span>
              <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Basic Premium
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatCurrency
                        .format(premium.Value_RoundedBasicPremium)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">VAT</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatCurrency
                        .format(premium.Value_PremiumTax)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">DST</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">0.00</span>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">LGT</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatCurrency
                        .format(premium.Value_RoundedLGT)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>
                <hr className="my-3" />
                <div className="flex">
                  <div className="flex-1 text-left">
                    <span className="text-right text-sm font-bold">
                      Total Amount
                    </span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">PHP</span>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-sm">
                      {formatCurrency
                        .format(premium.Value_PremiumAndTaxes)
                        .replace("₱", "")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-5">
            <span className="text-base font-bold">
              Your Pet Health Insurance policy includes the following
              categories:
            </span>
            <div className="mt-3 bg-blue-50 p-3 rounded-lg">
              <ul className="text-sm p-2 list-disc">
                <li>
                  Accident and Illness Treatment (Subject to terms and
                  conditions of the policy)
                </li>
                <li>Cruciate Ligament</li>
                <li>Emergency Boarding</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <button type="submit" style={{ display: "none" }} id="btnSubmit">
        submit
      </button>
    </form>
  );
};

export default PetQuotation;
