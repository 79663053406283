import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/organisms/Header";
import Footer from "../../components/organisms/Footer";

import { Link } from "react-router-dom";
import CTPLPackages from "../../components/organisms/CTPL/packages";

function CTPLForm(props) {
  return <CTPLPackages headerTitle={props.headerTitle}/>
}
export default CTPLForm;
