import React, { useState } from "react";

const FaqClaimCTPL = () => {
  return (
    <>
      <div className="w-full">
        <input type="checkbox" name="panel" id="panel1" className="hidden" />
        <label
          htmlFor="panel1"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            When should I file my claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel1">
            <p className="text-sm">
              You can file a claim for third-party bodily injury immediately
              after the occurrence or up to 30 days.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-2" className="hidden" />
        <label
          htmlFor="panel-2"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How do I claim the benefits from my insurance?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel2">
            <p className="text-sm">
              You may file a claim by going to the Standard Insurance Online
              Claims Notification{" "}
              <a
                href="https://bit.ly/OnlineClaimNotification"
                target="_blank"
                className="text-yellow-500"
              >
                https://bit.ly/OnlineClaimNotification
              </a>
              . Once you have completed the form and attached the required
              documents, a representative of Standard Insurance will email you
              the next steps necessary to process your claim.
            </p>

            <p className="mt-3">
              Alternatively, you may also message, email or call the following:{" "}
            </p>
            <p className="mt-3 text-sm">
              Mobile Hotline Number: (632) 917-828-3984 (Avail from 8am to 5pm)
            </p>
            <p className="text-sm">
              24-Hour Customer Hotline Number: (632) 8845-1111
            </p>
            <p className="text-sm">
              Email Address:{" "}
              <span className="text-blue-500">
                ginsure@standard-insurance.com
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-3" className="hidden" />
        <label
          htmlFor="panel-3"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            What documents do I need to submit in filing a claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel3">
            <p className="text-sm">Basic Documents</p>
            <ul className="text-sm mt-3">
              <li>
                <strong>1. </strong>CCertificate of Registration (CR) and
                official receipt (OR) or CR covering loss date
              </li>
              <li>
                <strong>2. </strong>Photocopy of Driver's license and official
                receipt
              </li>
              <li>
                <strong>3. </strong>Police report
              </li>
              <li>
                {" "}
                <p>Additional documents/information (from third-party):</p>{" "}
              </li>
              <li>
                <strong>4. </strong> Original medical certificate
              </li>
              <li>
                <strong>5. </strong>Original hospital bills/receipts
              </li>
              <li>
                <strong>6. </strong>Birth certificate (for minor)
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-4" className="hidden" />
        <label
          htmlFor="panel-4"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            How long would it take before I receive my claim settlement?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel4">
            <p className="text-sm">
              Once you have filed a claim and have completed the required
              documents, it will be processed within one to two weeks.
              Complicated cases will be settled within one to two months.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-5" className="hidden" />
        <label
          htmlFor="panel-5"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I claim if my insured vehicle gets into an accident while being
            driven by another person?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel5">
            <p className="text-sm">
              Yes, your policy may respond if the insured vehicle gets into an
              accident while being driven by another person with the following
              conditions:
            </p>
            <ul className="text-sm mt-3">
              <li>
                a. You have authorized/permitted them to operate or drive your
                insured vehicle.
              </li>
              <li>
                b. The authorized/permitted driver carries a driver's license
                according to the licensing law.
              </li>
              <li>c. Subject to all other policy terms and conditions.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-6" className="hidden" />
        <label
          htmlFor="panel-6"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Can I get my claim settlement via cash mode?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel6">
            <p className="text-sm">
              Yes. We can process this based on our evaluation and computation
              if you prefer to receive your settlement via cash. Claims proceeds
              may be availed either through check or online transfer.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2">
        <input type="checkbox" name="panel" id="panel-7" className="hidden" />
        <label
          htmlFor="panel-7"
          className="relative block pb-2 px-1 pt-1 accordion-label text-base font-bold border-b-2"
        >
          <span className="inline-block w-11/12">
            Will there be fees or participation every time I file a claim?
          </span>
        </label>
        <div className="accordion__content overflow-hidden bg-grey-lighter">
          <div className="accordion__body px-3 pt-2 pb-5" id="panel7">
            <p className="text-sm">
              No. Unlike a Comprehensive Policy, CTPL does not require you to
              pay any participation.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqClaimCTPL;
