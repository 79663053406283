import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Cache } from "aws-amplify";

import { Link } from "react-router-dom";
function CTPLCoverType(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(0);
  const [year, setYear] = useState("");
  const ctpl = (val) => {
    console.log(val);
    if (val == "1") {
      Cache.setItem("cptl_year", 1);
      //history.push("/ctpl/covered")
      history.push("/ctpl/packages/?year=1&customerId="+Cache.getItem("customerId"));
    }else{
      Cache.setItem("cptl_year", 3);
      history.push("/ctpl/packages/?year=3&customerId="+Cache.getItem("customerId"));
    }
  };
  return (
    <>
      <div>
        <Header headerTitle={props.headerTitle} />
        <div className="absolute w-full">
          <img alt="motorcar" src={"/images/bg.png"} className="image" />
        </div>
        <div className={"main p-5 pt-12"}>
          <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
        </div>
        <main className={"main p-5"}>
          <p className="font-bold text-lg">Compulsary Third Party Liability</p>
          <div className="grid grid-cols-1 mb-10">
            <div className="max-w-md p-5 max-w-full bg-white shadow rounded-lg mt-5">
                <div className="flex">
                <button
                    className="w-full mx-auto"
                    type="button"
                    onClick={() => ctpl(3)}
                >
                    <div className="bg-gray-100 rounded-lg text-center p-5 mt-5 shadow border border-gray-100">
                        <h4 className="text-xl font-bold">Brand New</h4>
                        <span className="text-sm">3 year coverage</span>
                        </div>
                    
                </button>
                </div>
                <div className="flex">
                <button
                    type="button"
                    className="w-full mx-auto"
                    onClick={() => ctpl(1)}
                >
                    <div className="bg-gray-100 rounded-lg text-center p-5 mt-5 shadow">
                        <h4 className="text-xl font-bold">Renewal</h4>
                        <span className="text-sm">1 year coverage</span>
                        </div>
                </button>
                </div>
            </div>
          </div>
        </main>
        <div className="bg-white p-5 mt-5">
          <div className="relative flex">
            <div className="flex-1 flex">
              <div className="pl-3 pr-3">
                <div className="w-full">
                  <img
                    src={"/images/help.png"}
                    alt="help"
                    className="covered-image"
                  />
                </div>
              </div>
              <div className="w-full">
                <h4 className="font-black text-left text-sm">
                  Have More Questions
                </h4>
                <p className="text-left text-xs text-gray-400">
                  We're here to help
                </p>

                <div className="mt-8 mb-3 text-left">
                  <ul>
                    <Link
                      to={"/ctpl/faq"}
                      className="border-b border-gray-300 pb-2 block"
                    >
                      <span className="text-blue-500 font-black text-xs">
                        Frequently Asked Questions
                      </span>
                      <div className="absolute footer-position">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-blue-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </Link>
                    <Link
                      to={"/contact"}
                      className="border-b border-gray-300 pb-2 block mt-2"
                    >
                      <span className="text-blue-500 font-black text-xs">
                        Contact Us
                      </span>
                      <div className="absolute footer-position-contact">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-blue-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CTPLCoverType;
