import { Cache } from "aws-amplify";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../components/organisms/Footer";
import Header from "../components/organisms/Header";
import MotorCoverSection from "../components/organisms/MotorCoverSection";
import { gcashAPI, authAPI, authDetails } from "../api/fetch-api";

function MainPage(props) {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(1);
  const [isDisabled, setIsDisabled] = useState(1);
  const [open, setOpen] = useState(false);
  const EnableButton = () => {
    setIsDisabled(0);
  };
  const DisableButton = () => {
    setIsDisabled(1);
  };
  const SubmitForm = () => {
    setOpen(true);
  };

  const IdChecker = (id)=>{
    id = id.replaceAll(" ", "+");
    const whitelists = ["Sq399m+Gawv0W30F/oV2LgIFP2DeSU8nEjRdbMWa8rM=","/mdIRBQt6g3IRRebxlkHqtRyhAnaQcYw/efCUXEqFM4=", "/mdIRBQt6g3IRRebxlkHqkSN6jO6a3GkiE2L8SlZ5F4=", "/mdIRBQt6g3IRRebxlkHqsCspIKy1YfCF2wXulTotIY=","/mdIRBQt6g3IRRebxlkHqhrqG09gSyMAEjAtG7notTQ="];
    for (let index = 0; index < whitelists.length; index++) {
      const element = whitelists[index];
      if(id===element){
        return true;
      }
    }
    
    return false;
  };
  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  useEffect(() => {
    // if(iOS()){
    //   history.push("/coming-soon");
    // }
    console.log("test main page")
    const search = window.location.search;
    const params = new URLSearchParams(search);

    let customerId = params.get("customerId");

    Cache.setItem("unid", null);
    if (customerId === null){
    // if (customerId === null || IdChecker(customerId)===false) {
      history.push("/coming-soon");
      //history.push("/ctpl/packages");
    } else {
      customerId = customerId.replaceAll(" ", "%2B");
      console.log(customerId);
      authAPI
        .getAuth(authDetails)
        .then((data1) => {
          Cache.setItem("ginsure_token", data1.token);
          console.log("Token: ", data1.token);
          gcashAPI
            .getClient(customerId, data1.token)
            .then((data) => {
              setIsLoading(0);
              console.log("Customer information :", data);
              Cache.setItem("clientInfo", JSON.stringify(data));
              Cache.setItem("customerId", customerId);
              if (
                data.resultInfo.resultCode === "SYSTEM_ERROR" ||
                data.resultInfo.resultCode === "FAILED"
              ) {
                history.push("/500");
              }
            })
            .catch((error) => {
              console.log(error);
              console.log("There was an error!");
            });
        })
        .catch((error) => {
          console.log("error", error);
          console.log("There was an error!");
          history.push("/500");
        });
    }
    
  }, []);
  return (
    <div>
      <Header headerTitle={props.headerTitle} />
      <div
        className="absolute w-full"
        // style={{
        //   backgroundImage: `url("/images/bg.png")`,
        //   backgroundRepeat: "no-repeat",
        //   width: "250px",
        // }}
      >
        <img alt="motorcar" src={"/images/bg.png"} className="image" />
      </div>
      <div className={"main p-5 pt-12"}>
        <img src="/images/sici-logo.png" width="170" height="50" alt="logo" />
      </div>
      <main className={"main p-5"}>
        <div className="mb-16">
          <MotorCoverSection
            enableButton={EnableButton}
            disableButton={DisableButton}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </main>
      <Footer
        isMain={"1"}
        buttonType="button"
        saveData={SubmitForm}
        isLoading={isLoading}
        isDisabled={isDisabled}
        buttonTitle={"Get Started"}
      />
    </div>
  );
}
export default MainPage;
